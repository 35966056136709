import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  PeerGroupAssessmentReviewsQuery,
  PeerGroupAssessmentReviewsQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { UserAssessmentReview } from "@libs/service/graphql/generated/types";

const query = loader(
  "../../lib/service/graphql/query/peerGroupAssessmentReviews.graphql"
);

export const createGetPeerGroupAssessmentReviewsCacheKey = (
  body: PeerGroupAssessmentReviewsQueryVariables
) => `getPeerGroupAssessmentReviews?${qs.stringify(body)}`;

const usePeerGroupAssessmentReviews = (
  challengeId: string,
  milestoneId: string,
  peerGroupId: string
) => {
  const { apigatewayClient: client } = useService();
  const reqBody: PeerGroupAssessmentReviewsQueryVariables = {
    challengeId,
    milestoneId,
    peerGroupId,
  };
  const isEnabled = client && challengeId && milestoneId && peerGroupId;
  const { data, error } = useSWR(
    isEnabled ? createGetPeerGroupAssessmentReviewsCacheKey(reqBody) : null,
    () =>
      client
        ?.request<
          PeerGroupAssessmentReviewsQuery,
          PeerGroupAssessmentReviewsQueryVariables
        >(query, reqBody)
        .then((result) => {
          return result.peerGroupAssessmentReviews;
        })
  );

  return {
    peerGroupAssessments: data as UserAssessmentReview[],
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default usePeerGroupAssessmentReviews;

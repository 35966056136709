import { APP_ROUTES, UserDetailsTab } from "@/routes";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { FC, memo, useMemo } from "react";

import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import useProgressBySubs from "@hooks/query/useProgressBySubs";

import {
  IdAndStatusType,
  ProgressTypeEnum,
  StaticChallenge,
} from "@libs/service/graphql/generated/types.d";
import { UserProfile } from "@libs/service/user-graphql/generated/types";
import { sift } from "@libs/utils/array";

import CourseStatusColumn from "./components/CourseStatusColumn";

type UserProfileWithChallenges = UserProfile & {
  soloChallenges: StaticChallenge[];
  groupChallenge: StaticChallenge;
};

const SOLO_CHALLENGE_PREFIX = "soloChallenge:";

type Props = {
  learners: UserProfile[];
  soloChallenges: StaticChallenge[];
  groupChallenge: StaticChallenge;
};
const CourseOverviewTable: FC<Props> = ({
  learners,
  soloChallenges,
  groupChallenge,
}) => {
  const challengesProgress = [
    ...soloChallenges.map((item) => ({
      id: item.id as string,
      type: ProgressTypeEnum.SoloChallenge,
    })),
  ];
  if (groupChallenge) {
    challengesProgress.push({
      id: groupChallenge.id as string,
      type: ProgressTypeEnum.GroupChallenge,
    });
  }

  const progress = useProgressBySubs(
    learners?.map((item) => item.userId),
    challengesProgress
  );
  const populatedLearners = useMemo(() => {
    return learners?.map((learner) => {
      const learnerProgress = find(progress?.data, { sub: learner.userId });
      const soloChallengesColumn: { [key: string]: IdAndStatusType } = {};

      if (!isEmpty(learnerProgress?.soloChallenges)) {
        for (const sc of learnerProgress?.soloChallenges as IdAndStatusType[]) {
          soloChallengesColumn[`${SOLO_CHALLENGE_PREFIX}${sc.id}`] = sc;
        }
      }
      return {
        ...learner,
        ...soloChallengesColumn,
        name: learner.name || learner.userId,
        groupChallenge: last(learnerProgress?.groupChallenges),
      };
    }) as UserProfileWithChallenges[];
  }, [learners, progress?.data]);

  const challenges = useMemo(() => {
    return sift([...soloChallenges, groupChallenge]) as StaticChallenge[];
  }, [soloChallenges, groupChallenge]);

  const columns = useMemo(() => {
    const challengesColumns = challenges.map((challenge, i) => {
      const isGroupChallenge = groupChallenge && i === challenges.length - 1;

      const base = {
        width: "250px",
        render: (props: IdAndStatusType, record: UserProfileWithChallenges) => (
          <CourseStatusColumn
            {...props}
            isLoading={progress.isLoading}
            onClick={() => {
              window.open(
                APP_ROUTES.USER_DETAIL_TAB(
                  record?.userId,
                  isGroupChallenge
                    ? UserDetailsTab.GROUP_CHALLENGES
                    : UserDetailsTab.SOLO_CHALLENGES,
                  isGroupChallenge
                    ? `:${challenge.id}`
                    : (challenge?.id as string)
                )
              );
            }}
          />
        ),
      };

      if (isGroupChallenge) {
        return {
          title: "Group Challenge",
          dataIndex: `groupChallenge`,
          ...base,
        };
      }

      return {
        title: challenge?.name,
        dataIndex: `${SOLO_CHALLENGE_PREFIX}${challenge?.id}`,
        key: `${SOLO_CHALLENGE_PREFIX}${challenge?.id}`,
        ...base,
      };
    }) as GenericTableColumn<UserProfileWithChallenges>;

    return [
      {
        title: "Learner",
        dataIndex: "sub",
        key: "sub",
        width: "200px",
        render: (key, record) => {
          return <span key={key}>{record?.name}</span>;
        },
      },
      ...challengesColumns,
    ] as GenericTableColumn<UserProfileWithChallenges>;
  }, [challenges, groupChallenge, progress.isLoading]);

  return (
    <GenericTable<UserProfileWithChallenges>
      loading={progress.isLoading}
      dataSource={populatedLearners}
      scroll={{ x: "auto" }}
      columns={columns}
      rowKey={(item: UserProfileWithChallenges) =>
        `course-table-row-${item.userId}`
      }
      pagination={false}
    />
  );
};

export default memo(CourseOverviewTable);

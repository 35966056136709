import { APP_ROUTES } from "@/routes";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import find from "lodash/find";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import { FC, memo, useMemo } from "react";
import { Link } from "react-router-dom";

import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import useProgressBySubs from "@hooks/query/useProgressBySubs";

import {
  ChallengeProgress,
  ProgressTypeEnum,
} from "@libs/service/graphql/generated/types.d";
import { UserProfile } from "@libs/service/user-graphql/generated/types";
import { UserChallengeWithStatusState } from "@libs/types/types";

import UserChallengeStatusColumn from "@views/PeerGroup/components/UserChallengeStatusColumn";

import { populateUserChallengesWithStatusState } from "../PeerGroup/helpers/populate-user-challenges-with-status-state";

type UserProfileWithChallenges = UserProfile & {
  challenge: UserChallengeWithStatusState;
};

type Props = {
  challengeId: string;
  learners: UserProfile[];
};
const ChallengeOverviewTable: FC<Props> = ({ challengeId, learners }) => {
  const progress = useProgressBySubs(
    learners?.map((item) => item.userId),
    [{ id: challengeId, type: ProgressTypeEnum.Challenge }]
  );
  const populatedLearners = useMemo(() => {
    return learners?.map((learner) => {
      const learnerProgress = find(progress?.data, { sub: learner.userId });
      let challenges: ChallengeProgress[] = [];
      if (!isEmpty(learnerProgress?.challenges)) {
        challenges = populateUserChallengesWithStatusState(
          learnerProgress?.challenges as ChallengeProgress[]
        );
      }
      return {
        ...learner,
        name: learner.name || learner.userId,
        challenge: first(challenges),
      };
    }) as UserProfileWithChallenges[];
  }, [learners, progress?.data]);

  const columns = useMemo(() => {
    return [
      {
        title: "Learner",
        dataIndex: "sub",
        key: "sub",
        render: (key, record) => {
          return <span key={key}>{record?.name}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "challenge.statusState",
        key: "challenge.statusState",
        width: "200px",
        render: (_, record) => {
          const value = record.challenge?.statusState;
          return (
            <UserChallengeStatusColumn
              statusState={value}
              isLoading={progress.isLoading}
            />
          );
        },
      },
      {
        title: "Milestones",
        dataIndex: "challenge.milestones",
        key: "challenge.milestones",
        width: "200px",
        render: (_, record) => {
          if (!record?.challenge?.peerGroupId) {
            return;
          }
          const value = record.challenge?.milestones || [];
          let completed = 0;
          value?.forEach((milestone) =>
            milestone?.isMilestoneComplete ? completed++ : null
          );
          return (
            <span>
              {progress.isLoading && <SyncOutlined spin />}
              {!progress.isLoading && `${completed} of ${value?.length}`}
            </span>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "userId",
        width: "100px",
        key: "userId",
        render: (userId, record) => {
          const hasPeerGroupId = !!record?.challenge?.peerGroupId;
          const viewDetailsButton = (
            <Button disabled={!hasPeerGroupId}>View details</Button>
          );
          return (
            <Space>
              {!hasPeerGroupId && (
                <Tooltip title="No peer group id available">
                  {viewDetailsButton}
                </Tooltip>
              )}

              {hasPeerGroupId && record?.challenge?.id && (
                <Link
                  target="_blank"
                  to={APP_ROUTES.CHALLENGE_MILESTONES(
                    record?.challenge?.id,
                    record?.challenge?.peerGroupId as string,
                    userId
                  )}
                >
                  {viewDetailsButton}
                </Link>
              )}
            </Space>
          );
        },
      },
    ] as GenericTableColumn<UserProfileWithChallenges>;
  }, [progress.isLoading]);

  return (
    <GenericTable<UserProfileWithChallenges>
      loading={progress.isLoading}
      dataSource={populatedLearners}
      columns={columns}
      rowKey={(item: UserProfileWithChallenges) =>
        `challenge-table-row-${item.userId}`
      }
      pagination={false}
    />
  );
};

export default memo(ChallengeOverviewTable);

function createDataSoloChallenge(
  id: string,
  student: string,
  title: string,
  description: string,
  status: string
) {
  return { id, student, title, description, status };
}

const rowsSoloChallenge = [
  createDataSoloChallenge(
    "1",
    "Priscila Flores",
    "Solo Challenge 1",
    "Solo Challenge description 1",
    "SUBMITTED"
  ),
  createDataSoloChallenge(
    "2",
    "Heru",
    "Solo Challenge 2",
    "Solo Challenge description 2",
    "RE_SUBMITTED"
  ),
  createDataSoloChallenge(
    "3",
    "Tonny",
    "Solo Challenge 3",
    "Solo Challenge description 3",
    "GRADED_PASSED"
  ),
  createDataSoloChallenge(
    "4",
    "Alex",
    "Solo Challenge 4",
    "Solo Challenge description 4",
    "GRADED_PASSED"
  ),
  createDataSoloChallenge(
    "5",
    "Heru",
    "Solo Challenge 5",
    "Solo Challenge description 5",
    "RE_SUBMITTED"
  ),
  createDataSoloChallenge(
    "6",
    "Hasan",
    "Solo Challenge 6",
    "Solo Challenge description 6",
    "GRADED_PASSED"
  ),
  createDataSoloChallenge(
    "7",
    "Priscila Flores",
    "Solo Challenge 7",
    "Solo Challenge description 7",
    "REVIEWED"
  ),
  createDataSoloChallenge(
    "8",
    "Heru",
    "Solo Challenge 8",
    "Solo Challenge description 8",
    "UNREVIEWED"
  ),
  createDataSoloChallenge(
    "9",
    "Tonny",
    "Solo Challenge 9",
    "Solo Challengee description 9",
    "GRADED_PASSED"
  ),
];

export default rowsSoloChallenge;

import * as yup from "yup";
import { AssertsShape, ObjectShape, TypeOfShape } from "yup/lib/object";
import { AnyObject, Maybe, Optionals } from "yup/lib/types";

yup.addMethod<yup.StringSchema>(yup.string, "emptyAsUndefined", function () {
  return this.transform((value) => (value ? value : undefined));
});

yup.addMethod<yup.NumberSchema>(yup.number, "emptyAsUndefined", function () {
  return this.transform((value, originalValue) =>
    String(originalValue)?.trim() ? value : undefined
  );
});

// TODO: The `any` type should be replaced with the `Date` type
// from `lib.es5.d.ts` instead of `lib.es2015.symbol.wellknown.d.ts`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.addMethod<yup.ObjectSchema<{ dueDate: any }>>(
  yup.object,
  "milestoneDueDates",
  function () {
    return this.test("milestones-due-date", (item, ctx) => {
      const { options, path, parent, createError } = ctx;
      const index = (options as { index: number })?.index;

      if (index > 0 && item.dueDate) {
        const prevItem = parent?.[index - 1];
        const isDateLess =
          item.dueDate.getTime() < new Date(prevItem.dueDate).getTime();

        if (isDateLess) {
          return createError({
            message:
              "Due date can't be before the one of the previous milestone",
            path: `${path}.dueDate`,
          });
        }
      }

      if (index < parent.length - 1 && item.dueDate) {
        const nextItem = parent?.[index + 1];
        const isDateMore =
          item.dueDate.getTime() > new Date(nextItem.dueDate).getTime();

        if (isDateMore) {
          return createError({
            message:
              "Due date can't be after the one of the following milestone",
            path: `${path}.dueDate`,
          });
        }
      }

      return true;
    });
  }
);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): NumberSchema<TType, TContext>;
  }

  interface ObjectSchema<
    TShape extends ObjectShape,
    TContext extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>> = TypeOfShape<TShape>,
    TOut extends Maybe<AssertsShape<TShape>> =
      | AssertsShape<TShape>
      | Optionals<TIn>
  > extends yup.BaseSchema<TIn, TContext, TOut> {
    milestoneDueDates(): ObjectSchema<TShape, TContext>;
  }
}

export default yup;

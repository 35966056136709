import { GraphQLClient } from "graphql-request";
import { loader } from "graphql.macro";

import { CurriculaQuery } from "./graphql/generated/operations";
import { CurriculumEntity } from "./graphql/generated/types";
import { SWRPayload } from "./graphql/swrpayload";

const curriculaQuery = loader("./graphql/query/curricula.graphql");

type CurriculaResult = CurriculumEntity[];

export function curriculaRequest(
  client: GraphQLClient | undefined
): SWRPayload<CurriculaResult> {
  return [
    () => (client ? (curriculaQuery.loc?.source.body as string) : null),
    async () => {
      const result = await client?.request<CurriculaQuery>(curriculaQuery);
      return result?.curricula?.data as CurriculaResult;
    },
  ];
}

import colors from "@css/colors.json";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Button as AntButton, Modal, notification } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import GradeSelect from "@components/GradeSelect";
import IconSlack from "@components/IconSlack";
import AssignMentorGroup from "@components/challenges/AssignMentorGroup";
import AssignUserGroup from "@components/challenges/AssignUserGroup";
import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import {
  ChallengeReviewGradingStatus,
  SoloChallengeCriterionEvaluationInput,
  StudentProfile,
} from "@libs/service/graphql/generated/types.d";
import {
  fetchGroupChallengeDetail,
  fetchGroupOfGroupChallengeDetail,
  submitGroupReview,
} from "@libs/service/groupChallenge";
import {
  removeMentorFromGroup,
  removeStudentFromGroup,
} from "@libs/service/groupChallenge";
import { URLParams } from "@libs/types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "68px",
    },
    title: {
      textAlign: "left",
    },
    fieldLabel: {
      fontSize: "16px",
      fontWeight: 600,
      textAlign: "left",
      textTransform: "capitalize",
    },
    alertMessage: {
      fontSize: "16px",
      fontWeight: 600,
      textAlign: "center",
      color: colors.primaryOrange,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    listTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sendIcon: {
      color: "#848382",
      paddingLeft: "200px",
      paddingTop: "5px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "50px",
    },
    divAlign: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginLeft: "20px",
    },
    h3: {
      color: "#848382",
      textAlign: "left",
    },
    h2: {
      color: "#494746",
      fontSize: "30px",
      textAlign: "left",
      fontFamily: "TTNormsProMedium",
    },
    p1: {
      color: "#494746",

      textAlign: "left",
    },
    p2: {
      color: "#848382",
      textAlign: "left",
      width: "80%",
      fontSize: "18px",
    },
    centralizeDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    divContent: {
      width: "50%",
    },
    button: {
      marginTop: theme.spacing(3),
      backgroundColor: "#fad585",
    },
    formContainer: {
      marginTop: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flexStart",
      width: "100%",
    },
    formCard: {
      marginTop: "16px",
    },
    btnAction: {
      marginLeft: "4px",
      marginTop: "8px",
    },
  })
);
enum modalType {
  mentor = "mentor",
  member = "member",
  mentorConfirm = "mentorConfirm",
  memberConfirm = "memberConfirm",
}
export type State = {
  gradeCriteria: Array<SoloChallengeCriterionEvaluationInput>;
  alertMessage: string;
  isFormError: boolean;
  isSubmitting: boolean;
  showModal: {
    type: modalType;
    value: boolean;
  };
  shouldFetch: boolean;
  selectedUserId: string;
};
export default function GroupItemDetail() {
  const styles = useStyles();
  const { id, groupID: selectedGroup } = useParams<URLParams>();
  const history = useHistory();
  const groupChallengeID = base64.decode(id);
  const groupID = base64.decode(selectedGroup);

  const { apigatewayClient: client } = useService();
  const [state, setState] = React.useState<State>({
    gradeCriteria: [],
    alertMessage: "",
    isFormError: false,
    isSubmitting: false,
    showModal: {
      type: modalType.member,
      value: false,
    },
    shouldFetch: true,
    selectedUserId: "",
  });

  const setConfirmModal = (
    val: boolean,
    modalType: modalType,
    userId: string
  ) => {
    setState({
      ...state,
      selectedUserId: userId,
      showModal: {
        type: modalType,
        value: val,
      },
    });
  };

  // TODO: refactor to use custom hook.
  const { data: groupOfGroupChallengeData, error: groupOfGroupChallengeError } =
    useSWR(
      (client &&
        fetchGroupOfGroupChallengeDetail(
          client as GraphQLClient,
          groupChallengeID,
          groupID
        ).cacheKey) ||
        null,
      (client &&
        fetchGroupOfGroupChallengeDetail(
          client as GraphQLClient,
          groupChallengeID,
          groupID
        ).fetch) ||
        null
    );

  React.useEffect(() => {
    if (
      groupOfGroupChallengeData &&
      groupOfGroupChallengeData.group &&
      state.gradeCriteria.length === 0
    ) {
      const storedReviews: Array<SoloChallengeCriterionEvaluationInput> = [];
      const groupReview = groupOfGroupChallengeData.group.reviews[0];
      groupReview &&
        groupReview.criteria &&
        groupReview.criteria.forEach((val) => {
          const grade =
            val.gradingStatus === ChallengeReviewGradingStatus.GradedPassed
              ? 1
              : 5;
          storedReviews.push({
            id: val.id ?? "",
            grade: grade.toString(),
            comment: val.comment,
          });
        });
      if (
        groupReview &&
        groupReview.criteria &&
        groupReview.criteria.length > 0
      ) {
        setState({ ...state, gradeCriteria: storedReviews });
      }
    }
  }, [groupOfGroupChallengeData, state]);

  const { data: groupChallengeData, error: groupChallengeError } = useSWR(
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .cacheKey) ||
      null,
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .fetch) ||
      null
  );
  const formErrorMessage = "Please filled up all of input fields to Submit.";
  const handleSubmitReview = (
    groupId: string,
    groupOfGroupChallengeId: string,
    criteria: Array<SoloChallengeCriterionEvaluationInput>
  ) => {
    submitGroupReview(
      client as GraphQLClient,
      groupId,
      groupOfGroupChallengeId,
      criteria
    )
      .then(() => {
        setState({ ...state, alertMessage: "Data saved!" });
      })
      .catch(() => {
        setState({
          ...state,
          alertMessage: "Failed to save data. Please try again later.",
        });
      });
  };

  if (groupChallengeError) return <p>Error</p>;
  const gdriveUrl =
    groupOfGroupChallengeData &&
    groupOfGroupChallengeData.group &&
    groupOfGroupChallengeData.group.googleDriveId;

  const columns: GenericTableColumn<StudentProfile> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        return (
          <AntButton
            danger
            onClick={() => {
              setConfirmModal(true, modalType.memberConfirm, record.id ?? "");
            }}
          >
            remove
          </AntButton>
        );
      },
    },
  ];
  const columnsMentor: GenericTableColumn<StudentProfile> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        return (
          <AntButton
            danger
            onClick={() => {
              setConfirmModal(true, modalType.mentorConfirm, record.id ?? "");
            }}
          >
            remove
          </AntButton>
        );
      },
    },
  ];

  const handleRemove = (
    sub: string,
    groupChallengeID: string,
    groupInstanceId: string
  ) => {
    setState({ ...state, isSubmitting: true });
    const removeAction =
      state.showModal.type === modalType.memberConfirm
        ? removeStudentFromGroup
        : removeMentorFromGroup;
    removeAction(
      client as GraphQLClient,
      sub,
      groupChallengeID,
      groupInstanceId
    )
      .then(() => {
        notification.success({
          message: "Success",
          description: "Item has been successfully removed",
        });
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: "Oops!",
          description: "Unable to remove item, please try again later",
        });
      })
      .finally(() => {
        history.push(
          `/redirection?actionTo=group-challenge/${base64.encode(
            groupChallengeID
          )}/${base64.encode(groupID)}`
        );
      });
  };
  const modalComfirm =
    state.showModal.type === modalType.memberConfirm ||
    state.showModal.type === modalType.mentorConfirm;

  const members =
    groupOfGroupChallengeData && groupOfGroupChallengeData.group?.members
      ? groupOfGroupChallengeData.group?.members
      : [];
  const mentors =
    groupOfGroupChallengeData && groupOfGroupChallengeData.group?.mentors
      ? groupOfGroupChallengeData.group?.mentors
      : [];

  function assignStudent(modalType: modalType) {
    setState({ ...state, showModal: { type: modalType, value: true } });
  }

  function onAssignFinished(modalType: modalType) {
    setState({ ...state, showModal: { type: modalType, value: false } });
    history.push(
      `/redirection?actionTo=group-challenge/${base64.encode(
        groupChallengeID
      )}/${base64.encode(groupID)}`
    );
  }

  function onAssignError(modalType: modalType) {
    setState({ ...state, showModal: { type: modalType, value: false } });
  }

  if (groupOfGroupChallengeError) {
    return <p>Failed to fetching detail info.</p>;
  }

  return (
    <AppLayout
      onPressBack={() =>
        history.push(`/group-challenge/${base64.encode(groupChallengeID)}`)
      }
    >
      <Container className={styles.root}>
        {!groupChallengeData || !groupOfGroupChallengeData ? (
          <Typography
            style={{
              color: "#494746",
              textAlign: "left",
            }}
          >
            Loading...
          </Typography>
        ) : (
          <>
            <div className={styles.header}>
              <Typography
                variant="h5"
                style={{
                  color: "#494746",
                  textAlign: "left",
                }}
              >
                Group Of Group Challenge Detail
              </Typography>
              {groupOfGroupChallengeData &&
              groupOfGroupChallengeData.group &&
              groupOfGroupChallengeData.group.slackChannel ? (
                <a
                  rel="TomorrowEducation noreferrer"
                  href={groupOfGroupChallengeData.group.slackChannel ?? ""}
                  target="_blank"
                >
                  <IconSlack />
                </a>
              ) : (
                ""
              )}
            </div>
            <Card>
              <CardContent>
                <Paper elevation={0}>
                  <h3 className={styles.h3}>
                    {" "}
                    {groupChallengeData.attributes?.name}{" "}
                  </h3>
                  <p className={styles.p1}>
                    {" "}
                    {groupChallengeData.attributes?.description}
                  </p>

                  <div className={styles.centralizeDiv}>
                    <div className={styles.divContent}>
                      <Typography className={styles.title}>
                        Creation Date{" "}
                      </Typography>
                      <p className={styles.p2}>
                        {" "}
                        {groupChallengeData.attributes?.createdAt}{" "}
                      </p>

                      <Typography className={styles.title}>
                        Update Date:
                      </Typography>
                      <p className={styles.p2}>
                        {" "}
                        {groupChallengeData.attributes?.updatedAt}
                      </p>

                      <Typography className={styles.title}>
                        Deliverables{" "}
                      </Typography>
                      <p className={styles.p2}>
                        {" "}
                        {groupChallengeData.attributes?.deliverables}
                      </p>
                    </div>
                    <div className={styles.divContent}>
                      <Typography className={styles.title}>
                        Publish Date{" "}
                      </Typography>
                      <p className={styles.p2}>
                        {" "}
                        {groupChallengeData.attributes?.publishedAt}
                      </p>

                      <Typography className={styles.title}>
                        Evaluation Criteria{" "}
                      </Typography>
                      <p className={styles.p2}>
                        {groupChallengeData.attributes?.evaluationCriteria}
                      </p>
                    </div>
                  </div>
                </Paper>
                {gdriveUrl ? (
                  <div className={styles.divAlign}>
                    <div className={styles.divButton}>
                      <Button
                        variant="contained"
                        color="default"
                        type="button"
                        href={gdriveUrl ?? ""}
                        target="_blank"
                        className={styles.button}
                      >
                        {" "}
                        Open Google Drive Folder
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </CardContent>
            </Card>
            <Card className={styles.formCard}>
              <CardContent>
                <Typography className={styles.listTitle}>
                  Group Mentors
                  <AntButton
                    className={styles.btnAction}
                    onClick={() => assignStudent(modalType.mentor)}
                  >
                    Assign Mentors
                  </AntButton>
                </Typography>

                {mentors.length > 0 ? (
                  <GenericTable
                    columns={columnsMentor}
                    dataSource={mentors}
                    loading={!groupOfGroupChallengeData}
                    rowKey={(item: StudentProfile) =>
                      `${item.id}-${item.slackId}`
                    }
                  />
                ) : (
                  <p>{"No mentors assingned"}</p>
                )}
              </CardContent>
            </Card>
            <Card className={styles.formCard}>
              <CardContent>
                <Typography className={styles.listTitle}>
                  Group Member
                  <AntButton
                    className={styles.btnAction}
                    onClick={() => assignStudent(modalType.member)}
                  >
                    Assign Students
                  </AntButton>
                </Typography>

                {members.length > 0 ? (
                  <GenericTable
                    columns={columns}
                    dataSource={members as StudentProfile[]}
                    loading={!groupOfGroupChallengeData}
                    rowKey={(item: StudentProfile) =>
                      `${item.id}-${item.slackId}`
                    }
                  />
                ) : (
                  <p>{"No members assingned"}</p>
                )}
              </CardContent>
            </Card>

            {groupChallengeData &&
              groupChallengeData.attributes?.itemizedEvaluationCriteria &&
              groupChallengeData.attributes?.itemizedEvaluationCriteria.length >
                0 && (
                <Card className={styles.formCard}>
                  <CardContent>
                    <Typography className={styles.formGridTitle}>
                      Grade Criteria
                    </Typography>
                    {groupChallengeData.attributes &&
                      groupChallengeData.attributes?.itemizedEvaluationCriteria?.map(
                        (val, key) => {
                          return (
                            <Grid
                              className={styles.formContainer}
                              key={val?.id ?? "" + key}
                            >
                              <Typography className={styles.fieldLabel}>
                                {val?.content ?? ""}
                              </Typography>
                              <GradeSelect
                                criteriaID={val?.id ?? ""}
                                gradeCriteria={state.gradeCriteria}
                                setGradeCriteria={(criteria) =>
                                  setState({
                                    ...state,
                                    gradeCriteria: criteria,
                                  })
                                }
                              />
                            </Grid>
                          );
                        }
                      )}
                    <div className={styles.divAlign}>
                      {state.isFormError || state.alertMessage ? (
                        <Typography className={styles.alertMessage}>
                          {state.alertMessage}
                        </Typography>
                      ) : null}
                      <div className={styles.divButton}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const emptyForm = state.gradeCriteria.find(
                              (val) => val.comment === "" || val.grade === ""
                            );
                            const isFoundEmptyForm = emptyForm ? true : false;
                            const isEmpty =
                              isFoundEmptyForm ||
                              state.gradeCriteria.length === 0;
                            setState({
                              ...state,
                              alertMessage: isEmpty ? formErrorMessage : "",
                              isSubmitting: !isEmpty,
                              isFormError: isEmpty,
                            });
                            if (!isEmpty) {
                              handleSubmitReview(
                                groupChallengeID,
                                groupID,
                                state.gradeCriteria
                              );
                            }
                          }}
                          color="default"
                          disabled={state.isSubmitting}
                          type="button"
                          className={styles.button}
                        >
                          {state.isSubmitting
                            ? "Processing..."
                            : "Submit Review"}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}
            {groupOfGroupChallengeData.group && (
              <Modal
                title="Comfirmation"
                visible={state.showModal.value && modalComfirm}
                onOk={() =>
                  handleRemove(
                    state.selectedUserId,
                    groupChallengeID,
                    groupOfGroupChallengeData.group?.id ?? ""
                  )
                }
                onCancel={() =>
                  setConfirmModal(false, modalType.memberConfirm, "")
                }
                okText="Yes"
                okButtonProps={{ loading: state.isSubmitting }}
                cancelText="Cancel"
              >
                <p>Are you sure want to remove this item?</p>
              </Modal>
            )}

            {groupOfGroupChallengeData.group && (
              <AssignUserGroup
                show={
                  state.showModal.type === modalType.member &&
                  state.showModal.value
                }
                groupName={groupChallengeData.attributes?.name ?? ""}
                target={groupOfGroupChallengeData.group}
                groupChallengeID={groupChallengeID}
                onError={() => onAssignError(modalType.member)}
                onFinished={() => onAssignFinished(modalType.member)}
              />
            )}
            {groupOfGroupChallengeData.group && (
              <AssignMentorGroup
                show={
                  state.showModal.type === modalType.mentor &&
                  state.showModal.value
                }
                groupName={groupChallengeData.attributes?.name ?? ""}
                target={groupOfGroupChallengeData.group}
                groupChallengeID={groupChallengeID}
                onError={() => onAssignError(modalType.mentor)}
                onFinished={() => onAssignFinished(modalType.mentor)}
              />
            )}
          </>
        )}
      </Container>
    </AppLayout>
  );
}

import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  MilestoneByIdQuery,
  MilestoneByIdQueryVariables,
} from "@libs/service/graphql/generated/operations";

const GQL_QUERY = loader("../../lib/service/graphql/query/milestone.graphql");

const useMilestoneById = (id: string) => {
  const { apigatewayClient: client } = useService();

  const reqBody = { id };
  const { data, error } = useSWR(
    client && id ? `getMilestoneById?${qs.stringify(reqBody)}` : null,
    () =>
      client?.request<MilestoneByIdQuery, MilestoneByIdQueryVariables>(
        GQL_QUERY,
        reqBody
      )
  );
  return {
    milestone: data?.milestone?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useMilestoneById;

import { APP_ROUTES, ROUTE_QUERY_KEYS } from "@/routes";
import styles from "@css/common.module.scss";
import { useHistory, useParams } from "react-router-dom";

import useLocationQuery from "@hooks/useLocationQuery";

import AppLayout from "@layouts/AppLayout";

import {
  PeerGroupUserChallenges,
  PeerGroupUserMilestones,
} from "@views/PeerGroup";

const PeerGroupPage = () => {
  const { id: challengeId, peerGroupId } = useParams<{
    id: string;
    peerGroupId: string;
  }>();
  const history = useHistory();
  const query = useLocationQuery();

  const userId = query.get(ROUTE_QUERY_KEYS.USER_ID) as string;
  const isViewMilestones = !!userId;

  const handlePressBack = () => {
    if (isViewMilestones) {
      return history.push(
        APP_ROUTES.CHALLENGE_PEER_GROUP(challengeId, peerGroupId)
      );
    }

    return history.push(APP_ROUTES.CHALLENGE_DETAIL(challengeId));
  };

  return (
    <AppLayout onPressBack={handlePressBack}>
      <div className={styles.root}>
        {isViewMilestones && (
          <PeerGroupUserMilestones
            peerGroupId={peerGroupId}
            challengeId={challengeId}
            userId={userId}
          />
        )}
        {!isViewMilestones && (
          <PeerGroupUserChallenges
            challengeId={challengeId}
            peerGroupId={peerGroupId}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default PeerGroupPage;

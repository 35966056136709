import { loader } from "graphql.macro";
import { useState } from "react";
import { mutate } from "swr";

import {
  CreatePeerGroupMutation,
  CreatePeerGroupMutationVariables,
} from "@libs/service/graphql/generated/operations";

import { useService } from "../lib/hooks/useService";

const createPeerGroupQuery = loader(
  "../lib/service/graphql/mutation/createPeerGroup.graphql"
);

const useCreatePeerGroup = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleMutate = async (variables: CreatePeerGroupMutationVariables) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        CreatePeerGroupMutation,
        CreatePeerGroupMutationVariables
      >(createPeerGroupQuery, variables);
      mutate(`getPeerGroups?challengeId=${variables.challengeId}`);
      return result?.createPeerGroup;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    mutate: handleMutate,
  };
};

export default useCreatePeerGroup;

import { Form, FormItemProps, Input, InputProps } from "antd";
import { PropsWithChildren } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { controllerToFormItemProps } from "@libs/utils/hookform-antd";

type InputControllerProps<FormValues> = FormItemProps &
  InputProps &
  Omit<ControllerProps<FormValues>, "render">;

function InputController<FormValues extends Record<string, unknown>>({
  control,
  name,
  placeholder,
  ...restProps
}: PropsWithChildren<InputControllerProps<FormValues>>) {
  return (
    <Controller<FormValues>
      control={control}
      name={name}
      render={({ field, formState }) => (
        <Form.Item
          {...controllerToFormItemProps({ field, formState }, restProps)}
        >
          <div className="input">
            <Input
              placeholder={placeholder}
              {...field}
              value={field.value as string}
            />
          </div>
        </Form.Item>
      )}
    />
  );
}

export default InputController;

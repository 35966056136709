import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {},
});
export default function IconSlack({
  color = "#808080",
}: {
  color?: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <span className={classes.icon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0803 9.00001C19.8103 1.41001 16.5403 -0.349994 9.00026 1.92001C1.46026 4.19001 -0.349739 7.46001 1.92026 15C4.19026 22.54 7.46026 24.35 15.0003 22.08C22.5403 19.81 24.3503 16.54 22.0803 9.00001Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5703 5.98999L16.1503 16.39"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.8501 7.60999L11.4301 18.01"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3902 7.84998L5.99023 11.43"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0099 12.57L7.60986 16.15"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

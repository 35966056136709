import { SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { FC } from "react";

import { UserChallengeStatusState } from "@libs/types/types";

type Props = { statusState: UserChallengeStatusState; isLoading?: boolean };
const UserChallengeStatusColumn: FC<Props> = ({ statusState, isLoading }) => {
  let tagAttrs = {};
  switch (statusState) {
    case UserChallengeStatusState.IN_PROGRESS:
      tagAttrs = { ...tagAttrs, color: "blue", children: "IN PROGRESS" };
      break;
    case UserChallengeStatusState.COMPLETED:
      tagAttrs = { ...tagAttrs, color: "green", children: "COMPLETED" };
      break;
    case UserChallengeStatusState.TO_BE_ASSESSED:
      tagAttrs = { ...tagAttrs, color: "orange", children: "TO BE ASSESSED" };
      break;
    case UserChallengeStatusState.NOT_ACHIEVED:
      tagAttrs = { ...tagAttrs, color: "red", children: "NOT ACHIEVED" };
      break;
    case UserChallengeStatusState.NO_PEER_GROUP:
      tagAttrs = { ...tagAttrs, color: "yellow", children: "NO PEER GROUP" };
      break;
    default:
      tagAttrs = { color: "default", children: "NOT STARTED" };
      break;
  }

  if (isLoading) {
    return <Tag icon={<SyncOutlined spin />}></Tag>;
  }

  return <Tag {...tagAttrs} />;
};

export default UserChallengeStatusColumn;

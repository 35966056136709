import { SyncOutlined } from "@ant-design/icons";
import { Tag, TagProps } from "antd";

import {
  GroupChallengeGradingStatus,
  IdAndStatusType,
  SoloChallengeGradingStatus,
} from "@libs/service/graphql/generated/types.d";

type Props = IdAndStatusType & { isLoading?: boolean; onClick?: () => void };
const CourseStatusColumn = (props: Props) => {
  let color: TagProps["color"] = "default";
  let text = props?.status;
  switch (props?.status) {
    case SoloChallengeGradingStatus.UnSubmitted:
      color = "cyan";
      break;
    case SoloChallengeGradingStatus.Submitted:
    case GroupChallengeGradingStatus.Submitted:
    case SoloChallengeGradingStatus.ReSubmitted:
    case GroupChallengeGradingStatus.ReSubmitted:
      color = "purple";
      break;
    case SoloChallengeGradingStatus.GradedPassed:
    case GroupChallengeGradingStatus.GradedPassed:
    case SoloChallengeGradingStatus.PeerReviewed:
      color = "green";
      break;
    case SoloChallengeGradingStatus.GradeFailed:
    case SoloChallengeGradingStatus.GradeFailedReSubmittable:
    case GroupChallengeGradingStatus.GradeFailed:
    case GroupChallengeGradingStatus.GradeFailedReSubmittable:
      color = "red";
      break;
    default:
      text = "NOT DONE";
      break;
  }

  if (props.isLoading) {
    return <Tag icon={<SyncOutlined spin />}></Tag>;
  }

  return (
    <Tag style={{ cursor: "pointer" }} onClick={props?.onClick} color={color}>
      {text}
    </Tag>
  );
};

export default CourseStatusColumn;

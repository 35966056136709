import {
  WithAuthenticationRequiredOptions,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

import useRoles, { AuthRoleName } from "@hooks/auth/useAuthRoles";

import Unauthorized from "@pages/Unauthorized";

type Scope = AuthRoleName;
export const ProtectedRouteScope = AuthRoleName;

export type ProtectedRouteProps = {
  component: FC;
  scopes?: Scope[];
} & WithAuthenticationRequiredOptions;

export const ProtectedRoute = ({
  component,
  scopes,
  ...args
}: ProtectedRouteProps) => {
  const roles = useRoles();
  const { pathname, search } = useLocation();
  const currentLocation = pathname + search;

  const isRolesAllowed = useMemo(
    () => roles.some((role) => scopes?.includes(role.name as Scope)),
    [roles, scopes]
  );

  const Component = withAuthenticationRequired(
    isRolesAllowed ? component : () => <Unauthorized />,
    {
      loginOptions: { appState: { redirectPath: currentLocation } },
      ...args,
    }
  );

  return <Component />;
};

export default ProtectedRoute;

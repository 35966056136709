import { Button, Modal, Table, Tag, notification } from "antd";
import { GraphQLClient } from "graphql-request";
import React from "react";

import { GenericTableColumn } from "@components/organism/GenericTable";

import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import {
  SoloChallengeEntity,
  SoloChallengeEvaluation,
} from "@libs/service/graphql/generated/types";
import { initSoloChallengeRecord } from "@libs/service/solochallenge";

interface InitSoloChallengeRecordModalProps {
  show: boolean;
  onOk(): void;
  onCancel(): void;
  userId: string;
  nonGradedSoloChallenge: SoloChallengeEntity[];
  client: GraphQLClient | undefined;
  currentSoloChallengeProgress?: SoloChallengeEvaluation[];
}

const InitSoloChallengeRecordModal: React.FC<
  InitSoloChallengeRecordModalProps
> = (props) => {
  const { getColumnSearchProps } = useTableColumnSearch();

  async function initSoloChallengeRecordAction(
    target: SoloChallengeEntity,
    otherSub: string,
    currentSoloChallengeProgress?: SoloChallengeEvaluation[]
  ): Promise<void> {
    const progressFound = !!currentSoloChallengeProgress?.find(
      (currentProgress) =>
        !!currentProgress.gradingStatus && currentProgress.id == target.id
    );
    if (progressFound) {
      notification.warning({
        message: "Oops!",
        description: "Record already added",
      });
    }
    if (!progressFound && props.client && target?.id) {
      try {
        await initSoloChallengeRecord(props.client, target.id, otherSub);
        notification.success({
          message: "Success",
          description: "Record added",
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: "Error",
          description: err as React.ReactNode,
        });
      }
    }
  }
  const columnsSoloChallenge: GenericTableColumn<SoloChallengeEntity> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Graded",
      dataIndex: "graded",
      key: "graded",
      render: (text, record) =>
        record === null ? (
          <></>
        ) : record?.attributes?.graded ? (
          <Tag color="green">YES</Tag>
        ) : (
          <Tag color="red">NO</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Button
            onClick={() =>
              initSoloChallengeRecordAction(
                record,
                props.userId,
                props.currentSoloChallengeProgress
              )
            }
          >
            Add record
          </Button>
        );
      },
    },
  ];
  return (
    <Modal
      title="Init Solo Challenge Record"
      visible={props.show}
      onOk={props.onOk}
      onCancel={props.onCancel}
      centered
      width={1000}
      footer={null}
    >
      <Table<SoloChallengeEntity>
        columns={columnsSoloChallenge}
        dataSource={props.nonGradedSoloChallenge}
        rowKey={(item) => `${item.id}`}
        pagination={false}
        size="small"
        scroll={{ y: 500 }}
      />
    </Modal>
  );
};

export default InitSoloChallengeRecordModal;

import get from "lodash/get";

import {
  UserMilestone,
  UserMilestoneStatus,
} from "@libs/service/graphql/generated/types.d";
import {
  UserMilestoneStatusState,
  UserMilestoneType,
  UserMilestoneWithStateState,
} from "@libs/types/types";

export function populateUserMilestonesWithStatusState(
  milestones: UserMilestone[]
): UserMilestoneWithStateState[] {
  if (milestones?.length === 0) {
    return [];
  }

  // https://www.figma.com/file/8DCFLrPhyI8TarLPdkzzDK/Faculty-App?node-id=436%3A3530
  return milestones?.map((milestone) => {
    let statusState = UserMilestoneStatusState.TODO;

    const lessonsTotal = get(milestone, "lessons.total", 0);
    const lessonsCompleted = get(milestone, "lessons.completed", 0);
    const hasLessons = lessonsTotal > 0;
    const isLessonsDone = hasLessons && lessonsTotal === lessonsCompleted;

    const isCompleteStatus = [
      UserMilestoneStatus.Confirmed,
      UserMilestoneStatus.Submitted,
      UserMilestoneStatus.PeerReviewed,
    ].includes(milestone.status as UserMilestoneStatus);
    const isStatusNotAchieved =
      milestone.status === UserMilestoneStatus.NotAchieved;
    const isStatusAchieved = milestone.status === UserMilestoneStatus.Achieved;
    const isStatusResubmitted =
      milestone.status === UserMilestoneStatus.ReSubmitted;
    const isStatusPeerReviewed =
      milestone.status === UserMilestoneStatus.PeerReviewed;

    switch (milestone.milestoneType) {
      case UserMilestoneType.None:
        if (hasLessons && lessonsCompleted !== 0) {
          statusState = UserMilestoneStatusState.IN_PROGRESS;
        }

        if (isLessonsDone) {
          statusState = UserMilestoneStatusState.COMPLETED;
        }
        break;

      case UserMilestoneType.Confirmation:
      case UserMilestoneType.GroupConfirmation:
        if (isCompleteStatus) {
          statusState = hasLessons
            ? UserMilestoneStatusState.IN_PROGRESS
            : UserMilestoneStatusState.COMPLETED;
        } else if (!isCompleteStatus && isLessonsDone) {
          statusState = UserMilestoneStatusState.IN_PROGRESS;
        }

        if (isCompleteStatus && isLessonsDone) {
          statusState = UserMilestoneStatusState.COMPLETED;
        }
        break;

      case UserMilestoneType.Submission: // known as the PEER FEEDBACK
        if (hasLessons) {
          if (isLessonsDone || isCompleteStatus) {
            statusState = UserMilestoneStatusState.IN_PROGRESS;
          }

          if (isLessonsDone && isCompleteStatus) {
            statusState = UserMilestoneStatusState.COMPLETED;
          }
        } else {
          if (isCompleteStatus) {
            statusState = UserMilestoneStatusState.COMPLETED;
          }
        }
        break;

      case UserMilestoneType.Assessment:
      case UserMilestoneType.GroupAssessment:
        if (hasLessons && isLessonsDone) {
          statusState = UserMilestoneStatusState.IN_PROGRESS;
        }

        if (isCompleteStatus || isStatusResubmitted) {
          statusState = UserMilestoneStatusState.TO_BE_ASSESSED;
        }

        if (isStatusAchieved || isStatusPeerReviewed) {
          statusState = hasLessons
            ? UserMilestoneStatusState.IN_PROGRESS
            : UserMilestoneStatusState.COMPLETED;
        }

        if (isStatusNotAchieved) {
          statusState = UserMilestoneStatusState.NOT_ACHIEVED;
        }

        if (isLessonsDone && (isStatusAchieved || isStatusPeerReviewed)) {
          statusState = UserMilestoneStatusState.COMPLETED;
        }
        break;
    }

    return { ...milestone, statusState };
  });
}

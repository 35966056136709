import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR, { useSWRConfig } from "swr";

import { useService } from "@libs/hooks/useService";
import {
  CohortDashboardStructureQuery,
  CohortDashboardStructureQueryVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/query/cohortDashboardStructure.graphql"
);

const useCohortDashboardStructure = (curriculumId: string) => {
  const { apigatewayClient: client } = useService();
  const { mutate } = useSWRConfig();
  const reqBody: CohortDashboardStructureQueryVariables = {
    curriculumId,
  };
  const isEnabled = client && curriculumId;
  const key = `getCohortDashboardStructure?${qs.stringify(reqBody)}`;
  const { data, error } = useSWR(isEnabled ? key : null, () =>
    client
      ?.request<
        CohortDashboardStructureQuery,
        CohortDashboardStructureQueryVariables
      >(query, reqBody)
      .then((result) => {
        return result.cohortDashboardStructure;
      })
  );

  const revalidate = () => mutate(key);

  return {
    data,
    revalidate,
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default useCohortDashboardStructure;

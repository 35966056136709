import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";
import { ColumnType } from "antd/lib/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import get from "lodash/get";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";

type ColumnSearchPropsParams = FilterDropdownProps;

const useTableColumnSearch = () => {
  const searchInputRef = useRef(null);
  const [searchKey, setSearchKey] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/ban-types
  function getColumnSearchProps<T extends {}>(
    dataIndex: string,
    options?: {
      initialValue?: string;
      placeholder?: string;
      onReset?: () => void;
    }
  ): ColumnType<T> {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: ColumnSearchPropsParams) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={options?.placeholder ?? `Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setSearchKey(e.target.value);
            }}
            onPressEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              confirm({ closeDropdown: true });
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Divider style={{ margin: "10px 0" }} />
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Button
              size="small"
              onClick={() => {
                clearFilters?.();
                setSearchKey("");
                confirm({ closeDropdown: true });
                options?.onReset?.();
              }}
              disabled={searchKey?.length === 0 && !options?.initialValue}
              block={true}
              type="link"
            >
              Reset
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlined />}
              size="small"
              block={true}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFilter: (value: string | number | boolean, record: any) => {
        // TODO: change this implementation if server side filtering is needed
        const searchValue = get(record, dataIndex);
        return typeof value === "string" && searchValue
          ? searchValue.toString().toLowerCase().includes(value.toLowerCase())
          : "";
      },
      render: (text: string, record) => {
        const searchValue = get(record, dataIndex);
        const renderedText = typeof text === "string" ? text : searchValue;
        const searchText = searchKey || (options?.initialValue ?? "");
        return searchText?.length > 0 ? (
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={renderedText}
          />
        ) : (
          <span>{renderedText}</span>
        );
      },
    };
  }

  return {
    getColumnSearchProps,
  };
};

export default useTableColumnSearch;

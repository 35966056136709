import { Tag } from "antd";
import React from "react";

import { SoloChallengeGradingStatusLocal } from "@components/challenges/UserSoloChallenges/types";

import {
  ChallengeReviewGradingStatus,
  GroupChallengeGradingStatus,
  Maybe,
  SoloChallengeGradingStatus,
} from "@libs/service/graphql/generated/types.d";

interface ChallengeGradingStatusTagProps {
  status?:
    | Maybe<GroupChallengeGradingStatus>
    | Maybe<SoloChallengeGradingStatus>
    | Maybe<ChallengeReviewGradingStatus>
    | undefined;
}

const ChallengeGradingStatusTag: React.FC<ChallengeGradingStatusTagProps> = (
  props
) => {
  switch (props.status) {
    case SoloChallengeGradingStatus.UnSubmitted:
      return <Tag color="cyan">{props.status}</Tag>;
    case SoloChallengeGradingStatus.Submitted:
    case GroupChallengeGradingStatus.Submitted:
    case SoloChallengeGradingStatus.ReSubmitted:
    case GroupChallengeGradingStatus.ReSubmitted:
      return <Tag color={"purple"}>{props.status}</Tag>;
    case SoloChallengeGradingStatus.GradedPassed:
    case GroupChallengeGradingStatus.GradedPassed:
    case SoloChallengeGradingStatus.PeerReviewed:
      return <Tag color={"green"}>{props.status}</Tag>;
    case SoloChallengeGradingStatus.GradeFailed:
    case SoloChallengeGradingStatus.GradeFailedReSubmittable:
    case GroupChallengeGradingStatus.GradeFailed:
    case GroupChallengeGradingStatus.GradeFailedReSubmittable:
      return <Tag color={"red"}>{props.status}</Tag>;
    default:
      return <Tag>{SoloChallengeGradingStatusLocal.NO_RECORD}</Tag>;
  }
};

export default ChallengeGradingStatusTag;

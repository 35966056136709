import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import {
  ListLearnersForSameIntakeQuery,
  ListLearnersForSameIntakeQueryVariables,
} from "@libs/service/intake-graphql/generated/operations";
import {
  Enrolment,
  EnrolmentStatus,
  ModelEnrolmentFilterInput,
} from "@libs/service/intake-graphql/generated/types";
import client from "@libs/utils/intake-client";

const listLearnersForSameIntakeQuery = loader(
  "../../lib/service/intake-graphql/query/ListLearnersForSameIntake.graphql"
);

type FilterOptions = {
  status: EnrolmentStatus[];
};

const useListLearnersForSameIntake = (
  intakeId: string,
  filter?: FilterOptions
) => {
  // input: ['ADMITTED', 'COMPLETED']
  // output: [{ status: { eq: 'ADMITTED' } }, { status: { eq: 'COMPLETED' } }]
  const filterStatuses = filter?.status?.reduce<ModelEnrolmentFilterInput[]>(
    (acc, status) => [...acc, { status: { eq: status } }],
    []
  );

  const reqBody = {
    // TODO: just a quick workaround to display most of the learners data.
    limit: 1000,
    filter: {
      and: {
        intakeId: {
          eq: intakeId,
        },
        or: filterStatuses,
      },
    },
  };

  const isEnabled = client && intakeId;
  const { data, error } = useSWR(
    isEnabled
      ? `getListLearnersForSameIntake?${qs.stringify({
          intakeId,
          body: JSON.stringify(reqBody),
        })}`
      : null,
    () =>
      client?.request<
        ListLearnersForSameIntakeQuery,
        ListLearnersForSameIntakeQueryVariables
      >(listLearnersForSameIntakeQuery, reqBody)
  );

  return {
    data: data?.listEnrolments?.items as Enrolment[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useListLearnersForSameIntake;

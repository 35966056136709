import yup from "@/yup-extended";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal, Skeleton, notification } from "antd";
import { first, isEmpty } from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import useUpdatePeerGroupDueDates from "@hooks/mutation/useUpdatePeerGroupDueDates";
import useUserChallengesByPeerGroupId from "@hooks/query/useUserChallengesByPeerGroupId";
import { UseDisclosureReturn } from "@hooks/useDisclosure";

import MilestoneDueDatesFieldArray, {
  MilestoneDueDateInput,
  milestoneDueDatesValidation,
} from "./MilestoneDueDatesFieldArray";

type Props = UseDisclosureReturn & {
  challengeId: string;
  peerGroupId: string;
};

type FormValues = {
  milestones: MilestoneDueDateInput[];
};

const validationSchema = yup
  .object()
  .shape({
    milestones: milestoneDueDatesValidation,
  })
  .required();

const EditDueDatesModal: FC<Props> = ({
  isOpen,
  onClose,
  challengeId,
  peerGroupId,
}) => {
  const updateDueDates = useUpdatePeerGroupDueDates();
  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      milestones: [],
    },
  });
  const {
    userChallenges,
    isLoading: isUserChallengesLoading,
    revalidate: revalidateUserChallenges,
  } = useUserChallengesByPeerGroupId(challengeId, peerGroupId);

  const milestonesInitialValue = useMemo(() => {
    if (!userChallenges) return [];
    const milestones = first(userChallenges)?.milestones;
    return milestones?.map((item) => ({
      milestoneId: item?.id,
      dueDate: item?.dueDate,
      isCapstone: item?.isCapstone,
    })) as MilestoneDueDateInput[];
  }, [userChallenges]);

  const onSubmit = handleSubmit(async (values) => {
    try {
      await updateDueDates.mutate({
        challengeId,
        peerGroupId,
        data: values.milestones?.map((milestone) => ({
          milestoneId: milestone.milestoneId,
          dueDate: milestone.dueDate,
        })),
      });
      await revalidateUserChallenges();
      notification.success({ message: "Due dates updated successfully" });
      onClose?.();
    } catch (err) {
      console.error(err);
      notification.error({ message: "Error updating due dates" });
    }
  });

  useEffect(() => {
    if (isOpen) {
      reset({ milestones: milestonesInitialValue });
    }
  }, [reset, isOpen, milestonesInitialValue]);

  return (
    <Modal
      maskClosable={false}
      title="Edit Due Dates"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={updateDueDates.isLoading}
          onClick={onSubmit}
          disabled={!isEmpty(formState.errors)}
        >
          Apply
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Skeleton loading={isUserChallengesLoading}>
          <MilestoneDueDatesFieldArray
            name="milestones"
            // @ts-expect-error because this should be solved after upgrading to RHF v8
            control={control}
          />
        </Skeleton>
      </Form>
    </Modal>
  );
};

export default EditDueDatesModal;

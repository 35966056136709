import { APP_ROUTES } from "@/routes";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import { GraphQLClient } from "graphql-request";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import { allUsersRequest } from "@libs/service/allusers";
import { curriculaRequest } from "@libs/service/curricula";
import { UserProfile } from "@libs/service/graphql/generated/types";
import { allLearningProfilesRequest } from "@libs/service/learningProfile";

import useTableColumnSearch from "../lib/hooks/useTableColumnSearch";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-around",
    },
    container: {
      overflowX: "auto",
      marginTop: "30px",
      paddingBottom: "60px",
    },
  })
);

type UserWithCohort = UserProfile & {
  cohortId: string;
  cohortValue: string;
};

function UsersPermissionTable() {
  const styles = useStyles();
  const history = useHistory();
  const { adminClient: clientAdmin, apigatewayClient: client } = useService();

  const { data: allUsersData, error: allUsersError } = useSWR(
    (clientAdmin && allUsersRequest(clientAdmin as GraphQLClient).cacheKey) ||
      null,
    (clientAdmin && allUsersRequest(clientAdmin as GraphQLClient).fetch) || null
  );
  const { data: allLearningProfilesData } = useSWR(
    ...allLearningProfilesRequest(client)
  );
  const { data: curriculaData } = useSWR(...curriculaRequest(client));
  const { getColumnSearchProps } = useTableColumnSearch();

  function redirectToUser(item: UserProfile) {
    history.push(APP_ROUTES.USER_DETAIL(item.userId));
  }

  const usersData = useMemo<UserWithCohort[]>(() => {
    return (
      allUsersData?.map((user) => {
        const learningProfile = allLearningProfilesData?.find(
          (data) => data.sub === user.userId
        );
        const cohortId = learningProfile?.activeCurriculumId as string;
        const curricula = curriculaData?.find(
          (curriculum) => curriculum.id === cohortId
        );

        const curriculumName = curricula?.attributes?.name;
        const cohortValue = [cohortId, curriculumName]
          .filter((val) => !!val)
          .join(", ");

        return {
          ...user,
          cohortId,
          cohortValue,
        };
      }) || []
    );
  }, [allLearningProfilesData, allUsersData, curriculaData]);

  const columns: GenericTableColumn<UserProfile> = [
    {
      title: "ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Cohort, Program",
      dataIndex: "cohortValue",
      key: "cohortId",
      ...getColumnSearchProps("cohortId"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => redirectToUser(record)}>View Detail</Button>
      ),
    },
  ];

  if (allUsersError) return <div>Error {JSON.stringify(allUsersError)}</div>;

  return (
    <AppLayout title="Users">
      <Container maxWidth="lg" className={styles.container}>
        <GenericTable
          columns={columns}
          dataSource={usersData}
          loading={usersData?.length < 1}
          rowKey={(item: UserProfile) => `${item.userId}-${item.name}`}
        />
      </Container>
    </AppLayout>
  );
}

export default UsersPermissionTable;

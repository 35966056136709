import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";

import { NamedSoloChallengeEvaluation } from "@components/challenges/UserSoloChallenges/types";
import UserPicker from "@components/picker/UserPicker";

import { useService } from "@libs/hooks/useService";
import { assignReviewer } from "@libs/service/solochallenge";

interface AssignReviewerModalProps {
  show: boolean;
  revieweeId: string;
  target?: NamedSoloChallengeEvaluation;
  onFinished(canceled?: boolean): void;
  onError(err: unknown): void;
}

const AssignReviewerModal: React.FC<AssignReviewerModalProps> = (props) => {
  const [sending, setSending] = useState<boolean>(false);
  const [target, setTarget] = useState<string | undefined>(undefined);
  const { apigatewayClient: client } = useService();

  function onUserSelected(id: string) {
    setTarget(id);
  }

  async function submitReviewer() {
    setSending(true);

    if (client && props.target && props.target.id && target) {
      try {
        await assignReviewer(
          client,
          `${props.target.id}`,
          props.revieweeId,
          target
        );
        notification.success({
          message: "Success",
          description: "The reviewer has been successfully assigned",
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: "Oops!",
          description: "Unable to assign reviewer, please try again later",
        });
      } finally {
        setSending(false);
        props.onFinished();
      }
    }
  }

  useEffect(() => {
    if (props.target?.reviewerSub) {
      setTarget(props.target.reviewerSub);
    }
  }, [props.target?.reviewerSub]);

  return (
    <>
      <Modal
        title="Assign Reviewer"
        visible={props.show}
        onOk={submitReviewer}
        onCancel={() => {
          if (!sending) {
            props.onFinished(true);
          }
        }}
        okButtonProps={{ loading: sending }}
        cancelButtonProps={{ disabled: sending }}
      >
        {props.target && (
          <>
            <p>
              Select the reviewer for Challenge <b>{props.target.name}</b>
            </p>
            <UserPicker
              onChange={onUserSelected}
              selectedId={target}
              disabled={sending}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default AssignReviewerModal;

import { NamedSoloChallengeEvaluation } from "@components/challenges/UserSoloChallenges/types";

import {
  SoloChallengeEntity,
  SoloChallengeEvaluation,
} from "@libs/service/graphql/generated/types";

export function mapSoloChallengeProgressWithTemplateV2(
  soloChallengesData: SoloChallengeEntity[],
  soloChallengeProgress: SoloChallengeEvaluation[]
): NamedSoloChallengeEvaluation[] {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    soloChallengeProgress?.map((prg) => {
      const scData = soloChallengesData.find((sc) => sc.id === prg.id);
      return {
        ...prg,
        name: scData?.attributes?.name,
        graded: scData?.attributes?.graded,
        reviewer: {
          name: prg.reviewer ? prg.reviewer.name : "-",
        },
      };
    }) || []
  );
}

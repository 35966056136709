export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdminUtils = {
  __typename?: 'AdminUtils';
  users?: Maybe<Array<Maybe<UserProfile>>>;
};

export type AssessmentDeliverable = {
  __typename?: 'AssessmentDeliverable';
  createdAt?: Maybe<Scalars['String']>;
  deliverables?: Maybe<Array<Maybe<GoogleDriveIdLinks>>>;
  incrementId?: Maybe<Scalars['Int']>;
  reviewId?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Challenge = {
  __typename?: 'Challenge';
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverables?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disableReview?: Maybe<Scalars['Boolean']>;
  dueDate?: Maybe<Scalars['String']>;
  ects?: Maybe<Scalars['Float']>;
  evaluationCriteria?: Maybe<Scalars['String']>;
  extendedReview?: Maybe<Scalars['String']>;
  graded?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  phase?: Maybe<PhaseRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  reviewTemplate?: Maybe<Array<Maybe<ComponentChallengesReviewField>>>;
  slackChannel?: Maybe<Scalars['String']>;
  teachingAssistants?: Maybe<Array<Maybe<ComponentChallengesTeachingAssistantField>>>;
  teaser?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  v2Milestones?: Maybe<V2MilestoneRelationResponseCollection>;
};


export type ChallengePhaseArgs = {
  filters?: InputMaybe<PhaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChallengeReviewTemplateArgs = {
  filters?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChallengeTeachingAssistantsArgs = {
  filters?: InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChallengeV2MilestonesArgs = {
  filters?: InputMaybe<V2MilestoneFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChallengeEntity = {
  __typename?: 'ChallengeEntity';
  attributes?: Maybe<Challenge>;
  id?: Maybe<Scalars['ID']>;
};

export type ChallengeEntityResponse = {
  __typename?: 'ChallengeEntityResponse';
  data?: Maybe<ChallengeEntity>;
};

export type ChallengeEntityResponseCollection = {
  __typename?: 'ChallengeEntityResponseCollection';
  data: Array<ChallengeEntity>;
  meta: ResponseCollectionMeta;
};

/**
 * SoloChallenge type has been reserved for content service,
 * to prevent duplication this service will use SoloChallengeProgress
 */
export type ChallengeEvaluation = {
  __typename?: 'ChallengeEvaluation';
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradedReviews?: Maybe<Array<Maybe<ChallengeGradedReviewProgress>>>;
  gradingStatus?: Maybe<SoloChallengeGradingStatus>;
  id?: Maybe<Scalars['ID']>;
  pendingReviews?: Maybe<Array<Maybe<ChallengeReviewRequest>>>;
  publishStatus?: Maybe<SoloChallengeProgressPublishStatus>;
  reviewer?: Maybe<StudentProfile>;
  reviewerSub?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<Maybe<ChallengeReviewRequest>>>;
};

export type ChallengeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deliverables?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  disableReview?: InputMaybe<BooleanFilterInput>;
  dueDate?: InputMaybe<StringFilterInput>;
  ects?: InputMaybe<FloatFilterInput>;
  evaluationCriteria?: InputMaybe<StringFilterInput>;
  extendedReview?: InputMaybe<StringFilterInput>;
  graded?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ChallengeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  owner?: InputMaybe<StringFilterInput>;
  phase?: InputMaybe<PhaseFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  reviewTemplate?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  slackChannel?: InputMaybe<StringFilterInput>;
  teachingAssistants?: InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>;
  teaser?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  v2Milestones?: InputMaybe<V2MilestoneFiltersInput>;
};

/** type will be deprecated and should implement ChallengeGradedReviewProgress */
export type ChallengeGradedReviewProgress = {
  __typename?: 'ChallengeGradedReviewProgress';
  criteria?: Maybe<Array<Maybe<SoloChallengeCriterionEvaluation>>>;
  /**
   * The summarized status. If any sub element is failed, this
   * status will be failed.
   */
  gradingStatus?: Maybe<SoloChallengeGradingStatus>;
  /** Idenfifier. Uses this a reviewRequestId or reviewId. */
  id: Scalars['ID'];
  /** Available when a review has been comitted. */
  reviewedOn?: Maybe<Scalars['String']>;
  /** The general review status. */
  status?: Maybe<ReviewStatus>;
};

export type ChallengeInput = {
  deliverables?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disableReview?: InputMaybe<Scalars['Boolean']>;
  dueDate?: InputMaybe<Scalars['String']>;
  ects?: InputMaybe<Scalars['Float']>;
  evaluationCriteria?: InputMaybe<Scalars['String']>;
  extendedReview?: InputMaybe<Scalars['String']>;
  graded?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  phase?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  reviewTemplate?: InputMaybe<Array<InputMaybe<ComponentChallengesReviewFieldInput>>>;
  slackChannel?: InputMaybe<Scalars['String']>;
  teachingAssistants?: InputMaybe<Array<InputMaybe<ComponentChallengesTeachingAssistantFieldInput>>>;
  teaser?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  v2Milestones?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** Category for working on solo challenges. */
export type ChallengeMutation = {
  __typename?: 'ChallengeMutation';
  /**
   * This will set the reviewer for the solo challenge. This reviewer
   * will be assigned to the attention field, when submitted for review.
   */
  assignReviewer?: Maybe<ChallengeEvaluation>;
  /** Flag if review has been read by the current user. */
  markReviewAsRead?: Maybe<ChallengeEvaluation>;
  publishToCohort?: Maybe<ChallengeEvaluation>;
  reSubmitForGrading?: Maybe<ChallengeEvaluation>;
  /** Update the Google drive Id associated with this solo challenge. */
  setGoogleDriveId?: Maybe<ChallengeEvaluation>;
  submitForGrading?: Maybe<ChallengeEvaluation>;
  /** Creates a review card, with the assigned reviewer. */
  submitForReview?: Maybe<ChallengeEvaluation>;
  /**
   * Submit evaluation of criteria for a graded solo challenge review request.
   * This method will discrimate on the graded flag of the solo challenge, but
   * will simply submit any provided data.
   */
  submitGradedReview?: Maybe<SoloChallengeGradedReviewProgress>;
  /**
   * This is an action to done by the reviewer. And will upate the
   * reivew card of the reviewee.
   */
  submitReview?: Maybe<SoloChallengeReviewProgress>;
};


/** Category for working on solo challenges. */
export type ChallengeMutationAssignReviewerArgs = {
  revieweeSub: Scalars['ID'];
  reviewerSub: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type ChallengeMutationMarkReviewAsReadArgs = {
  reviewRequestId: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type ChallengeMutationSetGoogleDriveIdArgs = {
  driveId: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type ChallengeMutationSubmitGradedReviewArgs = {
  criteria?: InputMaybe<Array<InputMaybe<SoloChallengeCriterionEvaluationInput>>>;
  reviewRequestId: Scalars['ID'];
  revieweeSub: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type ChallengeMutationSubmitReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export type ChallengeProgress = {
  __typename?: 'ChallengeProgress';
  areMilestonesComplete?: Maybe<Scalars['Boolean']>;
  challengeId?: Maybe<Scalars['ID']>;
  dueDate?: Maybe<Scalars['String']>;
  googleDriveId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isChallengeComplete?: Maybe<Scalars['Boolean']>;
  milestones?: Maybe<Array<Maybe<UserMilestone>>>;
  peerGroupId?: Maybe<Scalars['String']>;
  sub: Scalars['String'];
  user?: Maybe<FeedbackPartnerProfile>;
};

export type ChallengeRelationResponseCollection = {
  __typename?: 'ChallengeRelationResponseCollection';
  data: Array<ChallengeEntity>;
};

/**
 * A object which contains a review and graded about a gradable object like
 * solo challenge or group challenge.
 * All reviews have a gradingStatus. This status covers both graded and
 * non-graded challenges. The practical difference is that graded challenge
 * will also have a grading value and can be bloc progress if the grade is
 * not passed.
 */
export type ChallengeReview = {
  __typename?: 'ChallengeReview';
  /**
   * List of criterion for a challenge. All must be passed
   * for the review to be passed as a whole.
   */
  criteria?: Maybe<Array<ChallengeReviewCriterion>>;
  /**
   * The summarized status of all critera statuses.
   * If any sub element is failed, this
   * status will be failed.
   */
  gradingStatus?: Maybe<ChallengeReviewGradingStatus>;
  /** Available when a review has been comitted. */
  reviewedOn?: Maybe<Scalars['String']>;
  /** The general review status. */
  status?: Maybe<ReviewStatus>;
};

export type ChallengeReviewCriterion = {
  __typename?: 'ChallengeReviewCriterion';
  comment?: Maybe<Scalars['String']>;
  gradingStatus: ChallengeReviewGradingStatus;
  /** Identifer of the associated solo challenge criterion from Strapi. */
  id?: Maybe<Scalars['ID']>;
};

export type ChallengeReviewCriterionInput = {
  comment?: InputMaybe<Scalars['String']>;
  grade: Scalars['String'];
  /** Identifer of the associated solo challenge criterion from Strapi. */
  id: Scalars['ID'];
};

export enum ChallengeReviewGradingStatus {
  DoneNoReview = 'DONE_NO_REVIEW',
  GradedPassed = 'GRADED_PASSED',
  GradeFailed = 'GRADE_FAILED',
  GradeFailedReSubmittable = 'GRADE_FAILED_RE_SUBMITTABLE',
  PeerReviewed = 'PEER_REVIEWED',
  ReSubmitted = 'RE_SUBMITTED',
  Submitted = 'SUBMITTED',
  UnSubmitted = 'UN_SUBMITTED'
}

export type ChallengeReviewRequest = {
  __typename?: 'ChallengeReviewRequest';
  googleDriveId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reviewee: StudentProfile;
  /**
   * Will be one of OPEN, DONE or READ. Open when requested. Done
   * when a review has been submitted. READ when marked has having been
   * read by a client
   */
  status?: Maybe<ReviewStatus>;
};

export type Chapter = {
  __typename?: 'Chapter';
  course?: Maybe<CourseEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  exam?: Maybe<Scalars['Int']>;
  introduction_lessons?: Maybe<LessonRelationResponseCollection>;
  lessonGroupOrder?: Maybe<Array<Maybe<ComponentOrderingGroup>>>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  solo_challenge?: Maybe<SoloChallengeEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ChapterIntroduction_LessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChapterLessonGroupOrderArgs = {
  filters?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChapterLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChapterEntity = {
  __typename?: 'ChapterEntity';
  attributes?: Maybe<Chapter>;
  id?: Maybe<Scalars['ID']>;
};

export type ChapterEntityResponse = {
  __typename?: 'ChapterEntityResponse';
  data?: Maybe<ChapterEntity>;
};

export type ChapterEntityResponseCollection = {
  __typename?: 'ChapterEntityResponseCollection';
  data: Array<ChapterEntity>;
  meta: ResponseCollectionMeta;
};

export type ChapterFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ChapterFiltersInput>>>;
  course?: InputMaybe<CourseFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  exam?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  introduction_lessons?: InputMaybe<LessonFiltersInput>;
  lessonGroupOrder?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ChapterFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ChapterFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  solo_challenge?: InputMaybe<SoloChallengeFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ChapterFinishStats = {
  __typename?: 'ChapterFinishStats';
  id: Scalars['ID'];
  introductionLessonSubStats: Array<Maybe<LessonFinishStats>>;
  introductionLessons: FinishStats;
  lessonSubStats: Array<LessonFinishStats>;
  lessons: FinishStats;
  passed: Scalars['Boolean'];
  soloChallenge: FinishStats;
};

export type ChapterInput = {
  course?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  exam?: InputMaybe<Scalars['Int']>;
  introduction_lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lessonGroupOrder?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupInput>>>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  solo_challenge?: InputMaybe<Scalars['ID']>;
};

export type ChapterRelationResponseCollection = {
  __typename?: 'ChapterRelationResponseCollection';
  data: Array<ChapterEntity>;
};

export type CohortDashboardStructure = {
  __typename?: 'CohortDashboardStructure';
  phases?: Maybe<Array<Maybe<StaticPhase>>>;
};

export type Competency = {
  __typename?: 'Competency';
  competency_area?: Maybe<CompetencyAreaEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  sub_competencies?: Maybe<SubCompetencyRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CompetencySub_CompetenciesArgs = {
  filters?: InputMaybe<SubCompetencyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CompetencyArea = {
  __typename?: 'CompetencyArea';
  competencies?: Maybe<CompetencyRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CompetencyAreaCompetenciesArgs = {
  filters?: InputMaybe<CompetencyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CompetencyAreaEntity = {
  __typename?: 'CompetencyAreaEntity';
  attributes?: Maybe<CompetencyArea>;
  id?: Maybe<Scalars['ID']>;
};

export type CompetencyAreaEntityResponse = {
  __typename?: 'CompetencyAreaEntityResponse';
  data?: Maybe<CompetencyAreaEntity>;
};

export type CompetencyAreaEntityResponseCollection = {
  __typename?: 'CompetencyAreaEntityResponseCollection';
  data: Array<CompetencyAreaEntity>;
  meta: ResponseCollectionMeta;
};

export type CompetencyAreaFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CompetencyAreaFiltersInput>>>;
  competencies?: InputMaybe<CompetencyFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CompetencyAreaFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CompetencyAreaFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CompetencyAreaInput = {
  competencies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompetencyEntity = {
  __typename?: 'CompetencyEntity';
  attributes?: Maybe<Competency>;
  id?: Maybe<Scalars['ID']>;
};

export type CompetencyEntityResponse = {
  __typename?: 'CompetencyEntityResponse';
  data?: Maybe<CompetencyEntity>;
};

export type CompetencyEntityResponseCollection = {
  __typename?: 'CompetencyEntityResponseCollection';
  data: Array<CompetencyEntity>;
  meta: ResponseCollectionMeta;
};

export type CompetencyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CompetencyFiltersInput>>>;
  competency_area?: InputMaybe<CompetencyAreaFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CompetencyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CompetencyFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sub_competencies?: InputMaybe<SubCompetencyFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CompetencyInput = {
  competency_area?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  sub_competencies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CompetencyRelationResponseCollection = {
  __typename?: 'CompetencyRelationResponseCollection';
  data: Array<CompetencyEntity>;
};

export type ComponentChallengesChallengeMilestone = {
  __typename?: 'ComponentChallengesChallengeMilestone';
  content?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentChallengesEvaluationCriterion = {
  __typename?: 'ComponentChallengesEvaluationCriterion';
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentChallengesEvaluationCriterionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>>>;
};

export type ComponentChallengesEvaluationCriterionInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentChallengesGroupChallengeMilestoneField = {
  __typename?: 'ComponentChallengesGroupChallengeMilestoneField';
  content: Scalars['String'];
  id: Scalars['ID'];
  milestoneDescription?: Maybe<Scalars['String']>;
  startDateDescription: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ComponentChallengesGroupChallengeMilestoneFieldFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  milestoneDescription?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldFiltersInput>>>;
  startDateDescription?: InputMaybe<StringFilterInput>;
  subTitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentChallengesGroupChallengeMilestoneFieldInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  milestoneDescription?: InputMaybe<Scalars['String']>;
  startDateDescription?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ComponentChallengesMilestoneField = {
  __typename?: 'ComponentChallengesMilestoneField';
  content: Scalars['String'];
  id: Scalars['ID'];
  isPublishing?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ComponentChallengesMilestoneFieldFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChallengesMilestoneFieldFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  isPublishing?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentChallengesMilestoneFieldFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentChallengesMilestoneFieldFiltersInput>>>;
  subTitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentChallengesMilestoneFieldInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isPublishing?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ComponentChallengesReviewField = {
  __typename?: 'ComponentChallengesReviewField';
  formType?: Maybe<Enum_Componentchallengesreviewfield_Formtype>;
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type ComponentChallengesReviewFieldFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChallengesReviewFieldFiltersInput>>>;
  formType?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentChallengesReviewFieldFiltersInput>>>;
  question?: InputMaybe<StringFilterInput>;
};

export type ComponentChallengesReviewFieldInput = {
  formType?: InputMaybe<Enum_Componentchallengesreviewfield_Formtype>;
  id?: InputMaybe<Scalars['ID']>;
  question?: InputMaybe<Scalars['String']>;
};

export type ComponentChallengesTeachingAssistantField = {
  __typename?: 'ComponentChallengesTeachingAssistantField';
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type ComponentChallengesTeachingAssistantFieldFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>>>;
  email?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>>>;
};

export type ComponentChallengesTeachingAssistantFieldInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentChallengesTeamChallengeMilestone = {
  __typename?: 'ComponentChallengesTeamChallengeMilestone';
  content: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  milestoneDescription?: Maybe<Scalars['String']>;
  startDateDescription: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ComponentLearningOutcomeRubric = {
  __typename?: 'ComponentLearningOutcomeRubric';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
};

export type ComponentLearningOutcomeRubricFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeRubricFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  level?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentLearningOutcomeRubricFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeRubricFiltersInput>>>;
};

export type ComponentLearningOutcomeRubricInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<Scalars['Int']>;
};

export type ComponentLearningOutcomeThreshold = {
  __typename?: 'ComponentLearningOutcomeThreshold';
  id: Scalars['ID'];
  learning_outcome?: Maybe<LearningOutcomeEntityResponse>;
  threshold?: Maybe<Scalars['Int']>;
};

export type ComponentLearningOutcomeThresholdFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeThresholdFiltersInput>>>;
  learning_outcome?: InputMaybe<LearningOutcomeFiltersInput>;
  not?: InputMaybe<ComponentLearningOutcomeThresholdFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeThresholdFiltersInput>>>;
  threshold?: InputMaybe<IntFilterInput>;
};

export type ComponentLearningOutcomeThresholdInput = {
  id?: InputMaybe<Scalars['ID']>;
  learning_outcome?: InputMaybe<Scalars['ID']>;
  threshold?: InputMaybe<Scalars['Int']>;
};

export type ComponentLessonsCardFillInBlanksAnswer = {
  __typename?: 'ComponentLessonsCardFillInBlanksAnswer';
  Paragraph?: Maybe<Scalars['String']>;
  TypeOfParagraph: Enum_Componentlessonscardfillinblanksanswer_Typeofparagraph;
  id: Scalars['ID'];
};

export type ComponentLessonsCardMatchingAnswer = {
  __typename?: 'ComponentLessonsCardMatchingAnswer';
  GroupName?: Maybe<Scalars['String']>;
  MatchedCard?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentLessonsCardMatchingAnswerFiltersInput = {
  GroupName?: InputMaybe<StringFilterInput>;
  MatchedCard?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingAnswerFiltersInput>>>;
  not?: InputMaybe<ComponentLessonsCardMatchingAnswerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingAnswerFiltersInput>>>;
};

export type ComponentLessonsCardMatchingAnswerInput = {
  GroupName?: InputMaybe<Scalars['String']>;
  MatchedCard?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentLessonsCardMatchingGroup = {
  __typename?: 'ComponentLessonsCardMatchingGroup';
  correctAnswers?: Maybe<Array<Maybe<ComponentLessonsCardMatchingGroupChildren>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type ComponentLessonsCardMatchingGroupCorrectAnswersArgs = {
  filters?: InputMaybe<ComponentLessonsCardMatchingGroupChildrenFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentLessonsCardMatchingGroupChildren = {
  __typename?: 'ComponentLessonsCardMatchingGroupChildren';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type ComponentLessonsCardMatchingGroupChildrenFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupChildrenFiltersInput>>>;
  not?: InputMaybe<ComponentLessonsCardMatchingGroupChildrenFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupChildrenFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentLessonsCardMatchingGroupChildrenInput = {
  id?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
};

export type ComponentLessonsCardMatchingGroupFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupFiltersInput>>>;
  correctAnswers?: InputMaybe<ComponentLessonsCardMatchingGroupChildrenFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentLessonsCardMatchingGroupFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupFiltersInput>>>;
};

export type ComponentLessonsCardMatchingGroupInput = {
  correctAnswers?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupChildrenInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ComponentLessonsCardOptionsAnswer = {
  __typename?: 'ComponentLessonsCardOptionsAnswer';
  Answer?: Maybe<Scalars['String']>;
  Correct: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ComponentLessonsCardOptionsAnswerFiltersInput = {
  Answer?: InputMaybe<StringFilterInput>;
  Correct?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardOptionsAnswerFiltersInput>>>;
  not?: InputMaybe<ComponentLessonsCardOptionsAnswerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardOptionsAnswerFiltersInput>>>;
};

export type ComponentLessonsCardOptionsAnswerInput = {
  Answer?: InputMaybe<Scalars['String']>;
  Correct?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentLessonsCardRankAnswer = {
  __typename?: 'ComponentLessonsCardRankAnswer';
  Answer?: Maybe<Scalars['String']>;
  Weight?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type ComponentLessonsCardRankAnswerFiltersInput = {
  Answer?: InputMaybe<StringFilterInput>;
  Weight?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardRankAnswerFiltersInput>>>;
  not?: InputMaybe<ComponentLessonsCardRankAnswerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardRankAnswerFiltersInput>>>;
};

export type ComponentLessonsCardRankAnswerInput = {
  Answer?: InputMaybe<Scalars['String']>;
  Weight?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentLessonsCardTrueFalseAnswer = {
  __typename?: 'ComponentLessonsCardTrueFalseAnswer';
  Answer?: Maybe<Scalars['String']>;
  Correct: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type ComponentLessonsCardTrueFalseAnswerFiltersInput = {
  Answer?: InputMaybe<StringFilterInput>;
  Correct?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCardTrueFalseAnswerFiltersInput>>>;
  not?: InputMaybe<ComponentLessonsCardTrueFalseAnswerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCardTrueFalseAnswerFiltersInput>>>;
};

export type ComponentLessonsCardTrueFalseAnswerInput = {
  Answer?: InputMaybe<Scalars['String']>;
  Correct?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentLessonsContentReference = {
  __typename?: 'ComponentLessonsContentReference';
  author?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lesson_card_id?: Maybe<Scalars['Int']>;
  publisher?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Componentlessonscontentreference_Type>;
  url?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type ComponentLessonsContentReferenceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceFiltersInput>>>;
  author?: InputMaybe<StringFilterInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceFiltersInput>>>;
  publisher?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  year?: InputMaybe<StringFilterInput>;
};

export type ComponentLessonsContentReferenceInput = {
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  publisher?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Componentlessonscontentreference_Type>;
  url?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type ComponentLessonsCredit = {
  __typename?: 'ComponentLessonsCredit';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type ComponentLessonsCreditFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLessonsCreditFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentLessonsCreditFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLessonsCreditFiltersInput>>>;
  role?: InputMaybe<StringFilterInput>;
};

export type ComponentLessonsCreditInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type ComponentMilestonesEvaluationCriteria = {
  __typename?: 'ComponentMilestonesEvaluationCriteria';
  criterion?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentMilestonesEvaluationCriteriaFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentMilestonesEvaluationCriteriaFiltersInput>>>;
  criterion?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentMilestonesEvaluationCriteriaFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentMilestonesEvaluationCriteriaFiltersInput>>>;
};

export type ComponentMilestonesEvaluationCriteriaInput = {
  criterion?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentMilestonesLessonOrder = {
  __typename?: 'ComponentMilestonesLessonOrder';
  id: Scalars['ID'];
  itemId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type ComponentMilestonesLessonOrderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentMilestonesLessonOrderFiltersInput>>>;
  itemId?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentMilestonesLessonOrderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentMilestonesLessonOrderFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
};

export type ComponentMilestonesLessonOrderInput = {
  id?: InputMaybe<Scalars['ID']>;
  itemId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type ComponentOrderingGroup = {
  __typename?: 'ComponentOrderingGroup';
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<ComponentOrderingItemId>>>;
  weight?: Maybe<Scalars['Int']>;
};


export type ComponentOrderingGroupItemsArgs = {
  filters?: InputMaybe<ComponentOrderingItemIdFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentOrderingGroupFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupFiltersInput>>>;
  items?: InputMaybe<ComponentOrderingItemIdFiltersInput>;
  not?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupFiltersInput>>>;
  weight?: InputMaybe<IntFilterInput>;
};

export type ComponentOrderingGroupInput = {
  id?: InputMaybe<Scalars['ID']>;
  items?: InputMaybe<Array<InputMaybe<ComponentOrderingItemIdInput>>>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ComponentOrderingItemId = {
  __typename?: 'ComponentOrderingItemId';
  id: Scalars['ID'];
  itemId?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type ComponentOrderingItemIdFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentOrderingItemIdFiltersInput>>>;
  itemId?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentOrderingItemIdFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentOrderingItemIdFiltersInput>>>;
  weight?: InputMaybe<IntFilterInput>;
};

export type ComponentOrderingItemIdInput = {
  id?: InputMaybe<Scalars['ID']>;
  itemId?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type Concentration = {
  __typename?: 'Concentration';
  courseGroupOrder?: Maybe<Array<Maybe<ComponentOrderingGroup>>>;
  courses?: Maybe<CourseRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  mandatory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phase?: Maybe<PhaseEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ConcentrationCourseGroupOrderArgs = {
  filters?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ConcentrationCoursesArgs = {
  filters?: InputMaybe<CourseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConcentrationEntity = {
  __typename?: 'ConcentrationEntity';
  attributes?: Maybe<Concentration>;
  id?: Maybe<Scalars['ID']>;
};

export type ConcentrationEntityResponse = {
  __typename?: 'ConcentrationEntityResponse';
  data?: Maybe<ConcentrationEntity>;
};

export type ConcentrationEntityResponseCollection = {
  __typename?: 'ConcentrationEntityResponseCollection';
  data: Array<ConcentrationEntity>;
  meta: ResponseCollectionMeta;
};

export type ConcentrationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ConcentrationFiltersInput>>>;
  courseGroupOrder?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  courses?: InputMaybe<CourseFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mandatory?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ConcentrationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ConcentrationFiltersInput>>>;
  phase?: InputMaybe<PhaseFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ConcentrationFinishStats = {
  __typename?: 'ConcentrationFinishStats';
  allChapters: FinishStats;
  allExam: FinishStats;
  allGroupChallenge: FinishStats;
  allLessons: FinishStats;
  allSoloChallenge: FinishStats;
  courseSubStats: Array<CourseFinishStats>;
  courses: FinishStats;
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
};

export type ConcentrationInput = {
  courseGroupOrder?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupInput>>>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phase?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ConcentrationRelationResponseCollection = {
  __typename?: 'ConcentrationRelationResponseCollection';
  data: Array<ConcentrationEntity>;
};

export type ConsentHistory = {
  __typename?: 'ConsentHistory';
  consentedAt?: Maybe<Scalars['String']>;
  hasConsented?: Maybe<Scalars['Boolean']>;
  name?: Maybe<ConsentName>;
  version?: Maybe<Scalars['String']>;
};

export enum ConsentName {
  Terms = 'TERMS',
  Tracking = 'TRACKING'
}

export type Course = {
  __typename?: 'Course';
  chapterGroupOrder?: Maybe<Array<Maybe<ComponentOrderingGroup>>>;
  chapters?: Maybe<ChapterRelationResponseCollection>;
  concentrations?: Maybe<ConcentrationRelationResponseCollection>;
  courseId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  exam?: Maybe<ExamEntityResponse>;
  group_challenge?: Maybe<GroupChallengeEntityResponse>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slackChannel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CourseChapterGroupOrderArgs = {
  filters?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CourseChaptersArgs = {
  filters?: InputMaybe<ChapterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CourseConcentrationsArgs = {
  filters?: InputMaybe<ConcentrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CourseEntity = {
  __typename?: 'CourseEntity';
  attributes?: Maybe<Course>;
  id?: Maybe<Scalars['ID']>;
};

export type CourseEntityResponse = {
  __typename?: 'CourseEntityResponse';
  data?: Maybe<CourseEntity>;
};

export type CourseEntityResponseCollection = {
  __typename?: 'CourseEntityResponseCollection';
  data: Array<CourseEntity>;
  meta: ResponseCollectionMeta;
};

export type CourseFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CourseFiltersInput>>>;
  chapterGroupOrder?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  chapters?: InputMaybe<ChapterFiltersInput>;
  concentrations?: InputMaybe<ConcentrationFiltersInput>;
  courseId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  exam?: InputMaybe<ExamFiltersInput>;
  group_challenge?: InputMaybe<GroupChallengeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CourseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CourseFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slackChannel?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CourseFinishStats = {
  __typename?: 'CourseFinishStats';
  allLessons: FinishStats;
  allSoloChallenge: FinishStats;
  chapterSubStats: Array<ChapterFinishStats>;
  chapters: FinishStats;
  exam: FinishStats;
  groupChallenge: FinishStats;
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
};

export type CourseInput = {
  chapterGroupOrder?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupInput>>>;
  chapters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  concentrations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  courseId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  exam?: InputMaybe<Scalars['ID']>;
  group_challenge?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slackChannel?: InputMaybe<Scalars['String']>;
};

export type CourseProgressMutation = {
  __typename?: 'CourseProgressMutation';
  /**
   * __Unstable! Design not complete.__
   * Will register a student for a given group challenge.
   * The challenge _must be in the students curriculm_ and _must be open_.
   * Currently we do not check the validity of array ids passed in.
   */
  registerForGroupChallenge: GroupChallengeRegistration;
};


export type CourseProgressMutationRegisterForGroupChallengeArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CourseRelationResponseCollection = {
  __typename?: 'CourseRelationResponseCollection';
  data: Array<CourseEntity>;
};

export type Curriculum = {
  __typename?: 'Curriculum';
  createdAt?: Maybe<Scalars['DateTime']>;
  degree?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partner?: Maybe<PartnerEntityResponse>;
  phaseGroupOrder?: Maybe<Array<Maybe<ComponentOrderingGroup>>>;
  phases?: Maybe<PhaseRelationResponseCollection>;
  program?: Maybe<ProgramEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CurriculumPhaseGroupOrderArgs = {
  filters?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CurriculumPhasesArgs = {
  filters?: InputMaybe<PhaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CurriculumEntity = {
  __typename?: 'CurriculumEntity';
  attributes?: Maybe<Curriculum>;
  id?: Maybe<Scalars['ID']>;
};

export type CurriculumEntityResponse = {
  __typename?: 'CurriculumEntityResponse';
  data?: Maybe<CurriculumEntity>;
};

export type CurriculumEntityResponseCollection = {
  __typename?: 'CurriculumEntityResponseCollection';
  data: Array<CurriculumEntity>;
  meta: ResponseCollectionMeta;
};

export type CurriculumFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CurriculumFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  degree?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CurriculumFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CurriculumFiltersInput>>>;
  partner?: InputMaybe<PartnerFiltersInput>;
  phaseGroupOrder?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  phases?: InputMaybe<PhaseFiltersInput>;
  program?: InputMaybe<ProgramFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CurriculumFinishStats = {
  __typename?: 'CurriculumFinishStats';
  allChapters: FinishStats;
  allConcentrations: FinishStats;
  allCourses: FinishStats;
  allExam: FinishStats;
  allGroupChallenge: FinishStats;
  allLessons: FinishStats;
  allSoloChallenge: FinishStats;
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
  phaseSubStats: Array<PhaseFinishStats>;
  phases: FinishStats;
  skillScore?: Maybe<Scalars['Int']>;
};

export type CurriculumInput = {
  degree?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<Scalars['ID']>;
  phaseGroupOrder?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupInput>>>;
  phases?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  program?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export enum Enum_Componentchallengesreviewfield_Formtype {
  Text = 'text'
}

export enum Enum_Componentlessonscardfillinblanksanswer_Typeofparagraph {
  Answer = 'ANSWER',
  NormalParagraph = 'NORMAL_PARAGRAPH'
}

export enum Enum_Componentlessonscontentreference_Type {
  Article = 'article',
  Book = 'book',
  Online = 'online'
}

export enum Enum_V2Milestone_Type {
  Assessment = 'ASSESSMENT',
  Confirmation = 'CONFIRMATION',
  GroupAssessment = 'GROUP_ASSESSMENT',
  GroupConfirmation = 'GROUP_CONFIRMATION',
  None = 'NONE',
  Submission = 'SUBMISSION'
}

export type Evaluation = {
  __typename?: 'Evaluation';
  id: Scalars['String'];
};

export type Exam = {
  __typename?: 'Exam';
  course?: Maybe<CourseEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExamEntity = {
  __typename?: 'ExamEntity';
  attributes?: Maybe<Exam>;
  id?: Maybe<Scalars['ID']>;
};

export type ExamEntityResponse = {
  __typename?: 'ExamEntityResponse';
  data?: Maybe<ExamEntity>;
};

export type ExamEntityResponseCollection = {
  __typename?: 'ExamEntityResponseCollection';
  data: Array<ExamEntity>;
  meta: ResponseCollectionMeta;
};

export type ExamFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ExamFiltersInput>>>;
  course?: InputMaybe<CourseFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ExamFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ExamFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ExamInput = {
  course?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  feedback?: Maybe<Array<Maybe<ReviewTemplate>>>;
  feedbackViewedAt?: Maybe<Scalars['String']>;
  googleDriveIdLinks?: Maybe<Array<Maybe<GoogleDriveIdLinks>>>;
  id?: Maybe<Scalars['ID']>;
  isCapstone?: Maybe<Scalars['Boolean']>;
  milestoneType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  reviewTemplate?: Maybe<Array<Maybe<ReviewTemplate>>>;
  reviewee?: Maybe<UserProfile>;
  reviewer?: Maybe<UserProfile>;
  status?: Maybe<FeedbackStatus>;
  submittedAt?: Maybe<Scalars['String']>;
};

export type FeedbackInput = {
  id: Scalars['String'];
  question: Scalars['String'];
  value: Scalars['String'];
};

export type FeedbackPartner = {
  __typename?: 'FeedbackPartner';
  feedbackIn?: Maybe<Array<Maybe<Feedback>>>;
  feedbackOut?: Maybe<Array<Maybe<Feedback>>>;
  peers?: Maybe<Array<Maybe<UserProfile>>>;
};

export type FeedbackPartnerProfile = {
  __typename?: 'FeedbackPartnerProfile';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  slackId?: Maybe<Scalars['ID']>;
};

export enum FeedbackStatus {
  Given = 'GIVEN',
  Pending = 'PENDING'
}

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FinishStats = {
  __typename?: 'FinishStats';
  completed: Scalars['Int'];
  total: Scalars['Int'];
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = Challenge | Chapter | Competency | CompetencyArea | ComponentChallengesChallengeMilestone | ComponentChallengesEvaluationCriterion | ComponentChallengesGroupChallengeMilestoneField | ComponentChallengesMilestoneField | ComponentChallengesReviewField | ComponentChallengesTeachingAssistantField | ComponentChallengesTeamChallengeMilestone | ComponentLearningOutcomeRubric | ComponentLearningOutcomeThreshold | ComponentLessonsCardFillInBlanksAnswer | ComponentLessonsCardMatchingAnswer | ComponentLessonsCardMatchingGroup | ComponentLessonsCardMatchingGroupChildren | ComponentLessonsCardOptionsAnswer | ComponentLessonsCardRankAnswer | ComponentLessonsCardTrueFalseAnswer | ComponentLessonsContentReference | ComponentLessonsCredit | ComponentMilestonesEvaluationCriteria | ComponentMilestonesLessonOrder | ComponentOrderingGroup | ComponentOrderingItemId | Concentration | Course | Curriculum | Exam | GroupChallenge | KeyTerm | LearningOutcome | Lesson | LessonCardFillInBlank | LessonCardMatching | LessonCardMultipleMatching | LessonCardOption | LessonCardRank | LessonCardReflection | LessonCardTrueFalse | LessonCardView | Partner | Phase | Program | ProgramType | SoloChallenge | SubCompetency | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | V2Milestone;

export type GoogleDriveIdLinks = {
  __typename?: 'GoogleDriveIdLinks';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GoogleDriveLinkInput = {
  name: Scalars['String'];
  type?: InputMaybe<GoogleDriveLinkType>;
  url: Scalars['String'];
};

export enum GoogleDriveLinkType {
  Document = 'DOCUMENT',
  Folder = 'FOLDER'
}

export type GradeAssessmentCriteriaInput = {
  commendation: Scalars['String'];
  description: Scalars['String'];
  gradeNumber: Scalars['Int'];
  recommendation: Scalars['String'];
  title: Scalars['String'];
};

export type GroupAssessmentDeliverable = {
  __typename?: 'GroupAssessmentDeliverable';
  createdAt?: Maybe<Scalars['String']>;
  deliverables?: Maybe<Array<Maybe<GoogleDriveIdLinks>>>;
  incrementId?: Maybe<Scalars['Int']>;
  reviewId?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
};

export type GroupChallenge = {
  __typename?: 'GroupChallenge';
  course?: Maybe<CourseEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverables?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDateDescription?: Maybe<Scalars['String']>;
  evaluationCriteria?: Maybe<Scalars['String']>;
  finalPitchInformation?: Maybe<Scalars['String']>;
  introduction_lessons?: Maybe<LessonRelationResponseCollection>;
  itemizedEvaluationCriteria?: Maybe<Array<Maybe<ComponentChallengesEvaluationCriterion>>>;
  milestones?: Maybe<Array<Maybe<ComponentChallengesGroupChallengeMilestoneField>>>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type GroupChallengeIntroduction_LessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type GroupChallengeItemizedEvaluationCriteriaArgs = {
  filters?: InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type GroupChallengeMilestonesArgs = {
  filters?: InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GroupChallengeEntity = {
  __typename?: 'GroupChallengeEntity';
  attributes?: Maybe<GroupChallenge>;
  id?: Maybe<Scalars['ID']>;
};

export type GroupChallengeEntityResponse = {
  __typename?: 'GroupChallengeEntityResponse';
  data?: Maybe<GroupChallengeEntity>;
};

export type GroupChallengeEntityResponseCollection = {
  __typename?: 'GroupChallengeEntityResponseCollection';
  data: Array<GroupChallengeEntity>;
  meta: ResponseCollectionMeta;
};

export type GroupChallengeEvaluation = {
  __typename?: 'GroupChallengeEvaluation';
  demoOn?: Maybe<Scalars['String']>;
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradingStatus: ChallengeReviewGradingStatus;
  id?: Maybe<Scalars['ID']>;
  introLesson?: Maybe<Array<Maybe<GroupChallengeIntroFinishStat>>>;
  meetingLink?: Maybe<Scalars['String']>;
  /** Students involved in the group challenge */
  members?: Maybe<Array<Maybe<StudentProfile>>>;
  /** Deprecated, use the plural version 'mentors'. */
  mentor?: Maybe<StudentProfile>;
  mentors: Array<StudentProfile>;
  milestones?: Maybe<Array<Maybe<GroupChallengeMilestoneEvaluation>>>;
  reviews: Array<ChallengeReview>;
  /** Private slack channel for the group */
  slackChannel?: Maybe<Scalars['String']>;
};

export type GroupChallengeEvaluationV2 = {
  __typename?: 'GroupChallengeEvaluationV2';
  /** Listing all groups by group challenge id */
  allGroups: Array<GroupChallengeGroupEvaluation>;
  /** Get group details (member, grade etc) by group challenge group id. */
  group?: Maybe<GroupChallengeGroupEvaluation>;
  myGroup?: Maybe<GroupChallengeGroupEvaluation>;
};


export type GroupChallengeEvaluationV2GroupArgs = {
  id: Scalars['ID'];
};

export type GroupChallengeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GroupChallengeFiltersInput>>>;
  course?: InputMaybe<CourseFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deliverables?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  dueDateDescription?: InputMaybe<StringFilterInput>;
  evaluationCriteria?: InputMaybe<StringFilterInput>;
  finalPitchInformation?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  introduction_lessons?: InputMaybe<LessonFiltersInput>;
  itemizedEvaluationCriteria?: InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>;
  milestones?: InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GroupChallengeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GroupChallengeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export enum GroupChallengeGradingStatus {
  GradedPassed = 'GRADED_PASSED',
  GradeFailed = 'GRADE_FAILED',
  GradeFailedReSubmittable = 'GRADE_FAILED_RE_SUBMITTABLE',
  PeerReviewed = 'PEER_REVIEWED',
  ReSubmitted = 'RE_SUBMITTED',
  Submitted = 'SUBMITTED',
  UnSubmitted = 'UN_SUBMITTED'
}

export type GroupChallengeGroupEvaluation = {
  __typename?: 'GroupChallengeGroupEvaluation';
  demoOn?: Maybe<Scalars['String']>;
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradingStatus: ChallengeReviewGradingStatus;
  id?: Maybe<Scalars['ID']>;
  introLesson?: Maybe<Array<Maybe<GroupChallengeIntroFinishStat>>>;
  meetingLink?: Maybe<Scalars['String']>;
  /** Students involved in the group challenge */
  members?: Maybe<Array<Maybe<StudentProfile>>>;
  /** Deprecated, use the plural version 'mentors'. */
  mentor?: Maybe<StudentProfile>;
  mentors: Array<StudentProfile>;
  milestones?: Maybe<Array<Maybe<GroupChallengeMilestoneEvaluation>>>;
  reviews: Array<ChallengeReview>;
  /** Private slack channel for the group */
  slackChannel?: Maybe<Scalars['String']>;
};

export type GroupChallengeGroupInput = {
  demoOn?: InputMaybe<Scalars['String']>;
  /** Identifier default working folder. */
  googleDriveId?: InputMaybe<Scalars['ID']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  /** Private slack channel for the group */
  slackChannel?: InputMaybe<Scalars['String']>;
};

export type GroupChallengeGroupMutation = {
  __typename?: 'GroupChallengeGroupMutation';
  /**
   * Add mentor with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  addMentor?: Maybe<GroupChallengeEvaluation>;
  /**
   * Add student with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  addStudent?: Maybe<GroupChallengeEvaluation>;
  /**
   * Remove mentor with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  removeMentor?: Maybe<GroupChallengeEvaluation>;
  /**
   * Remove student with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  removeStudent?: Maybe<GroupChallengeEvaluation>;
  /**
   * Submit a review a new review for the current group.
   * Requires elevated access.
   * [Set grade to a group]
   * TODO not yet implemented
   */
  submitReview?: Maybe<GroupChallengeEvaluation>;
  /**
   * Submit update group instance for setting googleDriveId & slack channel id
   *
   * The groupInstanceId is id's of group challenge instances its from GroupChallengeGroupEvaluation that will be updated.
   * The groupInstance is object payload of GroupChallengeEvaluationInput.
   *
   * [Set data group of group challenge data]
   */
  update: GroupChallengeGroupEvaluation;
  /**
   * Update an existing review for the current group.
   * Requires elevated access.
   * TODO not yet implemented
   */
  updateReview?: Maybe<GroupChallengeEvaluation>;
};


export type GroupChallengeGroupMutationAddMentorArgs = {
  sub: Scalars['ID'];
};


export type GroupChallengeGroupMutationAddStudentArgs = {
  sub: Scalars['ID'];
};


export type GroupChallengeGroupMutationRemoveMentorArgs = {
  sub: Scalars['ID'];
};


export type GroupChallengeGroupMutationRemoveStudentArgs = {
  sub: Scalars['ID'];
};


export type GroupChallengeGroupMutationSubmitReviewArgs = {
  criteria: Array<ChallengeReviewCriterionInput>;
};


export type GroupChallengeGroupMutationUpdateArgs = {
  groupInstanceData: GroupChallengeGroupInput;
};


export type GroupChallengeGroupMutationUpdateReviewArgs = {
  criteria: Array<ChallengeReviewCriterionInput>;
  reviewId: Scalars['ID'];
};

export type GroupChallengeInput = {
  course?: InputMaybe<Scalars['ID']>;
  deliverables?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDateDescription?: InputMaybe<Scalars['String']>;
  evaluationCriteria?: InputMaybe<Scalars['String']>;
  finalPitchInformation?: InputMaybe<Scalars['String']>;
  introduction_lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  itemizedEvaluationCriteria?: InputMaybe<Array<InputMaybe<ComponentChallengesEvaluationCriterionInput>>>;
  milestones?: InputMaybe<Array<InputMaybe<ComponentChallengesGroupChallengeMilestoneFieldInput>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GroupChallengeIntroFinishStat = {
  __typename?: 'GroupChallengeIntroFinishStat';
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
  step: Scalars['Int'];
  /** Zero based (start count at zero) version of step */
  stepZ: Scalars['Int'];
  /** info to be used to calculate the % of progress if not passed by comparing step and totalCard */
  totalCardCount: Scalars['Int'];
};

export type GroupChallengeMilestoneEvaluation = {
  __typename?: 'GroupChallengeMilestoneEvaluation';
  gradingStatus?: Maybe<GroupChallengeGradingStatus>;
  id?: Maybe<Scalars['ID']>;
};

export type GroupChallengeMutation = {
  __typename?: 'GroupChallengeMutation';
  /**
   * Create a new group for the provided group challenge.
   * The id id the group challenge id from Strapi.
   * If a group already exist for the provided ID, that group is returned.
   *
   * The cohortId is a unique values that is associated with a cluster of students.
   * The groupId is and identifier which must be unique within gohortId.
   *
   * [create group by group challenge id]
   *
   * Requires elevated access.
   */
  addGroup: GroupChallengeEvaluation;
  /**
   * Get specific group by id.
   * Requires elevated access.
   */
  group?: Maybe<GroupChallengeGroupMutation>;
  /**
   * Flags if review has been read by the current user
   * TODO should moved to group mutation.
   */
  markReviewAsRead?: Maybe<GroupChallengeEvaluation>;
  submitForReview?: Maybe<GroupChallengeEvaluation>;
};


export type GroupChallengeMutationAddGroupArgs = {
  cohortId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type GroupChallengeMutationGroupArgs = {
  id: Scalars['ID'];
};


export type GroupChallengeMutationMarkReviewAsReadArgs = {
  reviewRequestId: Scalars['ID'];
};


export type GroupChallengeMutationSubmitForReviewArgs = {
  milestoneId: Scalars['ID'];
};

/**
 * The outcome of a a group registration, will list the challenges
 * that the current user has registered for.
 */
export type GroupChallengeRegistration = {
  __typename?: 'GroupChallengeRegistration';
  /** All challenges registred for this user for the given course. */
  groupChallengeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IdAndStatusType = {
  __typename?: 'IdAndStatusType';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/**
 * The inbox contains fields for message, tasks
 * of notitication which goes across grouping such as
 * lessons, challenges, etc.
 */
export type Inbox = {
  __typename?: 'Inbox';
  soloChallengeReviewRequests?: Maybe<Array<Maybe<SoloChallengeReviewRequest>>>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type KeyTerm = {
  __typename?: 'KeyTerm';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  lesson?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KeyTermEntity = {
  __typename?: 'KeyTermEntity';
  attributes?: Maybe<KeyTerm>;
  id?: Maybe<Scalars['ID']>;
};

export type KeyTermEntityResponse = {
  __typename?: 'KeyTermEntityResponse';
  data?: Maybe<KeyTermEntity>;
};

export type KeyTermEntityResponseCollection = {
  __typename?: 'KeyTermEntityResponseCollection';
  data: Array<KeyTermEntity>;
  meta: ResponseCollectionMeta;
};

export type KeyTermFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<KeyTermFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  example?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<KeyTermFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<KeyTermFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type KeyTermInput = {
  description?: InputMaybe<Scalars['String']>;
  example?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type KeyTermRelationResponseCollection = {
  __typename?: 'KeyTermRelationResponseCollection';
  data: Array<KeyTermEntity>;
};

export type LearningOutcome = {
  __typename?: 'LearningOutcome';
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  rubrics?: Maybe<Array<Maybe<ComponentLearningOutcomeRubric>>>;
  sub_competency?: Maybe<SubCompetencyEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type LearningOutcomeRubricsArgs = {
  filters?: InputMaybe<ComponentLearningOutcomeRubricFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LearningOutcomeEntity = {
  __typename?: 'LearningOutcomeEntity';
  attributes?: Maybe<LearningOutcome>;
  id?: Maybe<Scalars['ID']>;
};

export type LearningOutcomeEntityResponse = {
  __typename?: 'LearningOutcomeEntityResponse';
  data?: Maybe<LearningOutcomeEntity>;
};

export type LearningOutcomeEntityResponseCollection = {
  __typename?: 'LearningOutcomeEntityResponseCollection';
  data: Array<LearningOutcomeEntity>;
  meta: ResponseCollectionMeta;
};

export type LearningOutcomeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LearningOutcomeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LearningOutcomeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LearningOutcomeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rubrics?: InputMaybe<ComponentLearningOutcomeRubricFiltersInput>;
  sub_competency?: InputMaybe<SubCompetencyFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LearningOutcomeInput = {
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  rubrics?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeRubricInput>>>;
  sub_competency?: InputMaybe<Scalars['ID']>;
};

export type LearningOutcomeRelationResponseCollection = {
  __typename?: 'LearningOutcomeRelationResponseCollection';
  data: Array<LearningOutcomeEntity>;
};

export type LearningProfile = {
  __typename?: 'LearningProfile';
  activeCurriculumId?: Maybe<Scalars['String']>;
  progress: Progress;
  soloChallengeEvaluation?: Maybe<SoloChallengeEvaluation>;
  sub: Scalars['ID'];
};


export type LearningProfileSoloChallengeEvaluationArgs = {
  id: Scalars['ID'];
};

export type LearningProfileMutation = {
  __typename?: 'LearningProfileMutation';
  challenge?: Maybe<ChallengeMutation>;
  progress?: Maybe<ProgressMutation>;
  /**
   * This will attach a review the owner the review request
   * (the student submitting work for the challenge).
   */
  soloChallenge?: Maybe<SoloChallengeMutation>;
};


export type LearningProfileMutationChallengeArgs = {
  id: Scalars['ID'];
};


export type LearningProfileMutationSoloChallengeArgs = {
  id: Scalars['ID'];
};

export type Lesson = {
  __typename?: 'Lesson';
  chapter?: Maybe<ChapterEntityResponse>;
  class?: Maybe<Scalars['Int']>;
  class_introduced?: Maybe<ChapterEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  credits?: Maybe<Array<Maybe<ComponentLessonsCredit>>>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  group_challenge_introduced?: Maybe<GroupChallengeEntityResponse>;
  key_terms?: Maybe<KeyTermRelationResponseCollection>;
  learningGoals?: Maybe<Scalars['String']>;
  legacy_lesson_card_fill_in_blanks?: Maybe<LessonCardFillInBlankRelationResponseCollection>;
  lesson_card_fill_in_blanks?: Maybe<LessonCardFillInBlankRelationResponseCollection>;
  lesson_card_matching?: Maybe<Scalars['Int']>;
  lesson_card_matchings?: Maybe<LessonCardMatchingRelationResponseCollection>;
  lesson_card_multiple_matching?: Maybe<Scalars['Int']>;
  lesson_card_multiple_matchings?: Maybe<LessonCardMultipleMatchingRelationResponseCollection>;
  lesson_card_options?: Maybe<LessonCardOptionRelationResponseCollection>;
  lesson_card_ranks?: Maybe<LessonCardRankRelationResponseCollection>;
  lesson_card_reflection?: Maybe<LessonCardReflectionRelationResponseCollection>;
  lesson_card_true_falses?: Maybe<LessonCardTrueFalseRelationResponseCollection>;
  lesson_card_views?: Maybe<LessonCardViewRelationResponseCollection>;
  lesson_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type LessonCreditsArgs = {
  filters?: InputMaybe<ComponentLessonsCreditFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonKey_TermsArgs = {
  filters?: InputMaybe<KeyTermFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLegacy_Lesson_Card_Fill_In_BlanksArgs = {
  filters?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_Fill_In_BlanksArgs = {
  filters?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_MatchingsArgs = {
  filters?: InputMaybe<LessonCardMatchingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_Multiple_MatchingsArgs = {
  filters?: InputMaybe<LessonCardMultipleMatchingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_OptionsArgs = {
  filters?: InputMaybe<LessonCardOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_RanksArgs = {
  filters?: InputMaybe<LessonCardRankFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_ReflectionArgs = {
  filters?: InputMaybe<LessonCardReflectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_True_FalsesArgs = {
  filters?: InputMaybe<LessonCardTrueFalseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonLesson_Card_ViewsArgs = {
  filters?: InputMaybe<LessonCardViewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardFillInBlank = {
  __typename?: 'LessonCardFillInBlank';
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  textFillInChallenge?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardFillInBlankLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardFillInBlankReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardFillInBlankEntity = {
  __typename?: 'LessonCardFillInBlankEntity';
  attributes?: Maybe<LessonCardFillInBlank>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardFillInBlankEntityResponse = {
  __typename?: 'LessonCardFillInBlankEntityResponse';
  data?: Maybe<LessonCardFillInBlankEntity>;
};

export type LessonCardFillInBlankEntityResponseCollection = {
  __typename?: 'LessonCardFillInBlankEntityResponseCollection';
  data: Array<LessonCardFillInBlankEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardFillInBlankFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardFillInBlankFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardFillInBlankFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  textFillInChallenge?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardFillInBlankInput = {
  failureText?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  textFillInChallenge?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardFillInBlankRelationResponseCollection = {
  __typename?: 'LessonCardFillInBlankRelationResponseCollection';
  data: Array<LessonCardFillInBlankEntity>;
};

export type LessonCardInput = {
  id: Scalars['ID'];
};

export type LessonCardMatching = {
  __typename?: 'LessonCardMatching';
  AnswerMatchingV2?: Maybe<Array<Maybe<ComponentLessonsCardMatchingAnswer>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  leftMatchingCard?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  rightMatchingCard?: Maybe<Scalars['String']>;
  successText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardMatchingAnswerMatchingV2Args = {
  filters?: InputMaybe<ComponentLessonsCardMatchingAnswerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardMatchingReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardMatchingEntity = {
  __typename?: 'LessonCardMatchingEntity';
  attributes?: Maybe<LessonCardMatching>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardMatchingEntityResponse = {
  __typename?: 'LessonCardMatchingEntityResponse';
  data?: Maybe<LessonCardMatchingEntity>;
};

export type LessonCardMatchingEntityResponseCollection = {
  __typename?: 'LessonCardMatchingEntityResponseCollection';
  data: Array<LessonCardMatchingEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardMatchingFiltersInput = {
  AnswerMatchingV2?: InputMaybe<ComponentLessonsCardMatchingAnswerFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<LessonCardMatchingFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  leftMatchingCard?: InputMaybe<StringFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardMatchingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardMatchingFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  rightMatchingCard?: InputMaybe<StringFilterInput>;
  successText?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardMatchingInput = {
  AnswerMatchingV2?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingAnswerInput>>>;
  failureText?: InputMaybe<Scalars['String']>;
  leftMatchingCard?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  rightMatchingCard?: InputMaybe<Scalars['String']>;
  successText?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardMatchingRelationResponseCollection = {
  __typename?: 'LessonCardMatchingRelationResponseCollection';
  data: Array<LessonCardMatchingEntity>;
};

export type LessonCardMultipleMatching = {
  __typename?: 'LessonCardMultipleMatching';
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lessonContent?: Maybe<Scalars['String']>;
  matchingGroup?: Maybe<Array<Maybe<ComponentLessonsCardMatchingGroup>>>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardMultipleMatchingMatchingGroupArgs = {
  filters?: InputMaybe<ComponentLessonsCardMatchingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardMultipleMatchingReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardMultipleMatchingEntity = {
  __typename?: 'LessonCardMultipleMatchingEntity';
  attributes?: Maybe<LessonCardMultipleMatching>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardMultipleMatchingEntityResponse = {
  __typename?: 'LessonCardMultipleMatchingEntityResponse';
  data?: Maybe<LessonCardMultipleMatchingEntity>;
};

export type LessonCardMultipleMatchingEntityResponseCollection = {
  __typename?: 'LessonCardMultipleMatchingEntityResponseCollection';
  data: Array<LessonCardMultipleMatchingEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardMultipleMatchingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardMultipleMatchingFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lessonContent?: InputMaybe<StringFilterInput>;
  matchingGroup?: InputMaybe<ComponentLessonsCardMatchingGroupFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardMultipleMatchingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardMultipleMatchingFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardMultipleMatchingInput = {
  failureText?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lessonContent?: InputMaybe<Scalars['String']>;
  matchingGroup?: InputMaybe<Array<InputMaybe<ComponentLessonsCardMatchingGroupInput>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardMultipleMatchingRelationResponseCollection = {
  __typename?: 'LessonCardMultipleMatchingRelationResponseCollection';
  data: Array<LessonCardMultipleMatchingEntity>;
};

export type LessonCardOption = {
  __typename?: 'LessonCardOption';
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  optionsV2?: Maybe<Array<Maybe<ComponentLessonsCardOptionsAnswer>>>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardOptionOptionsV2Args = {
  filters?: InputMaybe<ComponentLessonsCardOptionsAnswerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardOptionReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardOptionEntity = {
  __typename?: 'LessonCardOptionEntity';
  attributes?: Maybe<LessonCardOption>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardOptionEntityResponse = {
  __typename?: 'LessonCardOptionEntityResponse';
  data?: Maybe<LessonCardOptionEntity>;
};

export type LessonCardOptionEntityResponseCollection = {
  __typename?: 'LessonCardOptionEntityResponseCollection';
  data: Array<LessonCardOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardOptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardOptionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardOptionFiltersInput>;
  options?: InputMaybe<StringFilterInput>;
  optionsV2?: InputMaybe<ComponentLessonsCardOptionsAnswerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardOptionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardOptionInput = {
  failureText?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['String']>;
  optionsV2?: InputMaybe<Array<InputMaybe<ComponentLessonsCardOptionsAnswerInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardOptionRelationResponseCollection = {
  __typename?: 'LessonCardOptionRelationResponseCollection';
  data: Array<LessonCardOptionEntity>;
};

export type LessonCardRank = {
  __typename?: 'LessonCardRank';
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  rankedList?: Maybe<Scalars['String']>;
  rankedListV2?: Maybe<Array<Maybe<ComponentLessonsCardRankAnswer>>>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardRankRankedListV2Args = {
  filters?: InputMaybe<ComponentLessonsCardRankAnswerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardRankReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardRankEntity = {
  __typename?: 'LessonCardRankEntity';
  attributes?: Maybe<LessonCardRank>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardRankEntityResponse = {
  __typename?: 'LessonCardRankEntityResponse';
  data?: Maybe<LessonCardRankEntity>;
};

export type LessonCardRankEntityResponseCollection = {
  __typename?: 'LessonCardRankEntityResponseCollection';
  data: Array<LessonCardRankEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardRankFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardRankFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardRankFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardRankFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  rankedList?: InputMaybe<StringFilterInput>;
  rankedListV2?: InputMaybe<ComponentLessonsCardRankAnswerFiltersInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardRankInput = {
  failureText?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  rankedList?: InputMaybe<Scalars['String']>;
  rankedListV2?: InputMaybe<Array<InputMaybe<ComponentLessonsCardRankAnswerInput>>>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardRankRelationResponseCollection = {
  __typename?: 'LessonCardRankRelationResponseCollection';
  data: Array<LessonCardRankEntity>;
};

export type LessonCardReflection = {
  __typename?: 'LessonCardReflection';
  createdAt?: Maybe<Scalars['DateTime']>;
  expertAnswer?: Maybe<Scalars['String']>;
  expertEmail?: Maybe<Scalars['String']>;
  expertName?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  mentorComment?: Maybe<Scalars['String']>;
  mentorEmail?: Maybe<Scalars['String']>;
  mentorName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardReflectionReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardReflectionEntity = {
  __typename?: 'LessonCardReflectionEntity';
  attributes?: Maybe<LessonCardReflection>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardReflectionEntityResponse = {
  __typename?: 'LessonCardReflectionEntityResponse';
  data?: Maybe<LessonCardReflectionEntity>;
};

export type LessonCardReflectionEntityResponseCollection = {
  __typename?: 'LessonCardReflectionEntityResponseCollection';
  data: Array<LessonCardReflectionEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardReflectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardReflectionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  expertAnswer?: InputMaybe<StringFilterInput>;
  expertEmail?: InputMaybe<StringFilterInput>;
  expertName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  mentorComment?: InputMaybe<StringFilterInput>;
  mentorEmail?: InputMaybe<StringFilterInput>;
  mentorName?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardReflectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardReflectionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardReflectionInput = {
  expertAnswer?: InputMaybe<Scalars['String']>;
  expertEmail?: InputMaybe<Scalars['String']>;
  expertName?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  mentorComment?: InputMaybe<Scalars['String']>;
  mentorEmail?: InputMaybe<Scalars['String']>;
  mentorName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardReflectionRelationResponseCollection = {
  __typename?: 'LessonCardReflectionRelationResponseCollection';
  data: Array<LessonCardReflectionEntity>;
};

export type LessonCardTrueFalse = {
  __typename?: 'LessonCardTrueFalse';
  AnswerStatementsV2?: Maybe<Array<Maybe<ComponentLessonsCardTrueFalseAnswer>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  failureText?: Maybe<Scalars['String']>;
  falseStatements?: Maybe<Scalars['String']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  successText?: Maybe<Scalars['String']>;
  trueStatements?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardTrueFalseAnswerStatementsV2Args = {
  filters?: InputMaybe<ComponentLessonsCardTrueFalseAnswerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LessonCardTrueFalseReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardTrueFalseEntity = {
  __typename?: 'LessonCardTrueFalseEntity';
  attributes?: Maybe<LessonCardTrueFalse>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardTrueFalseEntityResponse = {
  __typename?: 'LessonCardTrueFalseEntityResponse';
  data?: Maybe<LessonCardTrueFalseEntity>;
};

export type LessonCardTrueFalseEntityResponseCollection = {
  __typename?: 'LessonCardTrueFalseEntityResponseCollection';
  data: Array<LessonCardTrueFalseEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardTrueFalseFiltersInput = {
  AnswerStatementsV2?: InputMaybe<ComponentLessonsCardTrueFalseAnswerFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<LessonCardTrueFalseFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  failureText?: InputMaybe<StringFilterInput>;
  falseStatements?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardTrueFalseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardTrueFalseFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  question?: InputMaybe<StringFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  successText?: InputMaybe<StringFilterInput>;
  trueStatements?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardTrueFalseInput = {
  AnswerStatementsV2?: InputMaybe<Array<InputMaybe<ComponentLessonsCardTrueFalseAnswerInput>>>;
  failureText?: InputMaybe<Scalars['String']>;
  falseStatements?: InputMaybe<Scalars['String']>;
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  question?: InputMaybe<Scalars['String']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  successText?: InputMaybe<Scalars['String']>;
  trueStatements?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardTrueFalseRelationResponseCollection = {
  __typename?: 'LessonCardTrueFalseRelationResponseCollection';
  data: Array<LessonCardTrueFalseEntity>;
};

export type LessonCardView = {
  __typename?: 'LessonCardView';
  createdAt?: Maybe<Scalars['DateTime']>;
  lesson?: Maybe<LessonEntityResponse>;
  lesson_card_id?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  references?: Maybe<Array<Maybe<ComponentLessonsContentReference>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight?: Maybe<Scalars['Int']>;
};


export type LessonCardViewReferencesArgs = {
  filters?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LessonCardViewEntity = {
  __typename?: 'LessonCardViewEntity';
  attributes?: Maybe<LessonCardView>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonCardViewEntityResponse = {
  __typename?: 'LessonCardViewEntityResponse';
  data?: Maybe<LessonCardViewEntity>;
};

export type LessonCardViewEntityResponseCollection = {
  __typename?: 'LessonCardViewEntityResponseCollection';
  data: Array<LessonCardViewEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonCardViewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonCardViewFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lesson?: InputMaybe<LessonFiltersInput>;
  lesson_card_id?: InputMaybe<IntFilterInput>;
  material?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonCardViewFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonCardViewFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  references?: InputMaybe<ComponentLessonsContentReferenceFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  weight?: InputMaybe<IntFilterInput>;
};

export type LessonCardViewInput = {
  lesson?: InputMaybe<Scalars['ID']>;
  lesson_card_id?: InputMaybe<Scalars['Int']>;
  material?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  references?: InputMaybe<Array<InputMaybe<ComponentLessonsContentReferenceInput>>>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type LessonCardViewRelationResponseCollection = {
  __typename?: 'LessonCardViewRelationResponseCollection';
  data: Array<LessonCardViewEntity>;
};

export type LessonEntity = {
  __typename?: 'LessonEntity';
  attributes?: Maybe<Lesson>;
  id?: Maybe<Scalars['ID']>;
};

export type LessonEntityResponse = {
  __typename?: 'LessonEntityResponse';
  data?: Maybe<LessonEntity>;
};

export type LessonEntityResponseCollection = {
  __typename?: 'LessonEntityResponseCollection';
  data: Array<LessonEntity>;
  meta: ResponseCollectionMeta;
};

/**
 * Lesson evaluation have an ID and a step count. The step
 * count is the number of sub cards either in the state of
 * COMPLETE or RE_OPENED.
 */
export type LessonEvaluation = {
  __typename?: 'LessonEvaluation';
  id: Scalars['String'];
  step?: Maybe<Scalars['Int']>;
};

export type LessonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonFiltersInput>>>;
  chapter?: InputMaybe<ChapterFiltersInput>;
  class?: InputMaybe<IntFilterInput>;
  class_introduced?: InputMaybe<ChapterFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  credits?: InputMaybe<ComponentLessonsCreditFiltersInput>;
  description?: InputMaybe<StringFilterInput>;
  duration?: InputMaybe<StringFilterInput>;
  group_challenge_introduced?: InputMaybe<GroupChallengeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  key_terms?: InputMaybe<KeyTermFiltersInput>;
  learningGoals?: InputMaybe<StringFilterInput>;
  legacy_lesson_card_fill_in_blanks?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  lesson_card_fill_in_blanks?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  lesson_card_matching?: InputMaybe<IntFilterInput>;
  lesson_card_matchings?: InputMaybe<LessonCardMatchingFiltersInput>;
  lesson_card_multiple_matching?: InputMaybe<IntFilterInput>;
  lesson_card_multiple_matchings?: InputMaybe<LessonCardMultipleMatchingFiltersInput>;
  lesson_card_options?: InputMaybe<LessonCardOptionFiltersInput>;
  lesson_card_ranks?: InputMaybe<LessonCardRankFiltersInput>;
  lesson_card_reflection?: InputMaybe<LessonCardReflectionFiltersInput>;
  lesson_card_true_falses?: InputMaybe<LessonCardTrueFalseFiltersInput>;
  lesson_card_views?: InputMaybe<LessonCardViewFiltersInput>;
  lesson_id?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LessonFinishStats = {
  __typename?: 'LessonFinishStats';
  allQuizCards?: Maybe<FinishStats>;
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
  step: Scalars['Int'];
  /** Zero based (start count at zero) version of step */
  stepZ: Scalars['Int'];
  /** info to be used to calculate the % of progress if not passed by comparing step and totalCard */
  totalCardCount: Scalars['Int'];
};

export type LessonInput = {
  chapter?: InputMaybe<Scalars['ID']>;
  class?: InputMaybe<Scalars['Int']>;
  class_introduced?: InputMaybe<Scalars['ID']>;
  credits?: InputMaybe<Array<InputMaybe<ComponentLessonsCreditInput>>>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  group_challenge_introduced?: InputMaybe<Scalars['ID']>;
  key_terms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  learningGoals?: InputMaybe<Scalars['String']>;
  legacy_lesson_card_fill_in_blanks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_fill_in_blanks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_matching?: InputMaybe<Scalars['Int']>;
  lesson_card_matchings?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_multiple_matching?: InputMaybe<Scalars['Int']>;
  lesson_card_multiple_matchings?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_ranks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_reflection?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_true_falses?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_card_views?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lesson_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LessonProgress = {
  __typename?: 'LessonProgress';
  id?: Maybe<Scalars['ID']>;
  isLessonComplete?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<FinishStats>;
};

/**
 * Set the current progress for the use, indicated as a step where
 * a step is a completed card.
 *
 * E.g. a value of
 *   E.g. a value of
 * E.g. a value of
 * 2 means the student has completed the second card.
 * If the step is equal to or higher than then number of cards
 * in a lesson, the lession will marked as complete.
 */
export type LessonProgressMutation = {
  __typename?: 'LessonProgressMutation';
  /**
   * Completes all card for this current lesson.
   * Requires elevated access
   */
  complete?: Maybe<Array<Maybe<LessonEvaluation>>>;
  /** Will deprecate */
  progress?: Maybe<LessonEvaluation>;
  /** Not implemented */
  quizCard?: Maybe<LessonQuizCardMutation>;
  /**
   * Clear values on all sub cards. Sets state to RE_OPENED on all
   * sub cards.
   */
  retake?: Maybe<LessonEvaluation>;
};


/**
 * Set the current progress for the use, indicated as a step where
 * a step is a completed card.
 *
 * E.g. a value of
 *   E.g. a value of
 * E.g. a value of
 * 2 means the student has completed the second card.
 * If the step is equal to or higher than then number of cards
 * in a lesson, the lession will marked as complete.
 */
export type LessonProgressMutationCompleteArgs = {
  sub: Scalars['ID'];
};


/**
 * Set the current progress for the use, indicated as a step where
 * a step is a completed card.
 *
 * E.g. a value of
 *   E.g. a value of
 * E.g. a value of
 * 2 means the student has completed the second card.
 * If the step is equal to or higher than then number of cards
 * in a lesson, the lession will marked as complete.
 */
export type LessonProgressMutationProgressArgs = {
  step?: InputMaybe<Scalars['Int']>;
};


/**
 * Set the current progress for the use, indicated as a step where
 * a step is a completed card.
 *
 * E.g. a value of
 *   E.g. a value of
 * E.g. a value of
 * 2 means the student has completed the second card.
 * If the step is equal to or higher than then number of cards
 * in a lesson, the lession will marked as complete.
 */
export type LessonProgressMutationQuizCardArgs = {
  id: Scalars['ID'];
  subType: LessonQuizCardType;
};

export type LessonQuizCard = {
  __typename?: 'LessonQuizCard';
  id?: Maybe<Scalars['ID']>;
  state: LessonQuizCardState;
  subType: LessonQuizCardType;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LessonQuizCardMutation = {
  __typename?: 'LessonQuizCardMutation';
  /** Complete the card */
  complete?: Maybe<Evaluation>;
  /**
   * Persist the state of the identified lesson card.
   * E.g. save(left: [])
   */
  save?: Maybe<Evaluation>;
};


export type LessonQuizCardMutationSaveArgs = {
  state?: InputMaybe<LessonQuizCardState>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum LessonQuizCardState {
  Complete = 'COMPLETE',
  /**
   * State start in DRAFT or COMPLETE. DRAFT can move to complete.
   * A COMPLETED card can become RE_OPENED (whhich is similar to draft
   * except that the card is considered completed when evaluating the completeness
   * of a lesson).
   */
  Draft = 'DRAFT',
  ReOpened = 'RE_OPENED'
}

export enum LessonQuizCardType {
  FillInBlanks = 'FILL_IN_BLANKS',
  Matchings = 'MATCHINGS',
  MultipleMatchings = 'MULTIPLE_MATCHINGS',
  Options = 'OPTIONS',
  Ranks = 'RANKS',
  Reflection = 'REFLECTION',
  TrueFalses = 'TRUE_FALSES',
  Views = 'VIEWS'
}

export type LessonRelationResponseCollection = {
  __typename?: 'LessonRelationResponseCollection';
  data: Array<LessonEntity>;
};

export type LessonStatsInput = {
  id?: InputMaybe<Scalars['String']>;
  lesson_card_fill_in_blanks?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_matchings?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_multiple_matchings?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_options?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_ranks?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_reflection?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_true_falses?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
  lesson_card_views?: InputMaybe<Array<InputMaybe<LessonStatsInput>>>;
};

export type MilestoneStats = {
  __typename?: 'MilestoneStats';
  lessons?: Maybe<Array<Maybe<LessonFinishStats>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assessmentMilestoneSubmission?: Maybe<UserMilestone>;
  assessmentPeerGroupMilestoneSubmission?: Maybe<PeerGroupMilestone>;
  changeReviewerUserMilestone?: Maybe<UserMilestone>;
  createChallenge?: Maybe<ChallengeEntityResponse>;
  createChapter?: Maybe<ChapterEntityResponse>;
  createCompetency?: Maybe<CompetencyEntityResponse>;
  createCompetencyArea?: Maybe<CompetencyAreaEntityResponse>;
  createConcentration?: Maybe<ConcentrationEntityResponse>;
  createCourse?: Maybe<CourseEntityResponse>;
  createCurriculum?: Maybe<CurriculumEntityResponse>;
  createExam?: Maybe<ExamEntityResponse>;
  createGroupChallenge?: Maybe<GroupChallengeEntityResponse>;
  createKeyTerm?: Maybe<KeyTermEntityResponse>;
  createLearningOutcome?: Maybe<LearningOutcomeEntityResponse>;
  createLesson?: Maybe<LessonEntityResponse>;
  createLessonCardFillInBlank?: Maybe<LessonCardFillInBlankEntityResponse>;
  createLessonCardMatching?: Maybe<LessonCardMatchingEntityResponse>;
  createLessonCardMultipleMatching?: Maybe<LessonCardMultipleMatchingEntityResponse>;
  createLessonCardOption?: Maybe<LessonCardOptionEntityResponse>;
  createLessonCardRank?: Maybe<LessonCardRankEntityResponse>;
  createLessonCardReflection?: Maybe<LessonCardReflectionEntityResponse>;
  createLessonCardTrueFalse?: Maybe<LessonCardTrueFalseEntityResponse>;
  createLessonCardView?: Maybe<LessonCardViewEntityResponse>;
  createOrUpdateUserConsent?: Maybe<UserProfile>;
  createOrUpdateUserProfile?: Maybe<UserProfile>;
  createPartner?: Maybe<PartnerEntityResponse>;
  createPeerGroup?: Maybe<PeerGroup>;
  createPhase?: Maybe<PhaseEntityResponse>;
  createProgram?: Maybe<ProgramEntityResponse>;
  createProgramType?: Maybe<ProgramTypeEntityResponse>;
  createSoloChallenge?: Maybe<SoloChallengeEntityResponse>;
  createSubCompetency?: Maybe<SubCompetencyEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createV2Milestone?: Maybe<V2MilestoneEntityResponse>;
  deleteChallenge?: Maybe<ChallengeEntityResponse>;
  deleteChapter?: Maybe<ChapterEntityResponse>;
  deleteCompetency?: Maybe<CompetencyEntityResponse>;
  deleteCompetencyArea?: Maybe<CompetencyAreaEntityResponse>;
  deleteConcentration?: Maybe<ConcentrationEntityResponse>;
  deleteCourse?: Maybe<CourseEntityResponse>;
  deleteCurriculum?: Maybe<CurriculumEntityResponse>;
  deleteExam?: Maybe<ExamEntityResponse>;
  deleteGroupChallenge?: Maybe<GroupChallengeEntityResponse>;
  deleteKeyTerm?: Maybe<KeyTermEntityResponse>;
  deleteLearningOutcome?: Maybe<LearningOutcomeEntityResponse>;
  deleteLesson?: Maybe<LessonEntityResponse>;
  deleteLessonCard?: Maybe<Scalars['Boolean']>;
  deleteLessonCardFillInBlank?: Maybe<LessonCardFillInBlankEntityResponse>;
  deleteLessonCardMatching?: Maybe<LessonCardMatchingEntityResponse>;
  deleteLessonCardMultipleMatching?: Maybe<LessonCardMultipleMatchingEntityResponse>;
  deleteLessonCardOption?: Maybe<LessonCardOptionEntityResponse>;
  deleteLessonCardRank?: Maybe<LessonCardRankEntityResponse>;
  deleteLessonCardReflection?: Maybe<LessonCardReflectionEntityResponse>;
  deleteLessonCardTrueFalse?: Maybe<LessonCardTrueFalseEntityResponse>;
  deleteLessonCardView?: Maybe<LessonCardViewEntityResponse>;
  deletePartner?: Maybe<PartnerEntityResponse>;
  deletePhase?: Maybe<PhaseEntityResponse>;
  deleteProgram?: Maybe<ProgramEntityResponse>;
  deleteProgramType?: Maybe<ProgramTypeEntityResponse>;
  deleteSoloChallenge?: Maybe<SoloChallengeEntityResponse>;
  deleteSubCompetency?: Maybe<SubCompetencyEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteV2Milestone?: Maybe<V2MilestoneEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  gradeAssessmentMilestone?: Maybe<UserAssessmentReview>;
  gradePeerGroupAssessmentMilestone?: Maybe<UserAssessmentReview>;
  hackSolo?: Maybe<Scalars['String']>;
  hackSoloProfile?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  learningProfile?: Maybe<LearningProfileMutation>;
  login: UsersPermissionsLoginPayload;
  markMilestoneAsComplete?: Maybe<Scalars['Boolean']>;
  markPeerGroupMilestoneAsComplete?: Maybe<Scalars['Boolean']>;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  readAssessmentReview?: Maybe<Scalars['Boolean']>;
  readFeedback?: Maybe<Scalars['Boolean']>;
  readPeerGroupAssessment?: Maybe<Scalars['Boolean']>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  registerUserForCurriculum?: Maybe<Scalars['Boolean']>;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  reviewUserMilestone?: Maybe<UserMilestone>;
  saveLessonCardProgress?: Maybe<Scalars['Boolean']>;
  self: Scalars['String'];
  setChallengeGoogleDriveId?: Maybe<Scalars['Boolean']>;
  submitUserMilestone?: Maybe<UserMilestone>;
  synchroniseUserProfileWithAuth0?: Maybe<UserProfile>;
  updateChallenge?: Maybe<ChallengeEntityResponse>;
  updateChapter?: Maybe<ChapterEntityResponse>;
  updateCompetency?: Maybe<CompetencyEntityResponse>;
  updateCompetencyArea?: Maybe<CompetencyAreaEntityResponse>;
  updateConcentration?: Maybe<ConcentrationEntityResponse>;
  updateCourse?: Maybe<CourseEntityResponse>;
  updateCurriculum?: Maybe<CurriculumEntityResponse>;
  updateExam?: Maybe<ExamEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGroupChallenge?: Maybe<GroupChallengeEntityResponse>;
  updateKeyTerm?: Maybe<KeyTermEntityResponse>;
  updateLearningOutcome?: Maybe<LearningOutcomeEntityResponse>;
  updateLesson?: Maybe<LessonEntityResponse>;
  updateLessonCardFillInBlank?: Maybe<LessonCardFillInBlankEntityResponse>;
  updateLessonCardMatching?: Maybe<LessonCardMatchingEntityResponse>;
  updateLessonCardMultipleMatching?: Maybe<LessonCardMultipleMatchingEntityResponse>;
  updateLessonCardOption?: Maybe<LessonCardOptionEntityResponse>;
  updateLessonCardRank?: Maybe<LessonCardRankEntityResponse>;
  updateLessonCardReflection?: Maybe<LessonCardReflectionEntityResponse>;
  updateLessonCardTrueFalse?: Maybe<LessonCardTrueFalseEntityResponse>;
  updateLessonCardView?: Maybe<LessonCardViewEntityResponse>;
  updatePartner?: Maybe<PartnerEntityResponse>;
  updatePeerGroupDueDates?: Maybe<Scalars['Boolean']>;
  updatePhase?: Maybe<PhaseEntityResponse>;
  updateProgram?: Maybe<ProgramEntityResponse>;
  updateProgramType?: Maybe<ProgramTypeEntityResponse>;
  updateSoloChallenge?: Maybe<SoloChallengeEntityResponse>;
  updateSubCompetency?: Maybe<SubCompetencyEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateV2Milestone?: Maybe<V2MilestoneEntityResponse>;
  upload: UploadFileEntityResponse;
  userConsent?: Maybe<UserConsentMutation>;
};


export type MutationAssessmentMilestoneSubmissionArgs = {
  challengeId: Scalars['ID'];
  deliverables: Array<GoogleDriveLinkInput>;
  milestoneId: Scalars['ID'];
};


export type MutationAssessmentPeerGroupMilestoneSubmissionArgs = {
  challengeId: Scalars['ID'];
  deliverables: Array<GoogleDriveLinkInput>;
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type MutationChangeReviewerUserMilestoneArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  reviewerSub: Scalars['ID'];
  userSub: Scalars['ID'];
};


export type MutationCreateChallengeArgs = {
  data: ChallengeInput;
};


export type MutationCreateChapterArgs = {
  data: ChapterInput;
};


export type MutationCreateCompetencyArgs = {
  data: CompetencyInput;
};


export type MutationCreateCompetencyAreaArgs = {
  data: CompetencyAreaInput;
};


export type MutationCreateConcentrationArgs = {
  data: ConcentrationInput;
};


export type MutationCreateCourseArgs = {
  data: CourseInput;
};


export type MutationCreateCurriculumArgs = {
  data: CurriculumInput;
};


export type MutationCreateExamArgs = {
  data: ExamInput;
};


export type MutationCreateGroupChallengeArgs = {
  data: GroupChallengeInput;
};


export type MutationCreateKeyTermArgs = {
  data: KeyTermInput;
};


export type MutationCreateLearningOutcomeArgs = {
  data: LearningOutcomeInput;
};


export type MutationCreateLessonArgs = {
  data: LessonInput;
};


export type MutationCreateLessonCardFillInBlankArgs = {
  data: LessonCardFillInBlankInput;
};


export type MutationCreateLessonCardMatchingArgs = {
  data: LessonCardMatchingInput;
};


export type MutationCreateLessonCardMultipleMatchingArgs = {
  data: LessonCardMultipleMatchingInput;
};


export type MutationCreateLessonCardOptionArgs = {
  data: LessonCardOptionInput;
};


export type MutationCreateLessonCardRankArgs = {
  data: LessonCardRankInput;
};


export type MutationCreateLessonCardReflectionArgs = {
  data: LessonCardReflectionInput;
};


export type MutationCreateLessonCardTrueFalseArgs = {
  data: LessonCardTrueFalseInput;
};


export type MutationCreateLessonCardViewArgs = {
  data: LessonCardViewInput;
};


export type MutationCreateOrUpdateUserConsentArgs = {
  userConsentInput: Array<UserConsentInput>;
};


export type MutationCreateOrUpdateUserProfileArgs = {
  about?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  locationCity?: InputMaybe<Scalars['String']>;
  locationCountryCode?: InputMaybe<Scalars['String']>;
  locationCountryName?: InputMaybe<Scalars['String']>;
  locationLatitude?: InputMaybe<Scalars['String']>;
  locationLongitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Pronouns>;
  socialMediaProfiles?: InputMaybe<Array<InputMaybe<SocialMediaProfileInput>>>;
  sub: Scalars['ID'];
};


export type MutationCreatePartnerArgs = {
  data: PartnerInput;
};


export type MutationCreatePeerGroupArgs = {
  challengeId: Scalars['ID'];
  disableDueDateCalculation?: InputMaybe<Scalars['Boolean']>;
  dueDates?: InputMaybe<Array<InputMaybe<UpdatePeerGroupDueDateInput>>>;
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  userSubs: Array<Scalars['ID']>;
};


export type MutationCreatePhaseArgs = {
  data: PhaseInput;
};


export type MutationCreateProgramArgs = {
  data: ProgramInput;
};


export type MutationCreateProgramTypeArgs = {
  data: ProgramTypeInput;
};


export type MutationCreateSoloChallengeArgs = {
  data: SoloChallengeInput;
};


export type MutationCreateSubCompetencyArgs = {
  data: SubCompetencyInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationCreateV2MilestoneArgs = {
  data: V2MilestoneInput;
};


export type MutationDeleteChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChapterArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompetencyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompetencyAreaArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConcentrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCourseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCurriculumArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExamArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteKeyTermArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLearningOutcomeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardArgs = {
  cardIds: Array<Scalars['ID']>;
  cardType: LessonQuizCardType;
  lessonId: Scalars['ID'];
};


export type MutationDeleteLessonCardFillInBlankArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardMatchingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardMultipleMatchingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardOptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardRankArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardReflectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardTrueFalseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonCardViewArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePartnerArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePhaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProgramArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProgramTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSoloChallengeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSubCompetencyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteV2MilestoneArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGradeAssessmentMilestoneArgs = {
  assessorSub: Scalars['String'];
  challengeId: Scalars['ID'];
  criteria: Array<GradeAssessmentCriteriaInput>;
  milestoneId: Scalars['ID'];
  sub: Scalars['String'];
};


export type MutationGradePeerGroupAssessmentMilestoneArgs = {
  assessorSub: Scalars['String'];
  challengeId: Scalars['ID'];
  criteria: Array<GradeAssessmentCriteriaInput>;
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMarkMilestoneAsCompleteArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
};


export type MutationMarkPeerGroupMilestoneAsCompleteArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationReadAssessmentReviewArgs = {
  challengeId: Scalars['ID'];
  incrementId: Scalars['ID'];
  milestoneId: Scalars['ID'];
};


export type MutationReadFeedbackArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
};


export type MutationReadPeerGroupAssessmentArgs = {
  challengeId: Scalars['ID'];
  incrementId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRegisterUserForCurriculumArgs = {
  curriculumId: Scalars['Int'];
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationReviewUserMilestoneArgs = {
  challengeId: Scalars['ID'];
  feedbackInput: Array<FeedbackInput>;
  milestoneId: Scalars['ID'];
  revieweeSub: Scalars['String'];
};


export type MutationSaveLessonCardProgressArgs = {
  cardId: Scalars['ID'];
  cardType: LessonQuizCardType;
  challengeId?: InputMaybe<Scalars['ID']>;
  lessonId: Scalars['ID'];
  milestoneId?: InputMaybe<Scalars['ID']>;
  values: Array<InputMaybe<Scalars['String']>>;
};


export type MutationSelfArgs = {
  id: Scalars['String'];
};


export type MutationSetChallengeGoogleDriveIdArgs = {
  challengeId: Scalars['String'];
  url: Scalars['String'];
};


export type MutationSubmitUserMilestoneArgs = {
  challengeId: Scalars['ID'];
  googleDriveLinks: Array<GoogleDriveLinkInput>;
  milestoneId: Scalars['ID'];
};


export type MutationSynchroniseUserProfileWithAuth0Args = {
  avatar?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  slackId?: InputMaybe<Scalars['String']>;
  sub: Scalars['ID'];
};


export type MutationUpdateChallengeArgs = {
  data: ChallengeInput;
  id: Scalars['ID'];
};


export type MutationUpdateChapterArgs = {
  data: ChapterInput;
  id: Scalars['ID'];
};


export type MutationUpdateCompetencyArgs = {
  data: CompetencyInput;
  id: Scalars['ID'];
};


export type MutationUpdateCompetencyAreaArgs = {
  data: CompetencyAreaInput;
  id: Scalars['ID'];
};


export type MutationUpdateConcentrationArgs = {
  data: ConcentrationInput;
  id: Scalars['ID'];
};


export type MutationUpdateCourseArgs = {
  data: CourseInput;
  id: Scalars['ID'];
};


export type MutationUpdateCurriculumArgs = {
  data: CurriculumInput;
  id: Scalars['ID'];
};


export type MutationUpdateExamArgs = {
  data: ExamInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateGroupChallengeArgs = {
  data: GroupChallengeInput;
  id: Scalars['ID'];
};


export type MutationUpdateKeyTermArgs = {
  data: KeyTermInput;
  id: Scalars['ID'];
};


export type MutationUpdateLearningOutcomeArgs = {
  data: LearningOutcomeInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonArgs = {
  data: LessonInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardFillInBlankArgs = {
  data: LessonCardFillInBlankInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardMatchingArgs = {
  data: LessonCardMatchingInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardMultipleMatchingArgs = {
  data: LessonCardMultipleMatchingInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardOptionArgs = {
  data: LessonCardOptionInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardRankArgs = {
  data: LessonCardRankInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardReflectionArgs = {
  data: LessonCardReflectionInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardTrueFalseArgs = {
  data: LessonCardTrueFalseInput;
  id: Scalars['ID'];
};


export type MutationUpdateLessonCardViewArgs = {
  data: LessonCardViewInput;
  id: Scalars['ID'];
};


export type MutationUpdatePartnerArgs = {
  data: PartnerInput;
  id: Scalars['ID'];
};


export type MutationUpdatePeerGroupDueDatesArgs = {
  challengeId: Scalars['ID'];
  data?: InputMaybe<Array<InputMaybe<UpdatePeerGroupDueDateInput>>>;
  peerGroupId: Scalars['ID'];
};


export type MutationUpdatePhaseArgs = {
  data: PhaseInput;
  id: Scalars['ID'];
};


export type MutationUpdateProgramArgs = {
  data: ProgramInput;
  id: Scalars['ID'];
};


export type MutationUpdateProgramTypeArgs = {
  data: ProgramTypeInput;
  id: Scalars['ID'];
};


export type MutationUpdateSoloChallengeArgs = {
  data: SoloChallengeInput;
  id: Scalars['ID'];
};


export type MutationUpdateSubCompetencyArgs = {
  data: SubCompetencyInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUpdateV2MilestoneArgs = {
  data: V2MilestoneInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationUserConsentArgs = {
  consentId: Scalars['ID'];
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  createdAt?: Maybe<Scalars['DateTime']>;
  logo?: Maybe<UploadFileEntityResponse>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PartnerEntity = {
  __typename?: 'PartnerEntity';
  attributes?: Maybe<Partner>;
  id?: Maybe<Scalars['ID']>;
};

export type PartnerEntityResponse = {
  __typename?: 'PartnerEntityResponse';
  data?: Maybe<PartnerEntity>;
};

export type PartnerEntityResponseCollection = {
  __typename?: 'PartnerEntityResponseCollection';
  data: Array<PartnerEntity>;
  meta: ResponseCollectionMeta;
};

export type PartnerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PartnerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PartnerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PartnerFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PartnerInput = {
  logo?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PeerGroup = {
  __typename?: 'PeerGroup';
  challengeId: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  userSubs?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PeerGroupMilestone = {
  __typename?: 'PeerGroupMilestone';
  completedAt?: Maybe<Scalars['String']>;
  completedBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  groupAssessmentDeliverables?: Maybe<Array<Maybe<GroupAssessmentDeliverable>>>;
  id?: Maybe<Scalars['String']>;
  milestoneType?: Maybe<Scalars['String']>;
  status?: Maybe<UserMilestoneStatus>;
  sub?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userProfiles?: Maybe<Array<Maybe<UserProfile>>>;
  userSubs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Phase = {
  __typename?: 'Phase';
  challengeOrder?: Maybe<Array<Maybe<ComponentMilestonesLessonOrder>>>;
  challenges?: Maybe<ChallengeRelationResponseCollection>;
  concentrationGroupOrder?: Maybe<Array<Maybe<ComponentOrderingGroup>>>;
  concentrations?: Maybe<ConcentrationRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  curriculum?: Maybe<CurriculumEntityResponse>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  mainConcentration?: Maybe<Scalars['Int']>;
  minimumNonMandatoryConcentration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  requiredConcentration?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PhaseChallengeOrderArgs = {
  filters?: InputMaybe<ComponentMilestonesLessonOrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PhaseChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PhaseConcentrationGroupOrderArgs = {
  filters?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PhaseConcentrationsArgs = {
  filters?: InputMaybe<ConcentrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PhaseEntity = {
  __typename?: 'PhaseEntity';
  attributes?: Maybe<Phase>;
  id?: Maybe<Scalars['ID']>;
};

export type PhaseEntityResponse = {
  __typename?: 'PhaseEntityResponse';
  data?: Maybe<PhaseEntity>;
};

export type PhaseEntityResponseCollection = {
  __typename?: 'PhaseEntityResponseCollection';
  data: Array<PhaseEntity>;
  meta: ResponseCollectionMeta;
};

export type PhaseFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PhaseFiltersInput>>>;
  challengeOrder?: InputMaybe<ComponentMilestonesLessonOrderFiltersInput>;
  challenges?: InputMaybe<ChallengeFiltersInput>;
  concentrationGroupOrder?: InputMaybe<ComponentOrderingGroupFiltersInput>;
  concentrations?: InputMaybe<ConcentrationFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  curriculum?: InputMaybe<CurriculumFiltersInput>;
  description?: InputMaybe<StringFilterInput>;
  dueDate?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mainConcentration?: InputMaybe<IntFilterInput>;
  minimumNonMandatoryConcentration?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PhaseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PhaseFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  requiredConcentration?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PhaseFinishStats = {
  __typename?: 'PhaseFinishStats';
  allChapters: FinishStats;
  allCourses: FinishStats;
  allExam: FinishStats;
  allGroupChallenge: FinishStats;
  allLessons: FinishStats;
  allSoloChallenge: FinishStats;
  concentrationSubStats: Array<ConcentrationFinishStats>;
  concentrations: FinishStats;
  id: Scalars['ID'];
  passed: Scalars['Boolean'];
};

export type PhaseInput = {
  challengeOrder?: InputMaybe<Array<InputMaybe<ComponentMilestonesLessonOrderInput>>>;
  challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  concentrationGroupOrder?: InputMaybe<Array<InputMaybe<ComponentOrderingGroupInput>>>;
  concentrations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  curriculum?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  mainConcentration?: InputMaybe<Scalars['Int']>;
  minimumNonMandatoryConcentration?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  requiredConcentration?: InputMaybe<Scalars['Int']>;
};

export type PhaseProgress = {
  __typename?: 'PhaseProgress';
  challenges?: Maybe<Array<Maybe<ChallengeProgress>>>;
  id: Scalars['ID'];
  isPassed?: Maybe<Scalars['Boolean']>;
};

export type PhaseRelationResponseCollection = {
  __typename?: 'PhaseRelationResponseCollection';
  data: Array<PhaseEntity>;
};

export type Program = {
  __typename?: 'Program';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  curriculum?: Maybe<CurriculumEntityResponse>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProgramTypeEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProgramEntity = {
  __typename?: 'ProgramEntity';
  attributes?: Maybe<Program>;
  id?: Maybe<Scalars['ID']>;
};

export type ProgramEntityResponse = {
  __typename?: 'ProgramEntityResponse';
  data?: Maybe<ProgramEntity>;
};

export type ProgramEntityResponseCollection = {
  __typename?: 'ProgramEntityResponseCollection';
  data: Array<ProgramEntity>;
  meta: ResponseCollectionMeta;
};

export type ProgramFiltersInput = {
  alias?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ProgramFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  curriculum?: InputMaybe<CurriculumFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProgramFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProgramFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  type?: InputMaybe<ProgramTypeFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProgramInput = {
  alias?: InputMaybe<Scalars['String']>;
  curriculum?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['ID']>;
};

export type ProgramRelationResponseCollection = {
  __typename?: 'ProgramRelationResponseCollection';
  data: Array<ProgramEntity>;
};

export type ProgramType = {
  __typename?: 'ProgramType';
  alias?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  programs?: Maybe<ProgramRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProgramTypeProgramsArgs = {
  filters?: InputMaybe<ProgramFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProgramTypeEntity = {
  __typename?: 'ProgramTypeEntity';
  attributes?: Maybe<ProgramType>;
  id?: Maybe<Scalars['ID']>;
};

export type ProgramTypeEntityResponse = {
  __typename?: 'ProgramTypeEntityResponse';
  data?: Maybe<ProgramTypeEntity>;
};

export type ProgramTypeEntityResponseCollection = {
  __typename?: 'ProgramTypeEntityResponseCollection';
  data: Array<ProgramTypeEntity>;
  meta: ResponseCollectionMeta;
};

export type ProgramTypeFiltersInput = {
  alias?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ProgramTypeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProgramTypeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProgramTypeFiltersInput>>>;
  programs?: InputMaybe<ProgramFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProgramTypeInput = {
  alias?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Progress = {
  __typename?: 'Progress';
  allUsersFromSoloChallenge?: Maybe<Array<Maybe<SoloChallengeEvaluation>>>;
  challenge?: Maybe<ChallengeEvaluation>;
  challenges: Array<Maybe<ChallengeEvaluation>>;
  exams: Array<Evaluation>;
  groupChallenge?: Maybe<GroupChallengeEvaluation>;
  groupChallengeForGroup?: Maybe<GroupChallengeEvaluation>;
  groupChallengeForUser?: Maybe<GroupChallengeEvaluation>;
  /**
   * Attributes for a group challenge that is user specific.
   * For example contains group members ships.
   */
  groupChallengeV2?: Maybe<GroupChallengeEvaluationV2>;
  groupChallenges: Array<Maybe<GroupChallengeEvaluation>>;
  /**
   * Looks up a group challenge for any given student.
   * Requires elevated access.
   */
  groupChallengesForUser?: Maybe<Array<Maybe<GroupChallengeEvaluation>>>;
  lesson: Array<LessonQuizCard>;
  lessons: Array<LessonEvaluation>;
  quizCard?: Maybe<LessonQuizCard>;
  quizCards?: Maybe<Array<Maybe<LessonQuizCard>>>;
  soloChallenge?: Maybe<SoloChallengeEvaluation>;
  soloChallengeForUser?: Maybe<SoloChallengeEvaluation>;
  soloChallenges: Array<Maybe<SoloChallengeEvaluation>>;
  soloChallengesForUser?: Maybe<Array<Maybe<SoloChallengeEvaluation>>>;
  teamChallenge?: Maybe<TeamChallengeEvaluation>;
  teamChallengeForGroup?: Maybe<TeamChallengeEvaluation>;
  teamChallengeForUser?: Maybe<TeamChallengeEvaluation>;
  /**
   * Attributes for a team challenge that is user specific.
   * For example contains team members ships.
   */
  teamChallengeV2?: Maybe<TeamChallengeEvaluationV2>;
  teamChallenges: Array<Maybe<TeamChallengeEvaluation>>;
  /**
   * Looks up a team challenge for any given student.
   * Requires elevated access.
   */
  teamChallengesForUser?: Maybe<Array<Maybe<TeamChallengeEvaluation>>>;
};


export type ProgressAllUsersFromSoloChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressGroupChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressGroupChallengeForGroupArgs = {
  groupId: Scalars['ID'];
  id: Scalars['ID'];
};


export type ProgressGroupChallengeForUserArgs = {
  id: Scalars['ID'];
  sub: Scalars['ID'];
};


export type ProgressGroupChallengeV2Args = {
  id: Scalars['ID'];
};


export type ProgressGroupChallengesForUserArgs = {
  sub: Scalars['ID'];
};


export type ProgressLessonArgs = {
  id: Scalars['ID'];
};


export type ProgressQuizCardArgs = {
  id: Scalars['ID'];
  subType?: InputMaybe<LessonQuizCardType>;
};


export type ProgressSoloChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressSoloChallengeForUserArgs = {
  id: Scalars['ID'];
  sub: Scalars['ID'];
};


export type ProgressSoloChallengesForUserArgs = {
  sub: Scalars['ID'];
};


export type ProgressTeamChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressTeamChallengeForGroupArgs = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};


export type ProgressTeamChallengeForUserArgs = {
  id: Scalars['ID'];
  sub: Scalars['ID'];
};


export type ProgressTeamChallengeV2Args = {
  id: Scalars['ID'];
};


export type ProgressTeamChallengesForUserArgs = {
  sub: Scalars['ID'];
};

export type ProgressBySubs = {
  __typename?: 'ProgressBySubs';
  challenges?: Maybe<Array<Maybe<ChallengeProgress>>>;
  groupChallenges?: Maybe<Array<Maybe<IdAndStatusType>>>;
  soloChallenges?: Maybe<Array<Maybe<IdAndStatusType>>>;
  sub?: Maybe<Scalars['String']>;
};

export type ProgressBySubsDataInput = {
  id: Scalars['ID'];
  type: ProgressTypeEnum;
};

export type ProgressMutation = {
  __typename?: 'ProgressMutation';
  challenge?: Maybe<ChallengeMutation>;
  completeLesson: Scalars['String'];
  course?: Maybe<CourseProgressMutation>;
  groupChallenge?: Maybe<GroupChallengeMutation>;
  lesson?: Maybe<LessonProgressMutation>;
  quizCard?: Maybe<LessonQuizCardMutation>;
  soloChallenge?: Maybe<SoloChallengeMutation>;
  teamChallenge?: Maybe<TeamChallengeMutation>;
};


export type ProgressMutationChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressMutationCompleteLessonArgs = {
  id: Scalars['String'];
};


export type ProgressMutationCourseArgs = {
  id: Scalars['String'];
};


export type ProgressMutationGroupChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressMutationLessonArgs = {
  id: Scalars['String'];
};


export type ProgressMutationQuizCardArgs = {
  id: Scalars['ID'];
  subType: LessonQuizCardType;
};


export type ProgressMutationSoloChallengeArgs = {
  id: Scalars['ID'];
};


export type ProgressMutationTeamChallengeArgs = {
  id: Scalars['ID'];
};

export enum ProgressTypeEnum {
  Challenge = 'CHALLENGE',
  GroupChallenge = 'GROUP_CHALLENGE',
  SoloChallenge = 'SOLO_CHALLENGE'
}

export enum Pronouns {
  HeHimHis = 'HE_HIM_HIS',
  None = 'NONE',
  SheHerHers = 'SHE_HER_HERS',
  TheyThemTheir = 'THEY_THEM_THEIR'
}

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  adminUtils?: Maybe<AdminUtils>;
  allLearningProfiles?: Maybe<Array<Maybe<LearningProfile>>>;
  challenge?: Maybe<ChallengeEntityResponse>;
  challengeProgress?: Maybe<ChallengeProgress>;
  challenges?: Maybe<ChallengeEntityResponseCollection>;
  chapter?: Maybe<ChapterEntityResponse>;
  chapters?: Maybe<ChapterEntityResponseCollection>;
  cohortDashboardStructure?: Maybe<CohortDashboardStructure>;
  competencies?: Maybe<CompetencyEntityResponseCollection>;
  competency?: Maybe<CompetencyEntityResponse>;
  competencyArea?: Maybe<CompetencyAreaEntityResponse>;
  competencyAreas?: Maybe<CompetencyAreaEntityResponseCollection>;
  concentration?: Maybe<ConcentrationEntityResponse>;
  concentrations?: Maybe<ConcentrationEntityResponseCollection>;
  course?: Maybe<CourseEntityResponse>;
  courses?: Maybe<CourseEntityResponseCollection>;
  curricula?: Maybe<CurriculumEntityResponseCollection>;
  curriculum?: Maybe<CurriculumEntityResponse>;
  curriculumFinishStats: CurriculumFinishStats;
  curriculumFinishStatsForUser: CurriculumFinishStats;
  exam?: Maybe<ExamEntityResponse>;
  exams?: Maybe<ExamEntityResponseCollection>;
  feedbackPartner?: Maybe<FeedbackPartner>;
  getAllUserProfiles?: Maybe<Array<Maybe<UserProfile>>>;
  getFilteredUserProfiles?: Maybe<UserProfilePaginationResponse>;
  getIsUrlValid?: Maybe<Scalars['Boolean']>;
  getUserProfile?: Maybe<StudentProfile>;
  getUserProfileByEmail?: Maybe<UserProfile>;
  getUserProfileByRole?: Maybe<Array<Maybe<UserProfile>>>;
  getUserProfileBySub?: Maybe<UserProfile>;
  getUserProfilesBySubs?: Maybe<Array<Maybe<UserProfile>>>;
  groupChallenge?: Maybe<GroupChallengeEntityResponse>;
  groupChallengeFinishStats?: Maybe<Array<Maybe<LessonFinishStats>>>;
  groupChallenges?: Maybe<GroupChallengeEntityResponseCollection>;
  keyTerm?: Maybe<KeyTermEntityResponse>;
  keyTerms?: Maybe<KeyTermEntityResponseCollection>;
  learnersByCurriculumId?: Maybe<Array<Maybe<UserProfile>>>;
  learningInbox?: Maybe<Inbox>;
  learningOutcome?: Maybe<LearningOutcomeEntityResponse>;
  learningOutcomes?: Maybe<LearningOutcomeEntityResponseCollection>;
  learningProfile: LearningProfile;
  lesson?: Maybe<LessonEntityResponse>;
  lessonCardFillInBlank?: Maybe<LessonCardFillInBlankEntityResponse>;
  lessonCardFillInBlanks?: Maybe<LessonCardFillInBlankEntityResponseCollection>;
  lessonCardMatching?: Maybe<LessonCardMatchingEntityResponse>;
  lessonCardMatchings?: Maybe<LessonCardMatchingEntityResponseCollection>;
  lessonCardMultipleMatching?: Maybe<LessonCardMultipleMatchingEntityResponse>;
  lessonCardMultipleMatchings?: Maybe<LessonCardMultipleMatchingEntityResponseCollection>;
  lessonCardOption?: Maybe<LessonCardOptionEntityResponse>;
  lessonCardOptions?: Maybe<LessonCardOptionEntityResponseCollection>;
  lessonCardProgress?: Maybe<UserLessonCardProgress>;
  lessonCardRank?: Maybe<LessonCardRankEntityResponse>;
  lessonCardRanks?: Maybe<LessonCardRankEntityResponseCollection>;
  lessonCardReflection?: Maybe<LessonCardReflectionEntityResponse>;
  lessonCardReflections?: Maybe<LessonCardReflectionEntityResponseCollection>;
  lessonCardTrueFalse?: Maybe<LessonCardTrueFalseEntityResponse>;
  lessonCardTrueFalses?: Maybe<LessonCardTrueFalseEntityResponseCollection>;
  lessonCardView?: Maybe<LessonCardViewEntityResponse>;
  lessonCardViews?: Maybe<LessonCardViewEntityResponseCollection>;
  lessonFinishStat?: Maybe<LessonFinishStats>;
  lessonFinishStatForUser?: Maybe<LessonFinishStats>;
  lessonProgress?: Maybe<UserLessonProgress>;
  lessons?: Maybe<LessonEntityResponseCollection>;
  lessonsStatsByMilestone?: Maybe<MilestoneStats>;
  me?: Maybe<UsersPermissionsMe>;
  partner?: Maybe<PartnerEntityResponse>;
  partners?: Maybe<PartnerEntityResponseCollection>;
  peerGroup?: Maybe<PeerGroup>;
  peerGroupAssessmentReviews?: Maybe<Array<Maybe<UserAssessmentReview>>>;
  peerGroupMilestone?: Maybe<PeerGroupMilestone>;
  peerGroups?: Maybe<Array<Maybe<PeerGroup>>>;
  phase?: Maybe<PhaseEntityResponse>;
  phaseProgressByCurriculumId?: Maybe<Array<Maybe<PhaseProgress>>>;
  phases?: Maybe<PhaseEntityResponseCollection>;
  program?: Maybe<ProgramEntityResponse>;
  programType?: Maybe<ProgramTypeEntityResponse>;
  programTypes?: Maybe<ProgramTypeEntityResponseCollection>;
  programs?: Maybe<ProgramEntityResponseCollection>;
  progressBySubs?: Maybe<Array<Maybe<ProgressBySubs>>>;
  serverId: Scalars['String'];
  soloChallenge?: Maybe<SoloChallengeEntityResponse>;
  soloChallenges?: Maybe<SoloChallengeEntityResponseCollection>;
  subCompetencies?: Maybe<SubCompetencyEntityResponseCollection>;
  subCompetency?: Maybe<SubCompetencyEntityResponse>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userAssessmentReviews?: Maybe<Array<Maybe<UserAssessmentReview>>>;
  userById?: Maybe<StudentProfile>;
  userChallengesByPeerGroupId?: Maybe<Array<Maybe<ChallengeProgress>>>;
  userChallengesByPhaseId?: Maybe<Array<Maybe<ChallengeProgress>>>;
  userChallengesByUserId?: Maybe<Array<Maybe<ChallengeProgress>>>;
  userConsent?: Maybe<UserConsentQuery>;
  userMilestoneProgress?: Maybe<UserMilestone>;
  userMilestonesByUserId?: Maybe<Array<Maybe<UserMilestone>>>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  v2Milestone?: Maybe<V2MilestoneEntityResponse>;
  v2Milestones?: Maybe<V2MilestoneEntityResponseCollection>;
  validatePeerGroupMembers?: Maybe<Scalars['Boolean']>;
};


export type QueryChallengeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryChallengeProgressArgs = {
  challengeId: Scalars['ID'];
};


export type QueryChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryChapterArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryChaptersArgs = {
  filters?: InputMaybe<ChapterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCohortDashboardStructureArgs = {
  curriculumId: Scalars['ID'];
};


export type QueryCompetenciesArgs = {
  filters?: InputMaybe<CompetencyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCompetencyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompetencyAreaArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCompetencyAreasArgs = {
  filters?: InputMaybe<CompetencyAreaFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryConcentrationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryConcentrationsArgs = {
  filters?: InputMaybe<ConcentrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCourseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCoursesArgs = {
  filters?: InputMaybe<CourseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCurriculaArgs = {
  filters?: InputMaybe<CurriculumFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCurriculumArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCurriculumFinishStatsArgs = {
  id: Scalars['ID'];
};


export type QueryCurriculumFinishStatsForUserArgs = {
  id: Scalars['ID'];
  sub: Scalars['ID'];
};


export type QueryExamArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryExamsArgs = {
  filters?: InputMaybe<ExamFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFeedbackPartnerArgs = {
  challengeId: Scalars['ID'];
};


export type QueryGetFilteredUserProfilesArgs = {
  lastEvaluatedSub?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
};


export type QueryGetIsUrlValidArgs = {
  url: Scalars['String'];
};


export type QueryGetUserProfileArgs = {
  email: Scalars['String'];
};


export type QueryGetUserProfileByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetUserProfileByRoleArgs = {
  role: Scalars['String'];
};


export type QueryGetUserProfileBySubArgs = {
  sub: Scalars['ID'];
};


export type QueryGetUserProfilesBySubsArgs = {
  subs?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryGroupChallengeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupChallengeFinishStatsArgs = {
  id: Scalars['ID'];
};


export type QueryGroupChallengesArgs = {
  filters?: InputMaybe<GroupChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryKeyTermArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryKeyTermsArgs = {
  filters?: InputMaybe<KeyTermFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLearnersByCurriculumIdArgs = {
  curriculumId: Scalars['ID'];
};


export type QueryLearningOutcomeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLearningOutcomesArgs = {
  filters?: InputMaybe<LearningOutcomeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLearningProfileArgs = {
  userSub?: InputMaybe<Scalars['String']>;
};


export type QueryLessonArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardFillInBlankArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardFillInBlanksArgs = {
  filters?: InputMaybe<LessonCardFillInBlankFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardMatchingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardMatchingsArgs = {
  filters?: InputMaybe<LessonCardMatchingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardMultipleMatchingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardMultipleMatchingsArgs = {
  filters?: InputMaybe<LessonCardMultipleMatchingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardOptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardOptionsArgs = {
  filters?: InputMaybe<LessonCardOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardProgressArgs = {
  cardId: Scalars['ID'];
  cardType: LessonQuizCardType;
};


export type QueryLessonCardRankArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardRanksArgs = {
  filters?: InputMaybe<LessonCardRankFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardReflectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardReflectionsArgs = {
  filters?: InputMaybe<LessonCardReflectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardTrueFalseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardTrueFalsesArgs = {
  filters?: InputMaybe<LessonCardTrueFalseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonCardViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLessonCardViewsArgs = {
  filters?: InputMaybe<LessonCardViewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonFinishStatArgs = {
  id: Scalars['ID'];
};


export type QueryLessonFinishStatForUserArgs = {
  id: Scalars['ID'];
  sub: Scalars['ID'];
};


export type QueryLessonProgressArgs = {
  lessonId: Scalars['ID'];
};


export type QueryLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLessonsStatsByMilestoneArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPartnersArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPeerGroupArgs = {
  challengeId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryPeerGroupAssessmentReviewsArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type QueryPeerGroupMilestoneArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  peerGroupId: Scalars['ID'];
};


export type QueryPeerGroupsArgs = {
  challengeId: Scalars['ID'];
};


export type QueryPhaseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPhaseProgressByCurriculumIdArgs = {
  curriculumId: Scalars['ID'];
};


export type QueryPhasesArgs = {
  filters?: InputMaybe<PhaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProgramArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProgramTypeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProgramTypesArgs = {
  filters?: InputMaybe<ProgramTypeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProgramsArgs = {
  filters?: InputMaybe<ProgramFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProgressBySubsArgs = {
  data: Array<ProgressBySubsDataInput>;
  subs?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySoloChallengeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySoloChallengesArgs = {
  filters?: InputMaybe<SoloChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySubCompetenciesArgs = {
  filters?: InputMaybe<SubCompetencyFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySubCompetencyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUserAssessmentReviewsArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryUserChallengesByPeerGroupIdArgs = {
  challengeId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryUserChallengesByPhaseIdArgs = {
  phaseId: Scalars['ID'];
};


export type QueryUserChallengesByUserIdArgs = {
  id: Scalars['ID'];
  query?: InputMaybe<Queryable>;
};


export type QueryUserConsentArgs = {
  consentId: Scalars['ID'];
};


export type QueryUserMilestoneProgressArgs = {
  challengeId: Scalars['ID'];
  milestoneId: Scalars['ID'];
};


export type QueryUserMilestonesByUserIdArgs = {
  challengeId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryV2MilestoneArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryV2MilestonesArgs = {
  filters?: InputMaybe<V2MilestoneFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryValidatePeerGroupMembersArgs = {
  challengeId: Scalars['ID'];
  userSubs: Array<Scalars['ID']>;
};

export type Queryable = {
  cursor?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type ReviewFieldResponse = {
  __typename?: 'ReviewFieldResponse';
  question?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

export type ReviewFieldResponseInput = {
  question?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

export type ReviewInput = {
  responses?: InputMaybe<Array<InputMaybe<ReviewFieldResponseInput>>>;
  revieweeSub: Scalars['ID'];
};

export enum ReviewStatus {
  /**
   * Review has been submitted, this follows OPEN.
   * Next state is READ.
   */
  Done = 'DONE',
  /**
   * Revieee has requested feedback, initial state.
   * Next state is DONE.
   */
  Open = 'OPEN',
  /** Awaiting action of the reviewee. */
  Pending = 'PENDING',
  /**
   * Reviewee has opened the message, this follows DONE.
   * This is the final state.
   */
  Read = 'READ'
}

export type ReviewTemplate = {
  __typename?: 'ReviewTemplate';
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  question?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SocialMediaProfile = {
  __typename?: 'SocialMediaProfile';
  label?: Maybe<Scalars['String']>;
  type?: Maybe<SocialMediaType>;
  url?: Maybe<Scalars['String']>;
};

export type SocialMediaProfileInput = {
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SocialMediaType>;
  url?: InputMaybe<Scalars['String']>;
};

export enum SocialMediaType {
  Facebook = 'FACEBOOK',
  Github = 'GITHUB',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Medium = 'MEDIUM',
  Twitter = 'TWITTER',
  Website = 'WEBSITE',
  Xing = 'XING'
}

export type SoloChallenge = {
  __typename?: 'SoloChallenge';
  chapter?: Maybe<ChapterEntityResponse>;
  class?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliverables?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disableReview?: Maybe<Scalars['Boolean']>;
  evaluationCriteria?: Maybe<Scalars['String']>;
  extendedReview?: Maybe<Scalars['Boolean']>;
  graded?: Maybe<Scalars['Boolean']>;
  itemizedEvaluationCriteria?: Maybe<Array<Maybe<ComponentChallengesEvaluationCriterion>>>;
  milestones?: Maybe<Array<Maybe<ComponentChallengesMilestoneField>>>;
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  reviewTemplate?: Maybe<Array<Maybe<ComponentChallengesReviewField>>>;
  slackChannel?: Maybe<Scalars['String']>;
  teachingAssistants?: Maybe<Array<Maybe<ComponentChallengesTeachingAssistantField>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SoloChallengeItemizedEvaluationCriteriaArgs = {
  filters?: InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SoloChallengeMilestonesArgs = {
  filters?: InputMaybe<ComponentChallengesMilestoneFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SoloChallengeReviewTemplateArgs = {
  filters?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SoloChallengeTeachingAssistantsArgs = {
  filters?: InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** type will be deprecated and implement ChallengeCriterionEvaluation */
export type SoloChallengeCriterionEvaluation = {
  __typename?: 'SoloChallengeCriterionEvaluation';
  comment?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  gradingStatus?: Maybe<SoloChallengeGradingStatus>;
  /** Identifer of the associated solo challenge criterion from Strapi. */
  id?: Maybe<Scalars['ID']>;
};

export type SoloChallengeCriterionEvaluationInput = {
  comment?: InputMaybe<Scalars['String']>;
  grade: Scalars['String'];
  /** Identifer of the associated solo challenge criterion from Strapi. */
  id: Scalars['ID'];
};

export type SoloChallengeEntity = {
  __typename?: 'SoloChallengeEntity';
  attributes?: Maybe<SoloChallenge>;
  id?: Maybe<Scalars['ID']>;
};

export type SoloChallengeEntityResponse = {
  __typename?: 'SoloChallengeEntityResponse';
  data?: Maybe<SoloChallengeEntity>;
};

export type SoloChallengeEntityResponseCollection = {
  __typename?: 'SoloChallengeEntityResponseCollection';
  data: Array<SoloChallengeEntity>;
  meta: ResponseCollectionMeta;
};

/**
 * SoloChallenge type has been reserved for content service,
 * to prevent duplication this service will use SoloChallengeProgress
 */
export type SoloChallengeEvaluation = {
  __typename?: 'SoloChallengeEvaluation';
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradedReviews?: Maybe<Array<Maybe<SoloChallengeGradedReviewProgress>>>;
  gradingStatus?: Maybe<SoloChallengeGradingStatus>;
  id?: Maybe<Scalars['ID']>;
  pendingReviews?: Maybe<Array<Maybe<SoloChallengeReviewRequest>>>;
  publishStatus?: Maybe<SoloChallengeProgressPublishStatus>;
  reviewer?: Maybe<StudentProfile>;
  reviewerSub?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<Maybe<SoloChallengeReviewProgress>>>;
  sub?: Maybe<Scalars['ID']>;
};

export type SoloChallengeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SoloChallengeFiltersInput>>>;
  chapter?: InputMaybe<ChapterFiltersInput>;
  class?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deliverables?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  disableReview?: InputMaybe<BooleanFilterInput>;
  evaluationCriteria?: InputMaybe<StringFilterInput>;
  extendedReview?: InputMaybe<BooleanFilterInput>;
  graded?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  itemizedEvaluationCriteria?: InputMaybe<ComponentChallengesEvaluationCriterionFiltersInput>;
  milestones?: InputMaybe<ComponentChallengesMilestoneFieldFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SoloChallengeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SoloChallengeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  reviewTemplate?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  slackChannel?: InputMaybe<StringFilterInput>;
  teachingAssistants?: InputMaybe<ComponentChallengesTeachingAssistantFieldFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

/** type will be deprecated and should implement ChallengeGradedReviewProgress */
export type SoloChallengeGradedReviewProgress = {
  __typename?: 'SoloChallengeGradedReviewProgress';
  criteria?: Maybe<Array<Maybe<SoloChallengeCriterionEvaluation>>>;
  /**
   * The summarized status. If any sub element is failed, this
   * status will be failed.
   */
  gradingStatus?: Maybe<SoloChallengeGradingStatus>;
  /** Idenfifier. Uses this a reviewRequestId or reviewId. */
  id: Scalars['ID'];
  /** Available when a review has been comitted. */
  reviewedOn?: Maybe<Scalars['String']>;
  /** The general review status. */
  status?: Maybe<ReviewStatus>;
};

/**
 * UN_SUBMITTED -> SUBMITTED -> PEER_REVIED
 * Or
 * UN_SUBMITTED -> SUBMITTED -> GRADED_PASSED
 * Or
 * UN_SUBMITTED -> SUBMITTED -> GRADE_FAILED_RE_SUBMITTABLE -> RE_SUBMITTED -> GRADE_FAILED
 * Or
 * UN_SUBMITTED -> SUBMITTED -> GRADE_FAILED_RE_SUBMITTABLE -> RE_SUBMITTED -> GRADED_PASSED
 */
export enum SoloChallengeGradingStatus {
  DoneNoReview = 'DONE_NO_REVIEW',
  GradedPassed = 'GRADED_PASSED',
  GradeFailed = 'GRADE_FAILED',
  GradeFailedReSubmittable = 'GRADE_FAILED_RE_SUBMITTABLE',
  PeerReviewed = 'PEER_REVIEWED',
  ReSubmitted = 'RE_SUBMITTED',
  Submitted = 'SUBMITTED',
  UnSubmitted = 'UN_SUBMITTED'
}

export type SoloChallengeInput = {
  chapter?: InputMaybe<Scalars['ID']>;
  class?: InputMaybe<Scalars['Int']>;
  deliverables?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disableReview?: InputMaybe<Scalars['Boolean']>;
  evaluationCriteria?: InputMaybe<Scalars['String']>;
  extendedReview?: InputMaybe<Scalars['Boolean']>;
  graded?: InputMaybe<Scalars['Boolean']>;
  itemizedEvaluationCriteria?: InputMaybe<Array<InputMaybe<ComponentChallengesEvaluationCriterionInput>>>;
  milestones?: InputMaybe<Array<InputMaybe<ComponentChallengesMilestoneFieldInput>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  reviewTemplate?: InputMaybe<Array<InputMaybe<ComponentChallengesReviewFieldInput>>>;
  slackChannel?: InputMaybe<Scalars['String']>;
  teachingAssistants?: InputMaybe<Array<InputMaybe<ComponentChallengesTeachingAssistantFieldInput>>>;
};

/** Category for working on solo challenges. */
export type SoloChallengeMutation = {
  __typename?: 'SoloChallengeMutation';
  /**
   * This will set the reviewer for the solo challenge. This reviewer
   * will be assigned to the attention field, when submitted for review.
   */
  assignReviewer?: Maybe<SoloChallengeEvaluation>;
  initSoloChallengeFolder?: Maybe<SoloChallengeEvaluation>;
  /** Flag if review has been read by the current user. */
  markReviewAsRead?: Maybe<SoloChallengeEvaluation>;
  publishToCohort?: Maybe<SoloChallengeEvaluation>;
  reSubmitForGrading?: Maybe<SoloChallengeEvaluation>;
  /** Update the Google drive Id associated with this solo challenge. */
  setGoogleDriveId?: Maybe<SoloChallengeEvaluation>;
  submitForGrading?: Maybe<SoloChallengeEvaluation>;
  /** Creates a review card, with the assigned reviewer. */
  submitForReview?: Maybe<SoloChallengeEvaluation>;
  /**
   * Submit evaluation of criteria for a graded solo challenge review request.
   * This method will discrimate on the graded flag of the solo challenge, but
   * will simply submit any provided data.
   */
  submitGradedReview?: Maybe<SoloChallengeGradedReviewProgress>;
  /**
   * This is an action to done by the reviewer. And will upate the
   * reivew card of the reviewee.
   */
  submitReview?: Maybe<SoloChallengeReviewProgress>;
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationAssignReviewerArgs = {
  revieweeSub: Scalars['ID'];
  reviewerSub: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationInitSoloChallengeFolderArgs = {
  otherUserSub?: InputMaybe<Scalars['ID']>;
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationMarkReviewAsReadArgs = {
  reviewRequestId: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationSetGoogleDriveIdArgs = {
  driveId: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationSubmitGradedReviewArgs = {
  criteria?: InputMaybe<Array<InputMaybe<SoloChallengeCriterionEvaluationInput>>>;
  reviewRequestId: Scalars['ID'];
  revieweeSub: Scalars['ID'];
};


/** Category for working on solo challenges. */
export type SoloChallengeMutationSubmitReviewArgs = {
  review?: InputMaybe<ReviewInput>;
};

export enum SoloChallengeProgressPublishStatus {
  Published = 'PUBLISHED',
  UnPublished = 'UN_PUBLISHED'
}

export type SoloChallengeReview = {
  __typename?: 'SoloChallengeReview';
  /** The question reponded to. */
  question?: Maybe<Scalars['String']>;
  /** The value submitted by the reviews */
  text?: Maybe<Scalars['String']>;
};

export type SoloChallengeReviewProgress = {
  __typename?: 'SoloChallengeReviewProgress';
  id: Scalars['ID'];
  responses?: Maybe<Array<Maybe<ReviewFieldResponse>>>;
  /** Available when a review has been comitted. */
  reviewedOn?: Maybe<Scalars['String']>;
  status?: Maybe<ReviewStatus>;
};

export type SoloChallengeReviewRequest = {
  __typename?: 'SoloChallengeReviewRequest';
  googleDriveId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reviewee: StudentProfile;
  /**
   * Will be one of OPEN, DONE or READ. Open when requested. Done
   * when a review has been submitted. READ when marked has having been
   * read by a client
   */
  status?: Maybe<ReviewStatus>;
};

export type StaticChallenge = {
  __typename?: 'StaticChallenge';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type StaticCourse = {
  __typename?: 'StaticCourse';
  groupChallenge?: Maybe<StaticChallenge>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  soloChallenges?: Maybe<Array<Maybe<StaticChallenge>>>;
};

export type StaticPhase = {
  __typename?: 'StaticPhase';
  challenges?: Maybe<Array<Maybe<StaticChallenge>>>;
  courses?: Maybe<Array<Maybe<StaticCourse>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StudentProfile = {
  __typename?: 'StudentProfile';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  slackId?: Maybe<Scalars['ID']>;
};

export type SubCompetency = {
  __typename?: 'SubCompetency';
  competency?: Maybe<CompetencyEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  learning_outcomes?: Maybe<LearningOutcomeRelationResponseCollection>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SubCompetencyLearning_OutcomesArgs = {
  filters?: InputMaybe<LearningOutcomeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SubCompetencyEntity = {
  __typename?: 'SubCompetencyEntity';
  attributes?: Maybe<SubCompetency>;
  id?: Maybe<Scalars['ID']>;
};

export type SubCompetencyEntityResponse = {
  __typename?: 'SubCompetencyEntityResponse';
  data?: Maybe<SubCompetencyEntity>;
};

export type SubCompetencyEntityResponseCollection = {
  __typename?: 'SubCompetencyEntityResponseCollection';
  data: Array<SubCompetencyEntity>;
  meta: ResponseCollectionMeta;
};

export type SubCompetencyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SubCompetencyFiltersInput>>>;
  competency?: InputMaybe<CompetencyFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  learning_outcomes?: InputMaybe<LearningOutcomeFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SubCompetencyFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubCompetencyFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubCompetencyInput = {
  competency?: InputMaybe<Scalars['ID']>;
  learning_outcomes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubCompetencyRelationResponseCollection = {
  __typename?: 'SubCompetencyRelationResponseCollection';
  data: Array<SubCompetencyEntity>;
};

export type TaskAssessment = {
  __typename?: 'TaskAssessment';
  commendation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  gradeNumber?: Maybe<Scalars['Int']>;
  recommendation?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TeamChallengeEvaluation = {
  __typename?: 'TeamChallengeEvaluation';
  demoOn?: Maybe<Scalars['String']>;
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradingStatus: ChallengeReviewGradingStatus;
  id?: Maybe<Scalars['ID']>;
  introLesson?: Maybe<Array<Maybe<GroupChallengeIntroFinishStat>>>;
  meetingLink?: Maybe<Scalars['String']>;
  /** Students involved in the group challenge */
  members?: Maybe<Array<Maybe<StudentProfile>>>;
  /** Deprecated, use the plural version 'mentors'. */
  mentor?: Maybe<StudentProfile>;
  mentors: Array<StudentProfile>;
  milestones?: Maybe<Array<Maybe<GroupChallengeMilestoneEvaluation>>>;
  reviews: Array<ChallengeReview>;
  /** Private slack channel for the group */
  slackChannel?: Maybe<Scalars['String']>;
};

export type TeamChallengeEvaluationV2 = {
  __typename?: 'TeamChallengeEvaluationV2';
  /** Listing all groups by group challenge id */
  allGroups: Array<GroupChallengeGroupEvaluation>;
  /** Get group details (member, grade etc) by group challenge group id. */
  group?: Maybe<TeamChallengeGroupEvaluation>;
  myGroup?: Maybe<TeamChallengeGroupEvaluation>;
};


export type TeamChallengeEvaluationV2GroupArgs = {
  id: Scalars['ID'];
};

export type TeamChallengeGroupEvaluation = {
  __typename?: 'TeamChallengeGroupEvaluation';
  demoOn?: Maybe<Scalars['String']>;
  /** Identifier default working folder. */
  googleDriveId?: Maybe<Scalars['ID']>;
  gradingStatus: ChallengeReviewGradingStatus;
  id?: Maybe<Scalars['ID']>;
  introLesson?: Maybe<Array<Maybe<GroupChallengeIntroFinishStat>>>;
  meetingLink?: Maybe<Scalars['String']>;
  /** Students involved in the group challenge */
  members?: Maybe<Array<Maybe<StudentProfile>>>;
  /** Deprecated, use the plural version 'mentors'. */
  mentor?: Maybe<StudentProfile>;
  mentors: Array<StudentProfile>;
  milestones?: Maybe<Array<Maybe<GroupChallengeMilestoneEvaluation>>>;
  reviews: Array<ChallengeReview>;
  /** Private slack channel for the group */
  slackChannel?: Maybe<Scalars['String']>;
};

export type TeamChallengeGroupMutation = {
  __typename?: 'TeamChallengeGroupMutation';
  /**
   * Add mentor with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  addMentor?: Maybe<TeamChallengeEvaluation>;
  /**
   * Add student with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  addStudent?: Maybe<TeamChallengeEvaluation>;
  /**
   * Remove mentor with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  removeMentor?: Maybe<TeamChallengeEvaluation>;
  /**
   * Remove student with the given sub the current group.
   * [to add new user into a group in group challenge]
   */
  removeStudent?: Maybe<TeamChallengeEvaluation>;
  /**
   * Submit a review a new review for the current group.
   * Requires elevated access.
   * [Set grade to a group]
   * TODO not yet implemented
   */
  submitReview?: Maybe<TeamChallengeEvaluation>;
  /**
   * Submit update group instance for setting googleDriveId & slack channel id
   *
   * The groupInstanceId is id's of group challenge instances its from GroupChallengeGroupEvaluation that will be updated.
   * The groupInstance is object payload of TeamChallengeEvaluationInput.
   *
   * [Set data group of group challenge data]
   */
  update: GroupChallengeGroupEvaluation;
  /**
   * Update an existing review for the current group.
   * Requires elevated access.
   * TODO not yet implemented
   */
  updateReview?: Maybe<TeamChallengeEvaluation>;
};


export type TeamChallengeGroupMutationAddMentorArgs = {
  sub: Scalars['ID'];
};


export type TeamChallengeGroupMutationAddStudentArgs = {
  sub: Scalars['ID'];
};


export type TeamChallengeGroupMutationRemoveMentorArgs = {
  sub: Scalars['ID'];
};


export type TeamChallengeGroupMutationRemoveStudentArgs = {
  sub: Scalars['ID'];
};


export type TeamChallengeGroupMutationSubmitReviewArgs = {
  criteria: Array<ChallengeReviewCriterionInput>;
};


export type TeamChallengeGroupMutationUpdateArgs = {
  groupInstanceData: GroupChallengeGroupInput;
};


export type TeamChallengeGroupMutationUpdateReviewArgs = {
  criteria: Array<ChallengeReviewCriterionInput>;
  reviewId: Scalars['ID'];
};

export type TeamChallengeMutation = {
  __typename?: 'TeamChallengeMutation';
  /**
   * Create a new group for the provided group challenge.
   * The id id the group challenge id from Strapi.
   * If a group already exist for the provided ID, that group is returned.
   *
   * The cohortId is a unique values that is associated with a cluster of students.
   * The groupId is and identifier which must be unique within gohortId.
   *
   * [create group by group challenge id]
   *
   * Requires elevated access.
   */
  addGroup: TeamChallengeEvaluation;
  /**
   * Get specific group by id.
   * Requires elevated access.
   */
  group?: Maybe<TeamChallengeGroupMutation>;
  /**
   * Flags if review has been read by the current user
   * TODO should moved to group mutation.
   */
  markReviewAsRead?: Maybe<TeamChallengeEvaluation>;
  submitForReview?: Maybe<TeamChallengeEvaluation>;
};


export type TeamChallengeMutationAddGroupArgs = {
  cohortId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type TeamChallengeMutationGroupArgs = {
  id: Scalars['ID'];
};


export type TeamChallengeMutationMarkReviewAsReadArgs = {
  reviewRequestId: Scalars['ID'];
};


export type TeamChallengeMutationSubmitForReviewArgs = {
  milestoneId: Scalars['ID'];
};

export type UpdatePeerGroupDueDateInput = {
  dueDate?: InputMaybe<Scalars['String']>;
  milestoneId: Scalars['ID'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserAssessmentReview = {
  __typename?: 'UserAssessmentReview';
  assessmentViewedAt?: Maybe<Scalars['String']>;
  assessorSub?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deliverable?: Maybe<AssessmentDeliverable>;
  grade?: Maybe<UserMilestoneStatus>;
  id: Scalars['String'];
  overallGrade?: Maybe<Scalars['Float']>;
  sub: Scalars['String'];
  taskAssessment?: Maybe<Array<Maybe<TaskAssessment>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  consentId?: Maybe<Scalars['ID']>;
  termsId?: Maybe<Scalars['String']>;
};

export type UserConsentInput = {
  hasConsented: Scalars['Boolean'];
  name: ConsentName;
  version: Scalars['String'];
};

export type UserConsentMutation = {
  __typename?: 'UserConsentMutation';
  giveConsentTo?: Maybe<UserConsent>;
};


export type UserConsentMutationGiveConsentToArgs = {
  termsId?: InputMaybe<Scalars['String']>;
};

export type UserConsentQuery = {
  __typename?: 'UserConsentQuery';
  /**
   *  Answers the data string of the latest data accepted.
   * date of terms are sorted lexicographically, which defines latest.
   */
  latest?: Maybe<UserConsent>;
};

export type UserLessonCardProgress = {
  __typename?: 'UserLessonCardProgress';
  cardType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserLessonProgress = {
  __typename?: 'UserLessonProgress';
  completedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isLessonComplete?: Maybe<Scalars['Boolean']>;
  lessonCardComplete?: Maybe<Scalars['Int']>;
  lessonCardCount?: Maybe<Scalars['Int']>;
  lessonCards?: Maybe<Array<Maybe<UserLessonCardProgress>>>;
  sub?: Maybe<Scalars['String']>;
};

export type UserMilestone = {
  __typename?: 'UserMilestone';
  assessmentDeliverables?: Maybe<Array<Maybe<AssessmentDeliverable>>>;
  challengeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  feedback?: Maybe<Array<Maybe<ReviewTemplate>>>;
  googleDriveIdLinks?: Maybe<Array<Maybe<GoogleDriveIdLinks>>>;
  id?: Maybe<Scalars['String']>;
  isCapstone?: Maybe<Scalars['Boolean']>;
  isMilestoneComplete?: Maybe<Scalars['Boolean']>;
  lessonProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  lessons?: Maybe<FinishStats>;
  milestoneId?: Maybe<Scalars['String']>;
  milestoneType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  peerGroupId?: Maybe<Scalars['String']>;
  reviewerProfile?: Maybe<FeedbackPartnerProfile>;
  reviewerSub?: Maybe<Scalars['String']>;
  status?: Maybe<UserMilestoneStatus>;
  sub?: Maybe<Scalars['String']>;
  userProfile?: Maybe<FeedbackPartnerProfile>;
};

export enum UserMilestoneStatus {
  Achieved = 'ACHIEVED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  NotAchieved = 'NOT_ACHIEVED',
  PeerReviewed = 'PEER_REVIEWED',
  ReSubmitted = 'RE_SUBMITTED',
  Submitted = 'SUBMITTED',
  UnSubmitted = 'UN_SUBMITTED'
}

export type UserProfile = {
  __typename?: 'UserProfile';
  about?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  consentHistory?: Maybe<Array<Maybe<ConsentHistory>>>;
  consentedToTermsAt?: Maybe<Scalars['String']>;
  consentedToTrackingAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  locationCity?: Maybe<Scalars['String']>;
  locationCountryCode?: Maybe<Scalars['String']>;
  locationCountryName?: Maybe<Scalars['String']>;
  locationLatitude?: Maybe<Scalars['String']>;
  locationLongitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  slackId?: Maybe<Scalars['String']>;
  socialMediaProfiles?: Maybe<Array<Maybe<SocialMediaProfile>>>;
  sub: Scalars['ID'];
  updatedAt?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UserProfilePaginationResponse = {
  __typename?: 'UserProfilePaginationResponse';
  items?: Maybe<Array<Maybe<UserProfile>>>;
  lastEvaluatedSub?: Maybe<Scalars['String']>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type V2Milestone = {
  __typename?: 'V2Milestone';
  challenges?: Maybe<ChallengeRelationResponseCollection>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationDays: Scalars['Int'];
  evaluationCriteria?: Maybe<Array<Maybe<ComponentMilestonesEvaluationCriteria>>>;
  google_drive_id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  learningOutcomes?: Maybe<Array<Maybe<ComponentLearningOutcomeThreshold>>>;
  lessonOrder?: Maybe<Array<Maybe<ComponentMilestonesLessonOrder>>>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  order?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  reviewTemplate?: Maybe<Array<Maybe<ComponentChallengesReviewField>>>;
  slack_id?: Maybe<Scalars['String']>;
  sub_title?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: Enum_V2Milestone_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type V2MilestoneChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type V2MilestoneEvaluationCriteriaArgs = {
  filters?: InputMaybe<ComponentMilestonesEvaluationCriteriaFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type V2MilestoneLearningOutcomesArgs = {
  filters?: InputMaybe<ComponentLearningOutcomeThresholdFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type V2MilestoneLessonOrderArgs = {
  filters?: InputMaybe<ComponentMilestonesLessonOrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type V2MilestoneLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type V2MilestoneReviewTemplateArgs = {
  filters?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type V2MilestoneEntity = {
  __typename?: 'V2MilestoneEntity';
  attributes?: Maybe<V2Milestone>;
  id?: Maybe<Scalars['ID']>;
};

export type V2MilestoneEntityResponse = {
  __typename?: 'V2MilestoneEntityResponse';
  data?: Maybe<V2MilestoneEntity>;
};

export type V2MilestoneEntityResponseCollection = {
  __typename?: 'V2MilestoneEntityResponseCollection';
  data: Array<V2MilestoneEntity>;
  meta: ResponseCollectionMeta;
};

export type V2MilestoneFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<V2MilestoneFiltersInput>>>;
  challenges?: InputMaybe<ChallengeFiltersInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dueDate?: InputMaybe<DateFilterInput>;
  durationDays?: InputMaybe<IntFilterInput>;
  evaluationCriteria?: InputMaybe<ComponentMilestonesEvaluationCriteriaFiltersInput>;
  google_drive_id?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  instructions?: InputMaybe<StringFilterInput>;
  learningOutcomes?: InputMaybe<ComponentLearningOutcomeThresholdFiltersInput>;
  lessonOrder?: InputMaybe<ComponentMilestonesLessonOrderFiltersInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  not?: InputMaybe<V2MilestoneFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<V2MilestoneFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  reviewTemplate?: InputMaybe<ComponentChallengesReviewFieldFiltersInput>;
  slack_id?: InputMaybe<StringFilterInput>;
  sub_title?: InputMaybe<StringFilterInput>;
  teaser?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type V2MilestoneInput = {
  challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  content?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  durationDays?: InputMaybe<Scalars['Int']>;
  evaluationCriteria?: InputMaybe<Array<InputMaybe<ComponentMilestonesEvaluationCriteriaInput>>>;
  google_drive_id?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  learningOutcomes?: InputMaybe<Array<InputMaybe<ComponentLearningOutcomeThresholdInput>>>;
  lessonOrder?: InputMaybe<Array<InputMaybe<ComponentMilestonesLessonOrderInput>>>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  reviewTemplate?: InputMaybe<Array<InputMaybe<ComponentChallengesReviewFieldInput>>>;
  slack_id?: InputMaybe<Scalars['String']>;
  sub_title?: InputMaybe<Scalars['String']>;
  teaser?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_V2Milestone_Type>;
};

export type V2MilestoneRelationResponseCollection = {
  __typename?: 'V2MilestoneRelationResponseCollection';
  data: Array<V2MilestoneEntity>;
};

import { APP_ROUTES } from "@/routes";
import { PlusOutlined, SlackOutlined } from "@ant-design/icons";
import styles from "@css/common.module.scss";
import { Button, Descriptions, Skeleton, Space, Tooltip } from "antd";
import Markdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";

import useChallenge from "@hooks/query/useChallenge";
import useDisclosure from "@hooks/useDisclosure";

import AppLayout from "@layouts/AppLayout";

import { arrayToPageTitle } from "@libs/utils/common";

import CreatePeerGroupModal from "@views/ChallengeDetail/CreatePeerGroupModal";
import PeerGroupsList from "@views/ChallengeDetail/PeerGroupsList";

const ChallengesDetailPage = () => {
  const createPeerGroupModal = useDisclosure();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { challenge: challengeData, isLoading } = useChallenge(id);
  const challenge = challengeData?.attributes;
  const hasMilestone = (challenge?.v2Milestones?.data ?? []).length > 0;

  return (
    <AppLayout
      title={arrayToPageTitle([challenge?.name, "Challenge Detail"])}
      onPressBack={() => history.push(APP_ROUTES.CHALLENGES)}
    >
      <div className={styles.root}>
        {isLoading && <Skeleton active />}
        {!isLoading && (
          <>
            <div className="challenge-detail">
              <Descriptions
                title="Challenge Detail"
                bordered
                size="small"
                layout="vertical"
              >
                <Descriptions.Item
                  label="Name"
                  span={3}
                  className={styles.left}
                >
                  {challenge?.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Description"
                  span={3}
                  className={styles.left}
                >
                  <Markdown>{challenge?.description || ""}</Markdown>
                </Descriptions.Item>
                <Descriptions.Item
                  label="TA Group"
                  span={2}
                  className={styles.left}
                >
                  {challenge?.teachingAssistants
                    ?.map((a) => a?.email)
                    .join(", ") || "--"}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div className={styles.tableActions}>
              <Space>
                <Button
                  disabled={!challenge?.slackChannel}
                  type="default"
                  icon={<SlackOutlined />}
                  href={`${challenge?.slackChannel}`}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                >
                  Open Slack channel
                </Button>
                {hasMilestone && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={createPeerGroupModal.onOpen}
                  >
                    Create Peer Group
                  </Button>
                )}

                {!hasMilestone && (
                  <Tooltip
                    placement="top"
                    title="No milestone attached to this challenge"
                    color="red"
                  >
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      disabled={true}
                    >
                      Create Peer Group
                    </Button>
                  </Tooltip>
                )}
              </Space>
            </div>

            <CreatePeerGroupModal challengeId={id} {...createPeerGroupModal} />

            <div className={styles.table}>
              <PeerGroupsList challengeId={id} />
            </div>
          </>
        )}
      </div>
    </AppLayout>
  );
};

export default ChallengesDetailPage;

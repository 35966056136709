import colors from "@css/colors.json";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Tag } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import { SoloChallengeEntity } from "@libs/service/graphql/generated/types.d";
import { soloChallengesRequestV2 } from "@libs/service/solochallenge";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
    button: {
      width: "120px",
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    newBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "12px",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: 1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      color: "#8A857F",
    },
  })
);

export default function SoloChallengePage() {
  const styles = useStyles();
  const history = useHistory();

  const { apigatewayClient: client } = useService();
  const { data: soloChallengeData, error: soloChallengeError } = useSWR(
    (client &&
      soloChallengesRequestV2(client as GraphQLClient, "name", 99, 0)
        .cacheKey) ||
      null,
    (client &&
      soloChallengesRequestV2(client as GraphQLClient, "name", 99, 0).fetch) ||
      null
  );
  const soloChallengesDataMapped = soloChallengeData?.map((scData) => {
    return {
      ...scData,
      taEmail: scData.attributes?.teachingAssistants
        ?.map((ta) => {
          return ta?.email || "";
        })
        .join(", "),
    };
  });

  function redirectToSoloList(record: SoloChallengeEntity) {
    history.push(`/solo-challenge/${base64.encode(record.id ?? "")}`);
  }

  const { getColumnSearchProps } = useTableColumnSearch();
  const columns: GenericTableColumn<SoloChallengeEntity> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Name",
      dataIndex: ["attributes", "name"],
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Graded",
      dataIndex: ["attributes", "graded"],
      key: "graded",
      filters: [
        { text: "GRADED", value: true },
        { text: "NON-GRADED", value: false },
      ],
      onFilter: (value, record) => {
        if (value) {
          return !!record.attributes?.graded;
        } else {
          return !record.attributes?.graded;
        }
      },
      render: (text, record) =>
        record === null ? (
          <></>
        ) : record.attributes?.graded ? (
          <Tag color="green">YES</Tag>
        ) : (
          <Tag color="red">NO</Tag>
        ),
    },
    {
      title: "TA Email",
      dataIndex: "taEmail",
      key: "taEmail",
      ...getColumnSearchProps("taEmail"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => redirectToSoloList(record)}>View Detail</Button>
      ),
    },
  ];
  if (soloChallengeError) {
    return <p>Failed to fetching detail info.</p>;
  }

  return (
    <AppLayout title="Solo Challenges">
      <div className={styles.root}>
        <GenericTable
          columns={columns}
          dataSource={soloChallengesDataMapped ?? []}
          loading={!soloChallengesDataMapped}
          rowKey={(item: SoloChallengeEntity) =>
            `${item.id}-${item.attributes?.name}`
          }
        />
      </div>
    </AppLayout>
  );
}

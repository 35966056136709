import { loader } from "graphql.macro";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  GetUserProfileByRoleQuery,
  GetUserProfileByRoleQueryVariables,
} from "@libs/service/user-graphql/generated/operations";
import { UserProfile } from "@libs/service/user-graphql/generated/types";

const gqlQuery = loader(
  "../../lib/service/user-graphql/query/GetUserProfileByRole.graphql"
);

export const USER_ROLE = {
  STUDENT: "Student",
  STAFF: "Staff,Student",
};

const useUsersByRole = (role: string) => {
  const { userClient: client } = useService();

  const { data, error } = useSWR(
    client && role ? `getUsersByRole${role}` : null,
    () =>
      client
        ?.request<
          GetUserProfileByRoleQuery,
          GetUserProfileByRoleQueryVariables
        >(gqlQuery, {
          role,
        })
        .then((result) => {
          return result?.getUserProfileByRole;
        })
  );

  return {
    data: data as UserProfile[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUsersByRole;

import colors from "@css/colors.json";
import { Avatar, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { GraphQLClient } from "graphql-request";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import { allUsersRequest } from "@libs/service/allusers";

interface UserDetailsProps {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: "0 50px 0 0",
    },
    userIntro: {
      display: "flex",
      alignItems: "center",
      textAlign: "start",
      marginBottom: "20px",
      color: colors.textLight,
    },
    userInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "start",
      marginBottom: "20px",
      borderRadius: "2px",
      padding: "20px 60px",
      color: "#8A857F",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  })
);

const UserDetailsAvatar: React.FC<UserDetailsProps> = (props) => {
  const id = props.userId;
  const { adminClient: client } = useService();

  const { data: allUsersData, error: allUsersError } = useSWR(
    (client && allUsersRequest(client as GraphQLClient, id).cacheKey) || null,
    (client && allUsersRequest(client as GraphQLClient, id).fetch) || null
  );

  const users = allUsersData;
  const user = users?.find((user) => user.userId === id);
  const classes = useStyles();

  if (!allUsersData) return <p>Loading...</p>;
  if (allUsersError) return <p>Error</p>;

  return (
    <div className={classes.userInfo}>
      <div>
        <Avatar
          alt={user?.name ?? ""}
          src={user?.picture ?? ""}
          className={classes.large}
        />
        <Typography>{user?.name}</Typography>
      </div>
      <div>
        <Typography>Email: {user?.email}</Typography>
      </div>
    </div>
  );
};

export default UserDetailsAvatar;

import { APP_ROUTES, ROUTE_QUERY_KEYS } from "@/routes";
import { Milestone } from "@/types/milestone";
import styles from "@css/common.module.scss";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import useMilestoneById from "@hooks/query/useMilestoneById";
import useUserMilestonesByUserId from "@hooks/query/useUserMilestonesByUserId";
import useLocationQuery from "@hooks/useLocationQuery";

import AppLayout from "@layouts/AppLayout";

import { UserMilestoneStatus } from "@libs/service/graphql/generated/types.d";
import { UserMilestoneType } from "@libs/types/types";

import { GradeAssessment, GradeGroupAssessment } from "@views/GradeMilestone";

const GradeMilestonePage = () => {
  const history = useHistory();
  const query = useLocationQuery();

  const userId = query.get(ROUTE_QUERY_KEYS.USER_ID) as string;

  const {
    id: challengeId,
    milestoneId,
    peerGroupId,
  } = useParams<{
    id: string;
    peerGroupId: string;
    milestoneId: string;
  }>();
  const { milestone } = useMilestoneById(milestoneId);
  const milestoneData = milestone?.attributes;

  const { userMilestones, isLoading: isUserMilestonesLoading } =
    useUserMilestonesByUserId(challengeId, userId);

  const userMilestone = useMemo(
    () => userMilestones?.find((item) => item.id === milestoneId),
    [userMilestones, milestoneId]
  );

  const isAbleToGrade = useMemo(
    () =>
      [UserMilestoneStatus.ReSubmitted, UserMilestoneStatus.Submitted].includes(
        userMilestone?.status as UserMilestoneStatus
      ),
    [userMilestone]
  );

  const title = `${milestoneData?.title} - ${
    userMilestone?.isCapstone ? "Capstone" : "Milestone " + userMilestone?.order
  }`;

  return (
    <AppLayout
      title="Grade User Milestone"
      onPressBack={() =>
        history.push(
          APP_ROUTES.CHALLENGE_MILESTONES(challengeId, peerGroupId, userId)
        )
      }
    >
      <div className={styles.root}>
        <Skeleton loading={isUserMilestonesLoading}>
          {milestone?.attributes?.type === UserMilestoneType.Assessment && (
            <GradeAssessment
              peerGroupId={peerGroupId}
              challengeId={challengeId}
              milestoneId={milestoneId}
              milestoneData={milestoneData as Milestone}
              userId={userId}
              title={title}
              isViewOnly={!isAbleToGrade}
            />
          )}

          {milestone?.attributes?.type ===
            UserMilestoneType.GroupAssessment && (
            <GradeGroupAssessment
              peerGroupId={peerGroupId}
              challengeId={challengeId}
              milestoneId={milestoneId}
              milestoneData={milestoneData as Milestone}
              userId={userId}
              title={title}
              isViewOnly={!isAbleToGrade}
            />
          )}
        </Skeleton>
      </div>
    </AppLayout>
  );
};

export default GradeMilestonePage;

import colors from "@css/colors.json";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import React from "react";
import { useParams } from "react-router";
import useSWR from "swr";

import GradeSelect from "@components/GradeSelect";
import IconSlack from "@components/IconSlack";
import UserDetailsAvatar from "@components/UserDetails";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import { SoloChallengeCriterionEvaluationInput } from "@libs/service/graphql/generated/types.d";
import {
  soloChallengeCriteriaRequest,
  soloChallengeDetailsRequest,
  soloChallengeUserDetailRequest,
  submitGradedReview,
} from "@libs/service/solochallenge";
import { URLParams } from "@libs/types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "68px",
    },
    title: {
      textAlign: "left",
    },
    fieldLabel: {
      fontSize: "16px",
      fontWeight: 600,
      textAlign: "left",
      textTransform: "capitalize",
    },
    alertMessage: {
      fontSize: "16px",
      fontWeight: 600,
      textAlign: "center",
      color: colors.primaryOrange,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    sendIcon: {
      color: "#848382",
      paddingLeft: "200px",
      paddingTop: "5px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "50px",
    },
    divAlign: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginLeft: "20px",
    },
    h3: {
      color: "#848382",
      textAlign: "left",
    },
    h2: {
      color: "#494746",
      fontSize: "30px",
      textAlign: "left",
      fontFamily: "TTNormsProMedium",
    },
    p1: {
      color: "#494746",

      textAlign: "left",
    },
    p2: {
      color: "#848382",
      textAlign: "left",
      width: "80%",
      fontSize: "18px",
    },
    centralizeDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    divContent: {
      width: "50%",
    },
    button: {
      marginTop: theme.spacing(3),
      backgroundColor: "#fad585",
    },
    formContainer: {
      marginTop: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flexStart",
      width: "100%",
    },
    formCard: {
      marginTop: "16px",
    },
  })
);
export type State = {
  gradeCriteria: Array<SoloChallengeCriterionEvaluationInput>;
  alertMessage: string;
  isFormError: boolean;
  isSubmitting: boolean;
};
export default function SoloChallengeDetails() {
  const styles = useStyles();
  const { id, revieweeID } = useParams<URLParams>();
  const decodedId = base64.decode(id);
  const decodedRevieweeID = base64.decode(revieweeID);

  const { apigatewayClient: client } = useService();
  const [state, setState] = React.useState<State>({
    gradeCriteria: [],
    alertMessage: "",
    isFormError: false,
    isSubmitting: false,
  });
  const { data: soloChallengeData, error: soloChallengeError } = useSWR(
    (client &&
      soloChallengeDetailsRequest(client as GraphQLClient, decodedId)
        .cacheKey) ||
      null,
    (client &&
      soloChallengeDetailsRequest(client as GraphQLClient, decodedId).fetch) ||
      null
  );
  const { data: soloChallengeUserData, error: soloUserChallengeError } = useSWR(
    (client &&
      soloChallengeUserDetailRequest(
        client as GraphQLClient,
        decodedRevieweeID,
        decodedId
      ).cacheKey) ||
      null,
    (client &&
      soloChallengeUserDetailRequest(
        client as GraphQLClient,
        decodedRevieweeID,
        decodedId
      ).fetch) ||
      null
  );
  const formErrorMessage = "Please filled up all of input fields to Submit.";
  const handleSubmitReview = (
    soloChallengeId: string,
    revieweeID: string,
    reviewRequestId: string,
    criteria: Array<SoloChallengeCriterionEvaluationInput>
  ) => {
    submitGradedReview(
      client as GraphQLClient,
      soloChallengeId,
      revieweeID, // using target user ID who will get the review
      reviewRequestId, // should using ID from gradedReview ID
      criteria
    )
      .then(() => {
        setState({ ...state, alertMessage: "Data saved!" });
      })
      .catch(() => {
        setState({
          ...state,
          alertMessage: "Failed to save data. Please try again later.",
        });
      });
  };
  // TODO: refactor to use custom hook.
  const { data: soloChallengeCriteriaData, error: soloChallengeCriteriaError } =
    useSWR(
      (client &&
        soloChallengeCriteriaRequest(client as GraphQLClient, decodedId)
          .cacheKey) ||
        null,
      (client &&
        soloChallengeCriteriaRequest(client as GraphQLClient, decodedId)
          .fetch) ||
        null
    );

  if (!soloChallengeData) return <p>Loading...</p>;
  if (
    soloChallengeError ||
    soloChallengeCriteriaError ||
    soloUserChallengeError
  )
    return <p>Error</p>;

  const currentGradedData =
    soloChallengeUserData &&
    soloChallengeUserData.gradedReviews &&
    soloChallengeUserData.gradedReviews?.length > 0
      ? soloChallengeUserData.gradedReviews[0]?.criteria
      : [];
  if (
    soloChallengeUserData &&
    currentGradedData &&
    currentGradedData?.length > 0 &&
    state.gradeCriteria.length < 1
  ) {
    const gradedState = state.gradeCriteria;
    currentGradedData.forEach((val) => {
      gradedState.push({
        id: val?.id ?? "",
        grade: val?.grade ?? "",
        comment: val?.comment,
      });
    });
    setState({ ...state, gradeCriteria: gradedState });
  }

  const gradedItemID = soloChallengeUserData?.gradedReviews?.[0]?.id
    ? soloChallengeUserData.gradedReviews[0].id
    : `${soloChallengeUserData?.id}:${soloChallengeUserData?.reviewerSub}`;

  return (
    <AppLayout>
      <Container className={styles.root}>
        <UserDetailsAvatar userId={decodedRevieweeID} />
        <div className={styles.header}>
          <Typography
            variant="h5"
            style={{
              color: "#494746",
              textAlign: "left",
            }}
          >
            Solo Challenge: {soloChallengeData.attributes?.name}
          </Typography>
          {soloChallengeData.attributes?.slackChannel && (
            <a
              href={soloChallengeData.attributes?.slackChannel}
              target="_blank"
              rel="noreferrer"
            >
              <IconSlack />
            </a>
          )}
        </div>
        <Card>
          <CardContent>
            <Paper elevation={0}>
              <p className={styles.p1}>
                {" "}
                {soloChallengeData.attributes?.description}
              </p>

              <div className={styles.centralizeDiv}>
                <div className={styles.divContent}>
                  <Typography className={styles.title}>
                    Creation Date{" "}
                  </Typography>
                  <p className={styles.p2}>
                    {" "}
                    {soloChallengeData.attributes?.createdAt}{" "}
                  </p>

                  <Typography className={styles.title}>Update Date:</Typography>
                  <p className={styles.p2}>
                    {" "}
                    {soloChallengeData.attributes?.updatedAt}
                  </p>

                  <Typography className={styles.title}>
                    Deliverables{" "}
                  </Typography>
                  <p className={styles.p2}>
                    {" "}
                    {soloChallengeData.attributes?.deliverables}
                  </p>
                </div>
                <div className={styles.divContent}>
                  <Typography className={styles.title}>
                    Publish Date{" "}
                  </Typography>
                  <p className={styles.p2}>
                    {" "}
                    {soloChallengeData.attributes?.publishedAt}
                  </p>

                  <Typography className={styles.title}>
                    Evaluation Criteria{" "}
                  </Typography>
                  <p className={styles.p2}>
                    {soloChallengeData.attributes?.evaluationCriteria}
                  </p>
                  {soloChallengeData.attributes?.graded && (
                    <>
                      <Typography className={styles.title}>Graded </Typography>
                      <p className={styles.p2}>
                        {" "}
                        {soloChallengeData.attributes?.graded}{" "}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Paper>
            <div className={styles.divAlign}>
              <div className={styles.divButton}>
                {soloChallengeUserData && (
                  <Button
                    variant="contained"
                    color="default"
                    type="button"
                    href={soloChallengeUserData.googleDriveId ?? ""}
                    target="_blank"
                    className={styles.button}
                  >
                    {" "}
                    Open Google Drive File
                  </Button>
                )}
              </div>

              {/*NOTE: temp hide this feedback btn 
              <div className={styles.divButton}>
                <ModalSoloChallenge />
              </div> */}
            </div>
          </CardContent>
        </Card>
        {(soloChallengeCriteriaData?.attributes?.itemizedEvaluationCriteria
          ?.length || 0) > 0 && (
          <Card className={styles.formCard}>
            <CardContent>
              <Typography className={styles.formGridTitle}>
                Grade Criteria
              </Typography>
              {soloChallengeCriteriaData?.attributes?.itemizedEvaluationCriteria?.map(
                (val, key) => {
                  return (
                    <Grid
                      className={styles.formContainer}
                      key={val?.id ?? "" + key}
                    >
                      <Typography className={styles.fieldLabel}>
                        {val?.content ?? ""}
                      </Typography>
                      <GradeSelect
                        criteriaID={val?.id ?? ""}
                        gradeCriteria={state.gradeCriteria}
                        setGradeCriteria={(criteria) =>
                          setState({ ...state, gradeCriteria: criteria })
                        }
                      />
                    </Grid>
                  );
                }
              )}
              <div className={styles.divAlign}>
                {state.isFormError || state.alertMessage ? (
                  <Typography className={styles.alertMessage}>
                    {state.alertMessage}
                  </Typography>
                ) : null}
                <div className={styles.divButton}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const emptyForm = state.gradeCriteria.find(
                        (val) => val.comment === "" || val.grade === ""
                      );
                      const isFoundEmptyForm = emptyForm ? true : false;
                      const isEmpty =
                        isFoundEmptyForm || state.gradeCriteria.length === 0;
                      setState({
                        ...state,
                        alertMessage: isEmpty ? formErrorMessage : "",
                        isSubmitting: !isEmpty,
                        isFormError: isEmpty,
                      });
                      if (!isEmpty) {
                        handleSubmitReview(
                          decodedId,
                          decodedRevieweeID ?? "",
                          `${gradedItemID}`,
                          state.gradeCriteria
                        );
                      }
                    }}
                    color="default"
                    disabled={state.isSubmitting}
                    type="button"
                    className={styles.button}
                  >
                    {state.isSubmitting ? "Processing..." : "Submit Review"}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </Container>
    </AppLayout>
  );
}

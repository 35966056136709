import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import {
  PeerGroupQuery,
  PeerGroupQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { PeerGroup } from "@libs/service/graphql/generated/types";

import { useService } from "../../lib/hooks/useService";

const peerGroupsQuery = loader(
  "../../lib/service/graphql/query/peerGroup.graphql"
);

const usePeerGroup = (challengeId: string, peerGroupId: string) => {
  const { apigatewayClient: client } = useService();

  const reqBody = { challengeId, id: peerGroupId };
  const isEnabled = client && challengeId && peerGroupId;

  const { data, error } = useSWR(
    isEnabled ? `getPeerGroup?${qs.stringify(reqBody)}` : null,
    () =>
      client
        ?.request<PeerGroupQuery, PeerGroupQueryVariables>(
          peerGroupsQuery,
          reqBody
        )
        .then((result) => {
          return result.peerGroup;
        })
  );

  return {
    peerGroup: data as PeerGroup,
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default usePeerGroup;

import { pickBy } from "lodash";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type Pagination = {
  sort?: string;
  pageSize?: number;
  current?: number;
  count?: number;
  total?: number;
};

export const defaultPagination: Pagination = {
  sort: "createdAt:DESC",
  pageSize: 10,
  current: 1,
  count: 0,
  total: 0,
};

const usePagination = (defaultValue = {} as Pagination) => {
  const history = useHistory();
  const location = useLocation();
  const search = useMemo(
    () => qs.parse(location.search, { parseNumbers: true }),
    [location.search]
  );
  const [pagination, setPagination] = useState<Pagination>({
    ...defaultPagination,
    ...defaultValue,
  });

  const historyPush = useCallback(
    (newVal) => {
      history.push({
        search: qs.stringify({
          ...search,
          ...paginationToSearch(newVal),
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const updatePagination = useCallback(
    (newVal: Pagination, options: { shallow?: boolean } = {}) => {
      setPagination((curr) => ({ ...curr, ...newVal }));
      if (!options?.shallow) {
        historyPush(newVal);
      }
    },
    [historyPush]
  );

  useEffect(() => {
    if (search?.page || search?.limit || search?.sort) {
      updatePagination(searchToPagination(search), { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    pagination,
    updatePagination,
  };
};

export const paginationToReqBody = (pagination: Pagination) => ({
  limit: pagination.pageSize,
  sort: pagination.sort,
  start: ((pagination.current || 0) - 1) * (pagination.pageSize || 0),
});

export const paginationToSearch = (pagination: Pagination) =>
  pickBy(
    {
      page: pagination.current,
      limit: pagination.pageSize,
      sort: pagination.sort,
    },
    (v) => !!v
  );

const searchToPagination = (search: {
  page?: number;
  limit?: number;
  sort?: string;
}) =>
  pickBy(
    {
      current: search?.page,
      pageSize: search?.limit,
      sort: search?.sort,
    },
    (v) => !!v
  );

export default usePagination;

import { useAuth0 } from "@auth0/auth0-react";
import fetch from "cross-fetch";
import { GraphQLClient } from "graphql-request";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import useForceUpdate from "@hooks/useForceUpdate";

export interface ServiceContextProps {
  readonly apigatewayClient?: GraphQLClient;
  readonly adminClient?: GraphQLClient;
  readonly userClient?: GraphQLClient;
  readonly token?: string;
}

export const ServiceContext = createContext<ServiceContextProps>({});
export const useService = (): ServiceContextProps => useContext(ServiceContext);

export const ServiceProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const apigatewayClient = useRef<GraphQLClient>();
  const adminClient = useRef<GraphQLClient>();
  const userClient = useRef<GraphQLClient>();
  const [token, setToken] = useState("");
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const initClient = async () => {
      if (isAuthenticated) {
        const newApigatewayClient = new GraphQLClient(
          process.env.REACT_APP_API_URL ?? "",
          { fetch }
        );
        const newAdminClient = new GraphQLClient(
          process.env.REACT_APP_ADMIN_API_URL ?? "",
          { fetch }
        );
        const newUserClient = new GraphQLClient(
          process.env.REACT_APP_USER_PROFILE_SERVICE_API_URL ?? "",
          { fetch }
        );

        const token = await getAccessTokenSilently();
        const authHeader = "Bearer " + token;
        newApigatewayClient.setHeader("Authorization", authHeader);
        newAdminClient.setHeader("Authorization", authHeader);
        newUserClient.setHeader("Authorization", authHeader);

        setToken(authHeader);

        apigatewayClient.current = newApigatewayClient;
        adminClient.current = newAdminClient;
        userClient.current = newUserClient;

        forceUpdate();
      }
    };

    initClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isAuthenticated && !apigatewayClient.current)
    return <div>Loading ...</div>;

  return (
    <ServiceContext.Provider
      value={{
        apigatewayClient: apigatewayClient.current,
        adminClient: adminClient.current,
        userClient: userClient.current,
        token,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

import { APP_ROUTES } from "@/routes";
import { useAuth0 } from "@auth0/auth0-react";
import { createStyles, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    menuButton: {
      marginRight: "16px",
      color: "#494746",
    },
    link: { textDecoration: "none", color: "#494746" },
  })
);

function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        style={{ backgroundColor: "transparent" }}
        onClick={handleClick}
      >
        {isAuthenticated && <MenuIcon />}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Link className={classes.link} to="/">
            {" "}
            Home
          </Link>
        </MenuItem>
        <Link className={classes.link} to={APP_ROUTES.COHORT_DASHBOARD}>
          <MenuItem>Cohort Dashboard</MenuItem>
        </Link>
        <Link className={classes.link} to={APP_ROUTES.USERS}>
          <MenuItem> Users</MenuItem>
        </Link>
        {/* 
        Temporary hide
        <Link className={classes.link} to="/inbox">
          <MenuItem> Inbox</MenuItem>
        </Link> */}
        <Link className={classes.link} to="/group-challenge">
          <MenuItem> Group Challenge</MenuItem>
        </Link>

        <Link className={classes.link} to="/solo-challenge">
          <MenuItem> Solo Challenge</MenuItem>
        </Link>

        <Link className={classes.link} to="/challenges">
          <MenuItem>Challenges</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

export default MenuButton;

import { APP_ROUTES } from "@/routes";
import { Button, Space, Tag } from "antd";
import { FC, memo, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import GenericTable from "@components/organism/GenericTable";

import useUserMilestonesByUserId from "@hooks/query/useUserMilestonesByUserId";
import useDisclosure from "@hooks/useDisclosure";

import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import {
  FinishStats,
  Enum_V2Milestone_Type as MilestoneType,
  UserMilestone,
  UserMilestoneStatus,
  UserProfile,
} from "@libs/service/graphql/generated/types.d";
import {
  EnumUserMilestone,
  UserMilestoneStatusState,
  UserMilestoneType,
  UserMilestoneWithStateState,
} from "@libs/types/types";

import ChangeReviewerModal from "./components/ChangeReviewerModal";
import UserMilestoneStatusColumn from "./components/UserMilestoneStatusColumn";
import { populateUserMilestonesWithStatusState } from "./helpers/populate-user-milestones-with-status-state";

type Props = {
  userId: string;
  peerGroupId: string;
  challengeId: string;
};
const UserMilestonesTable: FC<Props> = ({
  challengeId,
  userId,
  peerGroupId,
}) => {
  const changeReviewerModal = useDisclosure();
  const { getColumnSearchProps } = useTableColumnSearch();
  const { userMilestones, isLoading } = useUserMilestonesByUserId(
    challengeId,
    userId
  );
  const [selected, setSelected] = useState<UserMilestone | null>(null);

  const handleChangeReviewer = useCallback(
    (value) => () => {
      changeReviewerModal.onOpen();
      setSelected(value);
    },
    [changeReviewerModal]
  );

  const userMilestonesWithCustomStatus =
    populateUserMilestonesWithStatusState(userMilestones);

  return (
    <>
      <GenericTable<UserMilestoneWithStateState>
        columns={[
          {
            title: "Name",
            dataIndex: "milestoneId",
            key: "milestoneId",
            render: (_, row, index) => {
              if (row?.isCapstone) {
                return <span>Capstone</span>;
              }
              return <span>Milestone {index + 1}</span>;
            },
          },
          {
            title: "Status",
            render: UserMilestoneStatusColumn,
            onFilter: (value, record) => {
              return record?.statusState === value;
            },
            filters: [
              {
                text: "TODO",
                value: UserMilestoneStatusState.TODO,
              },
              {
                text: "IN PROGRESS",
                value: UserMilestoneStatusState.IN_PROGRESS,
              },
              {
                text: "TO BE ASSESSED",
                value: UserMilestoneStatusState.TO_BE_ASSESSED,
              },
              {
                text: "NOT ACHIEVED",
                value: UserMilestoneStatusState.NOT_ACHIEVED,
              },
              {
                text: "COMPLETED",
                value: UserMilestoneStatusState.COMPLETED,
              },
            ],
          },
          {
            title: "Type",
            dataIndex: "milestoneType",
            key: "milestoneType",
            render: (value) => {
              return <Tag>{value || "--"}</Tag>;
            },
            onFilter: (value, record) => {
              return record?.milestoneType === value;
            },
            filters: [
              MilestoneType.None,
              MilestoneType.Submission,
              MilestoneType.Confirmation,
              MilestoneType.Assessment,
            ].map((it) => ({
              text: it,
              value: it,
            })),
          },
          {
            title: "Lessons",
            dataIndex: "lessons",
            key: "lessons",
            render: (value: FinishStats) => {
              return (
                <span>
                  {value?.completed} of {value?.total}
                </span>
              );
            },
          },
          {
            title: "Reviewer",
            dataIndex: "reviewerProfile",
            key: "reviewerProfile",
            ...getColumnSearchProps("reviewerProfile.name", {
              placeholder: "Search reviewer name",
            }),
            render: (value: UserProfile, raw: UserMilestone) => (
              <Link
                to={APP_ROUTES.CHALLENGE_MILESTONES(
                  challengeId,
                  peerGroupId,
                  raw?.reviewerSub as string
                )}
              >
                {value?.name}
              </Link>
            ),
          },
          {
            title: "Actions",
            render: (_, value) => {
              const actionButtons = [];
              switch (value.statusState) {
                case UserMilestoneStatusState.TO_BE_ASSESSED:
                  actionButtons.push(
                    <Link
                      key="to-be-assessed"
                      to={APP_ROUTES.CHALLENGE_MILESTONE_GRADE(
                        challengeId,
                        peerGroupId,
                        value.milestoneId as string,
                        userId
                      )}
                    >
                      <Button>Grade</Button>
                    </Link>
                  );
                  break;
                default:
                  actionButtons.push(
                    <Button
                      key="change-reviewer"
                      onClick={handleChangeReviewer(value)}
                    >
                      Change Reviewer
                    </Button>
                  );
              }

              if (
                [
                  UserMilestoneType.Assessment,
                  UserMilestoneType.GroupAssessment,
                ].includes(value.milestoneType as EnumUserMilestone) &&
                ![
                  UserMilestoneStatus.ReSubmitted,
                  UserMilestoneStatus.Submitted,
                ].includes(value.status as UserMilestoneStatus)
              ) {
                actionButtons.push(
                  <Link
                    key="view-graded-feedback"
                    to={APP_ROUTES.CHALLENGE_MILESTONE_GRADE(
                      challengeId,
                      peerGroupId,
                      value.milestoneId as string,
                      userId
                    )}
                  >
                    <Button>View Feedback</Button>
                  </Link>
                );
              }

              return <Space>{actionButtons}</Space>;
            },
          },
        ]}
        dataSource={userMilestonesWithCustomStatus}
        loading={isLoading}
        rowKey={(item) => `${item.milestoneId}-${item.id}-${item.order}`}
        pagination={false}
      />

      <ChangeReviewerModal
        {...changeReviewerModal}
        challengeId={selected?.challengeId || ""}
        milestoneId={selected?.milestoneId || ""}
        userSub={selected?.sub || ""}
        reviewerSub={selected?.reviewerSub || ""}
      />
    </>
  );
};

export default memo(UserMilestonesTable);

import { APP_ROUTES, ROUTE_QUERY_KEYS, UserDetailsTab } from "@/routes";
import { Container, Paper, createStyles, makeStyles } from "@material-ui/core";
import { Tabs } from "antd";
import omit from "lodash/omit";
import qs from "query-string";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

import UserDetailsAvatar from "@components/UserDetails";
import UserGroupChallenges from "@components/challenges/UserGroupChallenges/UserGroupChallenges";
import UserSoloChallenges from "@components/challenges/UserSoloChallenges/UserSoloChallenges";

import useLocationQuery from "@hooks/useLocationQuery";

import AppLayout from "@layouts/AppLayout";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-around",
    },
    paper: {
      paddingBottom: "64px",
    },
    container: {
      overflowX: "auto",
      marginTop: "30px",
    },
  })
);

export default function UserDetails() {
  const classes = useStyles();
  const query = useLocationQuery();
  const history = useHistory();

  const userId = query.get(ROUTE_QUERY_KEYS.USER_ID);
  const tab = query.get(ROUTE_QUERY_KEYS.TAB);
  const filterId = query.get(ROUTE_QUERY_KEYS.FILTER_ID());

  const currentQuery = useMemo(
    () => qs.parse(query.toString(), { sort: false }),
    [query]
  );

  const handleSearchChange = (newSearch: Record<string, unknown>) =>
    history.push({
      search: qs.stringify(newSearch, { sort: false, encode: false }),
    });

  const handleFilterChange = (id: string, value: unknown) => {
    handleSearchChange({ ...currentQuery, [`filter[${id}]`]: value });
  };

  const handleChangeTab = (tabKey: string) => {
    const currentSearch = omit(
      currentQuery,
      Object.keys(currentQuery)?.filter((key) => /filter\[(.*)\]/.test(key))
    );
    handleSearchChange({ ...currentSearch, [ROUTE_QUERY_KEYS.TAB]: tabKey });
  };

  return (
    <AppLayout onPressBack={() => history.push(APP_ROUTES.USERS)}>
      <Container maxWidth="lg" className={classes.container}>
        <Paper elevation={0} className={classes.paper}>
          {!userId && (
            <div>
              <h3>User id is required.</h3>
              <Link to={APP_ROUTES.USERS}>Back to user list</Link>
            </div>
          )}

          {userId && (
            <>
              <UserDetailsAvatar userId={userId} />
              <Tabs
                activeKey={(tab as string) || undefined}
                onChange={handleChangeTab}
              >
                <Tabs.TabPane
                  tab="Solo Challenge"
                  key={UserDetailsTab.SOLO_CHALLENGES}
                >
                  {userId && (
                    <UserSoloChallenges
                      userId={userId}
                      filter={{ id: filterId as string }}
                      onFilterChange={handleFilterChange}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Group Challenge"
                  key={UserDetailsTab.GROUP_CHALLENGES}
                >
                  {userId && (
                    <UserGroupChallenges
                      userId={userId}
                      filter={{ id: filterId as string }}
                      onFilterChange={handleFilterChange}
                    />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </>
          )}
        </Paper>
      </Container>
    </AppLayout>
  );
}

import { User } from "@auth0/auth0-spa-js";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  avatar: {
    width: "32px",
    height: "32px",
    border: "2px solid #484746",
  },
});

export default function ProfileAvatar(user: User): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      {user && <Avatar src={user.user?.picture} className={classes.avatar} />}
    </div>
  );
}

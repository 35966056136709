import find from "lodash/find";

import {
  ChallengeProgress,
  UserMilestone,
  UserMilestoneStatus,
} from "@libs/service/graphql/generated/types.d";
import {
  EnumUserMilestone,
  UserChallengeStatusState,
  UserChallengeWithStatusState,
  UserMilestoneType,
} from "@libs/types/types";

export function populateUserChallengesWithStatusState(
  challenges: ChallengeProgress[]
): UserChallengeWithStatusState[] {
  return challenges?.map((challenge) => {
    const { milestones = [] } = challenge;
    const milestonesWithStates = milestones as UserMilestone[];

    // NOT STARTED
    let statusState = UserChallengeStatusState.NOT_STARTED;

    // COMPLETED
    if (challenge.isChallengeComplete) {
      statusState = UserChallengeStatusState.COMPLETED;
      return { ...challenge, statusState };
    }

    // NOT ACHIEVED
    if (
      find(milestonesWithStates, {
        isCapstone: true,
        status: UserMilestoneStatus.NotAchieved,
      })
    ) {
      statusState = UserChallengeStatusState.NOT_ACHIEVED;
    }

    // TO BE ASSESSED
    else if (
      find(milestonesWithStates, (item) => {
        return (
          item.isCapstone &&
          [
            UserMilestoneType.Assessment,
            UserMilestoneType.GroupAssessment,
          ].includes(item.milestoneType as EnumUserMilestone) &&
          [
            UserMilestoneStatus.Submitted,
            UserMilestoneStatus.ReSubmitted,
          ].includes(item.status as UserMilestoneStatus)
        );
      })
    ) {
      statusState = UserChallengeStatusState.TO_BE_ASSESSED;
    }

    // IN PROGRESS
    else if (
      find(milestonesWithStates, (item) => {
        const hasLessonProgress = (item.lessons?.completed || 0) > 0;
        const anyIsCompleted = item.isMilestoneComplete;

        return (
          [
            UserMilestoneStatus.Confirmed,
            UserMilestoneStatus.Submitted,
            UserMilestoneStatus.PeerReviewed,
            UserMilestoneStatus.ReSubmitted,
            UserMilestoneStatus.NotAchieved,
            UserMilestoneStatus.Achieved,
          ].includes(item.status as UserMilestoneStatus) ||
          hasLessonProgress ||
          anyIsCompleted
        );
      })
    ) {
      statusState = UserChallengeStatusState.IN_PROGRESS;
    }

    return { ...challenge, statusState };
  });
}

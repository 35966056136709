import { Form, FormItemProps, Select, SelectProps } from "antd";
import { PropsWithChildren } from "react";
import { Controller, ControllerProps, FieldValues } from "react-hook-form";

import { controllerToFormItemProps } from "@libs/utils/hookform-antd";

const { Option } = Select;

export type SelectOptions = { label: string; value: unknown }[];
type SelectControllerProps<FormValues extends FieldValues> = FormItemProps &
  Omit<SelectProps, "options"> &
  Omit<ControllerProps<FormValues>, "render"> & {
    options: SelectOptions;
  };

function SelectController<FormValues extends Record<string, unknown>>({
  control,
  name,
  options,
  placeholder,
  mode,
  allowClear,
  tagRender,
  style,
  label,
  ...restProps
}: PropsWithChildren<SelectControllerProps<FormValues>>) {
  return (
    <Controller<FormValues>
      control={control}
      name={name}
      render={({ field, formState }) => (
        <Form.Item
          label={label}
          {...controllerToFormItemProps({ field, formState }, restProps)}
        >
          <div className="select">
            <Select
              showSearch
              mode={mode}
              style={style ?? { width: "300px", maxWidth: "100%" }}
              placeholder={placeholder}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear={allowClear}
              tagRender={tagRender}
              {...field}
            >
              {options?.map((option) => (
                <Option key={option.value as string} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
        </Form.Item>
      )}
    />
  );
}

export default SelectController;

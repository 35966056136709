import { useState } from "react";

const useForceUpdate = () => {
  const [, forceUpdate] = useState(Date.now());
  return () => {
    forceUpdate(Date.now());
  };
};

export default useForceUpdate;

import React from "react";
import { useHistory } from "react-router-dom";

import { ParseQuery } from "@libs/utils/utility";

export default function RedirectAction() {
  const query = ParseQuery();
  const history = useHistory();
  React.useEffect(() => {
    if (query) {
      history.push(`${query.get("actionTo")}`);
    }
  }, [query, history]);
  return null;
}

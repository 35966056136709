import { SyncOutlined } from "@ant-design/icons";
import styles from "@css/common.module.scss";
import { Typography } from "antd";
import { FC, memo } from "react";

import DocumentTitle from "@components/DocumentTitle";

import useUser from "@hooks/query/useUser";

import { arrayToPageTitle } from "@libs/utils/common";

import UserMilestonesTable from "./UserMilestonesTable";

type Props = {
  peerGroupId: string;
  challengeId: string;
  userId: string;
};
const PeerGroupUserMilestones: FC<Props> = ({
  challengeId,
  userId,
  peerGroupId,
}) => {
  const { data: user, isLoading } = useUser(userId);
  const title = arrayToPageTitle([user?.name, "Milestone Records"]);
  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <div className={styles.pageTitle}>
        <Typography.Title level={3}>
          {isLoading && <SyncOutlined spin />}
          {!isLoading && title}
        </Typography.Title>
      </div>
      <UserMilestonesTable
        challengeId={challengeId}
        userId={userId}
        peerGroupId={peerGroupId}
      />
    </>
  );
};

export default memo(PeerGroupUserMilestones);

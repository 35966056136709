import { Modal, notification } from "antd";
import { GraphQLClient } from "graphql-request";
import React, { useState } from "react";

import UserPicker from "@components/picker/UserPicker";

import { useService } from "@libs/hooks/useService";
import { GroupChallengeGroupEvaluation } from "@libs/service/graphql/generated/types";
import { addStudentIntoGroup } from "@libs/service/groupChallenge";

interface AssignReviewerModalProps {
  show: boolean;
  groupChallengeID: string;
  target?: GroupChallengeGroupEvaluation;
  groupName: string;
  onFinished(canceled?: boolean): void;
  onError(err: unknown): void;
  onSubmit?: () => void;
}

type State = {
  sending: boolean;
  student: string;
};

const AssignReviewerModal: React.FC<AssignReviewerModalProps> = (props) => {
  const [state, setState] = useState<State>({
    sending: false,
    student: "",
  });
  const { apigatewayClient: client } = useService();

  function onUserSelected(id: string) {
    setState({ ...state, student: id });
  }

  async function handleAsignStudent(
    student: string,
    groupId: string,
    groupOfGroupChallengeId: string
  ) {
    setState({ ...state, sending: true });
    props.onSubmit && props.onSubmit();
    addStudentIntoGroup(
      client as GraphQLClient,
      student,
      groupId,
      groupOfGroupChallengeId
    )
      .then(() => {
        notification.success({
          message: "Success",
          description: "The student has been successfully assigned",
        });
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: "Oops!",
          description: "Unable to add student, please try again later",
        });
      })
      .finally(() => {
        setState({ ...state, sending: false });
        props.onFinished();
      });
  }

  return (
    <>
      <Modal
        title="Assign Students to The Group"
        visible={props.show}
        onOk={() =>
          handleAsignStudent(
            state.student,
            props.groupChallengeID ?? "",
            props.target?.id ?? ""
          )
        }
        onCancel={() => {
          if (!state.sending) {
            props.onFinished(true);
          }
        }}
        okButtonProps={{ loading: state.sending }}
        cancelButtonProps={{ disabled: state.sending }}
      >
        {props.target && (
          <>
            <p>
              Select Students to assign Group Challenge <b>{props.groupName}</b>
            </p>
            <UserPicker
              onChange={onUserSelected}
              selectedId={state.student}
              disabled={state.sending}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default AssignReviewerModal;

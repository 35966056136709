import { loader } from "graphql.macro";
import { useState } from "react";
import { mutate } from "swr";

import {
  ChangeReviewerUserMilestoneMutation,
  ChangeReviewerUserMilestoneMutationVariables,
} from "@libs/service/graphql/generated/operations";

import { useService } from "../lib/hooks/useService";
import { createGetUserMilestonesByUserCacheKey } from "./query/useUserMilestonesByUserId";

const query = loader(
  "../lib/service/graphql/mutation/changeReviewerUserMilestone.graphql"
);

const useChangeReviewerUserMilestone = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleMutate = async (
    variables: ChangeReviewerUserMilestoneMutationVariables
  ) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        ChangeReviewerUserMilestoneMutation,
        ChangeReviewerUserMilestoneMutationVariables
      >(query, variables);
      // revalidate the get user milestones by user id cache
      await mutate(
        createGetUserMilestonesByUserCacheKey({
          challengeId: variables.challengeId,
          id: variables.userSub,
        })
      );
      return result?.changeReviewerUserMilestone;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    mutate: handleMutate,
  };
};

export default useChangeReviewerUserMilestone;

import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  UserAssessmentReviewsQuery,
  UserAssessmentReviewsQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { UserAssessmentReview } from "@libs/service/graphql/generated/types";

const query = loader(
  "../../lib/service/graphql/query/userAssessmentReviews.graphql"
);

export const createGetUserAssessmentReviewsCacheKey = (
  body: UserAssessmentReviewsQueryVariables
) => `getUserAssessmentReviews?${qs.stringify(body)}`;

const useUserAssessmentReviews = (
  challengeId: string,
  milestoneId: string,
  userId: string
) => {
  const { apigatewayClient: client } = useService();
  const reqBody: UserAssessmentReviewsQueryVariables = {
    challengeId,
    milestoneId,
    userId,
  };
  const isEnabled = client && challengeId && milestoneId;
  const { data, error } = useSWR(
    isEnabled ? createGetUserAssessmentReviewsCacheKey(reqBody) : null,
    () =>
      client
        ?.request<
          UserAssessmentReviewsQuery,
          UserAssessmentReviewsQueryVariables
        >(query, reqBody)
        .then((result) => {
          return result.userAssessmentReviews;
        })
  );

  return {
    userAssessments: data as UserAssessmentReview[],
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default useUserAssessmentReviews;

import { NamedChallengeEvaluation } from "@/types/challenge";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { loader } from "graphql.macro";
import { isArray, pick } from "lodash";
import qs from "query-string";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";

import {
  ChallengesQuery,
  ChallengesQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { ChallengeEntity } from "@libs/service/graphql/generated/types";

import { useService } from "../lib/hooks/useService";
import usePagination, {
  Pagination,
  defaultPagination,
  paginationToReqBody,
  paginationToSearch,
} from "./usePagination";

const challengesQuery = loader(
  "../lib/service/graphql/query/challenges.graphql"
);

type Filters = {
  id: string;
  name: string;
  [key: string]: string;
};

const defaultFilters: Filters = {
  id: "",
  name: "",
};

const allowedFilters = Object.keys(defaultFilters);

const useChallenges = () => {
  const { pagination, updatePagination } = usePagination();
  const history = useHistory();
  const location = useLocation();
  const { apigatewayClient: client } = useService();

  const search = qs.parse(location.search, { parseNumbers: true });

  const reqBody = {
    ...paginationToReqBody({
      ...pagination,
      current: (search?.page || 1) as number,
    }),
    ...pick(search, allowedFilters),
  };
  const { data, error } = useSWR(
    client ? `getChallenges?${qs.stringify(reqBody)}` : null,
    () =>
      client?.request<ChallengesQuery, ChallengesQueryVariables>(
        challengesQuery,
        reqBody
      ),
    { revalidateOnFocus: false }
  );

  const handlePaginationChange = useCallback((newPagination: Pagination) => {
    updatePagination(newPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortChange = useCallback(
    (
      sort:
        | SorterResult<NamedChallengeEvaluation>
        | SorterResult<NamedChallengeEvaluation>[]
    ) => {
      const sortField = (
        isArray(sort) && sort?.length > 0 ? sort?.[0]?.columnKey : sort
      ) as SorterResult<NamedChallengeEvaluation>;
      if (!sortField.order) return;
      handlePaginationChange({
        ...defaultPagination,
        sort: `${sortField.columnKey}:${
          sortField.order === "ascend" ? "ASC" : "DESC"
        }`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePaginationChange]
  );

  const historyPush = useCallback(
    (newVal) => {
      history.push({
        search: qs.stringify({
          ...search,
          ...newVal,
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Record<string, FilterValue | null>) => {
      const filters: Filters = { ...defaultFilters };
      Object.keys(newFilters).forEach((key) => {
        if (!allowedFilters.includes(key)) {
          return;
        }

        filters[key] = newFilters[key]?.[0] as string;
      });

      historyPush({
        ...paginationToSearch(defaultPagination),
        ...filters,
      });
    },
    [historyPush]
  );

  /**
   * This will updates the page numbers shown on UI.
   */
  useEffect(() => {
    if (!data?.challenges?.meta) return;
    updatePagination(
      {
        count: data?.challenges?.meta?.pagination.pageSize,
        total: data?.challenges?.meta?.pagination.total,
      },
      { shallow: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.challenges?.meta]);

  return {
    challenges: data?.challenges?.data as ChallengeEntity[],
    isLoading: !error && !data,
    isError: error,
    pagination,
    filters: search,

    handlePaginationChange,
    handleSortChange,
    handleFiltersChange,
  };
};

export default useChallenges;

import { loader } from "graphql.macro";
import { useState } from "react";

import { useService } from "@libs/hooks/useService";
import {
  GradeAssessmentMutation,
  GradeAssessmentMutationVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/mutation/gradeAssessment.graphql"
);
const useGradeAssessment = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleMutate = async (variables: GradeAssessmentMutationVariables) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        GradeAssessmentMutation,
        GradeAssessmentMutationVariables
      >(query, variables);
      return result?.gradeAssessmentMilestone;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    mutate: handleMutate,
  };
};

export default useGradeAssessment;

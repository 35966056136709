import { APP_ROUTES } from "@/routes";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { FC, memo, useState } from "react";

import GenericTable from "@components/organism/GenericTable";

import useGetAllUserProfiles from "@hooks/query/useAllUserProfiles";
import usePeerGroups from "@hooks/query/usePeerGroups";
import useDisclosure from "@hooks/useDisclosure";

import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import { PeerGroup } from "@libs/service/graphql/generated/types";

import EditDueDatesModal from "./EditDueDatesModal";

type Props = {
  challengeId: string;
};
const PeerGroupsList: FC<Props> = ({ challengeId }) => {
  const [selectedPeerGroupId, setSelectedPeerGroupId] = useState<string | null>(
    null
  );
  const { data: users, isLoading: usersLoading } = useGetAllUserProfiles();
  const editDueDatesModal = useDisclosure();
  const { peerGroups, isLoading } = usePeerGroups(challengeId);
  const { getColumnSearchProps } = useTableColumnSearch();

  return (
    <>
      <GenericTable<PeerGroup>
        columns={[
          {
            title: "Identifier",
            dataIndex: "name",
            key: "name",
            sorter: true,
            width: "300px",
            ...getColumnSearchProps("name"),
          },
          {
            title: "Learners",
            dataIndex: "userSubs",
            key: "userSubs",
            render: (value) => {
              return value.map((sub: string) => {
                const user = users?.find((u) => u.userId === sub);
                return (
                  <Tag
                    icon={usersLoading ? <SyncOutlined spin /> : null}
                    color="blue"
                    key={sub}
                  >
                    {!usersLoading ? user?.name || `${sub} (Unknown User)` : ""}
                  </Tag>
                );
              });
            },
          },
          {
            title: "Actions",
            render: (value) => {
              return (
                <Space>
                  <Button
                    href={APP_ROUTES.CHALLENGE_PEER_GROUP(
                      challengeId,
                      value?.id
                    )}
                  >
                    View challenge records
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedPeerGroupId(value?.id);
                      editDueDatesModal.onOpen();
                    }}
                  >
                    Edit due dates
                  </Button>
                </Space>
              );
            },
          },
        ]}
        dataSource={peerGroups as PeerGroup[]}
        loading={isLoading || usersLoading}
        rowKey={(item) => `${item.id}-${item.name}`}
        pagination={false}
      />

      <EditDueDatesModal
        challengeId={challengeId}
        peerGroupId={selectedPeerGroupId as string}
        {...editDueDatesModal}
        onClose={() => {
          setSelectedPeerGroupId(null);
          editDueDatesModal.onClose();
        }}
      />
    </>
  );
};

export default memo(PeerGroupsList);

import { Button } from "antd";
import { FC, useMemo } from "react";
import { RiSlackFill } from "react-icons/ri";

import DropdownButton, {
  DropdownButtonMenuItems,
} from "@components/dropdown-button";

import { UserProfile } from "@libs/service/user-graphql/generated/types";
import { createSlackMagicLink } from "@libs/utils/external-party";

import style from "../grade-assessment.module.scss";

type Props = {
  users: UserProfile[];
};
const TalkDropdown: FC<Props> = ({ users = [] }) => {
  const menu = useMemo<DropdownButtonMenuItems>(
    () =>
      users?.map((item) => {
        const isDisabled = !(item?.slackId as string);
        return {
          key: item.userId as string,
          label: (
            <Button
              className={style.deliverableButton}
              disabled={isDisabled}
              type="link"
              href={createSlackMagicLink(item?.slackId as string)}
              target="_blank"
              icon={<RiSlackFill />}
            >
              {item.name}
            </Button>
          ),
        };
      }),
    [users]
  );

  if (users.length < 1) return null;

  return <DropdownButton label="Talk" menuItems={menu} />;
};

export default TalkDropdown;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "antd";
import isEmpty from "lodash/isEmpty";
import { FC, memo, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import SelectController from "@components/hookform/SelectController";
import TextareaController from "@components/hookform/TextareaController";

import useStaffs from "@hooks/query/useStaffs";
import useDisclosure from "@hooks/useDisclosure";

import {
  ComponentMilestonesEvaluationCriteria,
  GradeAssessmentCriteriaInput,
} from "@libs/service/graphql/generated/types";
import { UserProfile } from "@libs/service/user-graphql/generated/types";

import { GRADE_OPTIONS } from "../constants";
import styles from "../grade-assessment.module.scss";
import ConfirmSubmitAssessmentModal from "./ConfirmSubmitAssessmentModal";
import SectionContent from "./SectionContent";

type CriteriaInput = Omit<GradeAssessmentCriteriaInput, "gradeNumber"> & {
  gradeNumber: number | null;
};

export type AssessmentFormValues = {
  assessorSub: string;
  criteria: CriteriaInput[];
};

const validationSchema = yup
  .object()
  .shape({
    assessorSub: yup.string().required("Assessor is required"),
    criteria: yup.array().of(
      yup.object().shape({
        gradeNumber: yup
          .number()
          .required("Grade is required")
          .min(1, "Grade must be greater than 0")
          .max(5, "Grade must be less than 5")
          .nullable(false),
        commendation: yup.string().required("Commendation is required"),
        recommendation: yup.string().required("Recommendation is required"),
      })
    ),
  })
  .required();

type Props = {
  criterions: ComponentMilestonesEvaluationCriteria[];
  learners: UserProfile[];
  initialCriterionValues: GradeAssessmentCriteriaInput[];
  assessorSub?: string;
  confirmLoading?: boolean;
  onSubmit?: (values: AssessmentFormValues) => Promise<void> | void;
  isViewOnly?: boolean;
};

const AssessmentForm: FC<Props> = ({
  criterions,
  initialCriterionValues,
  learners,
  confirmLoading = false,
  assessorSub,
  isViewOnly,
  onSubmit,
}) => {
  const confirmSubmitAssessmentModal = useDisclosure();
  const { staffs } = useStaffs();
  const { control, reset, handleSubmit, formState, getValues } =
    useForm<AssessmentFormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        assessorSub,
        criteria: [],
      },
      mode: "all",
    });
  const { fields } = useFieldArray({
    control,
    name: "criteria",
  });

  const onFormSubmit = handleSubmit(() =>
    confirmSubmitAssessmentModal.onOpen()
  );

  const handleConfirmSubmit = () => onSubmit?.(getValues());

  useEffect(() => {
    if (criterions) {
      reset({
        assessorSub,
        criteria: criterions?.map((criterion) => {
          const curr = initialCriterionValues?.find(
            (item) => item.title === criterion.criterion
          );

          return {
            title: criterion.criterion as string,
            description: criterion.description as string,
            gradeNumber: curr?.gradeNumber,
            commendation: curr?.commendation,
            recommendation: curr?.recommendation,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criterions, assessorSub, initialCriterionValues]);

  return (
    <Form layout="vertical" disabled={isViewOnly}>
      <SectionContent>
        <SelectController<AssessmentFormValues>
          control={control}
          name="assessorSub"
          options={staffs?.map((staff) => ({
            label: staff.name as string,
            value: staff.userId,
          }))}
          placeholder="Select a assessor"
          initialValue={assessorSub}
        />
      </SectionContent>

      {fields.map((field, index) => (
        <SectionContent
          className={styles.section}
          key={field.id}
          title={field.title}
          subtitle={field.description}
        >
          <SelectController<AssessmentFormValues>
            control={control}
            name={`criteria.${index}.gradeNumber`}
            options={GRADE_OPTIONS}
            placeholder="Grade"
          />

          <TextareaController<AssessmentFormValues>
            control={control}
            name={`criteria.${index}.commendation`}
            placeholder="Commendation"
            autoSize={isViewOnly}
          />

          <TextareaController<AssessmentFormValues>
            control={control}
            name={`criteria.${index}.recommendation`}
            placeholder="Recommendation"
            autoSize={isViewOnly}
          />
        </SectionContent>
      ))}

      {!isViewOnly && (
        <SectionContent className={styles.submitSection}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={onFormSubmit}
            loading={formState.isSubmitting}
            disabled={!isEmpty(formState.errors)}
          >
            Submit Assessment
          </Button>
        </SectionContent>
      )}

      <ConfirmSubmitAssessmentModal
        learners={learners}
        onConfirm={handleConfirmSubmit}
        confirmLoading={confirmLoading}
        {...confirmSubmitAssessmentModal}
      />
    </Form>
  );
};

export default memo(AssessmentForm);

import { GraphQLClient } from "graphql-request";
import { loader } from "graphql.macro";
import { mutate } from "swr";

import {
  AddGroupOfGroupChallengeMutation,
  AddGroupOfGroupChallengeMutationVariables,
  AddMentorIntoGroupMutation,
  AddMentorIntoGroupMutationVariables,
  AddStudentIntoGroupMutation,
  AddStudentIntoGroupMutationVariables,
  EditGroupOfGroupChallengeMutation,
  EditGroupOfGroupChallengeMutationVariables,
  GroupChallengeDetailQuery,
  GroupChallengeDetailQueryVariables,
  GroupChallengesForUserQuery,
  GroupChallengesForUserQueryVariables,
  GroupChallengesQuery,
  GroupChallengesQueryVariables,
  GroupOfGroupChallengeDetailQuery,
  GroupOfGroupChallengeDetailQueryVariables,
  GroupsOfGroupChallengeQuery,
  GroupsOfGroupChallengeQueryVariables,
  RemoveMentorFromGroupMutation,
  RemoveMentorFromGroupMutationVariables,
  RemoveStudentFromGroupMutation,
  RemoveStudentFromGroupMutationVariables,
  SubmitGradedReviewMutation,
  SubmitGradedReviewMutationVariables,
} from "./graphql/generated/operations";
import {
  ChallengeReviewCriterionInput,
  GroupChallengeEntity,
  GroupChallengeEvaluation,
  GroupChallengeEvaluationV2,
  GroupChallengeGroupInput,
} from "./graphql/generated/types";
import { SWRConnector } from "./graphql/swrpayload";

const groupChallengeDetailQuery = loader(
  "./graphql/query/groupChallengeDetail.graphql"
);
const groupChallengesQuery = loader("./graphql/query/groupChallenges.graphql");
const groupsOfGroupChallengeQuery = loader(
  "./graphql/query/groupsOfGroupChallenge.graphql"
);
const groupChallengesForUserQuery = loader(
  "./graphql/query/groupChallengesForUser.graphql"
);
const groupOfGroupChallengeDetailQuery = loader(
  "./graphql/query/groupOfGroupChallengeDetail.graphql"
);

const addGroupOfGroupChallengeMutation = loader(
  "./graphql/mutation/addGroupOfGroupChallenge.graphql"
);

const editGroupOfGroupChallengeMutation = loader(
  "./graphql/mutation/editGroupOfGroupChallenge.graphql"
);

const addStudentIntoGroupMutation = loader(
  "./graphql/mutation/addStudentIntoGroup.graphql"
);

const removeStudentFromGroupMutation = loader(
  "./graphql/mutation/removeStudentFromGroup.graphql"
);
const removeMentorFromGroupMutation = loader(
  "./graphql/mutation/removeMentorFromGroup.graphql"
);

const addMentorIntoGroupMutation = loader(
  "./graphql/mutation/addMentorIntoGroup.graphql"
);

const submitGroupReviewMutation = loader(
  "./graphql/mutation/submitGroupReview.graphql"
);

export function fetchGroupOfGroupChallengeDetail(
  graphQLClient: GraphQLClient,
  id: string,
  groupOfGroupChallengeId: string
): SWRConnector<GroupChallengeEvaluationV2> {
  return {
    cacheKey: `groupOfGroupChallengeDetailQuery${id}${groupOfGroupChallengeId}`,
    fetch: () =>
      graphQLClient
        .request<
          GroupOfGroupChallengeDetailQuery,
          GroupOfGroupChallengeDetailQueryVariables
        >(groupOfGroupChallengeDetailQuery, { id, groupOfGroupChallengeId })
        .then((result) => {
          return result.learningProfile.progress
            .groupChallengeV2 as GroupChallengeEvaluationV2;
        }),
  };
}

export function fetchGroupChallengeDetail(
  graphQLClient: GraphQLClient,
  id: string
): SWRConnector<GroupChallengeEntity> {
  return {
    cacheKey: `groupChallengeDetailQuery${id}`,
    fetch: () =>
      graphQLClient
        .request<GroupChallengeDetailQuery, GroupChallengeDetailQueryVariables>(
          groupChallengeDetailQuery,
          { id }
        )
        .then((result) => {
          return result.groupChallenge?.data as GroupChallengeEntity;
        }),
  };
}

export function fetchGroupChallenges(
  graphQLClient: GraphQLClient,
  sort: string,
  limit: number,
  start: number
): SWRConnector<Array<GroupChallengeEntity>> {
  return {
    cacheKey: "groupChallengesQuery",
    fetch: () =>
      graphQLClient
        .request<GroupChallengesQuery, GroupChallengesQueryVariables>(
          groupChallengesQuery,
          { sort, limit, start }
        )
        .then((result) => {
          return result?.groupChallenges?.data as GroupChallengeEntity[];
        }),
  };
}

export function fetchGroupsOfGroupChallenge(
  graphQLClient: GraphQLClient,
  id: string
): SWRConnector<GroupChallengeEvaluationV2> {
  return {
    cacheKey: "groupsOfGroupChallengeQuery" + id,
    fetch: () =>
      graphQLClient
        .request<
          GroupsOfGroupChallengeQuery,
          GroupsOfGroupChallengeQueryVariables
        >(groupsOfGroupChallengeQuery, { id })
        .then((result) => {
          return result.learningProfile.progress
            .groupChallengeV2 as GroupChallengeEvaluationV2;
        }),
  };
}

export function fetchGroupChallengeForUser(
  graphQLClient: GraphQLClient,
  userId: string
): SWRConnector<Array<GroupChallengeEvaluation>> {
  return {
    cacheKey: userId ? "groupChallengeForUser" + userId : "",
    fetch: () =>
      graphQLClient
        .request<
          GroupChallengesForUserQuery,
          GroupChallengesForUserQueryVariables
        >(groupChallengesForUserQuery, { userId })
        .then((result) => {
          return result.learningProfile.progress
            .groupChallengesForUser as Array<GroupChallengeEvaluation>;
        }),
  };
}

export async function addGroupOfGroupChallenge(
  graphQLClient: GraphQLClient,
  cohortId: string,
  groupId: string
): Promise<AddGroupOfGroupChallengeMutation> {
  const response = await graphQLClient.request<
    AddGroupOfGroupChallengeMutation,
    AddGroupOfGroupChallengeMutationVariables
  >(addGroupOfGroupChallengeMutation, {
    cohortId,
    groupId,
  });

  if (response.learningProfile) {
    const cacheKey = `${cohortId}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}
export async function editGroupOfGroupChallenge(
  graphQLClient: GraphQLClient,
  groupId: string,
  groupInstanceId: string,
  groupInstanceData: GroupChallengeGroupInput
): Promise<EditGroupOfGroupChallengeMutation> {
  const response = await graphQLClient.request<
    EditGroupOfGroupChallengeMutation,
    EditGroupOfGroupChallengeMutationVariables
  >(editGroupOfGroupChallengeMutation, {
    groupId,
    groupInstanceId,
    groupInstanceData,
  });

  if (response.learningProfile) {
    const cacheKey = `${groupInstanceId}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}

export async function addStudentIntoGroup(
  graphQLClient: GraphQLClient,
  student: string,
  groupId: string,
  groupOfGroupChallengeId: string
): Promise<AddStudentIntoGroupMutation> {
  const response = await graphQLClient.request<
    AddStudentIntoGroupMutation,
    AddStudentIntoGroupMutationVariables
  >(addStudentIntoGroupMutation, {
    student,
    groupId,
    groupOfGroupChallengeId,
  });

  if (response.learningProfile) {
    const cacheKey = `${student}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}

export async function removeStudentFromGroup(
  graphQLClient: GraphQLClient,
  student: string,
  groupId: string,
  groupOfGroupChallengeId: string
): Promise<RemoveStudentFromGroupMutation> {
  const response = await graphQLClient.request<
    RemoveStudentFromGroupMutation,
    RemoveStudentFromGroupMutationVariables
  >(removeStudentFromGroupMutation, {
    student,
    groupId,
    groupOfGroupChallengeId,
  });

  if (response.learningProfile) {
    const cacheKey = `${student}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}

export async function removeMentorFromGroup(
  graphQLClient: GraphQLClient,
  mentor: string,
  groupId: string,
  groupOfGroupChallengeId: string
): Promise<RemoveMentorFromGroupMutation> {
  const response = await graphQLClient.request<
    RemoveMentorFromGroupMutation,
    RemoveMentorFromGroupMutationVariables
  >(removeMentorFromGroupMutation, {
    mentor,
    groupId,
    groupOfGroupChallengeId,
  });

  if (response.learningProfile) {
    const cacheKey = `${mentor}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}

export async function addMentorIntoGroup(
  graphQLClient: GraphQLClient,
  mentor: string,
  groupId: string,
  groupOfGroupChallengeId: string
): Promise<AddMentorIntoGroupMutation> {
  const response = await graphQLClient.request<
    AddMentorIntoGroupMutation,
    AddMentorIntoGroupMutationVariables
  >(addMentorIntoGroupMutation, {
    mentor,
    groupId,
    groupOfGroupChallengeId,
  });

  if (response.learningProfile) {
    const cacheKey = `${mentor}-${groupId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Data submit failed");
  }
}

export async function submitGroupReview(
  graphQLClient: GraphQLClient,
  groupId: string,
  groupOfGroupChallengeId: string,
  criteria: Array<ChallengeReviewCriterionInput>
): Promise<SubmitGradedReviewMutation> {
  const response = await graphQLClient.request<
    SubmitGradedReviewMutation,
    SubmitGradedReviewMutationVariables
  >(submitGroupReviewMutation, {
    groupId,
    groupOfGroupChallengeId,
    criteria,
  });

  if (response.learningProfile) {
    const cacheKey = `${groupId}-${groupOfGroupChallengeId}`;
    await mutate(cacheKey);
    return response;
  } else {
    throw new Error("Submit data failed");
  }
}

import { useAuth0 } from "@auth0/auth0-react";
import colors from "@css/colors.json";
import {
  AppBar,
  Button,
  Container,
  Theme,
  Toolbar,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { BottomNavigation } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import UserAvatar from "@components/Avatar";
import DocumentTitle from "@components/DocumentTitle";
import IconHome from "@components/IconHome";
import TOULogo from "@components/svgs/TOULogo";

import MenuButton from "./MainMenuButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      color: colors.logo,
    },
    div: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    appBar: {
      backgroundColor: "#FFF",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    bottomNavigation: {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
      position: "fixed",
      bottom: "0",
      width: "100%",
    },
    backButton: {
      textAlign: "left",
      margin: "16px",
      color: "#484746",
    },
    link: {
      textDecoration: "none",
      color: "black",
    },
    hideImg: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);
type Props = {
  title?: string;
  children?: React.ReactNode;
  onPressBack?: () => void;
};
const AppLayout: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const isHome =
    location.pathname.toLocaleLowerCase() === "/" ||
    location.pathname.toLocaleLowerCase() === "/main";

  return (
    <div className={classes.root}>
      <DocumentTitle>{props.title}</DocumentTitle>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.logo}>
            <MenuButton />

            <div className="tou-logo">
              <TOULogo />
            </div>
          </div>
          <div className={classes.div}>
            {/* Temp hide this, not implemented yet
            <IconButton aria-label="new mails">
              <Badge badgeContent={1} color="default">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 2 new notifications">
              <Badge badgeContent={2} color="default">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {isAuthenticated && (
              <Button onClick={() => logout({})}>Logout</Button>
            )}
            {!isAuthenticated && (
              <Button onClick={() => loginWithRedirect({})} color="inherit">
                Login
              </Button>
            )}
            <UserAvatar user={user} />
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.backButton}>
        {!isHome && (
          <Button
            style={{ backgroundColor: "transparent" }}
            onClick={props.onPressBack ?? history.goBack}
          >
            <ArrowBackIcon />
          </Button>
        )}
      </div>
      <Container maxWidth="lg">{props.children} </Container>
      <BottomNavigation className={classes.bottomNavigation}>
        <IconHome />
      </BottomNavigation>
    </div>
  );
};

export default AppLayout;

import { Grid } from "@material-ui/core";

import AppLayout from "@layouts/AppLayout";

const Unauthorized = () => {
  return (
    <AppLayout>
      <Grid>
        <p>Unauthorized</p>
      </Grid>
    </AppLayout>
  );
};

export default Unauthorized;

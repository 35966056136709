import { MilestoneEntity } from "@/types/milestone";
import moment from "moment";

export type MilestoneEntityWithDueDate = MilestoneEntity & {
  dueDate: string;
};
export function calculateDueDates(
  startDate: Date,
  milestones: MilestoneEntity[]
): MilestoneEntityWithDueDate[] {
  const result: MilestoneEntityWithDueDate[] = [];
  let lastDueDate = startDate || new Date();
  for (const milestone of milestones) {
    const dueDate = moment(lastDueDate).add(
      milestone.attributes?.durationDays,
      "days"
    );
    lastDueDate = dueDate.toDate();
    const newMilestone: MilestoneEntityWithDueDate = {
      ...milestone,
      dueDate: lastDueDate.toISOString(),
    };
    result.push(newMilestone);
  }

  return result;
}

import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR, { useSWRConfig } from "swr";

import { useService } from "@libs/hooks/useService";
import { ProgressBySubsQuery } from "@libs/service/graphql/generated/operations";
import {
  ProgressBySubsDataInput,
  Scalars,
} from "@libs/service/graphql/generated/types";

const query = loader("../../lib/service/graphql/query/progressBySubs.graphql");

export type ProgressBySubsQueryVariables = {
  subs?: Array<Scalars["String"]> | Scalars["String"];
  data: Array<ProgressBySubsDataInput> | ProgressBySubsDataInput;
};

const useProgressBySubs = (
  subs: string[],
  dataInput: ProgressBySubsDataInput[]
) => {
  const { apigatewayClient: client } = useService();
  const { mutate } = useSWRConfig();
  const reqBody: ProgressBySubsQueryVariables = {
    subs,
    data: dataInput,
  };
  const isEnabled = client && subs && dataInput;
  const key = `progressBySubs?${qs.stringify({
    subs,
    data: JSON.stringify(dataInput),
  })}`;
  const { data, error } = useSWR(
    isEnabled ? key : null,
    () =>
      client
        ?.request<ProgressBySubsQuery, ProgressBySubsQueryVariables>(
          query,
          reqBody
        )
        .then((result) => {
          return result.progressBySubs;
        }),
    { revalidateOnFocus: false }
  );

  const revalidate = () => mutate(key);

  return {
    data,
    revalidate,
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default useProgressBySubs;

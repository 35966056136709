import { Button } from "antd";
import { FC, memo, useCallback, useMemo, useState } from "react";
import { RiDriveLine, RiFolderLine, RiLinksLine } from "react-icons/ri";

import DropdownButton, {
  DropdownButtonMenuItems,
} from "@components/dropdown-button";

import { useService } from "@libs/hooks/useService";
import { GoogleDriveIdLinks } from "@libs/service/graphql/generated/types";
import { downloadFileFromBlob } from "@libs/utils/utility";

import style from "../grade-assessment.module.scss";
import { isFileuploadServiceURL, isGoogleDriveURL } from "./utils";

function getFileIcon(url: string) {
  if (isFileuploadServiceURL(url)) {
    return <RiFolderLine />;
  } else if (isGoogleDriveURL(url)) {
    return <RiDriveLine />;
  }

  return <RiLinksLine />;
}

type Props = {
  deliverables: GoogleDriveIdLinks[];
};
const DeliverablesDropdown: FC<Props> = ({ deliverables = [] }) => {
  const { token } = useService();
  const [activeIndexDownloading, setActiveIndexDownloading] = useState(-1);

  const handleDownload = useCallback(
    async (fileName: string, url: string) => {
      if (!isFileuploadServiceURL(url)) {
        window.open(url, "_blank");
        setActiveIndexDownloading(-1);
        return;
      }

      if (!token) {
        throw new Error("No token provided.");
      }
      try {
        const headers = new Headers({ Authorization: token as string });
        const data = await fetch(url, { headers });
        downloadFileFromBlob(fileName, await data.blob());
      } catch (err) {
        console.error(err);
      } finally {
        setActiveIndexDownloading(-1);
      }
    },
    [token]
  );

  const menu = useMemo<DropdownButtonMenuItems>(
    () =>
      deliverables?.map((item, i) => ({
        key: item.url as string,
        label: (
          <Button
            className={style.deliverableButton}
            type="link"
            loading={activeIndexDownloading === i}
            icon={getFileIcon(item.url as string)}
          >
            {item.name}
          </Button>
        ),
        onClick: () => {
          setActiveIndexDownloading(i);
          handleDownload(item.name as string, item.url as string);
        },
      })),
    [deliverables, activeIndexDownloading, handleDownload]
  );

  if (deliverables.length < 1) return null;

  return (
    <DropdownButton
      label={`${deliverables?.length} Deliverables`}
      menuItems={menu}
    />
  );
};

export default memo(DeliverablesDropdown);

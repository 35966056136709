import { loader } from "graphql.macro";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  GetAllUserProfilesQuery,
  GetAllUserProfilesQueryVariables,
} from "@libs/service/user-graphql/generated/operations";
import { UserProfile } from "@libs/service/user-graphql/generated/types";

const query = loader(
  "../../lib/service/user-graphql/query/GetAllUserProfiles.graphql"
);

const useGetAllUserProfiles = () => {
  const { userClient: client } = useService();

  const isEnabled = client;

  const { data, error } = useSWR(isEnabled ? `GetAllUserProfiles` : null, () =>
    client
      ?.request<GetAllUserProfilesQuery, GetAllUserProfilesQueryVariables>(
        query
      )
      .then((result) => {
        return result?.getAllUserProfiles;
      })
  );

  return {
    data: data as UserProfile[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useGetAllUserProfiles;

import { ComponentType, FC } from "react";

import ProtectedRoute, {
  ProtectedRouteProps,
} from "@components/ProtectedRoute";

type WithProtectedRouteOptions = Pick<ProtectedRouteProps, "scopes">;

// eslint-disable-next-line @typescript-eslint/ban-types
const withProtectedRoute = <P extends {}>(
  Component: ComponentType<P>,
  options: WithProtectedRouteOptions
): FC<P> => {
  return function withProtectedRoute(props: P): JSX.Element {
    return (
      <ProtectedRoute
        scopes={options.scopes}
        component={() => <Component {...props} />}
      />
    );
  };
};

export default withProtectedRoute;

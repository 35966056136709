/**
 * Collection of utility functions for user profile
 * @test: src/lib/utils/tests/user-profile.utils.spec.ts
 */
import { UserProfile } from "../service/user-graphql/generated/types";
import { sift } from "./array";

/**
 * Compose the user profile fallback values
 * At the moment it's only to compose the `name` field
 * @param userProfile object
 * @returns
 */
export function composeUserProfile(userProfile: UserProfile) {
  const newUserProfile = { ...userProfile };
  // From the https://tomorrows-education.atlassian.net/browse/LRN-572
  // AC: identified by full name. composed from first and last name
  const composedUserFullName = sift([
    userProfile.givenName, // The `givenName` is the first name of the user.
    userProfile.familyName, // The `familyName` is the last name of the user.
  ]);
  if (composedUserFullName.length > 0) {
    newUserProfile.name = composedUserFullName.join(" ");
  }
  // falling back to fullName
  else if (userProfile.name) {
    newUserProfile.name = userProfile.name;
  }
  // or finally email
  else if (userProfile.email) {
    newUserProfile.name = userProfile.email;
  } else {
    newUserProfile.name = userProfile.userId;
  }

  return newUserProfile;
}

/**
 * Compose the user profile fallback values
 * @param userProfiles UserProfile[]
 * @returns UserProfile[]
 */
export function composeUserProfiles(userProfiles: UserProfile[]) {
  if (!userProfiles) return userProfiles;
  return userProfiles.map((userProfile) => composeUserProfile(userProfile));
}

import { useState } from "react";

const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    onOpen: handleOpen,
    onClose: handleClose,
    onToggle: handleToggle,
  };
};

export type UseDisclosureReturn = Partial<ReturnType<typeof useDisclosure>>;

export default useDisclosure;

import { DatePicker, Form, FormItemProps, InputProps } from "antd";
import moment from "moment";
import { PropsWithChildren } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { controllerToFormItemProps } from "@libs/utils/hookform-antd";

type DatePickerControllerProps<FormValues> = FormItemProps &
  InputProps &
  Omit<ControllerProps<FormValues>, "render">;

// eslint-disable-next-line @typescript-eslint/ban-types
function DatePickerController<FormValues extends {}>({
  control,
  name,
  placeholder,
  ...restProps
}: PropsWithChildren<DatePickerControllerProps<FormValues>>) {
  return (
    <Controller<FormValues>
      control={control}
      name={name}
      render={({ field, formState }) => (
        <Form.Item
          {...controllerToFormItemProps(
            { field, formState },
            { ...restProps, initialValue: moment(restProps.initialValue) }
          )}
        >
          <div className="date-picker">
            <DatePicker
              defaultValue={restProps.initialValue}
              name={name}
              style={{ width: "100%" }}
              placeholder={placeholder}
              onChange={(date) => {
                field.onChange(date?.toISOString() || "");
              }}
              value={field.value ? moment(field.value) : null}
            />
          </div>
        </Form.Item>
      )}
    />
  );
}

export default DatePickerController;

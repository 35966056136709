import { Col, Row, Space, Typography } from "antd";
import { FC } from "react";

import styles from "../grade-assessment.module.scss";

type Props = {
  title?: string;
  subtitle?: string;
  className?: string;
};

const SectionContent: FC<Props> = ({ title, subtitle, children, ...props }) => {
  return (
    <Row {...props}>
      <Col span={8}>
        {(title || subtitle) && (
          <Space
            direction="vertical"
            size="middle"
            className={styles.leftColumnContent}
          >
            {title && <Typography.Text strong>{title}</Typography.Text>}
            {subtitle && <Typography.Text>{subtitle}</Typography.Text>}
          </Space>
        )}
      </Col>

      <Col span={16}>{children}</Col>
    </Row>
  );
};

export default SectionContent;

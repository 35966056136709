import { ProtectedRouteScope } from "@components/ProtectedRoute";

import ChallengesDetailPage from "@pages/ChallengesDetailPage";
import ChallengesPage from "@pages/ChallengesPage";
import CohortDashboardPage from "@pages/CohortDashboardPage";
import GradeMilestonePage from "@pages/GradeMilestonePage";
import GroupChallenge from "@pages/GroupChallenge";
import GroupChallengeForm from "@pages/GroupChallengeForm";
import GroupItemDetail from "@pages/GroupItemDetail";
import GroupList from "@pages/GroupList";
import GroupOfGroupChallengeForm from "@pages/GroupOfGroupChallengeForm";
import Inbox from "@pages/Inbox";
import LoginPage from "@pages/Login";
import Logged from "@pages/Main";
import NotFoundPage from "@pages/NotFound";
import PeerGroupPage from "@pages/PeerGroupPage";
import Redirection from "@pages/Redirection";
import SoloChallengeDetails from "@pages/SoloChallengeDetails";
import SoloChallengePage from "@pages/SoloChallengePage";
import SoloChallengePageUserList from "@pages/SoloChallengePageUserList";
import Unauthorized from "@pages/Unauthorized";
import UserDetails from "@pages/UserDetails";
import UsersPermissionTable from "@pages/UsersList";

import withProtectedRoute from "./hocs/withProtectedRoute";
import { APP_ROUTES } from "./routes";

export const BaseRoutes = [
  {
    path: "/",
    exact: true,
    component: LoginPage,
  },
  {
    component: NotFoundPage,
  },
];

export const ProtectedRoutes = [
  {
    path: APP_ROUTES.USERS,
    exact: true,
    component: UsersPermissionTable,
  },
  {
    path: APP_ROUTES.USER,
    exact: true,
    component: UserDetails,
  },
  {
    path: "/main",
    exact: true,
    component: Logged,
  },
  {
    path: "/inbox",
    exact: true,
    component: Inbox,
  },
  {
    path: "/group-challenge",
    exact: true,
    component: GroupChallenge,
  },
  {
    path: "/group-challenge-edit/:groupID/:groupInstanceId",
    exact: true,
    component: GroupChallengeForm,
  },
  {
    path: "/group-challenge/:id",
    exact: true,
    component: GroupList,
  },
  {
    path: "/new-group-challenge/:id",
    exact: true,
    component: GroupOfGroupChallengeForm,
  },
  {
    path: "/group-challenge/:id/:groupID",
    exact: true,
    component: GroupItemDetail,
  },
  {
    path: "/inbox/:id/:revieweeID",
    exact: true,
    component: SoloChallengeDetails,
  },
  {
    path: "/redirection",
    exact: true,
    component: Redirection,
  },
  {
    path: "/solo-challenge",
    exact: true,
    component: SoloChallengePage,
  },
  {
    path: "/solo-challenge/:id",
    exact: true,
    component: SoloChallengePageUserList,
  },
  {
    path: "/unauthorized",
    exact: true,
    component: Unauthorized,
  },
  {
    path: APP_ROUTES.CHALLENGES,
    exact: true,
    component: ChallengesPage,
  },
  {
    path: APP_ROUTES.CHALLENGE_DETAIL(":id"),
    exact: true,
    component: ChallengesDetailPage,
  },
  {
    path: APP_ROUTES.CHALLENGE_PEER_GROUP(":id", ":peerGroupId"),
    exact: true,
    component: PeerGroupPage,
  },
  {
    path: APP_ROUTES.CHALLENGE_MILESTONE_GRADE(
      ":id",
      ":peerGroupId",
      ":milestoneId"
    ),
    exact: true,
    component: GradeMilestonePage,
  },
  {
    path: APP_ROUTES.COHORT_DASHBOARD,
    exact: true,
    component: CohortDashboardPage,
  },
].map((route) => ({
  ...route,
  component: withProtectedRoute(route.component, {
    scopes: [ProtectedRouteScope.Staff],
  }),
}));

export const AppRoutes = [...ProtectedRoutes, ...BaseRoutes];

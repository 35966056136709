import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { PropsWithChildren } from "react";

export type GenericTableColumn<T> = ColumnsType<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
type GenericTableProps<T = {}> = TableProps<T> & {
  loading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
function GenericTable<T extends {}>({
  dataSource,
  columns,
  loading,
  rowKey,
  ...props
}: PropsWithChildren<GenericTableProps<T>>) {
  // function onTableChange(pagination: unknown, filters: unknown, sorter: unknown) {
  // TODO: implementation and typing needed
  //   console.error("onTableChange : Not Implemented");
  // }

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      onChange={() => ({})}
      rowKey={rowKey}
      onRow={() => ({
        role: "row",
      })}
      {...props}
    />
  );
}

export default GenericTable;

import { loader } from "graphql.macro";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  GetUserProfileByUserIdQuery,
  GetUserProfileByUserIdQueryVariables,
} from "@libs/service/user-graphql/generated/operations";
import { UserProfile } from "@libs/service/user-graphql/generated/types.d";

const query = loader(
  "../../lib/service/user-graphql/query/GetUserProfileByUserId.graphql"
);

const useUser = (userId: string) => {
  const { userClient: client } = useService();

  const { data, error } = useSWR(
    client && userId ? `getUser?id=${userId}` : null,
    () =>
      client
        ?.request<
          GetUserProfileByUserIdQuery,
          GetUserProfileByUserIdQueryVariables
        >(query, { userId })
        .then((result) => {
          return result.getUserProfileByUserId;
        })
  );

  return {
    data: data as UserProfile,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUser;

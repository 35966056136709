import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  ChallengeQuery,
  ChallengeQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { ChallengeEntity } from "@libs/service/graphql/generated/types";

const challengeQuery = loader(
  "../../lib/service/graphql/query/challenge.graphql"
);

const useChallenge = (id: string) => {
  const { apigatewayClient: client } = useService();

  const reqBody = { id };
  const { data, error } = useSWR(
    client ? `getChallenge?${qs.stringify(reqBody)}` : null,
    () =>
      client?.request<ChallengeQuery, ChallengeQueryVariables>(
        challengeQuery,
        reqBody
      )
  );
  return {
    challenge: data?.challenge?.data as ChallengeEntity,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useChallenge;

import { Input } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

import { SoloChallengeCriterionEvaluationInput } from "@libs/service/graphql/generated/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 300,
      color: "black",
      marginBottom: "16px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
type State = {
  id: string;
  grade: string;
  comment: string;
};

type Props = {
  setGradeCriteria: (
    criteria: Array<SoloChallengeCriterionEvaluationInput>
  ) => void;
  gradeCriteria: Array<SoloChallengeCriterionEvaluationInput>;
  criteriaID: string;
};

export default function GradeSelect(props: Props) {
  const classes = useStyles();
  const initialState = {
    id: props.criteriaID,
    grade: "",
    comment: "",
  };
  const [state, setState] = React.useState<State>(initialState);
  React.useEffect(() => {
    const gradedItem = props.gradeCriteria.find(
      (val) => val.id === props.criteriaID
    );
    if (state.grade === "" && state.comment === "" && gradedItem) {
      setState({
        ...state,
        id: props.criteriaID,
        grade: gradedItem ? gradedItem.grade : "",
        comment: gradedItem && gradedItem.comment ? gradedItem.comment : "",
      });
    }
  }, [props, state]);

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    field: string
  ) => {
    const formData = props.gradeCriteria;
    const criteriaExist = props.gradeCriteria.findIndex(
      (val) => val.id === props.criteriaID
    );
    const newState =
      field === "grade"
        ? { ...state, grade: event.target.value as string }
        : { ...state, comment: event.target.value as string };
    setState({ ...newState });
    if (criteriaExist >= 0) {
      formData.splice(criteriaExist, 1);
      formData.push({ ...newState });
      props.setGradeCriteria(formData);
    } else {
      formData.push({ ...newState });
      props.setGradeCriteria(formData);
    }
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Grade</InputLabel>
        <Select
          native
          value={Number(state.grade)}
          onChange={(event) => handleChange(event, "grade")}
        >
          <option aria-label="None" value="" />
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Comment</InputLabel>
        <Input
          rows={5}
          placeholder="Comment"
          value={state.comment}
          multiline={true}
          onChange={(event) => handleChange(event, "comment")}
        />
      </FormControl>
    </>
  );
}

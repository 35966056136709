import colors from "@css/colors.json";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import { GroupChallengeEntity } from "@libs/service/graphql/generated/types.d";
import { fetchGroupChallenges } from "@libs/service/groupChallenge";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
    button: {
      width: "120px",
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    newBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "12px",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: 1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      color: "#8A857F",
    },
  })
);

export default function GroupChallengePage() {
  const styles = useStyles();
  const history = useHistory();

  const { apigatewayClient: client } = useService();
  const { data: groupChallengeData, error: groupChallengeError } = useSWR(
    (client &&
      fetchGroupChallenges(client as GraphQLClient, "name", 20, 0).cacheKey) ||
      null,
    (client &&
      fetchGroupChallenges(client as GraphQLClient, "name", 20, 0).fetch) ||
      null
  );

  function redirectToGroupList(record: GroupChallengeEntity) {
    history.push(`/group-challenge/${base64.encode(record.id ?? "")}`);
  }

  const columns: GenericTableColumn<GroupChallengeEntity> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "name",
    },
    {
      title: "Name",
      dataIndex: ["attributes", "name"],
      key: "name",
    },
    {
      title: "Description",
      dataIndex: ["attributes", "description"],
      key: "description",
      render: (text) => {
        return (
          <p>
            {text.slice(0, 150)}
            {text.length > 150 ? "..." : ""}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => redirectToGroupList(record)}>View Detail</Button>
      ),
    },
  ];
  if (groupChallengeError) {
    return <p>Failed to fetching detail info.</p>;
  }

  return (
    <AppLayout title="Group Challenges">
      <div className={styles.root}>
        {/*
        NOTE: temp hide
        <div className={styles.newBtn}>
          <Typography className={styles.formGridTitle}>
            Group Challenges
          </Typography>
          <Button
            className={styles.button}
            onClick={() => history.push(`/new-group-challenge`)}
          >
            + Create
          </Button>
        </div> */}

        <GenericTable
          columns={columns}
          dataSource={groupChallengeData ?? []}
          loading={!groupChallengeData}
          rowKey={(item: GroupChallengeEntity) =>
            `${item.id}-${item.attributes?.name ?? ""}`
          }
        />
      </div>
    </AppLayout>
  );
}

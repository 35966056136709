import useUsersByRole, { USER_ROLE } from "./useUsersByRole";

const useStaffs = () => {
  const { data: usersData, ...data } = useUsersByRole(USER_ROLE.STAFF);
  return {
    ...data,
    staffs: usersData,
  };
};

export default useStaffs;

import { Container, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import rowsSoloChallenge from "../mocked-data/solo-challenge-data";

const useStyles = makeStyles({
  paper: {
    width: "100%",
    overflowX: "auto",
    border: "none",
  },
  container: {
    overflowX: "auto",
    marginTop: "30px",
    marginBottom: "30px",
  },
  read: {
    backgroundColor: "fafafa",
  },
  unread: {
    backgroundColor: "#ffffe9",
  },
  unreadFont: {
    textDecoration: "none",
    color: "#484746",
    fontWeight: 600,
  },
  readFont: {
    textDecoration: "none",
    color: "gray",
  },
  linkDecoration: {
    textDecoration: "none",
    color: "#494746",
  },
});

function SoloChallengeList() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headerStyle = {
    minWidth: 30,
    backgroundColor: "#FDF3E4",
    borderRadius: "2px",
    color: "#484746",
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper elevation={0}>
        <Typography
          variant="h6"
          style={{
            color: "#484746",
            marginBottom: "50px",
            textAlign: "left",
          }}
        >
          Review Request
        </Typography>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={headerStyle}>Id</TableCell>
                <TableCell style={headerStyle}>Reviewee</TableCell>
                <TableCell style={headerStyle}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rowsSoloChallenge.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.container}
        />
      </Paper>
    </Container>
  );
}
export default SoloChallengeList;

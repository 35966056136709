import { APP_ROUTES } from "@/routes";
import { createStyles, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AppLayout from "@layouts/AppLayout";

import CohortDashboard from "@views/CohortDashboard";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
  })
);

const CohortDashboardPage = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <AppLayout
      title="Cohort Dashboard"
      onPressBack={() => history.push(APP_ROUTES.HOME)}
    >
      <div className={styles.root}>
        <CohortDashboard />
      </div>
    </AppLayout>
  );
};

export default CohortDashboardPage;

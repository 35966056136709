import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";

import TOULogo from "@components/svgs/TOULogo";

import AppLayout from "@layouts/AppLayout";

const LoginPage: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <AppLayout>
      <div className="container">
        <div className="LogoName login-inbox">
          <div className="tou-logo" style={{ marginBottom: "50px" }}>
            <TOULogo style={{ width: "250px", height: "auto" }} />
          </div>
          <p>CAMPUS ADMIN</p>
        </div>
        {!isAuthenticated && (
          <Button onClick={() => loginWithRedirect()}>
            <div className="login-box">SIGN IN WITH GOOGLE</div>
          </Button>
        )}
      </div>
      <style>{`
            .container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 100vh;
            }
            p {
              font-size: 0.6em;
              font-weight: bold;
            }
            .LogoName{
              margin-top: 0px;
              margin-bottom: 3em;
              opacity: 0;
              animation: slidefadeup 2s;
              animation-fill-mode: forwards;
            }
            @keyframes slidefadeup {
              100% {
                margin-top: -20vh;
                opacity: 1;
              }
            }
            .login-box {
              color: #494746;
              margin: 0px 0px 0px 0px;
              padding: 13px 70px;
              border: 1px solid rgba(72, 71, 70, 1);
              border-radius: 8px;
              opacity: 0;
              animation: fadein 1s;
              animation-delay: 1s;
              animation-fill-mode: forwards;
            }
            .signup-box {
              margin-top: 40px;
              opacity: 0;
              animation: fadein 1s;
              animation-delay: 1.5s;
              animation-fill-mode: forwards;
            }
            @keyframes fadein {
              100% {
                opacity: 1;
              }
            }
          `}</style>
    </AppLayout>
  );
};

export default LoginPage;

import { Select } from "antd";
import { GraphQLClient } from "graphql-request";
import React from "react";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import { allUsersRequest } from "@libs/service/allusers";
import { Maybe } from "@libs/service/graphql/generated/types";

interface UserPickerProps {
  onChange(id: string): void;
  selectedId?: Maybe<string> | undefined;
  disabled?: boolean;
}

const UserPicker: React.FC<UserPickerProps> = (props) => {
  const { adminClient: client } = useService();

  const { data: allUsersData } = useSWR(
    (client && allUsersRequest(client as GraphQLClient).cacheKey) || null,
    (client && allUsersRequest(client as GraphQLClient).fetch) || null
  );

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      placeholder="Search to Select"
      optionFilterProp="children"
      value={props.selectedId || undefined}
      onChange={(value: string) => {
        props.onChange(value);
      }}
      disabled={props.disabled}
    >
      {allUsersData ? (
        allUsersData.map((user) => (
          <Select.Option value={user.userId} key={`picker-${user.userId}`}>
            {user.name}
          </Select.Option>
        ))
      ) : (
        <Select.Option value={""} key={`picker`}>
          Loading users...
        </Select.Option>
      )}
    </Select>
  );
};

export default UserPicker;

import { Button, Form, Modal, Select, notification } from "antd";
import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import useGetAllUserProfiles from "@hooks/query/useAllUserProfiles";
import useChangeReviewerUserMilestone from "@hooks/useChangeReviewerUserMilestone";
import useDisclosure from "@hooks/useDisclosure";

import { UserProfile } from "@libs/service/user-graphql/generated/types";

type Props = ReturnType<typeof useDisclosure> & {
  userSub: string;
  milestoneId: string;
  challengeId: string;
  reviewerSub?: string;
};
type FormValues = {
  reviewerSub: string;
};
const ChangeReviewerModal: FC<Props> = ({
  isOpen,
  onClose,

  userSub,
  milestoneId,
  challengeId,
  reviewerSub,
}) => {
  const { reset, control, getValues } = useForm<FormValues>({
    defaultValues: {
      reviewerSub,
    },
  });
  const { data: users } = useGetAllUserProfiles();
  const { mutate, isLoading } = useChangeReviewerUserMilestone();

  const filteredUsers = useMemo(
    () => users?.filter((user) => user.userId !== userSub),
    [users, userSub]
  );

  const handleAssign = useCallback(async () => {
    const values = getValues();
    try {
      await mutate({
        ...values,
        userSub,
        milestoneId,
        challengeId,
      });
      notification.success({ message: "Reviewer assigned" });
      onClose?.();
    } catch (err) {
      console.error(err);
      notification.error({ message: "Error assigning reviewer" });
    }
  }, [onClose, getValues, mutate, userSub, milestoneId, challengeId]);

  // This will reinitilize the defaultValues once changed
  useEffect(() => {
    if (isOpen) {
      reset({ reviewerSub });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reviewerSub]);

  return (
    <Modal
      title="Change Reviewer"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="assign"
          type="primary"
          onClick={handleAssign}
          loading={isLoading}
        >
          Assign
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label="Select a new reviewer to be assigned."
          rules={[{ required: true }]}
        >
          <Controller<FormValues>
            control={control}
            name="reviewerSub"
            render={({ field }) => (
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                {...field}
                placeholder="Select a person"
              >
                {filteredUsers?.map((user: UserProfile) => (
                  <Select.Option key={user.userId} value={user.userId}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(ChangeReviewerModal);

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
};

export type CreateEnrolmentForSetOfLearnersOutput = {
  __typename?: 'CreateEnrolmentForSetOfLearnersOutput';
  enrollments?: Maybe<Array<Maybe<IdOnly>>>;
  learners?: Maybe<Array<Maybe<IdOnly>>>;
};

export type CreateEnrolmentInput = {
  id?: InputMaybe<Scalars['ID']>;
  intakeId: Scalars['ID'];
  learnerId: Scalars['ID'];
  status: EnrolmentStatus;
};

export type CreateIntakeInput = {
  curriculumId?: InputMaybe<Scalars['String']>;
  dateApplicationEnd: Scalars['AWSDateTime'];
  dateApplicationStart: Scalars['AWSDateTime'];
  dateIntakeStart: Scalars['AWSDateTime'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  programId: Scalars['ID'];
};

export type CreateLearnerInput = {
  id?: InputMaybe<Scalars['ID']>;
  username: Scalars['String'];
};

export type CreateProgramInput = {
  alias?: InputMaybe<Scalars['String']>;
  currentCurriculumId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProgramStatus>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type DeleteEnrolmentInput = {
  id: Scalars['ID'];
};

export type DeleteIntakeInput = {
  id: Scalars['ID'];
};

export type DeleteLearnerInput = {
  id: Scalars['ID'];
};

export type DeleteProgramInput = {
  id: Scalars['ID'];
};

export type Enrolment = {
  __typename?: 'Enrolment';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  intake: Intake;
  intakeId: Scalars['ID'];
  learner?: Maybe<Learner>;
  learnerId: Scalars['ID'];
  status: EnrolmentStatus;
  updatedAt: Scalars['AWSDateTime'];
};

export enum EnrolmentStatus {
  Admission = 'ADMISSION',
  Admitted = 'ADMITTED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Paused = 'PAUSED',
  RejectedNotEligible = 'REJECTED_NOT_ELIGIBLE',
  RejectedNoFit = 'REJECTED_NO_FIT'
}

export type IdOnly = {
  __typename?: 'IdOnly';
  id: Scalars['ID'];
};

export type Intake = {
  __typename?: 'Intake';
  createdAt: Scalars['AWSDateTime'];
  curriculumId?: Maybe<Scalars['String']>;
  dateApplicationEnd: Scalars['AWSDateTime'];
  dateApplicationStart: Scalars['AWSDateTime'];
  dateIntakeStart: Scalars['AWSDateTime'];
  enrollments?: Maybe<ModelEnrolmentConnection>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  program?: Maybe<Program>;
  programId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};


export type IntakeEnrollmentsArgs = {
  filter?: InputMaybe<ModelEnrolmentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Learner = {
  __typename?: 'Learner';
  createdAt: Scalars['AWSDateTime'];
  enrollments?: Maybe<ModelEnrolmentConnection>;
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  username: Scalars['String'];
};


export type LearnerEnrollmentsArgs = {
  filter?: InputMaybe<ModelEnrolmentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelEnrolmentConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelEnrolmentConditionInput>>>;
  intakeId?: InputMaybe<ModelIdInput>;
  learnerId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelEnrolmentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEnrolmentConditionInput>>>;
  status?: InputMaybe<ModelEnrolmentStatusInput>;
};

export type ModelEnrolmentConnection = {
  __typename?: 'ModelEnrolmentConnection';
  items: Array<Maybe<Enrolment>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelEnrolmentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelEnrolmentFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  intakeId?: InputMaybe<ModelIdInput>;
  learnerId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelEnrolmentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelEnrolmentFilterInput>>>;
  status?: InputMaybe<ModelEnrolmentStatusInput>;
};

export type ModelEnrolmentStatusInput = {
  eq?: InputMaybe<EnrolmentStatus>;
  ne?: InputMaybe<EnrolmentStatus>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ModelIntakeConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelIntakeConditionInput>>>;
  curriculumId?: InputMaybe<ModelStringInput>;
  dateApplicationEnd?: InputMaybe<ModelStringInput>;
  dateApplicationStart?: InputMaybe<ModelStringInput>;
  dateIntakeStart?: InputMaybe<ModelStringInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelIntakeConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelIntakeConditionInput>>>;
  programId?: InputMaybe<ModelIdInput>;
};

export type ModelIntakeConnection = {
  __typename?: 'ModelIntakeConnection';
  items: Array<Maybe<Intake>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelIntakeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelIntakeFilterInput>>>;
  curriculumId?: InputMaybe<ModelStringInput>;
  dateApplicationEnd?: InputMaybe<ModelStringInput>;
  dateApplicationStart?: InputMaybe<ModelStringInput>;
  dateIntakeStart?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelIntakeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelIntakeFilterInput>>>;
  programId?: InputMaybe<ModelIdInput>;
};

export type ModelLearnerConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLearnerConditionInput>>>;
  not?: InputMaybe<ModelLearnerConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelLearnerConditionInput>>>;
  username?: InputMaybe<ModelStringInput>;
};

export type ModelLearnerConnection = {
  __typename?: 'ModelLearnerConnection';
  items: Array<Maybe<Learner>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelLearnerFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelLearnerFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelLearnerFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelLearnerFilterInput>>>;
  username?: InputMaybe<ModelStringInput>;
};

export type ModelProgramConditionInput = {
  alias?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelProgramConditionInput>>>;
  currentCurriculumId?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelProgramConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelProgramConditionInput>>>;
  status?: InputMaybe<ModelProgramStatusInput>;
  title?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
};

export type ModelProgramConnection = {
  __typename?: 'ModelProgramConnection';
  items: Array<Maybe<Program>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelProgramFilterInput = {
  alias?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelProgramFilterInput>>>;
  currentCurriculumId?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelProgramFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelProgramFilterInput>>>;
  status?: InputMaybe<ModelProgramStatusInput>;
  title?: InputMaybe<ModelStringInput>;
  type?: InputMaybe<ModelStringInput>;
};

export type ModelProgramStatusInput = {
  eq?: InputMaybe<ProgramStatus>;
  ne?: InputMaybe<ProgramStatus>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelStringKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
};

export type ModelSubscriptionBooleanInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelSubscriptionEnrolmentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEnrolmentFilterInput>>>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  intakeId?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEnrolmentFilterInput>>>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionFloatInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type ModelSubscriptionIdInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ModelSubscriptionIntInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ModelSubscriptionIntakeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionIntakeFilterInput>>>;
  curriculumId?: InputMaybe<ModelSubscriptionStringInput>;
  dateApplicationEnd?: InputMaybe<ModelSubscriptionStringInput>;
  dateApplicationStart?: InputMaybe<ModelSubscriptionStringInput>;
  dateIntakeStart?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  name?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionIntakeFilterInput>>>;
  programId?: InputMaybe<ModelSubscriptionIdInput>;
};

export type ModelSubscriptionLearnerFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionLearnerFilterInput>>>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionLearnerFilterInput>>>;
  username?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionProgramFilterInput = {
  alias?: InputMaybe<ModelSubscriptionStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionProgramFilterInput>>>;
  currentCurriculumId?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionProgramFilterInput>>>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
  title?: InputMaybe<ModelSubscriptionStringInput>;
  type?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionStringInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createEnrolment?: Maybe<Enrolment>;
  createEnrolmentForSetOfLearners?: Maybe<CreateEnrolmentForSetOfLearnersOutput>;
  createIntake?: Maybe<Intake>;
  createLearner?: Maybe<Learner>;
  createProgram?: Maybe<Program>;
  deleteEnrolment?: Maybe<Enrolment>;
  deleteIntake?: Maybe<Intake>;
  deleteLearner?: Maybe<Learner>;
  deleteProgram?: Maybe<Program>;
  syncProgramsFromSource?: Maybe<ProgramConnection>;
  updateEnrolment?: Maybe<Enrolment>;
  updateIntake?: Maybe<Intake>;
  updateLearner?: Maybe<Learner>;
  updateProgram?: Maybe<Program>;
};


export type MutationCreateEnrolmentArgs = {
  condition?: InputMaybe<ModelEnrolmentConditionInput>;
  input: CreateEnrolmentInput;
};


export type MutationCreateEnrolmentForSetOfLearnersArgs = {
  intakeId: Scalars['String'];
  learnersIdList: Array<Scalars['String']>;
  status: EnrolmentStatus;
};


export type MutationCreateIntakeArgs = {
  input: CreateIntakeInput;
};


export type MutationCreateLearnerArgs = {
  condition?: InputMaybe<ModelLearnerConditionInput>;
  input: CreateLearnerInput;
};


export type MutationCreateProgramArgs = {
  condition?: InputMaybe<ModelProgramConditionInput>;
  input: CreateProgramInput;
};


export type MutationDeleteEnrolmentArgs = {
  condition?: InputMaybe<ModelEnrolmentConditionInput>;
  input: DeleteEnrolmentInput;
};


export type MutationDeleteIntakeArgs = {
  condition?: InputMaybe<ModelIntakeConditionInput>;
  input: DeleteIntakeInput;
};


export type MutationDeleteLearnerArgs = {
  condition?: InputMaybe<ModelLearnerConditionInput>;
  input: DeleteLearnerInput;
};


export type MutationDeleteProgramArgs = {
  condition?: InputMaybe<ModelProgramConditionInput>;
  input: DeleteProgramInput;
};


export type MutationUpdateEnrolmentArgs = {
  condition?: InputMaybe<ModelEnrolmentConditionInput>;
  input: UpdateEnrolmentInput;
};


export type MutationUpdateIntakeArgs = {
  condition?: InputMaybe<ModelIntakeConditionInput>;
  input: UpdateIntakeInput;
};


export type MutationUpdateLearnerArgs = {
  condition?: InputMaybe<ModelLearnerConditionInput>;
  input: UpdateLearnerInput;
};


export type MutationUpdateProgramArgs = {
  condition?: InputMaybe<ModelProgramConditionInput>;
  input: UpdateProgramInput;
};

export type Program = {
  __typename?: 'Program';
  alias?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  currentCurriculumId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intakes?: Maybe<ModelIntakeConnection>;
  status?: Maybe<ProgramStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
};


export type ProgramIntakesArgs = {
  dateIntakeStart?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelIntakeFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ProgramConnection = {
  __typename?: 'ProgramConnection';
  items: Array<Maybe<Program>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum ProgramStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Retired = 'RETIRED'
}

export type Query = {
  __typename?: 'Query';
  enrolmentByIntake?: Maybe<ModelEnrolmentConnection>;
  enrolmentByLearner?: Maybe<ModelEnrolmentConnection>;
  fetchProgramsFromUC?: Maybe<ProgramConnection>;
  getEnrolment?: Maybe<Enrolment>;
  getIntake?: Maybe<Intake>;
  getLearner?: Maybe<Learner>;
  getProgram?: Maybe<Program>;
  intakeByProgram?: Maybe<ModelIntakeConnection>;
  listEnrolments?: Maybe<ModelEnrolmentConnection>;
  listIntakes?: Maybe<ModelIntakeConnection>;
  listLearners?: Maybe<ModelLearnerConnection>;
  listPrograms?: Maybe<ModelProgramConnection>;
};


export type QueryEnrolmentByIntakeArgs = {
  filter?: InputMaybe<ModelEnrolmentFilterInput>;
  intakeId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryEnrolmentByLearnerArgs = {
  filter?: InputMaybe<ModelEnrolmentFilterInput>;
  learnerId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetEnrolmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetIntakeArgs = {
  id: Scalars['ID'];
};


export type QueryGetLearnerArgs = {
  id: Scalars['ID'];
};


export type QueryGetProgramArgs = {
  id: Scalars['ID'];
};


export type QueryIntakeByProgramArgs = {
  dateIntakeStart?: InputMaybe<ModelStringKeyConditionInput>;
  filter?: InputMaybe<ModelIntakeFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  programId: Scalars['ID'];
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryListEnrolmentsArgs = {
  filter?: InputMaybe<ModelEnrolmentFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListIntakesArgs = {
  filter?: InputMaybe<ModelIntakeFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListLearnersArgs = {
  filter?: InputMaybe<ModelLearnerFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListProgramsArgs = {
  filter?: InputMaybe<ModelProgramFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateEnrolment?: Maybe<Enrolment>;
  onCreateLearner?: Maybe<Learner>;
  onCreateProgram?: Maybe<Program>;
  onDeleteEnrolment?: Maybe<Enrolment>;
  onDeleteIntake?: Maybe<Intake>;
  onDeleteLearner?: Maybe<Learner>;
  onDeleteProgram?: Maybe<Program>;
  onUpdateEnrolment?: Maybe<Enrolment>;
  onUpdateIntake?: Maybe<Intake>;
  onUpdateLearner?: Maybe<Learner>;
  onUpdateProgram?: Maybe<Program>;
};


export type SubscriptionOnCreateEnrolmentArgs = {
  learnerId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnDeleteEnrolmentArgs = {
  learnerId?: InputMaybe<Scalars['String']>;
};


export type SubscriptionOnUpdateEnrolmentArgs = {
  learnerId?: InputMaybe<Scalars['String']>;
};

export type UpdateEnrolmentInput = {
  id: Scalars['ID'];
  intakeId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<EnrolmentStatus>;
};

export type UpdateIntakeInput = {
  curriculumId?: InputMaybe<Scalars['String']>;
  dateApplicationEnd?: InputMaybe<Scalars['AWSDateTime']>;
  dateApplicationStart?: InputMaybe<Scalars['AWSDateTime']>;
  dateIntakeStart?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['ID']>;
};

export type UpdateLearnerInput = {
  id: Scalars['ID'];
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateProgramInput = {
  alias?: InputMaybe<Scalars['String']>;
  currentCurriculumId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: InputMaybe<ProgramStatus>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

import { loader } from "graphql.macro";
import { useState } from "react";

import { useService } from "@libs/hooks/useService";
import {
  ValidatePeerGroupMembersQuery,
  ValidatePeerGroupMembersQueryVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/query/validatePeerGroupMembers.graphql"
);

const useValidatePeerGroupMembers = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleRefetch = async (
    variables: ValidatePeerGroupMembersQueryVariables
  ) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        ValidatePeerGroupMembersQuery,
        ValidatePeerGroupMembersQueryVariables
      >(query, variables);
      return result?.validatePeerGroupMembers;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    refetch: handleRefetch,
    isLoading,
  };
};

export default useValidatePeerGroupMembers;

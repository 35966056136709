import { APP_ROUTES } from "@/routes";
import colors from "@css/colors.json";
import { Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Tag } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import _ from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import ChallengeGradingStatusTag from "@components/challenges/ChallengeGradingStatusTag";
import { SoloChallengeGradingStatusLocal } from "@components/challenges/UserSoloChallenges/types";
import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import { curriculaRequest } from "@libs/service/curricula";
import {
  SoloChallengeEvaluation,
  SoloChallengeGradingStatus,
} from "@libs/service/graphql/generated/types.d";
import { allLearningProfilesRequest } from "@libs/service/learningProfile";
import {
  allUsersFromSoloChallengeRequest,
  soloChallengeDetailsRequest,
} from "@libs/service/solochallenge";
import { URLParams } from "@libs/types/types";
import { constants } from "@libs/utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
    button: {
      width: "320px",
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    newBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "12px",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: 1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    btnAction: {
      marginLeft: "4px",
      marginTop: "8px",
    },
  })
);

export default function SoloChallengePageUserList() {
  const styles = useStyles();
  const { id } = useParams<URLParams>();
  const decodedId = base64.decode(id);

  const history = useHistory();
  const { apigatewayClient: client } = useService();
  const { data: soloChallengeData, error: soloChallengeError } = useSWR(
    (client &&
      soloChallengeDetailsRequest(client as GraphQLClient, decodedId)
        .cacheKey) ||
      null,
    (client &&
      soloChallengeDetailsRequest(client as GraphQLClient, decodedId).fetch) ||
      null
  );
  const {
    data: allUsersFromSoloChallengeData,
    error: allUsersFromSoloChallengeError,
  } = useSWR(
    (client &&
      allUsersFromSoloChallengeRequest(client as GraphQLClient, decodedId)
        .cacheKey) ||
      null,
    (client &&
      allUsersFromSoloChallengeRequest(client as GraphQLClient, decodedId)
        .fetch) ||
      null
  );
  const { data: allLearningProfilesData } = useSWR(
    ...allLearningProfilesRequest(client)
  );
  const { data: curriculaData } = useSWR(...curriculaRequest(client));
  const { getColumnSearchProps } = useTableColumnSearch();
  const getUniqueReviewerSub = _.sortBy(
    _.uniqBy(allUsersFromSoloChallengeData, "reviewerSub"),
    "reviewer.name"
  );

  const dataSource = useMemo<SoloChallengeEvaluation[]>(() => {
    return (
      allUsersFromSoloChallengeData?.map((userFromSoloChallenge) => {
        const learningProfile = allLearningProfilesData?.find(
          (data) => data.sub === userFromSoloChallenge.sub
        );
        const cohortId = learningProfile?.activeCurriculumId as string;
        const curricula = curriculaData?.find(
          (curriculum) => curriculum.id === cohortId
        );

        const curriculumName = curricula?.attributes?.name;
        const cohortValue = [cohortId, curriculumName]
          .filter((val) => !!val)
          .join(", ");

        return {
          ...userFromSoloChallenge,
          cohortId,
          cohortValue,
        };
      }) || []
    );
  }, [allUsersFromSoloChallengeData, allLearningProfilesData, curriculaData]);

  if (soloChallengeError || allUsersFromSoloChallengeError) {
    return <p>Failed to fetch solo challenge user list.</p>;
  }

  function redirectToGrading(scEvaluation: SoloChallengeEvaluation) {
    const encodedUserId = base64.encode(scEvaluation?.sub || "");
    history.push(`/inbox/${id}/${encodedUserId}`);
  }

  const columns: GenericTableColumn<SoloChallengeEvaluation> = [
    {
      title: "Learner",
      dataIndex: "sub",
      key: "sub",
      ...getColumnSearchProps("sub"),
      render(text, record) {
        return (
          <span>
            <Link
              to={APP_ROUTES.USER_DETAIL(record.sub as string)}
              target={"_blank"}
              rel="noreferrer"
            >
              {record.sub}
            </Link>
          </span>
        );
      },
    },
    {
      title: "Cohort, Program",
      dataIndex: "cohortValue",
      key: "cohortValue",
      ...getColumnSearchProps("cohortValue"),
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer.name",
      key: "reviewer.name",
      filters: getUniqueReviewerSub.map((user) => {
        return {
          text: user?.reviewer?.name || "NO_REVIEWER",
          value: user?.reviewer?.name || "-",
        };
      }),

      onFilter(value, record) {
        if (value === "-") {
          return !record.reviewer?.name;
        } else {
          return value === record.reviewer?.name;
        }
      },
      render: (text, record) => {
        if (!record) {
          return <></>;
        } else {
          return record.reviewerSub ? (
            <span>
              <Link
                to={APP_ROUTES.USER_DETAIL(record?.reviewerSub as string)}
                target={"_blank"}
                rel="noreferrer"
              >
                {record.reviewer?.name}
              </Link>
            </span>
          ) : (
            <span>-</span>
          );
        }
      },
    },
    {
      title: "Grading Status",
      dataIndex: "gradingStatus",
      key: "gradingStatus",
      filters: [
        {
          text: SoloChallengeGradingStatusLocal.NO_RECORD,
          value: SoloChallengeGradingStatusLocal.NO_RECORD,
        },
        {
          text: SoloChallengeGradingStatus.UnSubmitted,
          value: SoloChallengeGradingStatus.UnSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.Submitted,
          value: SoloChallengeGradingStatus.Submitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailedReSubmittable,
          value: SoloChallengeGradingStatus.GradeFailedReSubmittable,
        },
        {
          text: SoloChallengeGradingStatus.ReSubmitted,
          value: SoloChallengeGradingStatus.ReSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailed,
          value: SoloChallengeGradingStatus.GradeFailed,
        },
        {
          text: SoloChallengeGradingStatus.GradedPassed,
          value: SoloChallengeGradingStatus.GradedPassed,
        },
        {
          text: SoloChallengeGradingStatus.PeerReviewed,
          value: SoloChallengeGradingStatus.PeerReviewed,
        },
      ],
      onFilter: (value, record) => {
        if (value === SoloChallengeGradingStatusLocal.NO_RECORD) {
          return !record?.gradingStatus;
        } else {
          return record?.gradingStatus === value;
        }
      },
      render: (text, record) =>
        record === null ? (
          <></>
        ) : (
          <ChallengeGradingStatusTag status={record.gradingStatus} />
        ),
    },
    {
      title: "Google Drive",
      dataIndex: "googleDriveId",
      key: "googleDriveId",
      render: (text, record) => {
        const isDriveExist = record.googleDriveId?.match(
          constants.gDrivePrefix
        );

        return record === null ? (
          <></>
        ) : isDriveExist ? (
          <a href={record.googleDriveId ?? ""} target="_blank" rel="noreferrer">
            See Folder
          </a>
        ) : (
          <Tag color="red">Folder Not Found</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => redirectToGrading(record)}>view detail</Button>
      ),
    },
  ];

  return (
    <AppLayout onPressBack={() => history.push(`/solo-challenge`)}>
      <div className={styles.root}>
        <Typography className={styles.formGridTitle}>
          {soloChallengeData
            ? soloChallengeData.attributes?.name +
              " - " +
              (soloChallengeData.attributes?.graded ? "Graded" : "Non-graded")
            : ""}
        </Typography>
      </div>
      <GenericTable
        columns={columns}
        dataSource={dataSource}
        loading={!allUsersFromSoloChallengeData}
        rowKey={(item: SoloChallengeEvaluation) => `${item.sub}`}
      />
    </AppLayout>
  );
}

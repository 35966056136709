import { Grid } from "@material-ui/core";

import AppLayout from "@layouts/AppLayout";

const NotFoundPage = () => {
  return (
    <AppLayout>
      <Grid>
        <p>
          Page not found: go back to <a href="/">/</a>
        </p>
      </Grid>
    </AppLayout>
  );
};

export default NotFoundPage;

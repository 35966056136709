import { GraphQLClient } from "graphql-request";
import React from "react";

import { useService } from "@libs/hooks/useService";
import { GroupChallengeEntity } from "@libs/service/graphql/generated/types.d";
import { fetchGroupChallengeDetail } from "@libs/service/groupChallenge";
import { useSWRHooks } from "@libs/utils/utility";

type Props = {
  groupParentId: string;
  onFetchCompletted: (data: GroupChallengeEntity) => void;
};
export default function GetGroupChallengeParent(props: Props) {
  const { apigatewayClient: client } = useService();

  const [ids, setIds] = React.useState("");

  const { data: groupChallengeParentData, error } = useSWRHooks(client, () =>
    fetchGroupChallengeDetail(client as GraphQLClient, props.groupParentId)
  );

  React.useEffect(() => {
    if (groupChallengeParentData && !error && props.groupParentId !== ids) {
      props.onFetchCompletted(groupChallengeParentData);
      setIds(props.groupParentId);
    }
  }, [groupChallengeParentData, error, props, ids]);
  return null;
}

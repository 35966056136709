import yup from "@/yup-extended";
import { FC } from "react";
import { UseFieldArrayProps, useFieldArray } from "react-hook-form";

import DatePickerController from "@components/hookform/DatePickerController";

export type MilestoneDueDateInput = {
  milestoneId: string;
  dueDate: string;
  isCapstone?: boolean;
};

export type MilestoneDueDatesFieldArrayFormValues = {
  [key: string]: MilestoneDueDateInput[];
};

export const milestoneDueDatesValidation = yup.array().of(
  yup
    .object()
    .shape({
      milestoneId: yup.string().required(),
      dueDate: yup.date().required("Date is required"),
    })
    .milestoneDueDates()
);

type Props = UseFieldArrayProps<MilestoneDueDatesFieldArrayFormValues>;

const MilestoneDueDatesFieldArray: FC<Props> = ({ control, name }) => {
  const { fields } = useFieldArray({
    control,
    name,
  });
  return (
    <>
      {fields.map((field, index) => {
        const label = field.isCapstone ? `Capstone` : `Milestone ${index + 1}`;
        return (
          <DatePickerController
            key={field.id}
            control={control}
            label={`${label} due date`}
            name={`${name}.${index}.dueDate`}
            initialValue={field.dueDate}
            required={false}
          />
        );
      })}
    </>
  );
};

export default MilestoneDueDatesFieldArray;

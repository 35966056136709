import colors from "@css/colors.json";
import { Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import AssignUserGroup from "@components/challenges/AssignUserGroup";
import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import { GroupChallengeGroupEvaluation } from "@libs/service/graphql/generated/types";
import {
  fetchGroupChallengeDetail,
  fetchGroupsOfGroupChallenge,
} from "@libs/service/groupChallenge";
import { URLParams } from "@libs/types/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
    button: {
      width: "320px",
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    newBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "12px",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: 1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    btnAction: {
      marginLeft: "4px",
      marginTop: "8px",
    },
  })
);

export default function GroupListPage() {
  const styles = useStyles();
  const history = useHistory();
  const { id } = useParams<URLParams>();
  const groupChallengeID = base64.decode(id);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [challengeTarget, setChallengeTarget] = useState<
    GroupChallengeGroupEvaluation | undefined
  >(undefined);

  const { apigatewayClient: client } = useService();
  const { data: groupsData, error: groupsError } = useSWR(
    (client &&
      fetchGroupsOfGroupChallenge(client as GraphQLClient, groupChallengeID)
        .cacheKey) ||
      null,
    (client &&
      fetchGroupsOfGroupChallenge(client as GraphQLClient, groupChallengeID)
        .fetch) ||
      null
  );
  const { data: groupChallengeData, error: groupChallengeError } = useSWR(
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .cacheKey) ||
      null,
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .fetch) ||
      null
  );

  function redirectToGroupList(record: GroupChallengeGroupEvaluation) {
    history.push(
      `/group-challenge/${base64.encode(groupChallengeID)}/${base64.encode(
        record.id ?? ""
      )}`
    );
  }

  function redirectToGroupEdit(record: GroupChallengeGroupEvaluation) {
    history.push(
      `/group-challenge-edit/${base64.encode(groupChallengeID)}/${base64.encode(
        record.id ?? ""
      )}`
    );
  }

  function onAssignFinished() {
    setShowModal(false);
    setChallengeTarget(undefined);
  }

  function onAssignError() {
    setShowModal(false);
    setChallengeTarget(undefined);
  }

  function assignStudent(target: GroupChallengeGroupEvaluation) {
    setShowModal(true);
    setChallengeTarget(target);
  }

  const columns: GenericTableColumn<GroupChallengeGroupEvaluation> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <p>
            {groupChallengeData && groupChallengeData.attributes?.name}{" "}
            {record.id}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            className={styles.btnAction}
            onClick={() => redirectToGroupEdit(record)}
          >
            Edit
          </Button>
          <Button
            className={styles.btnAction}
            onClick={() => redirectToGroupList(record)}
          >
            View Detail
          </Button>
          <Button
            className={styles.btnAction}
            onClick={() => assignStudent(record)}
          >
            Assign Students
          </Button>
        </>
      ),
    },
  ];

  if (groupsError || groupChallengeError) {
    return <p>Failed to fetching detail info.</p>;
  }

  return (
    <AppLayout onPressBack={() => history.push(`/group-challenge`)}>
      <div className={styles.root}>
        <div className={styles.newBtn}>
          <Typography className={styles.formGridTitle}>
            {groupChallengeData &&
              "Groups Of " + groupChallengeData.attributes?.name}
          </Typography>
          <Button
            className={styles.button}
            onClick={() =>
              history.push(
                `/new-group-challenge/${base64.encode(groupChallengeID)}`
              )
            }
          >
            + Create Group Challenge Instances
          </Button>
        </div>
        <GenericTable
          columns={columns}
          dataSource={groupsData?.allGroups ?? []}
          loading={!groupsData}
          rowKey={(item: GroupChallengeGroupEvaluation) =>
            `${item.id}-${item.slackChannel}`
          }
        />
      </div>
      {groupChallengeData && (
        <AssignUserGroup
          show={showModal}
          groupName={groupChallengeData.attributes?.name ?? ""}
          target={challengeTarget}
          groupChallengeID={groupChallengeID}
          onError={onAssignError}
          onFinished={onAssignFinished}
        />
      )}
    </AppLayout>
  );
}

import { APP_ROUTES } from "@/routes";
import { Milestone } from "@/types/milestone.d";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Col,
  Collapse,
  Row,
  Skeleton,
  Space,
  Typography,
  notification,
} from "antd";
import { FC } from "react";
import Markdown from "react-markdown";

import useGradePeerGroupAssessmentMilestone from "@hooks/mutation/useGradePeerGroupAssessmentMilestone";
import usePeerGroup from "@hooks/query/usePeerGroup";
import usePeerGroupAssessmentReviews from "@hooks/query/usePeerGroupAssessmentReviews";
import usePeerGroupMilestone from "@hooks/query/usePeerGroupMilestone";

import {
  ComponentMilestonesEvaluationCriteria,
  GoogleDriveIdLinks,
} from "@libs/service/graphql/generated/types";
import { GradeAssessmentCriteriaInput } from "@libs/service/graphql/generated/types";
import { UserProfile } from "@libs/service/user-graphql/generated/types.d";

import AssessmentForm, {
  AssessmentFormValues,
} from "./components/AssessmentForm";
import DeliverablesDropdown from "./components/DeliverablesDropdown";
import SectionContent from "./components/SectionContent";
import TalkDropdown from "./components/TalkDropdown";
import styles from "./grade-assessment.module.scss";
import { LabelProfiles, getLabelProfiles, transformAssessments } from "./utils";

const { Panel } = Collapse;

type Props = {
  peerGroupId: string;
  userId: string;
  challengeId: string;
  milestoneId: string;
  milestoneData: Milestone;
  title: string;
  isViewOnly?: boolean;
};

const GradeGroupAssessment: FC<Props> = ({
  peerGroupId,
  userId,
  challengeId,
  milestoneId,
  milestoneData,
  title,
  isViewOnly,
}) => {
  const { user: currentUser } = useAuth0();
  const gradeGroupAssessment = useGradePeerGroupAssessmentMilestone();
  const { peerGroup, isLoading: isPeerGroupIsLoading } = usePeerGroup(
    challengeId,
    peerGroupId
  );
  const { peerGroupAssessments, isLoading: isPeerGroupAssessmentsLoading } =
    usePeerGroupAssessmentReviews(challengeId, milestoneId, peerGroupId);
  const { peerGroupMilestone, isLoading: isPeerGroupMilestoneLoading } =
    usePeerGroupMilestone(challengeId, milestoneId, peerGroupId);

  const isAssessmentsLoading =
    isPeerGroupAssessmentsLoading || isPeerGroupMilestoneLoading;

  const { currentSubmitted, prevSubmitted } =
    transformAssessments(peerGroupAssessments);

  const handleOnSubmit = async (values: AssessmentFormValues) => {
    try {
      await gradeGroupAssessment.mutate({
        peerGroupId,
        challengeId,
        milestoneId,
        assessorSub: values.assessorSub,
        criteria: values.criteria,
      });
      notification.success({
        message: "Submit grade group assessment success",
      });

      setTimeout(
        () =>
          (window.location.href = APP_ROUTES.CHALLENGE_MILESTONES(
            challengeId,
            peerGroupId,
            userId
          )),
        500
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Failed to submit grade group assessment",
      });
    }
  };

  const selectedSubmitted = isViewOnly ? currentSubmitted : prevSubmitted;

  return (
    <div className={styles.root}>
      <Skeleton loading={isAssessmentsLoading || isPeerGroupIsLoading}>
        <Typography.Title level={4}>{title}</Typography.Title>

        <div className={styles.section}>
          <Row>
            <Col span={12}>
              <Space>
                <Avatar.Group className={styles.avatars} maxCount={4}>
                  {peerGroupMilestone?.userProfiles?.map((user) => (
                    <Avatar key={user?.sub} src={user?.avatar} />
                  ))}
                </Avatar.Group>

                <div>
                  <Typography.Text>{peerGroup?.name}</Typography.Text>
                  <br />
                  <Typography.Text>
                    {getLabelProfiles(
                      peerGroupMilestone?.userProfiles as LabelProfiles
                    )}
                  </Typography.Text>
                </div>
              </Space>
            </Col>
            <Col span={12}>
              <Space className={styles.rightActions} align="end">
                <DeliverablesDropdown
                  deliverables={
                    currentSubmitted?.deliverable
                      ?.deliverables as GoogleDriveIdLinks[]
                  }
                />

                <TalkDropdown
                  users={peerGroupMilestone?.userProfiles as UserProfile[]}
                />
              </Space>
            </Col>
          </Row>
        </div>

        <div className={styles.section}>
          <Collapse
            className={styles.panelRoot}
            defaultActiveKey={["2"]}
            bordered={false}
            expandIconPosition="right"
          >
            <Panel
              header={
                <Typography.Title level={5}>Milestone Details</Typography.Title>
              }
              key="1"
              className={styles.panel}
            >
              <SectionContent title="Task">
                <Markdown>{milestoneData?.instructions as string}</Markdown>
              </SectionContent>
            </Panel>
            <Panel
              header={
                <Typography.Title level={5}>
                  Overall Assessment
                </Typography.Title>
              }
              key="2"
              className={styles.panel}
            >
              <AssessmentForm
                onSubmit={handleOnSubmit}
                assessorSub={
                  isViewOnly
                    ? (selectedSubmitted?.assessorSub as string)
                    : // prepoluted editing mode for assessor sub.
                      selectedSubmitted?.assessorSub || currentUser?.sub
                }
                learners={
                  (peerGroupMilestone?.userProfiles as UserProfile[]) || []
                }
                confirmLoading={gradeGroupAssessment.isLoading}
                criterions={
                  milestoneData?.evaluationCriteria as ComponentMilestonesEvaluationCriteria[]
                }
                initialCriterionValues={
                  selectedSubmitted?.taskAssessment as GradeAssessmentCriteriaInput[]
                }
                isViewOnly={isViewOnly}
              />
            </Panel>
          </Collapse>
        </div>
      </Skeleton>
    </div>
  );
};

export default GradeGroupAssessment;

import SoloChallengeList from "@components/SoloChallengeList";

import AppLayout from "@layouts/AppLayout";

const Inbox = () => {
  return (
    <AppLayout>
      <SoloChallengeList />
    </AppLayout>
  );
};

export default Inbox;

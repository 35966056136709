import fetch from "cross-fetch";
import { GraphQLClient } from "graphql-request";

const intakeClient = new GraphQLClient(
  process.env.REACT_APP_INTAKE_SERVICE_API_URL ?? "",
  {
    fetch,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_INTAKE_SERVICE_API_KEY ?? "",
    },
  }
);

export default intakeClient;

import { APP_ROUTES } from "@/routes";
import { Button, Space } from "antd";
import { FC, memo } from "react";
import { Link } from "react-router-dom";

import GenericTable from "@components/organism/GenericTable";

import useUserChallengesByPeerGroupId from "@hooks/query/useUserChallengesByPeerGroupId";

import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import {
  ChallengeProgress,
  UserMilestone,
} from "@libs/service/graphql/generated/types";
import {
  UserChallengeStatusState,
  UserChallengeWithStatusState,
} from "@libs/types/types";

import UserChallengeStatusColumn from "./components/UserChallengeStatusColumn";
import { populateUserChallengesWithStatusState } from "./helpers/populate-user-challenges-with-status-state";

type Props = {
  challengeId: string;
  id: string;
};
const UserChallengesTable: FC<Props> = ({ challengeId, id }) => {
  const { getColumnSearchProps } = useTableColumnSearch();
  const { userChallenges, isLoading } = useUserChallengesByPeerGroupId(
    challengeId,
    id
  );

  const userChallengesWithStatusState = populateUserChallengesWithStatusState(
    userChallenges as ChallengeProgress[]
  );

  return (
    <GenericTable<UserChallengeWithStatusState>
      columns={[
        {
          title: "Learners",
          dataIndex: "user",
          key: "user",
          ...getColumnSearchProps("user.name", {
            placeholder: "Search learner name",
          }),
        },
        {
          title: "Status",
          dataIndex: "statusState",
          key: "statusState",
          render: (value) => (
            <UserChallengeStatusColumn
              statusState={value}
              isLoading={isLoading}
            />
          ),
          onFilter: (value, record) => {
            return record?.statusState === value;
          },
          filters: [
            {
              text: "NOT STARTED",
              value: UserChallengeStatusState.NOT_STARTED,
            },
            {
              text: "IN PROGRESS",
              value: UserChallengeStatusState.IN_PROGRESS,
            },
            {
              text: "TO BE ASSESSED",
              value: UserChallengeStatusState.TO_BE_ASSESSED,
            },
            {
              text: "NOT ACHIEVED",
              value: UserChallengeStatusState.NOT_ACHIEVED,
            },
            {
              text: "COMPLETED",
              value: UserChallengeStatusState.COMPLETED,
            },
          ],
        },
        {
          title: "Milestones",
          dataIndex: "milestones",
          key: "milestones",
          render: (value: UserMilestone[]) => {
            let completed = 0;
            value.forEach((milestone) =>
              milestone?.isMilestoneComplete ? completed++ : null
            );
            return (
              <span>
                {completed} of {value.length}
              </span>
            );
          },
        },
        {
          title: "Actions",
          render: (value: ChallengeProgress) => {
            return (
              <Space>
                <Link
                  to={APP_ROUTES.CHALLENGE_MILESTONES(
                    challengeId,
                    id,
                    value?.user?.id as string
                  )}
                >
                  <Button>View details</Button>
                </Link>

                <Button
                  disabled={!value?.googleDriveId}
                  href={`${value?.googleDriveId}`}
                  target="_blank"
                >
                  Go to folder
                </Button>
                {/* TODO: Show when backend support edit due dates. */}
                {/* <Button>Edit due dates</Button> */}
              </Space>
            );
          },
        },
      ]}
      dataSource={userChallengesWithStatusState}
      loading={isLoading}
      rowKey={(item) => `${item.id}-${item.user?.id}`}
      pagination={false}
    />
  );
};

export default memo(UserChallengesTable);

import { Tag } from "antd";

import {
  UserMilestoneStatusState,
  UserMilestoneWithStateState,
} from "@libs/types/types";

const UserMilestoneStatusColumn = (props: UserMilestoneWithStateState) => {
  let tagAttrs = {};
  switch (props?.statusState) {
    case UserMilestoneStatusState.IN_PROGRESS:
      tagAttrs = { ...tagAttrs, color: "blue", children: "IN PROGRESS" };
      break;
    case UserMilestoneStatusState.COMPLETED:
      tagAttrs = { ...tagAttrs, color: "green", children: "COMPLETED" };
      break;
    case UserMilestoneStatusState.NOT_ACHIEVED:
      tagAttrs = { ...tagAttrs, color: "red", children: "NOT ACHIEVED" };
      break;
    case UserMilestoneStatusState.TO_BE_ASSESSED:
      tagAttrs = { ...tagAttrs, color: "orange", children: "TO BE ASSESSED" };
      break;
    default:
      tagAttrs = { color: "default", children: "TODO" };
      break;
  }

  return <Tag {...tagAttrs} />;
};

export default UserMilestoneStatusColumn;

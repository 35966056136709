import { APP_ROUTES } from "@/routes";
import { Milestone } from "@/types/milestone";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Col,
  Collapse,
  Row,
  Skeleton,
  Space,
  Typography,
  notification,
} from "antd";
import { FC } from "react";
import { RiSlackFill, RiUserLine } from "react-icons/ri";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

import useGradeAssessment from "@hooks/mutation/useGradeAssessment";
import useUser from "@hooks/query/useUser";
import useUserAssessmentReviews from "@hooks/query/useUserAssessmentReviews";

import {
  ComponentMilestonesEvaluationCriteria,
  GoogleDriveIdLinks,
  GradeAssessmentCriteriaInput,
} from "@libs/service/graphql/generated/types";
import { createSlackMagicLink } from "@libs/utils/external-party";

import AssessmentForm, {
  AssessmentFormValues,
} from "./components/AssessmentForm";
import DeliverablesDropdown from "./components/DeliverablesDropdown";
import SectionContent from "./components/SectionContent";
import styles from "./grade-assessment.module.scss";
import { transformAssessments } from "./utils";

const { Panel } = Collapse;

type Props = {
  peerGroupId: string;
  userId: string;
  challengeId: string;
  milestoneId: string;
  milestoneData: Milestone;
  title: string;
  isViewOnly?: boolean;
};

const GradeAssessment: FC<Props> = ({
  peerGroupId,
  userId,
  challengeId,
  milestoneId,
  milestoneData,
  title,
  isViewOnly,
}) => {
  const { user: currentUser } = useAuth0();
  const gradeAssessment = useGradeAssessment();
  const { data: user, isLoading: userIsLoading } = useUser(userId);
  const { userAssessments, isLoading: isAssessmentsLoading } =
    useUserAssessmentReviews(challengeId, milestoneId, userId);

  const { currentSubmitted, prevSubmitted } =
    transformAssessments(userAssessments);

  const handleOnSubmit = async (values: AssessmentFormValues) => {
    try {
      await gradeAssessment.mutate({
        sub: userId,
        challengeId,
        milestoneId,
        assessorSub: values.assessorSub,
        criteria: values.criteria,
      });
      notification.success({
        message: "Submit grade assessment success",
      });

      setTimeout(
        () =>
          (window.location.href = APP_ROUTES.CHALLENGE_MILESTONES(
            challengeId,
            peerGroupId,
            userId
          )),
        500
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Failed to submit grade assessment",
      });
    }
  };

  const selectedSubmitted = isViewOnly ? currentSubmitted : prevSubmitted;

  return (
    <div className={styles.root}>
      <Skeleton loading={isAssessmentsLoading}>
        <Typography.Title level={4}>{title}</Typography.Title>

        <div className={styles.section}>
          <Skeleton loading={userIsLoading}>
            <Row>
              <Col span={12}>
                <Space>
                  <Avatar src={user?.avatar} icon={<RiUserLine />} />
                  <Link to={APP_ROUTES.USER_DETAIL(user?.userId as string)}>
                    <Button type="link" size="large">
                      {user?.name}
                    </Button>
                  </Link>
                </Space>
              </Col>
              <Col span={12}>
                <Space className={styles.rightActions} align="end">
                  <DeliverablesDropdown
                    deliverables={
                      currentSubmitted?.deliverable
                        ?.deliverables as GoogleDriveIdLinks[]
                    }
                  />

                  {user?.slackId && (
                    <Button
                      href={createSlackMagicLink(user?.slackId)}
                      target="_blank"
                      icon={<RiSlackFill />}
                    >
                      Talk
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </Skeleton>
        </div>

        <div className={styles.section}>
          <Collapse
            className={styles.panelRoot}
            defaultActiveKey={["2"]}
            bordered={false}
            expandIconPosition="right"
          >
            <Panel
              header={
                <Typography.Title level={5}>Milestone Details</Typography.Title>
              }
              key="1"
              className={styles.panel}
            >
              <SectionContent title="Task">
                <Markdown>{milestoneData?.instructions as string}</Markdown>
              </SectionContent>
            </Panel>
            <Panel
              header={
                <Typography.Title level={5}>
                  Overall Assessment
                </Typography.Title>
              }
              key="2"
              className={styles.panel}
            >
              <AssessmentForm
                onSubmit={handleOnSubmit}
                assessorSub={
                  isViewOnly
                    ? (selectedSubmitted?.assessorSub as string)
                    : // prepoluted editing mode for assessor sub.
                      selectedSubmitted?.assessorSub || currentUser?.sub
                }
                learners={[user]}
                confirmLoading={gradeAssessment.isLoading}
                criterions={
                  milestoneData?.evaluationCriteria as ComponentMilestonesEvaluationCriteria[]
                }
                initialCriterionValues={
                  selectedSubmitted?.taskAssessment as GradeAssessmentCriteriaInput[]
                }
                isViewOnly={isViewOnly}
              />
            </Panel>
          </Collapse>
        </div>
      </Skeleton>
    </div>
  );
};

export default GradeAssessment;

import colors from "@css/colors.json";
import {
  Button,
  Card,
  CardContent,
  Container,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import {
  addGroupOfGroupChallenge,
  fetchGroupChallengeDetail,
} from "@libs/service/groupChallenge";
import { URLParams } from "@libs/types/types";
import {
  cohortYearId,
  composeCohortID,
  sanitizeString,
} from "@libs/utils/utility";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "68px",
    },
    formControl: {
      minWidth: 300,
      color: "black",
      marginBottom: "16px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    alertMessage: {
      fontSize: "16px",
      fontWeight: 600,
      textAlign: "center",
      color: colors.primaryOrange,
    },
    formContainer: {
      marginTop: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flexStart",
      width: "100%",
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
    },
    divButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingLeft: "20px",
    },
    customLabel: {
      textAlign: "left",
    },
    optionItems: {
      textAlign: "left",
      marginBottom: "12px",
    },
    button: {
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
      width: "220px",
    },
  })
);

type State = {
  alertMessage: string;
  isSubmitting: boolean;
  formData: {
    cohortYear: string;
    groupId: string;
  };
};
function GroupOfChallengeForm() {
  const styles = useStyles();
  const { id } = useParams<URLParams>();
  const groupChallengeID = base64.decode(id);
  const { apigatewayClient: client } = useService();
  const history = useHistory();
  const [state, setState] = React.useState<State>({
    alertMessage: "",
    isSubmitting: false,
    formData: {
      cohortYear: "",
      groupId: "",
    },
  });
  const handleSubmitReview = (cohortId: string, groupId: string) => {
    addGroupOfGroupChallenge(client as GraphQLClient, cohortId, groupId)
      .then(() => {
        history.push(`/group-challenge/${id}`);
      })
      .catch(() => {
        setState({
          ...state,
          alertMessage: "Failed to save data. Please try again later.",
          isSubmitting: false,
        });
      });
  };
  const { data: groupChallengeData, error: groupChallengeError } = useSWR(
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .cacheKey) ||
      null,
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupChallengeID)
        .fetch) ||
      null
  );

  const cohortID = composeCohortID(
    state.formData.cohortYear,
    state.formData.groupId
  );

  if (groupChallengeError) {
    return <p>Failed to fetching detail info.</p>;
  }

  return (
    <AppLayout
      onPressBack={() =>
        history.push(`/group-challenge/${base64.encode(groupChallengeID)}`)
      }
    >
      <Container className={styles.root}>
        <Card>
          <CardContent className={styles.formContainer}>
            <Typography className={styles.formGridTitle}>
              Group Of Groups Challenge
            </Typography>
            <InputLabel
              className={styles.customLabel}
              htmlFor="age-native-simple"
            >
              Name
            </InputLabel>
            <FormControl className={styles.formControl}>
              <Input
                placeholder="name"
                value={groupChallengeData?.attributes?.name ?? ""}
              />
            </FormControl>
            <InputLabel
              className={styles.customLabel}
              htmlFor="age-native-simple"
            >
              Description
            </InputLabel>
            <FormControl className={styles.formControl}>
              <Input
                rows={5}
                placeholder="Description"
                value={groupChallengeData?.attributes?.description ?? ""}
                multiline={true}
              />
            </FormControl>
            <InputLabel
              className={styles.customLabel}
              htmlFor="age-native-simple"
            >
              Select Cohort
            </InputLabel>
            <FormControl className={styles.formControl}>
              <Select
                className={styles.optionItems}
                value={state.formData.cohortYear}
                onChange={(e) =>
                  setState({
                    ...state,
                    formData: {
                      ...state.formData,
                      cohortYear: e.target.value as string,
                    },
                  })
                }
              >
                <MenuItem aria-label="None" value="">
                  --Select Cohort--
                </MenuItem>
                {cohortYearId.map((val, key) => {
                  return (
                    <MenuItem key={key + val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <InputLabel
              className={styles.customLabel}
              htmlFor="age-native-simple"
            >
              Group Name * space/special characters are not allowed
            </InputLabel>
            <FormControl className={styles.formControl}>
              <Input
                placeholder="Group Name"
                onChange={(e) =>
                  setState({
                    ...state,
                    formData: {
                      ...state.formData,
                      groupId: sanitizeString(e.target.value),
                    },
                  })
                }
                value={state.formData.groupId}
              />
            </FormControl>

            <Typography className={styles.alertMessage}>
              {state.alertMessage}
            </Typography>
            <div className={styles.divButton}>
              <Button
                variant="contained"
                onClick={() => {
                  if (client) {
                    handleSubmitReview(cohortID, groupChallengeID);
                    setState({
                      ...state,
                      isSubmitting: true,
                      alertMessage: "",
                    });
                  }
                }}
                color="default"
                disabled={state.isSubmitting || cohortID === ""}
                type="button"
                className={styles.button}
              >
                {state.isSubmitting ? "Processing..." : "Create Instance"}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    </AppLayout>
  );
}

export default GroupOfChallengeForm;

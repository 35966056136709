import { SoloChallengeEvaluation } from "@libs/service/graphql/generated/types";

export type NamedSoloChallengeEvaluation = SoloChallengeEvaluation & {
  id: string;
  name: string;
  graded: boolean;
};

export enum SoloChallengeGradingStatusLocal {
  NO_RECORD = "UN_PAIRED", //NO_RECORD
}

import useAuthDomain, { AuthDomainName } from "./useAuthDomain";

type AuthRole = { id: string; name: string; description: string };

export enum AuthRoleName {
  Staff = "Staff",
  Student = "Student",
}

const useAuthRoles = () => {
  const data = useAuthDomain(AuthDomainName.Roles);
  if (!data) return [];
  return data?.roles as AuthRole[];
};

export default useAuthRoles;

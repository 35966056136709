import { loader } from "graphql.macro";
import useSWR from "swr";

import {
  ListProgramsQuery,
  ListProgramsQueryVariables,
} from "@libs/service/intake-graphql/generated/operations";
import { Program } from "@libs/service/intake-graphql/generated/types";
import client from "@libs/utils/intake-client";

const listProgramsQuery = loader(
  "../../lib/service/intake-graphql/query/ListPrograms.graphql"
);

const useListPrograms = () => {
  const { data, error } = useSWR(client ? `getListPrograms` : null, () =>
    client?.request<ListProgramsQuery, ListProgramsQueryVariables>(
      listProgramsQuery
    )
  );
  return {
    data: data?.listPrograms?.items as Program[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useListPrograms;

import { Button, Dropdown, DropdownProps, Menu, Space } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FC, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

export type DropdownButtonMenuItems = ItemType[];
export type DropdownButtonProps = Omit<DropdownProps, "overlay"> & {
  label: string;
  menuItems: DropdownButtonMenuItems;
};
const DropdownButton: FC<DropdownButtonProps> = ({
  menuItems = [],
  label,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={setVisible}
      {...props}
      overlay={<Menu items={menuItems} />}
    >
      <Space>
        <Button>
          {label}
          {isVisible ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
        </Button>
      </Space>
    </Dropdown>
  );
};

export default DropdownButton;

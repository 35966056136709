import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR, { useSWRConfig } from "swr";

import { useService } from "@libs/hooks/useService";
import {
  UserChallengesByPeerGroupIdQuery,
  UserChallengesByPeerGroupIdQueryVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/query/userChallengesByPeerGroupId.graphql"
);

const useUserChallengesByPeerGroupId = (
  challengeId: string,
  peerGroupId: string
) => {
  const { apigatewayClient: client } = useService();
  const { mutate } = useSWRConfig();
  const reqBody: UserChallengesByPeerGroupIdQueryVariables = {
    challengeId,
    id: peerGroupId,
  };
  const isEnabled = client && challengeId && peerGroupId;
  const key = `getUserChallengesByPeerGroupId?${qs.stringify(reqBody)}`;
  const { data, error } = useSWR(isEnabled ? key : null, () =>
    client
      ?.request<
        UserChallengesByPeerGroupIdQuery,
        UserChallengesByPeerGroupIdQueryVariables
      >(query, reqBody)
      .then((result) => {
        return result.userChallengesByPeerGroupId;
      })
  );

  const revalidate = () => mutate(key);

  return {
    userChallenges: data,
    revalidate,
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default useUserChallengesByPeerGroupId;

import { Modal, ModalProps, Typography } from "antd";
import { FC, memo } from "react";

import { UseDisclosureReturn } from "@hooks/useDisclosure";

import { UserProfile } from "@libs/service/user-graphql/generated/types";

import { LabelProfiles, getLabelProfiles } from "../utils";

type Props = UseDisclosureReturn &
  ModalProps & {
    learners: UserProfile[];
    onConfirm: () => void;
  };

const ConfirmSubmitAssessmentModal: FC<Props> = ({
  onConfirm,
  isOpen,
  onClose,
  learners,
  ...props
}) => {
  return (
    <Modal
      title="Submit Assessment"
      okText="Submit"
      onOk={onConfirm}
      onCancel={onClose}
      visible={isOpen}
      width={370}
      {...props}
    >
      <Typography.Paragraph>
        Are you sure you want to submit this assessment to{" "}
        {getLabelProfiles(learners as LabelProfiles)} now?
      </Typography.Paragraph>
    </Modal>
  );
};

export default memo(ConfirmSubmitAssessmentModal);

import {
  ChallengeProgress,
  Enum_V2Milestone_Type,
  UserMilestone,
} from "@libs/service/graphql/generated/types.d";

export type URLParams = {
  id: string;
  email: string;
  revieweeID: string;
  groupID: string;
  groupInstanceId: string;
};

export type Order = "asc" | "desc";

export type User = {
  userId: string;
  name: string;
};

export interface SoloChallenge {
  id: string;
  name: string;
  status: string;
}

export interface GroupChallengeProperties {
  id: string;
  name: string;
  description: string;
}

export enum UserChallengeStatusState {
  NO_PEER_GROUP = "NO_PEER_GROUP",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  TO_BE_ASSESSED = "TO_BE_ASSESSED",
  NOT_ACHIEVED = "NOT_ACHIEVED",
}

export type UserChallengeWithStatusState = ChallengeProgress & {
  statusState: UserChallengeStatusState;
};

export enum UserMilestoneStatusState {
  TODO = "TODO",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_ACHIEVED = "NOT_ACHIEVED",
  TO_BE_ASSESSED = "TO_BE_ASSESSED",
}

export type UserMilestoneWithStateState = UserMilestone & {
  statusState: UserMilestoneStatusState;
};

export type EnumUserMilestone = Enum_V2Milestone_Type;
export const UserMilestoneType = Enum_V2Milestone_Type;

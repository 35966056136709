import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  PeerGroupMilestoneQuery,
  PeerGroupMilestoneQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { PeerGroupMilestone } from "@libs/service/graphql/generated/types";

const query = loader(
  "../../lib/service/graphql/query/peerGroupMilestone.graphql"
);

export const createGetPeerGroupMilestoneCacheKey = (
  body: PeerGroupMilestoneQueryVariables
) => `getPeerGroupMilestone?${qs.stringify(body)}`;

const usePeerGroupMilestone = (
  challengeId: string,
  milestoneId: string,
  peerGroupId: string
) => {
  const { apigatewayClient: client } = useService();
  const reqBody: PeerGroupMilestoneQueryVariables = {
    challengeId,
    milestoneId,
    peerGroupId,
  };
  const isEnabled = client && challengeId && milestoneId && peerGroupId;
  const { data, error } = useSWR(
    isEnabled ? createGetPeerGroupMilestoneCacheKey(reqBody) : null,
    () =>
      client
        ?.request<PeerGroupMilestoneQuery, PeerGroupMilestoneQueryVariables>(
          query,
          reqBody
        )
        .then((result) => {
          return result.peerGroupMilestone;
        })
  );

  return {
    peerGroupMilestone: data as PeerGroupMilestone,
    isLoading: Boolean(isEnabled && !error && !data),
    isError: error,
  };
};

export default usePeerGroupMilestone;

import colors from "@css/colors.json";
import {
  Button,
  Card,
  CardContent,
  Container,
  Input,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { DatePicker, notification } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import moment from "moment";
import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import AppLayout from "@layouts/AppLayout";

import { useService } from "@libs/hooks/useService";
import { GroupChallengeGroupInput } from "@libs/service/graphql/generated/types";
import {
  editGroupOfGroupChallenge,
  fetchGroupChallengeDetail,
  fetchGroupOfGroupChallengeDetail,
} from "@libs/service/groupChallenge";
import { URLParams } from "@libs/types/types";
import { getDateByStringJSON } from "@libs/utils/utility";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "68px",
    },
    formControl: {
      minWidth: 300,
      color: "black",
      marginBottom: "16px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formContainer: {
      marginTop: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flexStart",
      width: "100%",
    },
    formGridTitle: {
      textAlign: "left",
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "32px",
    },
    divButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingLeft: "20px",
    },
    customLabel: {
      textAlign: "left",
    },
    customLabelDate: {
      textAlign: "left",
      marginBottom: "12px",
    },
    button: {
      marginTop: theme.spacing(3),
      backgroundColor: colors.yellow100,
      width: "120px",
    },
    buttonDate: {
      backgroundColor: colors.yellow100,
      width: "120px",
    },
  })
);
type FormData = {
  slackChannel: string;
  meetingLink: string;
  googleDriveId: string;
  demoOn: string;
};
type State = {
  isSubmit: boolean;
  isValid: boolean;
  formData: FormData;
};
const validateForm = (data: FormData) => {
  return data.googleDriveId !== "";
};
function GroupChallengeForm() {
  const styles = useStyles();
  const history = useHistory();
  const handleSubmit = (
    groupChallengeID: string,
    groupInstanceId: string,
    formData: GroupChallengeGroupInput
  ) => {
    setState({ ...state, isSubmit: true });
    editGroupOfGroupChallenge(
      client as GraphQLClient,
      groupChallengeID,
      groupInstanceId,
      {
        ...formData,
        demoOn: new Date(formData.demoOn ?? "2021-01-01").toJSON(),
      }
    )
      .then(() => {
        notification.success({
          message: "Success",
          description: "Item has been successfully updated",
        });
      })
      .then(() => {
        fetchGroupOfGroupChallengeDetail(
          client as GraphQLClient,
          groupID,
          groupChallengeID
        ).fetch;
      })
      .catch((err) => {
        console.error(err);
        notification.error({
          message: "Oops!",
          description: "Unable to update item, please try again later",
        });
      })
      .finally(() => {
        setState({ ...state, isSubmit: false });
      });
  };
  const { groupInstanceId, groupID: selectedGroup } = useParams<URLParams>();
  const groupChallengeID = base64.decode(groupInstanceId);
  const groupID = base64.decode(selectedGroup);

  const { apigatewayClient: client } = useService();
  const { data: groupChallengeData, error: groupChallengeError } = useSWR(
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupID).cacheKey) ||
      null,
    (client &&
      fetchGroupChallengeDetail(client as GraphQLClient, groupID).fetch) ||
      null
  );
  // TODO: refactor to use custom hook.
  const { data: groupOfGroupChallengeData, error: groupOfGroupChallengeError } =
    useSWR(
      (client &&
        fetchGroupOfGroupChallengeDetail(
          client as GraphQLClient,
          groupID,
          groupChallengeID
        ).cacheKey) ||
        null,
      (client &&
        fetchGroupOfGroupChallengeDetail(
          client as GraphQLClient,
          groupID,
          groupChallengeID
        ).fetch) ||
        null
    );
  const [state, setState] = React.useState<State>({
    isSubmit: false,
    isValid: true,
    formData: {
      slackChannel: "",
      meetingLink: "",
      googleDriveId: "",
      demoOn: "",
    },
  });

  React.useEffect(() => {
    if (
      groupOfGroupChallengeData &&
      groupOfGroupChallengeData.group &&
      groupOfGroupChallengeData.group?.googleDriveId &&
      state.formData.googleDriveId === ""
    ) {
      const groupDetail = groupOfGroupChallengeData.group;
      const stringOfDate = groupDetail.demoOn
        ? getDateByStringJSON(groupDetail.demoOn).dateString
        : "";
      setState({
        ...state,
        formData: {
          ...state.formData,
          googleDriveId: groupDetail.googleDriveId ?? "",
          meetingLink: groupDetail.meetingLink ?? "",
          slackChannel: groupDetail.slackChannel ?? "",
          demoOn: stringOfDate,
        },
      });
    }
  }, [groupOfGroupChallengeData, state]);
  const setFormData = (val: FormData) => {
    setState({ ...state, formData: val, isValid: validateForm(val) });
  };
  if (!groupOfGroupChallengeData || !groupChallengeData) {
    return <p>Loading...</p>;
  }
  if (groupOfGroupChallengeError || groupChallengeError) {
    return <p>Error when fetching detail of group challenge</p>;
  }

  const today = getDateByStringJSON(new Date().toISOString()).dateString;

  return (
    <AppLayout
      onPressBack={() =>
        history.push(`/group-challenge/${base64.encode(groupID)}`)
      }
    >
      <Container className={styles.root}>
        <Card>
          <CardContent className={styles.formContainer}>
            <Typography className={styles.formGridTitle}>
              {groupChallengeData && groupChallengeData.attributes?.name}-
              {groupChallengeID}
            </Typography>
            <FormControl className={styles.formControl}>
              <InputLabel
                className={styles.customLabel}
                htmlFor="age-native-simple"
              >
                Slack Channel Link
              </InputLabel>
              <Input
                value={state.formData.slackChannel}
                onChange={(e) =>
                  setFormData({
                    ...state.formData,
                    slackChannel: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl className={styles.formControl}>
              <InputLabel
                className={styles.customLabel}
                htmlFor="age-native-simple"
              >
                Meeting Link
              </InputLabel>
              <Input
                value={state.formData.meetingLink}
                onChange={(e) =>
                  setFormData({
                    ...state.formData,
                    meetingLink: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl className={styles.formControl}>
              <InputLabel
                className={styles.customLabel}
                htmlFor="age-native-simple"
              >
                Google Drive Link
              </InputLabel>
              <Input
                value={state.formData.googleDriveId}
                onChange={(e) =>
                  setFormData({
                    ...state.formData,
                    googleDriveId: e.target.value,
                  })
                }
              />
            </FormControl>
            <InputLabel
              className={styles.customLabelDate}
              htmlFor="age-native-simple"
            >
              Final Pitching Date
            </InputLabel>
            <FormControl className={styles.formControl}>
              {state.formData.demoOn !== "" && (
                <DatePicker
                  defaultValue={moment(state.formData.demoOn, "YYYY-MM-DD")}
                  onChange={(date, dateString) =>
                    setFormData({ ...state.formData, demoOn: dateString })
                  }
                />
              )}
              {state.formData.demoOn === "" && (
                <Button
                  variant="contained"
                  onClick={() =>
                    setState({
                      ...state,
                      formData: { ...state.formData, demoOn: today },
                    })
                  }
                  color="primary"
                  type="button"
                  className={styles.buttonDate}
                >
                  Pick a date
                </Button>
              )}
            </FormControl>

            <div className={styles.divButton}>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit(groupID, groupChallengeID, state.formData);
                }}
                color="default"
                disabled={!state.isValid || state.isSubmit}
                type="button"
                className={styles.button}
              >
                {state.isSubmit ? "Processing..." : "Update"}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Container>
    </AppLayout>
  );
}

export default GroupChallengeForm;

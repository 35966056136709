import { loader } from "graphql.macro";
import { useState } from "react";

import { useService } from "@libs/hooks/useService";
import {
  UpdatePeerGroupDueDatesMutation,
  UpdatePeerGroupDueDatesMutationVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/mutation/updatePeerGroupDueDates.graphql"
);
const useUpdatePeerGroupDueDates = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleMutate = async (
    variables: UpdatePeerGroupDueDatesMutationVariables
  ) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        UpdatePeerGroupDueDatesMutation,
        UpdatePeerGroupDueDatesMutationVariables
      >(query, variables);
      return result?.updatePeerGroupDueDates;
    } catch (err) {
      console.error("[useUpdatePeerGroupDueDates]: mutation failed. ", err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    mutate: handleMutate,
  };
};

export default useUpdatePeerGroupDueDates;

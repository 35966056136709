import { ROUTE_QUERY_KEYS } from "@/routes";
import { Select, Typography } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import find from "lodash/find";
import qs from "query-string";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useListPrograms from "@hooks/query/useListPrograms";

import CurriculumCohortDashboard from "./CurriculumCohortDashboard";
import styles from "./cohort-dashboard.module.scss";

const { Option } = Select;

type IntakeOptionType = DefaultOptionType & {
  curriculumId: string;
};

const CohortDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search) as { intake_id?: string };
  const listPrograms = useListPrograms();

  const options = useMemo<IntakeOptionType[]>(() => {
    const result: IntakeOptionType[] = [];
    if (listPrograms.isLoading) return result;

    listPrograms.data?.forEach((program) => {
      program.intakes?.items.forEach((intake) => {
        result.push({
          value: intake?.id,
          label: `${program.alias}, ${program.title}, ${intake?.name}`,
          curriculumId: intake?.curriculumId as string,
        });
      });
    });

    return result;
  }, [listPrograms]);

  const selectedIntake = useMemo(
    () => find(options, { value: query.intake_id }),
    [query, options]
  );

  const changeIntake = (value: DefaultOptionType) => {
    history.push({
      search: `?${qs.stringify({ [ROUTE_QUERY_KEYS.INTAKE_ID]: value })}`,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.pageTitle}>
        <Typography.Title level={4}>Cohort Dashboard</Typography.Title>
      </div>

      <div>
        <Select
          loading={listPrograms.isLoading}
          disabled={listPrograms.isLoading}
          size="large"
          placeholder="Select cohort"
          optionFilterProp="children"
          onChange={changeIntake}
          value={selectedIntake}
          style={{ minWidth: "200px" }}
        >
          {options?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>

      <br />

      <div>
        {selectedIntake?.curriculumId && query.intake_id && (
          <CurriculumCohortDashboard
            curriculumId={selectedIntake?.curriculumId}
            intakeId={query.intake_id}
          />
        )}
      </div>
    </div>
  );
};

export default CohortDashboard;

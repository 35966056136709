import { loader } from "graphql.macro";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  GetUserProfilesByUserIdsQuery,
  GetUserProfilesByUserIdsQueryVariables,
} from "@libs/service/user-graphql/generated/operations";
import { UserProfile } from "@libs/service/user-graphql/generated/types";

const query = loader(
  "../../lib/service/user-graphql/query/GetUserProfilesByUserIds.graphql"
);

const useUserProfilesByUserIds = (userIds: string[]) => {
  const { userClient: client } = useService();

  const isEnabled = client && userIds?.length > 0;

  const { data, error } = useSWR(
    isEnabled ? `getUserProfilesByUserIds${JSON.stringify(userIds)}` : null,
    () =>
      client
        ?.request<
          GetUserProfilesByUserIdsQuery,
          GetUserProfilesByUserIdsQueryVariables
        >(query, { userIds })
        .then((result) => {
          return result?.getUserProfilesByUserIds;
        })
  );

  return {
    data: data as UserProfile[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUserProfilesByUserIds;

export const ROUTE_QUERY_KEYS = {
  USER_ID: "user_id",
  TAB: "tab",
  FILTER: "filter",
  FILTER_ID: function () {
    return `${this.FILTER}[id]`;
  },
  CURRICULUM_ID: "curriculum_id",
  INTAKE_ID: "intake_id",
};

export enum UserDetailsTab {
  SOLO_CHALLENGES = "solo-challenges",
  GROUP_CHALLENGES = "group-challenges",
}

export const APP_ROUTES = {
  HOME: "/",
  USER: "/user",
  USERS: "/users",
  USER_DETAIL: function (userId: string) {
    return `${this.USER}?${ROUTE_QUERY_KEYS.USER_ID}=${userId}`;
  },
  USER_DETAIL_TAB: function (
    userId: string,
    tab: UserDetailsTab,
    filterId?: string
  ) {
    let url = `${this.USER_DETAIL(userId)}&${ROUTE_QUERY_KEYS.TAB}=${tab}`;
    if (filterId) {
      url += `&${ROUTE_QUERY_KEYS.FILTER_ID()}=${filterId}`;
    }
    return url;
  },
  COHORT_DASHBOARD: "/cohort-dashboard",
  CHALLENGES: "/challenges",
  CHALLENGE_DETAIL: function (challengeId: string) {
    return `${this.CHALLENGES}/${challengeId}`;
  },
  CHALLENGE_PEER_GROUP: function (challengeId: string, peerGroupId: string) {
    return `${this.CHALLENGE_DETAIL(challengeId)}/peer-group/${peerGroupId}`;
  },
  CHALLENGE_MILESTONES: function (
    challengeId: string,
    peerGroupId: string,
    userId: string
  ) {
    return `${this.CHALLENGE_PEER_GROUP(challengeId, peerGroupId)}?${
      ROUTE_QUERY_KEYS.USER_ID
    }=${userId}`;
  },
  CHALLENGE_MILESTONE_GRADE: function (
    challengeId: string,
    peerGroupId: string,
    milestoneId: string,
    userId?: string
  ) {
    let path = `${this.CHALLENGE_PEER_GROUP(
      challengeId,
      peerGroupId
    )}/milestone/${milestoneId}/grade`;

    if (userId) {
      path += `?${ROUTE_QUERY_KEYS.USER_ID}=${userId}`;
    }

    return path;
  },
};

import { useAuth0 } from "@auth0/auth0-react";

export enum AuthDomainName {
  Roles = "roles",
  FeatureFlags = "featureFlags",
  Identities = "identities",
}
const useAuthDomain = (name: AuthDomainName) => {
  const { user, isAuthenticated } = useAuth0();
  if (!isAuthenticated || !user) return;
  return user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}${name}`];
};

export default useAuthDomain;

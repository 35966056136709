import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  PeerGroupsQuery,
  PeerGroupsQueryVariables,
} from "@libs/service/graphql/generated/operations";

const peerGroupsQuery = loader(
  "../../lib/service/graphql/query/peerGroups.graphql"
);

const usePeerGroups = (challengeId: string) => {
  if (!challengeId) {
    throw new Error("Challenge Id is required");
  }

  const { apigatewayClient: client } = useService();

  const reqBody = { challengeId };
  const { data, error } = useSWR(
    client ? `getPeerGroups?${qs.stringify(reqBody)}` : null,
    () =>
      client
        ?.request<PeerGroupsQuery, PeerGroupsQueryVariables>(
          peerGroupsQuery,
          reqBody
        )
        .then((result) => {
          return result.peerGroups;
        })
  );

  return {
    peerGroups: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default usePeerGroups;

import first from "lodash/first";

import { UserAssessmentReview } from "@libs/service/graphql/generated/types";
import { sortCreatedAtFromNewest } from "@libs/utils/sorter";

export type LabelProfiles = { name: string }[];
export function getLabelProfiles(userProfiles: LabelProfiles) {
  let label = `${first(userProfiles)?.name}`;
  if (userProfiles?.length > 1) {
    label += ` and ${userProfiles.length - 1} more`;
  }
  return label;
}

/**
 * Transform and get user assessment reviews
 *
 * @example
 * Given user A submitted
 * [Assessment 1, Assessment 2, Assessment 3]
 * it will be sorted to be
 * [Assessment 3, Assessment 2, Assessment 1]
 * The "Assessment 3" is the "current/active" assessment
 * The "Assessment 2" is the "previous" assessment
 * @param assessments
 * @returns
 */
export function transformAssessments(assessments: UserAssessmentReview[]) {
  // Transform from [Assessment 1, Assessment 2, Assessment 3]
  // to [Assessment 3, Assessment 2, Assessment 1]
  // based on the `createdAt` value.
  const sorted = sortCreatedAtFromNewest(assessments);

  // it was used to pre-populate the form to reuse the previous submitted values.
  const getPrevSubmitted = (assessments: UserAssessmentReview[]) => {
    if (!assessments) return;
    // Why it's index 1 instead of index 0?
    // Beacuse the first item (index 0) on the assessment list doesn't have the last submitted values.
    if (assessments?.length > 1) {
      return assessments?.[1];
    }
  };

  // The current (last submitted) assessment data.
  const getCurrentSubmitted = (assessments: UserAssessmentReview[]) =>
    first(assessments);

  return {
    sorted: sorted,
    prevSubmitted: getPrevSubmitted(sorted),
    currentSubmitted: getCurrentSubmitted(sorted),
  };
}

import { Button, Spin, Tag } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import React from "react";
import { useHistory } from "react-router-dom";

import ChallengeGradingStatusTag from "@components/challenges/ChallengeGradingStatusTag";
import { NamedGroupChallengeEvaluation } from "@components/challenges/UserGroupChallenges/types";
import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import { useService } from "@libs/hooks/useService";
import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import {
  GroupChallengeEntity,
  GroupChallengeEvaluation,
  SoloChallengeGradingStatus,
} from "@libs/service/graphql/generated/types.d";
import { fetchGroupChallengeForUser } from "@libs/service/groupChallenge";
import { constants } from "@libs/utils/constants";
import { useSWRHooks } from "@libs/utils/utility";

import GetGroupChallengeParent from "./GetGroupChallengeParent";

interface UserSoloChallengesProps {
  userId: string;
  filter?: { id?: string };
  onFilterChange?: (filterId: string, value: unknown) => void;
}
type State = {
  parentList: Array<GroupChallengeEntity>;
  parentIds: Array<GroupChallengeEvaluation>;
  userId: string;
  dataArranged: Array<NamedGroupChallengeEvaluation>;
};

const initialState = {
  parentList: [],
  parentIds: [],
  userId: "",
  dataArranged: [],
};

const UserGroupChallenges: React.FC<UserSoloChallengesProps> = (props) => {
  const { getColumnSearchProps } = useTableColumnSearch();

  const columns: GenericTableColumn<NamedGroupChallengeEvaluation> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      defaultFilteredValue: props.filter?.id
        ? [props.filter?.id as string]
        : [],
      ...getColumnSearchProps("id", {
        initialValue: props?.filter?.id,
        onReset: () => props?.onFilterChange?.("id", undefined),
      }),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record: NamedGroupChallengeEvaluation) => {
        return <p>{record.name ?? record.id}</p>;
      },
    },
    {
      title: "Milestone Done",
      dataIndex: "progress",
      key: "progress",
      render: (text, record: NamedGroupChallengeEvaluation) => {
        return <>{record.progress ? <p>{record.progress}</p> : <Spin />}</>;
      },
    },
    {
      title: "Grading Status",
      dataIndex: "gradingStatus",
      key: "gradingStatus",
      filters: [
        {
          text: SoloChallengeGradingStatus.UnSubmitted,
          value: SoloChallengeGradingStatus.UnSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.Submitted,
          value: SoloChallengeGradingStatus.Submitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailedReSubmittable,
          value: SoloChallengeGradingStatus.GradeFailedReSubmittable,
        },
        {
          text: SoloChallengeGradingStatus.ReSubmitted,
          value: SoloChallengeGradingStatus.ReSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailed,
          value: SoloChallengeGradingStatus.GradeFailed,
        },
        {
          text: SoloChallengeGradingStatus.GradedPassed,
          value: SoloChallengeGradingStatus.GradedPassed,
        },
        {
          text: SoloChallengeGradingStatus.PeerReviewed,
          value: SoloChallengeGradingStatus.PeerReviewed,
        },
      ],
      onFilter: (value, record) => {
        return record?.gradingStatus === value;
      },
      render: (text, record: NamedGroupChallengeEvaluation) =>
        record === null ? (
          <></>
        ) : (
          <ChallengeGradingStatusTag status={record.gradingStatus} />
        ),
    },
    {
      title: "Google Drive",
      dataIndex: "googleDriveId",
      key: "googleDriveId",
      render: (text, record: NamedGroupChallengeEvaluation) => {
        const isDriveExist = record.googleDriveId?.match(
          constants.gDrivePrefix
        );
        if (record && isDriveExist) {
          return (
            <a
              href={record.googleDriveId ?? ""}
              target="_blank"
              rel="noreferrer"
            >
              See Folder
            </a>
          );
        } else if (record && !isDriveExist) {
          return <Tag color="red">Folder Not Found</Tag>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => redirectToGrading(record)}>view detail</Button>
      ),
    },
  ];
  const [state, setState] = React.useState<State>({ ...initialState });
  const history = useHistory();

  const { apigatewayClient: client } = useService();

  const { data: groupChallengeData, error } = useSWRHooks(client, () =>
    fetchGroupChallengeForUser(client as GraphQLClient, state.userId)
  );

  function redirectToGrading(record: GroupChallengeEvaluation) {
    const arrGroupId = record.id?.split(":") ?? [];
    const groupId = base64.encode(arrGroupId[1] ?? "");
    const encodedRecordId = base64.encode(record.id ?? "");
    history.push(`/group-challenge/${groupId}/${encodedRecordId}`);
  }

  const setGroupParent = (item: GroupChallengeEntity) => {
    const currentList = state.parentList;
    const isExist = currentList.find((val) => val.id === item.id);
    !isExist && currentList.push(item);
    setState({ ...state, parentList: currentList });
  };

  const dataSource = groupChallengeData && !error ? groupChallengeData : [];

  React.useEffect(() => {
    if (
      groupChallengeData &&
      groupChallengeData.length > 0 &&
      state.parentIds.length === 0
    ) {
      const listIds: Array<GroupChallengeEvaluation> = [];
      groupChallengeData.forEach((item: GroupChallengeEvaluation) => {
        const id = Number(item?.id?.split(":")[1]);
        if (!isNaN(id)) {
          listIds.push(item);
        }
      });
      if (listIds.length > 0) {
        setState({ ...state, parentIds: listIds });
      }
    }
  }, [groupChallengeData, state]);

  React.useEffect(() => {
    if (props.userId !== state.userId) {
      setState({ ...state, userId: props.userId, parentList: [] });
    }
  }, [props, state]);

  React.useEffect(() => {
    if (
      groupChallengeData &&
      state.parentIds.length === state.parentList.length
    ) {
      const dataArranged: Array<NamedGroupChallengeEvaluation> = [];
      state.parentList.forEach((parent) => {
        const parentMilstone = parent.attributes?.milestones ?? [];
        const groupChild: GroupChallengeEvaluation = groupChallengeData.find(
          (child: GroupChallengeEvaluation) => {
            const id = child?.id?.split(":")[1];
            return parent.id === id;
          }
        );
        const childMilestone = groupChild?.milestones ?? [];
        const complettedList: Array<string> = [];
        parentMilstone.forEach((mils) => {
          const complettedItem = childMilestone.find(
            (childMils) => childMils?.id === mils?.id
          );
          complettedItem && complettedList.push(complettedItem?.id ?? "");
        });
        if (groupChild) {
          dataArranged.push({
            ...groupChild,
            name: `${parent.attributes?.name} (${groupChild.id})`,
            progress: `${complettedList.length} of ${parentMilstone.length}`,
          });
        }
      });

      if (state.dataArranged.length === 0 && dataArranged.length > 0) {
        setState({ ...state, dataArranged: dataArranged });
      }
    }
  }, [groupChallengeData, state]);

  return (
    <>
      {!error &&
        dataSource.map((item: GroupChallengeEvaluation, key: number) => {
          const id = item?.id?.split(":")[1];
          return (
            id && (
              <GetGroupChallengeParent
                key={key}
                groupParentId={id}
                onFetchCompletted={setGroupParent}
              />
            )
          );
        })}
      <GenericTable<NamedGroupChallengeEvaluation>
        columns={columns}
        dataSource={
          (state.dataArranged.length === 0
            ? state.parentIds
            : state.dataArranged) as NamedGroupChallengeEvaluation[]
        }
        loading={!groupChallengeData}
        rowKey={(item: GroupChallengeEvaluation) => `${item.id}`}
      />
    </>
  );
};

export default UserGroupChallenges;

import { makeStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  icon: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#494746",
  },
});

export default function IconHome() {
  const classes = useStyles();
  return (
    <Link to="/">
      <div className={classes.icon}>
        <HomeOutlinedIcon />
      </div>
    </Link>
  );
}

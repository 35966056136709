import { FC } from "react";
import { Helmet } from "react-helmet";

import { arrayToPageTitle } from "@libs/utils/common";

const DocumentTitle: FC = ({ children }) => {
  return (
    <Helmet>
      <title>{arrayToPageTitle([children, "Campus"])}</title>
    </Helmet>
  );
};

export default DocumentTitle;

import { Form, FormItemProps, Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { PropsWithChildren } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { controllerToFormItemProps } from "@libs/utils/hookform-antd";

const { TextArea } = Input;

type TextareaControllerProps<FormValues> = FormItemProps &
  TextAreaProps &
  Omit<ControllerProps<FormValues>, "render">;

function TextareaController<FormValues extends Record<string, unknown>>({
  control,
  name,
  placeholder,
  autoSize,
  ...restProps
}: PropsWithChildren<TextareaControllerProps<FormValues>>) {
  return (
    <Controller<FormValues>
      control={control}
      name={name}
      render={({ field, formState }) => (
        <Form.Item
          {...controllerToFormItemProps({ field, formState }, restProps)}
        >
          <TextArea
            placeholder={placeholder}
            autoSize={autoSize}
            {...field}
            value={field.value as string}
          />
        </Form.Item>
      )}
    />
  );
}

export default TextareaController;

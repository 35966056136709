import { GraphQLClient } from "graphql-request";
import { loader } from "graphql.macro";

import { AllLearningProfilesQuery } from "./graphql/generated/operations";
import { LearningProfile } from "./graphql/generated/types";
import { SWRPayload } from "./graphql/swrpayload";

const allLearningProfilesQuery = loader(
  "./graphql/query/allLearningProfiles.graphql"
);

type AllLearningProfilesResult = LearningProfile[];

export function allLearningProfilesRequest(
  client: GraphQLClient | undefined
): SWRPayload<AllLearningProfilesResult> {
  return [
    () =>
      client ? (allLearningProfilesQuery.loc?.source.body as string) : null,
    async () => {
      const result = await client?.request<AllLearningProfilesQuery>(
        allLearningProfilesQuery
      );
      return result?.allLearningProfiles as AllLearningProfilesResult;
    },
  ];
}

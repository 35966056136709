import { GraphQLClient } from "graphql-request";
import { useLocation } from "react-router-dom";
import useSWR from "swr";

export const composeCohortID = (year: string, groupSequence: string) => {
  if (year === "" || groupSequence === "") {
    return "";
  } else {
    return `${year}-${groupSequence}`;
  }
};

export function ParseQuery() {
  return new URLSearchParams(useLocation().search);
}
const initialYear = 2021;
const currentYear = new Date().getFullYear();
const yearList = [];

for (let year = initialYear; year < currentYear + 1; year++) {
  for (let cohort = 0; cohort < 6; cohort++) {
    yearList.push(`${year}-0${cohort + 1}`);
  }
}

export const cohortYearId = yearList;

export const sanitizeString = (str: string): string => {
  const inputStr = escape(str);
  return inputStr.replace(/[^a-zA-Z0-9 ]/g, "");
};
type DateUtil = {
  date: string;
  time: string;
  year: string;
  month: string;
  day: string;
  hour: string;
  minutes: string;
  seconds: string;
  dateString: string;
  newDateObject: Date;
};
export const getDateByStringJSON = (jsonDate: string): DateUtil => {
  const arrayDateTime = jsonDate.split("T");
  const date = arrayDateTime[0];
  const time = arrayDateTime[1];
  const dateArray = date.split("-");
  const timeArray = date.split(":");
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  const hour = timeArray[0];
  const minutes = timeArray[1];
  const seconds = timeArray[2];
  const newDateObject = new Date(jsonDate);

  return {
    date,
    time,
    year,
    month,
    day,
    hour,
    minutes,
    seconds,
    dateString: `${year}-${month}-${day}`,
    newDateObject,
  };
};

export const onPressBack = (action: () => void) => {
  action();
};
type callbackFuntion = {
  cacheKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetch: () => Promise<any>;
};
export function useSWRHooks(
  client: GraphQLClient | undefined,
  fetcherCallback: () => callbackFuntion
) {
  const { data, error } = useSWR(
    (client && fetcherCallback().cacheKey) || null,
    (client && fetcherCallback().fetch) || null
  );
  return { data, error };
}

export function downloadFileFromBlob(fileName: string, blob: Blob) {
  const url = window?.URL?.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 200);
}

import { loader } from "graphql.macro";
import { useState } from "react";

import { useService } from "@libs/hooks/useService";
import {
  GradePeerGroupAssessmentMilestoneMutation,
  GradePeerGroupAssessmentMilestoneMutationVariables,
} from "@libs/service/graphql/generated/operations";

const query = loader(
  "../../lib/service/graphql/mutation/gradePeerGroupAssessmentMilestone.graphql"
);
const useGradePeerGroupAssessmentMilestone = () => {
  const { apigatewayClient: client } = useService();
  const [isLoading, setIsLoading] = useState(false);

  const handleMutate = async (
    variables: GradePeerGroupAssessmentMilestoneMutationVariables
  ) => {
    setIsLoading(true);
    try {
      const result = await client?.request<
        GradePeerGroupAssessmentMilestoneMutation,
        GradePeerGroupAssessmentMilestoneMutationVariables
      >(query, variables);
      return result?.gradePeerGroupAssessmentMilestone;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    mutate: handleMutate,
  };
};

export default useGradePeerGroupAssessmentMilestone;

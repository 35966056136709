import { MilestoneEntity } from "@/types/milestone";
import yup from "@/yup-extended";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal, Tag, notification } from "antd";
import cx from "clsx";
import { ClientError } from "graphql-request";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";

import DatePickerController from "@components/hookform/DatePickerController";
import InputController from "@components/hookform/InputController";
import SelectController, {
  SelectOptions,
} from "@components/hookform/SelectController";

import useGetAllUserProfiles from "@hooks/query/useAllUserProfiles";
import useChallenge from "@hooks/query/useChallenge";
import useValidatePeerGroupMembers from "@hooks/query/useValidatePeerGroupMembers";
import useCreatePeerGroup from "@hooks/useCreatePeerGroup";
import useDisclosure from "@hooks/useDisclosure";

import { composeUserProfiles } from "@libs/utils/user-profile";

import MilestoneDueDatesFieldArray, {
  MilestoneDueDateInput,
  milestoneDueDatesValidation,
} from "./MilestoneDueDatesFieldArray";
import { calculateDueDates } from "./helpers/calculate-due-dates";

type FormValues = {
  name: string;
  userSubs: string[];
  challengeId: string;
  startDate: string;
  dueDates: MilestoneDueDateInput[];

  // Hack to persist the errors
  // https://react-hook-form.com/api/useform/seterror
  // An error that is not associated with an input field will be persisted until cleared with clearErrors.
  userSubsError?: string;
};

const MIN_MEMBERS_LENGTH = 2;

const validationSchema = yup
  .object()
  .shape({
    challengeId: yup.string().required(),
    name: yup.string().required("Peer group name is required"),
    startDate: yup.string().required("Start date is required"),
    userSubs: yup
      .array()
      .required("Please select users")
      .of(yup.string())
      .min(
        MIN_MEMBERS_LENGTH,
        (ctx) => `Must have at least ${ctx.min} users selected.`
      ),
    dueDates: milestoneDueDatesValidation,
  })
  .required();

type Props = ReturnType<typeof useDisclosure> & {
  challengeId: string;
};
const CreatePeerGroupModal: FC<Props> = ({ challengeId, isOpen, onClose }) => {
  const { data: users } = useGetAllUserProfiles();
  const customDueDates = useDisclosure();
  const { challenge } = useChallenge(challengeId);
  const createPeerGroup = useCreatePeerGroup();
  const validateMembers = useValidatePeerGroupMembers();
  const {
    reset,
    handleSubmit,
    control,
    setError,
    setValue,
    clearErrors,
    formState,
    watch,
    trigger,
  } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      challengeId,
      name: "",
      userSubs: [],
      startDate: "",
      dueDates: [],
    },
  });

  const userSubs = watch("userSubs");
  const startDate = watch("startDate");

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createPeerGroup.mutate({
        ...data,
        disableDueDateCalculation: true,
      });

      notification.success({
        message: "Create peer group success",
        description: "Your peer group has been created. ",
        placement: "topRight",
      });

      onClose();
    } catch (err: unknown) {
      console.error("create peer group error: ", err);
    }
  });

  const { errors, isValidating } = formState;

  const formStateUserSubsError = errors?.userSubsError;
  const [userSubsErrorMessage, userSubsError] =
    formStateUserSubsError?.message?.split(":") || [];
  const userSubsErrors = userSubsError?.split(",")?.map((item) => item?.trim());

  useEffect(() => {
    if (isOpen) {
      reset();
      customDueDates.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isValidating || userSubs?.length < MIN_MEMBERS_LENGTH) return;
    async function validateUserSubs() {
      try {
        await validateMembers.refetch({ challengeId, userSubs });
      } catch (err: unknown) {
        const errors = (err as ClientError)?.response?.errors;
        if ((errors?.length || 0) > 0) {
          errors?.forEach((error) => {
            setError("userSubsError", error);
          });
        }
      }
    }

    validateUserSubs();
  }, [userSubs, challengeId, isValidating, validateMembers, setError]);

  useEffect(() => {
    if (intersection(userSubs, userSubsErrors)?.length === 0) {
      clearErrors("userSubsError");
    }
  }, [userSubs, userSubsErrors, clearErrors]);

  useEffect(() => {
    if (!startDate) return;
    const milestones = calculateDueDates(
      new Date(startDate),
      challenge.attributes?.v2Milestones?.data as MilestoneEntity[]
    );
    if (milestones?.length < 1) return;
    setValue(
      "dueDates",
      milestones?.map((item) => ({
        milestoneId: item.id as string,
        dueDate: item.dueDate,
      }))
    );
  }, [setValue, startDate, challenge]);

  const handleNext = () =>
    trigger(["name", "userSubs", "startDate"]).then((isValid) =>
      isValid ? customDueDates.onOpen() : null
    );

  return (
    <Modal
      maskClosable={false}
      title="Create New Peer Group"
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={createPeerGroup.isLoading}
          disabled={!isEmpty(errors)}
          onClick={customDueDates.isOpen ? onSubmit : handleNext}
        >
          {customDueDates.isOpen ? "Create Peer Group" : "Next"}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <div className={cx({ hidden: customDueDates.isOpen })}>
          <InputController
            control={control}
            name="name"
            placeholder="Peer Group Name"
            required={true}
          />

          <SelectController
            control={control}
            name="userSubs"
            required={true}
            style={{ width: "100%" }}
            options={
              composeUserProfiles(users)?.map((user) => ({
                label: `${user.name} [${user.email ?? "No Email"}]`,
                value: user.userId,
              })) as SelectOptions
            }
            mode="multiple"
            allowClear
            placeholder="Select users"
            tagRender={(props) => (
              <Tag
                color={
                  userSubsErrors?.includes(props.value.toString())
                    ? "error"
                    : "default"
                }
                {...props}
              >
                {props.label}
              </Tag>
            )}
            validateStatus={formStateUserSubsError ? "error" : undefined}
            help={userSubsErrorMessage}
          />

          <DatePickerController
            control={control}
            name="startDate"
            style={{ width: "100%" }}
            required={true}
          />
        </div>

        <div className={cx({ hidden: !customDueDates.isOpen })}>
          <MilestoneDueDatesFieldArray
            name="dueDates"
            // @ts-expect-error because this should be solved after upgrading to RHF v8
            control={control}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreatePeerGroupModal;

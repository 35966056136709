import { GraphQLClient } from "graphql-request";
import { loader } from "graphql.macro";
import { mutate } from "swr";

import {
  AllUsersFromSoloChallengeQuery,
  AllUsersFromSoloChallengeQueryVariables,
  AssignReviewerMutation,
  AssignReviewerMutationVariables,
  InitSoloChallengeRecordMutation,
  InitSoloChallengeRecordMutationVariables,
  SoloChallengeDetailsQuery,
  SoloChallengeDetailsQueryVariables,
  SoloChallengeForUserQuery,
  SoloChallengeForUserQueryVariables,
  SoloChallengeUserDetailQuery,
  SoloChallengeUserDetailQueryVariables,
  SoloChallengesQuery,
  SoloChallengesQueryVariables,
  SubmitGradedReviewMutation,
  SubmitGradedReviewMutationVariables,
} from "./graphql/generated/operations";
import {
  SoloChallengeCriterionEvaluationInput,
  SoloChallengeEntity,
  SoloChallengeEvaluation,
} from "./graphql/generated/types";
import { SWRConnector } from "./graphql/swrpayload";

const initSoloChallengeRecordMutation = loader(
  "./graphql/mutation/initSoloChallengeRecord.graphql"
);

const assignReviewerMutation = loader(
  "./graphql/mutation/assignreviewer.graphql"
);

const submitGradedReviewMutation = loader(
  "./graphql/mutation/submitGradedReview.graphql"
);

const soloChallengesQuery = loader("./graphql/query/solochallenges.graphql");
const soloChallengesQueryV2 = loader(
  "./graphql/query/soloChallengesV2.graphql"
);
const allUsersFromSoloChallengeQuery = loader(
  "./graphql/query/allUsersFromSoloChallenge.graphql"
);
const soloChallengeCriteriaQuery = loader(
  "./graphql/query/soloChallengeCriteria.graphql"
);
const soloChallengeDetailsQuery = loader(
  "./graphql/query/solochallengedetails.graphql"
);
const soloChallengesForUserQuery = loader(
  "./graphql/query/solochallengeforuser.graphql"
);
const soloChallengeUserDetailQuery = loader(
  "./graphql/query/soloChallengeUserDetail.graphql"
);

export async function initSoloChallengeRecord(
  graphQLClient: GraphQLClient,
  soloChallengeId: string,
  otherUserSub: string
): Promise<InitSoloChallengeRecordMutation> {
  const response = await graphQLClient.request<
    InitSoloChallengeRecordMutation,
    InitSoloChallengeRecordMutationVariables
  >(initSoloChallengeRecordMutation, {
    soloChallengeId,
    otherUserSub,
  });
  const cacheKey = `soloChallengesForUserQuery-${otherUserSub}`;

  await mutate(cacheKey, (currentValue: Array<SoloChallengeEvaluation>) => {
    if (currentValue) {
      return currentValue.map((it: SoloChallengeEvaluation) => {
        if (it.id === soloChallengeId) {
          return {
            ...it,
            gradingStatus:
              response.learningProfile?.soloChallenge?.initSoloChallengeFolder
                ?.gradingStatus,
          };
        } else {
          return it;
        }
      });
    }
  });

  return response;
}

export function soloChallengesRequest(
  graphQLClient: GraphQLClient,
  ids: string[] = []
): SWRConnector<SoloChallengeEntity[]> {
  return {
    cacheKey: ["soloChallengesQuery", ...ids].join("."),
    fetch: () =>
      graphQLClient
        .request<SoloChallengesQuery, SoloChallengesQueryVariables>(
          soloChallengesQuery,
          {
            ids,
            limit: ids.length,
          }
        )
        .then((result) => {
          return result.soloChallenges?.data as SoloChallengeEntity[];
        }),
  };
}

export function soloChallengesRequestV2(
  graphQLClient: GraphQLClient,
  sort: string,
  limit: number,
  start: number
): SWRConnector<SoloChallengeEntity[]> {
  return {
    cacheKey: "soloChallengesQuery",
    fetch: () =>
      graphQLClient
        .request<SoloChallengesQuery, SoloChallengesQueryVariables>(
          soloChallengesQueryV2,
          { sort, limit, start }
        )
        .then((result) => {
          return result.soloChallenges?.data as SoloChallengeEntity[];
        }),
  };
}

export function allUsersFromSoloChallengeRequest(
  graphQLClient: GraphQLClient,
  soloChallengeId: string
): SWRConnector<SoloChallengeEvaluation[]> {
  return {
    cacheKey: `allUsersFromSoloChallenge-${soloChallengeId}`,
    fetch: () =>
      graphQLClient
        .request<
          AllUsersFromSoloChallengeQuery,
          AllUsersFromSoloChallengeQueryVariables
        >(allUsersFromSoloChallengeQuery, { soloChallengeId })
        .then((result) => {
          return result.learningProfile.progress
            .allUsersFromSoloChallenge as SoloChallengeEvaluation[];
        }),
  };
}

export function soloChallengeDetailsRequest(
  graphQLClient: GraphQLClient,
  soloChallengeId?: string
): SWRConnector<SoloChallengeEntity> {
  return {
    cacheKey: `soloChallengeDetailsQuery-${soloChallengeId}`,
    fetch: () =>
      graphQLClient
        .request<SoloChallengeDetailsQuery, SoloChallengeDetailsQueryVariables>(
          soloChallengeDetailsQuery,
          { soloChallengeId }
        )
        .then((result) => {
          return result.soloChallenge?.data as SoloChallengeEntity;
        }),
  };
}

export function soloChallengeCriteriaRequest(
  graphQLClient: GraphQLClient,
  soloChallengeId?: string
): SWRConnector<SoloChallengeEntity> {
  return {
    cacheKey: `soloChallengeCriteriaRequest-${soloChallengeId}`,
    fetch: () =>
      graphQLClient
        .request<SoloChallengeDetailsQuery, SoloChallengeDetailsQueryVariables>(
          soloChallengeCriteriaQuery,
          { soloChallengeId }
        )
        .then((result) => {
          return result.soloChallenge?.data as SoloChallengeEntity;
        }),
  };
}

export function soloChallengesForUserRequest(
  graphQLClient: GraphQLClient,
  userId?: string
): SWRConnector<SoloChallengeEvaluation[]> {
  return {
    cacheKey: `soloChallengesForUserQuery-${userId}`,
    fetch: () =>
      graphQLClient
        .request<SoloChallengeForUserQuery, SoloChallengeForUserQueryVariables>(
          soloChallengesForUserQuery,
          { userId }
        )
        .then((result) => {
          return result.learningProfile.progress
            .soloChallengesForUser as SoloChallengeEvaluation[];
        }),
  };
}

export function soloChallengeUserDetailRequest(
  graphQLClient: GraphQLClient,
  userId?: string,
  soloChallengeId?: string
): SWRConnector<SoloChallengeEvaluation> {
  return {
    cacheKey: `soloChallengeUserDetailQuery-${userId}-${soloChallengeId}`,
    fetch: () =>
      graphQLClient
        .request<
          SoloChallengeUserDetailQuery,
          SoloChallengeUserDetailQueryVariables
        >(soloChallengeUserDetailQuery, { userId, soloChallengeId })
        .then((result) => {
          return result.learningProfile.progress
            .soloChallengeForUser as SoloChallengeEvaluation;
        }),
  };
}

export async function assignReviewer(
  graphQLClient: GraphQLClient,
  soloChallengeId: string,
  revieweeId: string,
  reviewerId: string
): Promise<AssignReviewerMutation> {
  const response = await graphQLClient.request<
    AssignReviewerMutation,
    AssignReviewerMutationVariables
  >(assignReviewerMutation, {
    soloChallengeId,
    revieweeId,
    reviewerId,
  });

  if (
    response?.learningProfile?.soloChallenge?.assignReviewer?.reviewer?.name
  ) {
    const cacheKey = `soloChallengesForUserQuery-${revieweeId}`;
    // mutate local cache instead of refetching
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await mutate(cacheKey, (currentValue: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return currentValue.map((it: any) => {
        if (
          it.id === response?.learningProfile?.soloChallenge?.assignReviewer?.id
        ) {
          return {
            ...it,
            reviewerSub:
              response?.learningProfile?.soloChallenge?.assignReviewer
                ?.reviewerSub,
            reviewer:
              response?.learningProfile?.soloChallenge?.assignReviewer
                ?.reviewer,
          };
        } else {
          return it;
        }
      });
    });
    return response;
  } else {
    throw new Error("Reviewer name is not exist");
  }
}

export async function submitGradedReview(
  graphQLClient: GraphQLClient,
  soloChallengeId: string,
  revieweeSub: string,
  reviewRequestId: string,
  criteria: Array<SoloChallengeCriterionEvaluationInput>
): Promise<SubmitGradedReviewMutation> {
  const response = await graphQLClient.request<
    SubmitGradedReviewMutation,
    SubmitGradedReviewMutationVariables
  >(submitGradedReviewMutation, {
    soloChallengeId,
    revieweeSub,
    reviewRequestId,
    criteria,
  });

  if (
    response.learningProfile?.soloChallenge?.submitGradedReview?.gradingStatus
  ) {
    const cacheKey = `${soloChallengesForUserQuery.loc?.source.body}-${revieweeSub}`;
    const cacheKeyUser = `${soloChallengeUserDetailQuery.loc?.source.body}-${revieweeSub}`;
    // mutate local cache instead of refetching
    await mutate(cacheKeyUser, (currentValue: SoloChallengeEvaluation) => {
      if (currentValue) {
        return {
          ...currentValue,
          gradedReviews: {
            ...currentValue.gradedReviews,
            criteria:
              response.learningProfile?.soloChallenge?.submitGradedReview
                ?.criteria,
          },
        };
      }
    });
    await mutate(
      cacheKey,
      (currentValue: Array<SoloChallengeEvaluation> | undefined) => {
        const value = currentValue ?? [];
        return value.map((it: SoloChallengeEvaluation) => {
          if (it.id === soloChallengeId) {
            return {
              ...it,
              gradingStatus:
                response.learningProfile?.soloChallenge?.submitGradedReview
                  ?.gradingStatus,
            };
          } else {
            return it;
          }
        });
      }
    );
    return response;
  } else {
    throw new Error("Learning Profile not existing");
  }
}

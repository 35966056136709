import { FormItemProps } from "antd";
import { get, identity, pickBy } from "lodash";
import { FieldError, UseControllerReturn } from "react-hook-form";

export function controllerToFormItemProps(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller: Partial<UseControllerReturn<any, any>>,
  fromProps?: Partial<FormItemProps>
): FormItemProps {
  const { field, formState } = controller;

  const current = get(formState?.errors, field?.name) as FieldError;

  return {
    name: field?.name,
    rules: [{ required: fromProps?.required && current?.type === "required" }],
    validateStatus: get(formState?.errors, field?.name) ? "error" : "success",
    help: current?.message,

    // A workaround fix to prevent ant-design overrides the `value` field.
    valuePropName: "fix-ant-value",

    ...pickBy(fromProps, identity),
  };
}

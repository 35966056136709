import { SyncOutlined } from "@ant-design/icons";
import styles from "@css/common.module.scss";
import { Typography } from "antd";
import { FC, memo } from "react";

import DocumentTitle from "@components/DocumentTitle";

import usePeerGroup from "@hooks/query/usePeerGroup";

import { arrayToPageTitle } from "@libs/utils/common";

import UserChallengesTable from "./UserChallengesTable";

type Props = {
  challengeId: string;
  peerGroupId: string;
};
const PeerGroupUserChallenges: FC<Props> = ({ challengeId, peerGroupId }) => {
  const { peerGroup, isLoading } = usePeerGroup(challengeId, peerGroupId);

  const title = arrayToPageTitle([peerGroup?.name, "Challenge Records"]);

  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <div className={styles.pageTitle}>
        <Typography.Title level={3}>
          {isLoading && <SyncOutlined spin />}
          {!isLoading && title}
        </Typography.Title>
      </div>
      <UserChallengesTable challengeId={challengeId} id={peerGroupId} />
    </>
  );
};

export default memo(PeerGroupUserChallenges);

import { Collapse, Skeleton, Typography } from "antd";
import cx from "clsx";
import { FC, memo, useEffect, useMemo, useState } from "react";

import useCohortDashboardStructure from "@hooks/query/useCohortDashboardStructure";
import useListLearnersForSameIntake from "@hooks/query/useListLearnersForSameIntake";
import useUserProfilesByUserIds from "@hooks/query/useUserProfilesByUserIds";

import { StaticChallenge } from "@libs/service/graphql/generated/types";
import { EnrolmentStatus } from "@libs/service/intake-graphql/generated/types.d";
import { UserProfile } from "@libs/service/user-graphql/generated/types";
import { sift } from "@libs/utils/array";

import ChallengeOverviewTable from "./ChallengeOverviewTable";
import CourseOverviewTable from "./CourseOverviewTable";
import styles from "./cohort-dashboard.module.scss";

const { Panel } = Collapse;

type Props = { curriculumId: string; intakeId: string };
const CurriculumCohortDashbhoard: FC<Props> = ({ curriculumId, intakeId }) => {
  const [learnerSubs, setLearnerSubs] = useState<string[]>([]);

  const dashboardStructure = useCohortDashboardStructure(curriculumId);
  const enrollments = useListLearnersForSameIntake(intakeId, {
    status: [EnrolmentStatus.Admitted, EnrolmentStatus.Completed],
  });
  const usersBySubs = useUserProfilesByUserIds(learnerSubs);

  const learners = useMemo(() => {
    if (enrollments.isLoading || usersBySubs.isLoading) return [];
    return enrollments.data?.map((enrollment) => {
      const user = usersBySubs.data?.find(
        (user) => user.userId === enrollment.learnerId
      );

      let learner = {} as UserProfile;
      if (user) {
        /**
         * TODO: Refactor to use the `composeUserProfile` function from `src/lib/utils/user-profile.ts`
         */
        learner.userId = user.userId;

        // From the https://tomorrows-education.atlassian.net/browse/LRN-572 AC:
        // identified by full name. composed from first and last name
        const composedUserFullName = sift([user.givenName, user.familyName]);
        if (composedUserFullName.length > 0) {
          learner.name = composedUserFullName.join(" ");
        }
        // falling back to fullName
        else if (user.name) {
          learner.name = user.name;
        }
        // or finally email
        else if (user.email) {
          learner.name = user.email;
        } else {
          learner.name = user.userId;
        }
      } else {
        learner = {
          userId: enrollment.learnerId,
          name: enrollment.learnerId,
        };
      }

      return learner;
    });
  }, [enrollments, usersBySubs]);

  useEffect(() => {
    if (enrollments.isLoading) {
      // When the loading starts we need to make sure
      // we have clean up the list of learner subs
      // from the previous fetch.
      if (learnerSubs?.length > 0) {
        setLearnerSubs([]);
      }

      return;
    }

    if (!learnerSubs?.length) {
      const subs = enrollments.data?.map(
        (enrollment) => enrollment.learnerId
      ) as string[];
      setLearnerSubs(subs);
    }
  }, [enrollments, learnerSubs]);

  return (
    <Skeleton active loading={dashboardStructure.isLoading}>
      {dashboardStructure.data?.phases?.map((phase) => (
        <div className={cx("tou", styles.phase)} key={phase?.id}>
          <Typography.Title level={4}>{phase?.name}</Typography.Title>
          <div className={styles.phaseContent}>
            <Collapse
              key="course-collapsible"
              className={styles.collapsible}
              destroyInactivePanel
              bordered={false}
              expandIconPosition="end"
            >
              {phase?.courses?.map((item) => (
                <Panel
                  className={styles.panel}
                  header={item?.name}
                  key={`course-${item?.id}`}
                >
                  <CourseOverviewTable
                    key={`course-table-${item?.id}`}
                    learners={learners}
                    soloChallenges={item?.soloChallenges as StaticChallenge[]}
                    groupChallenge={item?.groupChallenge as StaticChallenge}
                  />
                </Panel>
              ))}
            </Collapse>

            <Collapse
              key="challenge-collapsible"
              className={styles.collapsible}
              destroyInactivePanel
              bordered={false}
              expandIconPosition="end"
            >
              {phase?.challenges?.map((item) => (
                <Panel
                  className={styles.panel}
                  header={item?.name}
                  key={`challenge-${item?.id}`}
                >
                  <ChallengeOverviewTable
                    key={`challenge-table-${item?.id}`}
                    challengeId={item?.id as string}
                    learners={learners}
                  />
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      ))}
    </Skeleton>
  );
};

export default memo(CurriculumCohortDashbhoard);

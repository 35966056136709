import { Auth0Provider } from "@auth0/auth0-react";
import "@css/App.css";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "fontsource-roboto";
import { createBrowserHistory } from "history";
import { Helmet } from "react-helmet";
import { IconContext } from "react-icons";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  matchPath,
} from "react-router-dom";

import { AppRoutes } from "./app-routes";
import { ServiceProvider } from "./lib/hooks/useService";

export const history = createBrowserHistory();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_ADMINUI_SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          AppRoutes.map((route) => ({ path: route.path })),
          matchPath
        ),
      }),
    ],
    environment: process.env.REACT_APP_BUILD_ENV || "development",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
}

function App() {
  const onRedirectCallback = () => {
    history.push("/main");
  };
  return (
    <div className="App">
      <IconContext.Provider value={{ className: "anticon", color: "#333" }}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
          redirectUri={`${window.location.protocol}//${window.location.host}/`}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
          scope="staff progress admin"
          onRedirectCallback={onRedirectCallback}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <Helmet>
            <title>Campus</title>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/favicons/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicons/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicons/favicon-16x16.png"
            />
            <link rel="manifest" href="/favicons/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/favicons/safari-pinned-tab.svg"
              color="#ffffff"
            />
            <link rel="shortcut icon" href="/favicons/favicon.ico" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-config"
              content="/favicons/browserconfig.xml"
            />
          </Helmet>
          <ServiceProvider>
            <Router>
              <Switch>
                {AppRoutes.map((route, i) => (
                  <Route key={`${route.path}-${i}`} {...route} />
                ))}
              </Switch>
            </Router>
          </ServiceProvider>
        </Auth0Provider>
      </IconContext.Provider>
    </div>
  );
}

export default App;

export const GRADE_OPTIONS = [
  {
    label: "1 - Above expectations",
    value: 1,
  },
  {
    label: "2 - Meets criterion",
    value: 2,
  },
  {
    label: "3 - Satisfies criterion mostly",
    value: 3,
  },
  {
    label: "4 - Satisfies criterion roughly",
    value: 4,
  },
  {
    label: "5 - Doesn't satisfy criterion",
    value: 5,
  },
];

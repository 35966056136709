import { GraphQLClient } from "graphql-request";
import { loader } from "graphql.macro";

import {
  AdminUtilsQuery,
  AdminUtilsQueryVariables,
} from "./graphql/generated/operations";
import { UserProfile } from "./graphql/generated/types";
import { SWRConnector } from "./graphql/swrpayload";

const adminUtilsQuery = loader("./graphql/query/allusers.graphql");

export type AdminUtilsResult = Array<UserProfile>;

export function allUsersRequest(
  graphQLClient: GraphQLClient,
  UserId?: string | never
): SWRConnector<AdminUtilsResult> {
  return {
    cacheKey: adminUtilsQuery.loc?.source.body + "", // TODO: add random string at the end
    fetch: () =>
      graphQLClient
        .request<AdminUtilsQuery, AdminUtilsQueryVariables>(adminUtilsQuery, {
          UserId,
        })
        .then((result) => {
          return (result.adminUtils?.users || []) as AdminUtilsResult;
        }),
  };
}

import { loader } from "graphql.macro";
import qs from "query-string";
import useSWR from "swr";

import { useService } from "@libs/hooks/useService";
import {
  UserMilestonesByUserIdQuery,
  UserMilestonesByUserIdQueryVariables,
} from "@libs/service/graphql/generated/operations";
import { UserMilestone } from "@libs/service/graphql/generated/types";

const query = loader(
  "../../lib/service/graphql/query/userMilestonesByUserId.graphql"
);

export const createGetUserMilestonesByUserCacheKey = (
  body: UserMilestonesByUserIdQueryVariables
) => `getUserMilestonesByUserId?${qs.stringify(body)}`;

const useUserMilestonesByUserId = (challengeId: string, id: string) => {
  const { apigatewayClient: client } = useService();
  const reqBody: UserMilestonesByUserIdQueryVariables = {
    challengeId,
    id,
  };
  const { data, error } = useSWR(
    client && challengeId && id
      ? createGetUserMilestonesByUserCacheKey(reqBody)
      : null,
    () =>
      client
        ?.request<
          UserMilestonesByUserIdQuery,
          UserMilestonesByUserIdQueryVariables
        >(query, reqBody)
        .then((result) => {
          return result.userMilestonesByUserId;
        })
  );

  return {
    userMilestones: data as UserMilestone[],
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUserMilestonesByUserId;

import { APP_ROUTES } from "@/routes";
import styles from "@css/common.module.scss";
import { Button, Space, Tag } from "antd";
import base64 from "base-64";
import { GraphQLClient } from "graphql-request";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import AssignReviewerModal from "@components/challenges/AssignReviewerModal";
import ChallengeGradingStatusTag from "@components/challenges/ChallengeGradingStatusTag";
import {
  NamedSoloChallengeEvaluation,
  SoloChallengeGradingStatusLocal,
} from "@components/challenges/UserSoloChallenges/types";
import { mapSoloChallengeProgressWithTemplateV2 } from "@components/challenges/UserSoloChallenges/utilities";
import InitSoloChallengeRecordModal from "@components/challenges/initSoloChallengeRecordModal";
import GenericTable, {
  GenericTableColumn,
} from "@components/organism/GenericTable";

import { useService } from "@libs/hooks/useService";
import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";
import { SoloChallengeGradingStatus } from "@libs/service/graphql/generated/types.d";
import {
  soloChallengesForUserRequest,
  soloChallengesRequest,
} from "@libs/service/solochallenge";
import { constants } from "@libs/utils/constants";

interface UserSoloChallengesProps {
  userId: string;
  filter?: { id?: string };
  onFilterChange?: (filterId: string, value: unknown) => void;
}

const UserSoloChallenges: React.FC<UserSoloChallengesProps> = (props) => {
  const { apigatewayClient: client } = useService();
  const { data: currentUserChallenges } = useSWR(
    (client &&
      soloChallengesForUserRequest(client as GraphQLClient, props.userId)
        .cacheKey) ||
      null,
    (client &&
      soloChallengesForUserRequest(client as GraphQLClient, props.userId)
        .fetch) ||
      null
  );

  const ids = (currentUserChallenges?.map(({ id }) => id) as string[]) ?? [];

  const { data: soloChallengeTemplateData } = useSWR(
    (client && soloChallengesRequest(client as GraphQLClient, ids).cacheKey) ||
      null,
    (client && soloChallengesRequest(client as GraphQLClient, ids).fetch) ||
      null
  );
  const nonGradedSoloChallenge = soloChallengeTemplateData?.filter(
    (sc) => !sc.attributes?.graded
  );

  const { getColumnSearchProps } = useTableColumnSearch();
  const getUniqueReviewerSub = _.sortBy(
    _.uniqBy(currentUserChallenges, "reviewerSub"),
    "reviewer.name"
  );
  const columns: GenericTableColumn<NamedSoloChallengeEvaluation> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      defaultFilteredValue: props.filter?.id
        ? [props.filter?.id as string]
        : [],
      ...getColumnSearchProps("id", {
        initialValue: props?.filter?.id,
        onReset: () => props?.onFilterChange?.("id", undefined),
      }),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Graded",
      dataIndex: "graded",
      key: "graded",
      filters: [
        { text: "GRADED", value: true },
        { text: "NON-GRADED", value: false },
      ],
      onFilter(value, record: NamedSoloChallengeEvaluation) {
        if (value) {
          return record.graded;
        } else {
          return !record.graded;
        }
      },
      // eslint-disable-next-line react/display-name
      render: (text, record: NamedSoloChallengeEvaluation) =>
        record === null ? (
          <></>
        ) : record.graded ? (
          <Tag color="green">YES</Tag>
        ) : (
          <Tag color="red">NO</Tag>
        ),
    },
    {
      title: "Grading Status",
      dataIndex: "gradingStatus",
      key: "gradingStatus",
      filters: [
        {
          text: SoloChallengeGradingStatusLocal.NO_RECORD,
          value: SoloChallengeGradingStatusLocal.NO_RECORD,
        },
        {
          text: SoloChallengeGradingStatus.UnSubmitted,
          value: SoloChallengeGradingStatus.UnSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.Submitted,
          value: SoloChallengeGradingStatus.Submitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailedReSubmittable,
          value: SoloChallengeGradingStatus.GradeFailedReSubmittable,
        },
        {
          text: SoloChallengeGradingStatus.ReSubmitted,
          value: SoloChallengeGradingStatus.ReSubmitted,
        },
        {
          text: SoloChallengeGradingStatus.GradeFailed,
          value: SoloChallengeGradingStatus.GradeFailed,
        },
        {
          text: SoloChallengeGradingStatus.GradedPassed,
          value: SoloChallengeGradingStatus.GradedPassed,
        },
        {
          text: SoloChallengeGradingStatus.PeerReviewed,
          value: SoloChallengeGradingStatus.PeerReviewed,
        },
      ],
      onFilter: (value, record) => {
        if (value === SoloChallengeGradingStatusLocal.NO_RECORD) {
          return !record?.gradingStatus;
        } else {
          return record?.gradingStatus === value;
        }
      },
      // eslint-disable-next-line react/display-name
      render: (text, record: NamedSoloChallengeEvaluation) =>
        record === null ? (
          <></>
        ) : (
          <ChallengeGradingStatusTag status={record.gradingStatus} />
        ),
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer.name",
      key: "reviewer.name",
      filters: getUniqueReviewerSub.map((user) => {
        return {
          text: user?.reviewer?.name || "NO_REVIEWER",
          value: user?.reviewer?.name || "-",
        };
      }),

      onFilter(value: unknown, record: NamedSoloChallengeEvaluation) {
        return value === record.reviewer?.name;
      },

      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        if (!record) {
          return <></>;
        } else {
          return record.reviewerSub ? (
            <span>
              <Link
                to={APP_ROUTES.USER_DETAIL(record?.reviewerSub)}
                target={"_blank"}
                rel="noreferrer"
              >
                {record.reviewer?.name}
              </Link>
            </span>
          ) : (
            <span>-</span>
          );
        }
      },
    },
    // { TODO: update when services available
    //   title: "Reviewee",
    //   dataIndex: "reviewee",
    //   key: "reviewee",
    //   render: (text, record) => {
    //     if (!record) {
    //       return <></>;
    //     } else {
    //       const encodedUserId = base64.encode(props.userId || "");
    //       return props.userId ? (
    //         <span>
    //           <a
    //             target={"_blank"}
    //             rel="noreferrer"
    //           >
    //             {props.userId}
    //           </a>
    //         </span>
    //       ) : (
    //         <span>-</span>
    //       );
    //     }
    //   },
    // },
    {
      title: "Google Drive",
      dataIndex: "googleDriveId",
      key: "googleDriveId",
      // eslint-disable-next-line react/display-name
      render: (text, record: NamedSoloChallengeEvaluation) => {
        const isDriveExist = record.googleDriveId?.match(
          constants.gDrivePrefix
        );

        return record === null ? (
          <></>
        ) : isDriveExist ? (
          <a href={record.googleDriveId ?? ""} target="_blank" rel="noreferrer">
            See Folder
          </a>
        ) : (
          <Tag color="red">Folder Not Found</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // eslint-disable-next-line react/display-name
      render: (text, record) =>
        record === null ? (
          <></>
        ) : record.graded ? (
          <Button onClick={() => redirectToGrading(record)}>set grade</Button>
        ) : (
          <>
            <Button onClick={() => redirectToGrading(record)}>
              view detail
            </Button>
            <Button onClick={() => pickReviewer(record)}>
              assign reviewer
            </Button>
          </>
        ),
    },
  ];

  const [dataSource, setDataSource] = useState<NamedSoloChallengeEvaluation[]>(
    []
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInitRecordModal, setShowInitRecordModal] =
    useState<boolean>(false);
  const [challengeTarget, setChallengeTarget] = useState<
    NamedSoloChallengeEvaluation | undefined
  >(undefined);
  const history = useHistory();

  function pickReviewer(target: NamedSoloChallengeEvaluation) {
    setShowModal(true);
    setChallengeTarget(target);
  }

  function onAssignFinished() {
    setShowModal(false);
    setChallengeTarget(undefined);
  }

  function onAssignError() {
    setShowModal(false);
    setChallengeTarget(undefined);
  }

  function redirectToGrading(record: NamedSoloChallengeEvaluation) {
    const encodedUserId = base64.encode(props.userId);
    const encodedRecordId = base64.encode(record.id ?? "");
    history.push(`/inbox/${encodedRecordId}/${encodedUserId}`);
  }

  useEffect(() => {
    if (currentUserChallenges && soloChallengeTemplateData) {
      const mapped = mapSoloChallengeProgressWithTemplateV2(
        soloChallengeTemplateData,
        currentUserChallenges
      );
      if (mapped) {
        setDataSource(mapped);
      }
    }
  }, [currentUserChallenges, soloChallengeTemplateData, showModal]);

  return (
    <>
      <div className={styles.tableActions}>
        <Space>
          <Button
            onClick={() => {
              setShowInitRecordModal(true);
            }}
          >
            Add new record
          </Button>
        </Space>
      </div>
      <GenericTable<NamedSoloChallengeEvaluation>
        columns={columns}
        dataSource={dataSource}
        loading={!currentUserChallenges}
        rowKey={(item) => `${item.id}-${item.name}`}
      />
      <AssignReviewerModal
        show={showModal}
        target={challengeTarget}
        revieweeId={props.userId}
        onError={onAssignError}
        onFinished={onAssignFinished}
      />
      <InitSoloChallengeRecordModal
        currentSoloChallengeProgress={currentUserChallenges}
        show={showInitRecordModal}
        client={client}
        userId={props.userId}
        nonGradedSoloChallenge={nonGradedSoloChallenge || []}
        onOk={() => {
          setShowInitRecordModal(false);
        }}
        onCancel={() => {
          setShowInitRecordModal(false);
        }}
      />
    </>
  );
};

export default UserSoloChallenges;

/* eslint-disable no-case-declarations */
import { APP_ROUTES } from "@/routes";
import { NamedChallengeEvaluation } from "@/types/challenge";
import { createStyles, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import { Key } from "react";
import { useHistory } from "react-router-dom";

import GenericTable from "@components/organism/GenericTable";

import useChallenges from "@hooks/useChallenges";

import AppLayout from "@layouts/AppLayout";

import useTableColumnSearch from "@libs/hooks/useTableColumnSearch";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      paddingBottom: "70px",
    },
  })
);

const ChallengesPage = () => {
  const { getColumnSearchProps } = useTableColumnSearch();
  const history = useHistory();
  const styles = useStyles();
  const {
    challenges,
    isLoading,
    pagination,
    filters,
    handlePaginationChange,
    handleSortChange,
    handleFiltersChange,
  } = useChallenges();

  const isAdvancePagination = (pagination.count || 0) === pagination.total;

  return (
    <AppLayout
      title="Challenges"
      onPressBack={() => history.push(APP_ROUTES.HOME)}
    >
      <div className={styles.root}>
        <GenericTable<NamedChallengeEvaluation>
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              ...getColumnSearchProps("id"),
              defaultFilteredValue: filters?.id ? [filters?.id as Key] : null,
              onFilter: () => true,
            },
            {
              title: "Name",
              dataIndex: ["attributes", "name"],
              key: "name",
              sorter: true,
              ...getColumnSearchProps("name"),
              defaultFilteredValue: filters?.name
                ? [filters?.name as Key]
                : null,
              onFilter: () => true,
            },
            {
              title: "TA Group",
              dataIndex: ["attributes", "teachingAssistants"],
              key: "teachingAssistants",
              render: (value) => {
                const names = value
                  ?.map((ta: { email: string }) => ta?.email)
                  ?.filter(Boolean)
                  .join(", ");
                return <div>{names || "-"}</div>;
              },
            },
            {
              title: "Action",
              dataIndex: "action",
              key: "action",
              render: (_, record) => {
                return (
                  <Button
                    onClick={() =>
                      history.push(APP_ROUTES.CHALLENGE_DETAIL(record.id))
                    }
                  >
                    View Detail
                  </Button>
                );
              },
            },
          ]}
          dataSource={challenges as NamedChallengeEvaluation[]}
          loading={isLoading}
          rowKey={(item) => `${item.id}-${item.name}`}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: isAdvancePagination,
          }}
          onChange={(pagination, filters, sort, extra) => {
            switch (extra.action) {
              case "filter":
                return handleFiltersChange(filters);
              case "sort":
                return handleSortChange(sort);
              case "paginate":
                return handlePaginationChange(pagination);
            }
          }}
        />
      </div>
    </AppLayout>
  );
};

export default ChallengesPage;
